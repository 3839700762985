import dayjs from "dayjs";
import { TeamList } from "rx/fbListSlices";
import { TrackDecoder, TrackEncoder } from "@nutilogi/trzip";
import { timeArrayInsert } from "./timeArrayInsert";
import { defaultLocSendHost } from "./TracksDataListener";
import { loadTrakcsFromStorage } from "./TrackUtils";
import {
  getDownloadURL,
  StorageReference,
  uploadBytes,
  ref as storageRef,
  getStorage,
  listAll,
  deleteObject,
  uploadString,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  child,
  DatabaseReference,
  get,
  getDatabase,
  ref,
  remove,
  set,
} from "firebase/database";
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  GeoPoint,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore/lite";
import { Gzip } from "zlibt2";
import { enqueueSnackbar } from "notistack";
import { PointData } from "@nutilogi/nutilogitypes";

function awsleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const olddeclocdata = (buf: Uint8Array) => {
  let idx = 0;
  let jend = 0;
  let entries = [];

  while ((jend = buf.indexOf(0, idx)) !== -1) {
    const h = JSON.parse(new TextDecoder("utf-8").decode(buf.slice(idx, jend)));
    //console.log(jend);
    //console.log(h)
    idx = jend + 1;
    h.tidx = idx + h.tstart;
    h.sidx = idx + h.sstart;
    h.aidx = idx + h.astart;
    h.lidx = idx + h.lstart;
    h.gidx = idx + h.gstart;
    h.lonidx = idx + h.lonstart;
    h.latidx = idx + h.latstart;
    let getnum = (f: string) => {
      let b = 0;
      let v = 0;
      let pos = 0;
      do {
        b = buf[h[f + "idx"]];
        if (b !== 0) h[f + "idx"]++;
        v = v | ((b & 0x7f) << (7 * pos));
        pos++;
      } while (b & 0x80);
      if (v === 0) {
        const zc = (buf[h[f + "idx"] + 1] -= 1);
        if (zc === 0) h[f + "idx"] += 2;
      }
      if (v & 0x1) v = ~v;
      v = v >> 1;
      //console.log(v/h[f+'prec']);
      return v / h[f + "prec"];
    };
    let objects = [];
    let prevob: PointData = {
      x: 0,
      y: 0,
      t: Number(h.firstt - 1000),
      s: 0,
      a: 0,
      g: 0,
      l: 0,
    };
    for (let i = 0; i < h.count; i++) {
      const nob = {
        y: prevob.y + getnum("lat"),
        x: prevob.x + getnum("lon"),
        t: prevob.t + getnum("t") + 1000,
        s: prevob.s + getnum("s"),
        a: prevob.a + getnum("a"),
        g: prevob.g + getnum("g"),
        l: prevob.l + getnum("l"),
      };
      objects.push(nob);
      prevob = nob;
    }
    idx = h.lonidx;

    objects.forEach((p) => (p.s = (p.s * 3600) / 1000));
    entries.push({ tid: h.tid, devid: h.devid, points: objects });
  }
  return entries;
};

const copyFireStore = async (from: StorageReference, to: StorageReference) => {
  return getDownloadURL(from)
    .then((url) => fetch(url))
    .then((r) => r.arrayBuffer())
    .then((abuf) => uploadBytes(to, abuf));
};

export const haveDeletedEvents = async () => {
  const uid = getAuth().currentUser?.uid;
  if (!uid) return false;
  const list = await listAll(storageRef(getStorage(), "/deletedevents/" + uid));
  return list.prefixes.length > 0;
};

const deleteStoragePrefix = async (sref: StorageReference) => {
  const list = await listAll(sref);
  list.items.forEach((it) => {
    deleteObject(it);
  });
  list.prefixes.forEach((it) => deleteStoragePrefix(it));
};

const setKPAnswerCheck = async (evid: string, enabled: boolean) => {
  const db = getDatabase();

  const kplistref = ref(db, `/eventsdata/${evid}/kp`);
  const kplist = await get(kplistref);
  const kpkeys: string[] = [];
  kplist.forEach((kp) => {
    if (kp.key) kpkeys.push(kp.key);
  });
  for (let kpkey of kpkeys) {
    const kpref = child(kplistref, kpkey + "/archop");
    if (enabled) await remove(kpref);
    else await set(kpref, true);
  }
};

export const restoreDeleted = async (
  from: string,
  evid: string,
  archived: boolean = false
) => {
  if (!evid || evid === "") {
    console.error("evid should not be empty");
    return;
  }
  const db = getDatabase();
  const fs = getFirestore();

  const docref = doc(fs, "/events/" + evid);

  const lsnap = await get(ref(db, `/events/${evid}`));
  if (!archived && lsnap.exists()) {
    console.error("Can't restore event that already has entry in events");
    return false;
  }

  if ((await getDoc(docref)).exists()) {
    console.error("Firestore document already exist for event " + evid);
    return false;
  }

  const archref = storageRef(getStorage(), from + "/" + evid);
  const archlist = await listAll(archref);
  if (!archlist.items.find((v) => v.name === "evdata.json")) {
    console.error("Missing required archive file evdata.json in " + from);
    return false;
  }

  const evurl = await getDownloadURL(storageRef(archref, "evdata.json"));
  const archivedoc = await fetch(evurl).then((r) => r.json());

  if (archivedoc.evdoc) {
    await setDoc(docref, latLngFieldsToGeoPoint(archivedoc.evdoc));
  } else {
    console.warn("No firestore event document.");
  }
  // Recreate firestore track documents. Does not work with real db currently.
  /*
  for (let li of archlist.items) {
    if (!li.name.startsWith("doctracks")) continue;
    const doctracksurl = await archref.child(li.name).getDownloadURL();
    const doctracks = await fetch(doctracksurl).then((r) => r.json());
    const doctracktid = li.name.replace("doctracks-", "").replace(".json", "");
    const doctrackref = docref.collection("tracks").doc(doctracktid);
    await doctrackref.set({ d: "Dummy" });
    await saveFirestoreCollection(doctracks, doctrackref.collection("devices"));
  }
  */

  for (let l of ["events", "eventsdata", "teams"]) {
    if (archived && l === "events") continue;
    if (l === "teams" && archivedoc[l]) {
      const aent = archivedoc[l];
      for (let k of Object.keys(aent)) {
        if (k === "track" || k === "trackstat") {
          // Skip track and trackstat. Should include in track.dat file.
          continue;
        }
        if (k === "kpanswers") {
          for (let answerkey of Object.keys(aent[k])) {
            await set(
              ref(db, l + "/" + evid + "/" + k + "/" + answerkey),
              aent[k][answerkey]
            );
          }
          continue;
        }
        await set(ref(db, `${l}/${evid}/${k}`), aent[k]);
      }
    } else {
      if (l === "eventsdata") {
        Object.keys(archivedoc[l].kp).forEach((kpkey) => {
          archivedoc[l]["kp"][kpkey].archop = true;
        });
      }
      await set(ref(db, l + "/" + evid), archivedoc[l]);
    }
  }

  await setKPAnswerCheck(evid, true);

  if (!archived) await deleteStoragePrefix(archref);
  return true;
};

const deleteTracksCollection = async (col: CollectionReference) => {
  const coldata = await getDocs(col);
  for (let doc of coldata.docs) {
    deleteDoc(doc.ref);
    if (col.id in tracksMapping) {
      for (let cname in tracksMapping[col.id]) {
        deleteTracksCollection(collection(doc.ref, cname));
      }
    }
  }
};

const removeChilds = async (
  ref: DatabaseReference,
  waitms: number = 0,
  addNumTasks?: (num: number) => void,
  completeTask?: () => void
) => {
  const childs = await get(ref);
  let childkeys: string[] = [];
  childs.forEach((child) => {
    if (child.key) childkeys.push(child.key);
  });
  if (addNumTasks) addNumTasks(childkeys.length);
  for (let akey of childkeys) {
    await remove(child(ref, akey));
    await awsleep(waitms);
    if (completeTask) completeTask();
  }
};

export const clearEvent = async (
  evid: string,
  progressInfo: (value: number, msg?: string) => void,
  dodelete: boolean = false
) => {
  const db = getDatabase();
  if (evid.length === 0) return;

  const fs = getFirestore();
  let total = 10;
  let done = 0;
  const prog = (msg?: string) => {
    progressInfo(++done / (total / 100), msg);
  };
  await setKPAnswerCheck(evid, false);
  prog();

  const evdoc = doc(fs, "events", evid);
  /* Delete track docs. Can't be done automatically at the moment. Only maybe through server api */
  deleteTracksCollection(collection(evdoc, "tracks"));

  await deleteDoc(doc(fs, "events", evid));
  prog("firestore");

  const callRemoveChilds = (
    group: string,
    child: string,
    sleeptime: number
  ) => {
    return removeChilds(
      ref(db, group + "/" + evid + "/" + child),
      sleeptime,
      (add) => (total += add),
      () => prog()
    );
  };

  await callRemoveChilds("teams", "kpanswers", 100);
  prog("teams kpanswers");

  await callRemoveChilds("teams", "list", 100);
  prog("teams list");

  await remove(ref(db, `/teams/${evid}`));
  prog("teams");
  await callRemoveChilds("eventsdata", "kpanswer", 100);
  prog("eventsdata kpanswers");
  await callRemoveChilds("eventsdata", "kpdata", 100);
  prog("eventsdata kpdata");
  await callRemoveChilds("eventsdata", "kp", 100);
  prog("eventsdata kp");
  await remove(ref(db, `/eventsdata/${evid}`));
  prog("eventsdata");
  if (dodelete) await remove(ref(db, `/events/${evid}`));
  prog();
};

const latLngFieldsToGeoPoint = (data: any): any => {
  Object.keys(data).forEach((k) => {
    if (typeof data[k] !== "object") return;
    const llkeys = Object.keys(data[k]);
    if (
      llkeys.length === 2 &&
      llkeys.includes("latitude") &&
      llkeys.includes("longitude")
    ) {
      let gob = new GeoPoint(data[k]["latitude"], data[k]["longitude"]);
      data[k] = gob;
    } else {
      data[k] = latLngFieldsToGeoPoint(data[k]);
    }
  });
  return data;
};

/*
const saveFirestoreCollection = async (
  data: any,
  target: firebase.firestore.CollectionReference
) => {
  const keys = Object.keys(data);
  for (let k of keys) {
    await target.doc(k).set(latLngFieldsToGeoPoint(data[k].doc));
    for (let l of Object.keys(data[k].collections)) {
      await saveFirestoreCollection(
        data[k].collections[l],
        target.doc(k).collection(l)
      );
    }
  }
};
*/

const tracksMapping: { [f: string]: string[] } = {
  tracks: ["devices"],
  devices: ["points"],
};

const collectionToJSON = async (col: CollectionReference) => {
  const coldata = await getDocs(col);
  let resp = {} as any;
  //  let resp = { docs: {} as any, collections: {} as any };
  for (let doc of coldata.docs) {
    resp[doc.id] = { doc: doc.data(), collections: {} };
    if (col.id in tracksMapping) {
      for (let subcolid of tracksMapping[col.id]) {
        resp[doc.id].collections[subcolid] = await collectionToJSON(
          collection(doc.ref, subcolid)
        );
      }
    }
  }

  return resp;
};

/*
const addPointsToArray = (target: PointData[], source: PointData[]) => {
  if (target.length === 0) {
    target.push(...source);
    return;
  }
  let sidx = 0;
  let tidx = 0;
  while (sidx < source.length && tidx < target.length) {
    if (source[sidx].t < target[tidx].t) {
      sidx++;
      continue;
    }
    if (source[sidx].t === target[tidx].t) {
      sidx++; tidx++;
      continue;
    }
    if (target.length - 1 === tidx) {
      target.push(...source.slice(sidx));
      break;
    }
    let eidx = sidx + 1;
    while(eidx < source.length && source[sidx])
  }
}
*/

const insertPoints = (arr: PointData[], at: number, points: PointData[]) => {
  if (points.length > 65535) {
    let si = 0;
    /* Border cases here probably need testing */
    while (si < points.length) {
      arr.splice(at + si, 0, ...points.slice(si, si + 65535));
      si += 65535;
    }
  } else arr.splice(at, 0, ...points);
};

export const genArchiveDoc = async (
  evid: string,
  prog: (adddone: number, addtotal: number, msg?: string) => void
) => {
  const db = getDatabase();
  prog(0, 10);
  const events = await get(ref(db, `/events/${evid}`));
  prog(1, 0, "teams");
  const eventsdata = await get(ref(db, `/eventsdata/${evid}`)).catch((err) => {
    if (err.message === "Permission denied") {
      enqueueSnackbar("No permission. Add yoursef as admin to event", {
        variant: "error",
      });
    }
    console.error("got err", err);
    return undefined;
  });
  if (eventsdata === undefined) {
    return false;
  }

  prog(1, 0, "events");
  const teams = await get(ref(db, `/teams/${evid}`));
  prog(1, 0, "eventsdata");

  if (!eventsdata || !teams) {
    console.error("No eventsdata or teams data. Maybe already archied event");
    return false;
  }
  const evdocref = doc(getFirestore(), "events", evid);
  const evdoc = await getDoc(evdocref);
  prog(1, 0, "firestore doc");

  return {
    events: events.val(),
    eventsdata: eventsdata.val(),
    teams: teams.val(),
    evdoc: evdoc.data(),
  };
};

export const archiveEvent = async (
  evid: string,
  dest: string,
  progressInfo: (value: number, msg?: string) => void
) => {
  let total = 0;
  let done = 0;
  let prog = (adddone: number, addtotal: number, msg?: string) => {
    done += adddone;
    total += addtotal;
    progressInfo(done / (total / 100), msg);
  };

  let archivedoc = await genArchiveDoc(evid, prog);
  if (archivedoc === false) return false;

  // Move existing data to backup directory
  const archref = storageRef(getStorage(), dest + "/" + evid);
  const stlist = await listAll(archref);
  prog(1, stlist.items.length * 2);

  const backupref = storageRef(archref, dayjs().format());
  for (let ent of stlist.items) {
    await copyFireStore(ent, storageRef(backupref, ent.name));
    prog(1, 0);
    await deleteObject(ent);
    prog(1, 0, "backup archive " + ent.name);
  }

  //const savebuffer = new TextEncoder().encode(JSON.stringify(archivedoc));
  //archref.child("evdata.json").put(savebuffer);

  const metacontent = {
    archivedate: dayjs().format(),
    version: "2",
  };
  await uploadString(
    storageRef(archref, "evdata.json"),
    JSON.stringify(archivedoc),
    undefined,
    { contentType: "application/json" }
  );

  prog(1, 0);

  await uploadString(
    storageRef(archref, "archivemeta.json"),
    JSON.stringify(metacontent),
    undefined,
    { contentType: "application/json", customMetadata: metacontent }
  );

  prog(1, 0, "metadata");

  let trackdata: {
    [tid: string]: {
      [devid: string]: PointData[];
    };
  } = {};

  const teamdata =
    (archivedoc.teams?.list as { [tid: string]: TeamList }) ?? {};

  const evdocref = doc(getFirestore(), "events", evid);
  const trackdocs = await getDocs(collection(evdocref, "tracks"));
  prog(1, trackdocs.docs.length * 2);

  for (let tdoc of trackdocs.docs) {
    const teamdoctrack = await collectionToJSON(
      collection(tdoc.ref, "devices")
    );
    prog(1, 0);
    if (tdoc.id in teamdata) {
      trackdata[tdoc.id] = {};
      Object.keys(teamdoctrack).forEach((devid) => {
        trackdata[tdoc.id][devid] = [];
        const devpoints = trackdata[tdoc.id][devid];
        Object.keys(teamdoctrack[devid].collections["points"]).forEach(
          (pcol) => {
            const points =
              teamdoctrack[devid].collections["points"][pcol].doc.points;
            if (!points) return;
            Object.keys(points).forEach((tb) => {
              Object.keys(points[tb]).forEach((te) => {
                const pent = points[tb][te];
                devpoints.push({
                  t: Number(tb + te),
                  a: pent.a,
                  g: pent.g,
                  l: pent.l,
                  s: pent.s,
                  x: pent.p.longitude,
                  y: pent.p.latitude,
                });
              });
            });
          }
        );
        // Sort and remove duplicates. Can happen.
        trackdata[tdoc.id][devid] = devpoints
          .sort((a, b) => a.t - b.t)
          .filter((a, idx, arr) => {
            if (idx === 0) return true;
            return arr[idx - 1].t !== a.t;
          });
      });
    }
    const asstr = JSON.stringify(teamdoctrack);
    const buf = Buffer.alloc(asstr.length);
    for (let i = 0; i < asstr.length; i++) buf[i] = asstr.charCodeAt(i);
    const gzip = new Gzip(buf);
    const gzipped = gzip.compress();

    if (gzipped instanceof Uint8Array) {
      await uploadBytes(
        storageRef(archref, "doctracks-" + tdoc.id + ".json.gz"),
        gzipped
      );
    } else {
      console.error("Bad compressed data", gzipped);
    }
    prog(1, 0, "track from firestore " + tdoc.id);

    //return Promise.resolve(true);
  }

  prog(0, 0, "firestore complete");

  await loadTrakcsFromStorage(evid, (tid, devid, points) => {
    if (!(tid in teamdata)) {
      console.warn("have track for", tid, "but its not in the event");
      return;
    }
    if (points.length === 0) return;
    if (!(tid in trackdata)) trackdata[tid] = { [devid]: [] };
    if (!(devid in trackdata[tid])) trackdata[tid][devid] = [];
    const parray = trackdata[tid][devid];
    timeArrayInsert(parray, points, insertPoints);
  });


  const oldlisting = await listAll(storageRef(getStorage(), `/tracks/${evid}`));
  prog(1, oldlisting.items.length);

  for (let oldtrackitem of oldlisting.items) {
    await getDownloadURL(oldtrackitem).then((url) => {
      return fetch(url)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          console.log("old track data", oldtrackitem.name);

          var view = new Uint8Array(data);
          const locdata = olddeclocdata(view);
          locdata.forEach((ent) => {
            if (!(ent.tid in teamdata)) {
              return;
            }
            if (!(ent.tid in trackdata))
              trackdata[ent.tid] = { [ent.devid]: [] };
            if (!(ent.devid in trackdata[ent.tid]))
              trackdata[ent.tid][ent.devid] = [];
            if (ent.points.length > 0)
              timeArrayInsert(
                trackdata[ent.tid][ent.devid],
                ent.points,
                insertPoints
              );
          });
          console.log("old track complete", oldtrackitem.name);
        });
    });
    prog(1, 0, "oldtrack " + oldtrackitem.name);
  }

  prog(0, Object.keys(trackdata).length);
  // Data from nuilogi gps collection server
  for (let tid of Object.keys(trackdata)) {
    for (let devid of Object.keys(trackdata[tid])) {
      let url =
        "https://" +
        defaultLocSendHost +
        "/points?tid=" +
        tid +
        "&devid=" +
        devid;
      if (teamdata[tid].starttime) url += "&startt=" + teamdata[tid].starttime;
      if (teamdata[tid].finishtime) url += "&endt=" + teamdata[tid].finishtime;
      await fetch(url)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          let decodeddata = TrackDecoder.decode(Buffer.from(data));
          Object.entries(decodeddata).forEach(([rtid, tdata]) => {
            Object.entries(tdata).forEach(([rdevid, points]) => {
              if (points.length > 0) {
                timeArrayInsert(trackdata[rtid][rdevid], points, insertPoints);
              }
            });
          });
        });
    }
    prog(1, 0, "Location server " + tid);
  }

  console.log(trackdata);

  let tlist: { tid: string; devid: string }[] = [];
  Object.entries(trackdata).forEach(([tid, devs]) => {
    Object.entries(devs).forEach(([devid]) => {
      tlist.push({ tid: tid, devid: devid });
    });
  });
  tlist.forEach((tentry) => {
    const plist = trackdata[tentry.tid][tentry.devid];
    if (plist.length < 2) return;
    let prevt = plist[0].t;
    for (let idx = 1; idx < plist.length; idx++) {
      if (prevt >= plist[idx].t) {
        console.error(
          "Bad time order at ",
          idx,
          "tid:" + tentry.tid,
          "devid: " + tentry.devid
        );
      }
      prevt = plist[idx].t;
    }
  });
  let trackbuffer = Buffer.concat(
    tlist.map((e) => {
      const encoder = new TrackEncoder(e);
      encoder.addPoints(trackdata[e.tid][e.devid]);
      return encoder.encodedBuffer();
    })
  );
  await uploadBytes(storageRef(archref, "tracks.dat"), trackbuffer);
  prog(1, 0, "tracks.dat");
  /*
  let trackbuffer: Buffer = Buffer.from('');

  Object.entries(trackdata).forEach(([tid, devs]) => {
    Object.entries(devs).forEach(([devid, points]) => {
      const encoder = new TrackEncoder({ tid: tid, devid: devid });
      encoder.addPoints(points);
      trackbuffer = Buffer.concat([trackbuffer, encoder.encodedBuffer()]);
    })
  })
  */

  // TODO delete all archived content.

  return Promise.resolve(true);
};
