import React from "react";
import { useSelector } from "react-redux";
import { drawerMenuContentTypes } from "rx/appStateSlice";
import { RootState, useTSelector } from "rx/store";
import Track from "./Track";

const TracksManager: React.FC = () => {
  const teamsList = useSelector((state: RootState) => state.teamsList);
  const hidetracks = useTSelector((state) => state.event.hidetracks);
  const eventAccess = useTSelector((state) => state.user.eventAccess);
  const hiddenTracks = useSelector(
    (state: RootState) => state.appState.hiddenTracks
  );
  const menuContent = useTSelector((state) => state.appState.drawerMenuContent);
  if (hidetracks && !eventAccess) return null;
  const tracks: JSX.Element[] = [];
  if (menuContent === drawerMenuContentTypes.Default) {
    Object.entries(teamsList).forEach(([tid, team]) => {
      if (team.disabled) return;
      if (hiddenTracks[tid]) return;
      if (Object.keys(team.devs || {}).length === 0) return;
      const devid = team.defaultdev || Object.keys(team.devs)[0];
      tracks.push(<Track key={tid + devid} tid={tid} devid={devid} />);
    });
  }

  return <div>{tracks}</div>;
};

export default TracksManager;
