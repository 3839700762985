import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import BaseTable, { AutoResizer, ColumnShape } from "react-base-table";
import { green, red } from "@mui/material/colors";
import { withNL, WithNLProps } from "utils/NLContext";
import { Cancel } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

type TeamDataEntry = {
  tid: string;
  name: string | undefined;
  allowed: boolean;
};
const mapState = (state: RootState) => ({
  teams: state.teamsList,
});
const mapDispatch = {
  closeDialog: () => closeDialog("teamsAllow"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TeamsAllowProps = PropsFromRedux &
  WithTranslation &
  WithNLProps & {
    eventId: string;
  };
type TeamsAllowState = {
  filterStr: string;
};
class TeamsAllow extends React.Component<TeamsAllowProps, TeamsAllowState> {
  state: TeamsAllowState = {
    filterStr: "",
  };
  render() {
    const { t, teams, fbSet, eventId } = this.props;
    const columns: ColumnShape<TeamDataEntry>[] = [
      {
        key: "name",
        dataKey: "name",
        width: 200,
        title: t("teamsallow.teamheader"),
      },
      {
        key: "allowed",
        width: 200,
        title: t("teamsallow.allowedheader"),
        cellRenderer: ({ rowData }) => {
          return (
            <Button
              key={rowData.tid}
              variant="contained"
              className={rowData.allowed ? "allowedbutton" : "notallowedbutton"}
              onClick={() => {
                fbSet(
                  "/teams/" + eventId + "/list/" + rowData.tid + "/allowed",
                  !rowData.allowed
                );
              }}
            >
              {rowData.allowed
                ? t("teamsallow.button.allow")
                : t("teamsallow.button.notallow")}
            </Button>
          );
        },
      },
    ];
    const filterStr = this.state.filterStr.toLowerCase();
    const tids = Object.keys(teams)
      .filter((tid) => !teams[tid].disabled)
      .filter((tid) => {
        if (filterStr.trim().length === 0) return true;
        if (teams[tid].name?.toLowerCase().includes(filterStr)) return true;
        return false;
      })
      .sort((l, r) => {
        if (teams[l].allowed !== teams[r].allowed) {
          return teams[l].allowed ? 1 : -1;
        }
        return teams[l].name?.localeCompare(teams[r].name || "") || 0;
      });
    const data: TeamDataEntry[] = tids.map((tid) => {
      return {
        tid: tid,
        name: teams[tid].name || "what",
        allowed: teams[tid].allowed || false,
      };
    });
    return (
      <Dialog open={true} fullWidth fullScreen={isMobile}>
        <DialogTitle>{t("teamsallow.title")}</DialogTitle>
        <DialogContent>
          <TextField
            focused
            autoFocus
            label={t("teams.filter")}
            value={this.state.filterStr}
            onChange={(event) =>
              this.setState({ filterStr: event.target.value })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ filterStr: "" })}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p />
          <Box
            height="70vh"
            sx={{
              "& .allowedbutton": { backgroundColor: green[200] },
              "& .notallowedbutton": { backgroundColor: red[200] },
            }}
          >
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed
                  width={width}
                  height={height}
                  columns={columns}
                  data={data}
                />
              )}
            </AutoResizer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.closeDialog}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(withNL(TeamsAllow)));
