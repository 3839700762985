import * as L from "leaflet";
import "leaflet-bing-layer";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";

const BingMapsLayer: React.FC<{ conf?: string }> = ({ conf }) => {
  const map = useMap();
  const leafletContext = useLeafletContext();

  useEffect(() => {
    const layer = (L.tileLayer as any).bing({
      bingMapsKey:
        "Au2TZ40ycUuI7TMLXLKWZUzbxDAFUWLgVVZxojJ0YsbCfcFO1yQftJFq81HN9yRZ",
      culture: "et-EE",
      imagerySet: conf || "CanvasLight",
    });
    if (leafletContext.layerContainer) {
      leafletContext.layerContainer.addLayer(layer);
    } else layer.addTo(map);
    return () => {
      if (leafletContext.layerContainer) {
        leafletContext.layerContainer.removeLayer(layer);
      }
      layer.remove();
    };
  }, [conf, map, leafletContext]);
  return null;
};

export default BingMapsLayer;
