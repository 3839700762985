import React from "react";
import { RootState, useTSelector } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import * as L from "leaflet";
import { TrackTailLengths } from "rx/appStateSlice";
import { TrackPoint } from "rx/tracksSlice";
import ArrowMarker from "./ArrowMarker";
import memoize from "memoize-one";
import dayjs from "dayjs";
import TrackTail from "./TrackTail";

const TeamLocation: React.FC<{
  map: L.Map;
  track: TrackPoint[];
  idx: number;
  tid: string;
}> = ({ track, idx, tid, map }) => {
  const [color, showlabels, name] = useTSelector(
    (state) => {
      return [
        state.teamsList[tid]?.col,
        state.appState.showTrackLabels,
        state.teamsList[tid]?.name,
      ];
    },
    (oldv, newv) =>
      oldv[0] === newv[0] && oldv[1] === newv[1] && oldv[2] === newv[2]
  );
  const tp = track[idx];
  return (
    <ArrowMarker
      loc={tp}
      map={map}
      color={color}
      title={showlabels ? undefined : name}
      label={showlabels ? name : undefined}
      nutiid={{ type: "teamloc", id: tid }}
    />
  );
};
const mapState = (state: RootState, props: TrackOwnProps) => ({
  track: (state.tracks[props.tid] || {})[props.devid],
  starttime: state.teamsList[props.tid]?.starttime,
  finishtime: state.teamsList[props.tid]?.finishtime,
  tailLength: state.appState.trackTailLength,
  playTime: state.appState.playTime,
  liveGPSData: dayjs().isAfter(state.event.endtime || 0)
    ? false
    : state.appState.liveGPSData,
  massStartTime: state.appState.massStartTime,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TrackOwnProps = {
  tid: string;
  devid: string;
};
type TrackProps = PropsFromRedux & TrackOwnProps;
type TrackState = {};

class Track extends React.Component<TrackProps, TrackState> {
  lastidx?: number;
  firstidx?: number;

  binarySearchTrack = (st: number, previdx: number) => {
    const track = this.props.track;
    let eidx = track.length;
    let idx = 0;
    if (st > track[previdx].t) {
      idx = previdx;
    } else {
      eidx = previdx;
    }
    while (eidx > idx) {
      let midx = Math.floor((idx + eidx) / 2);
      if (st > track[midx].t) idx = midx + 1;
      else if (st < track[midx].t) eidx = midx;
      else return midx;
    }

    if (idx === track.length) return idx - 1;

    return idx;
  };

  calcIndexes = memoize(
    (startt: number, endt: number, _startmemo?: number, _endmemo?: number) => {
      const track = this.props.track;
      const findIndex = (cidx: number | undefined, reqtime: number) => {
        if (!cidx) return this.binarySearchTrack(reqtime, 0);

        const tdiff = reqtime - track[cidx].t;
        if (
          tdiff > 0 &&
          (cidx + 10 >= track.length || track[cidx + 10].t > reqtime)
        )
          while (cidx < track.length - 1 && track[cidx + 1].t <= reqtime)
            cidx++;
        else if (tdiff < 0 && (cidx < 10 || track[cidx - 10].t < reqtime))
          while (cidx > 0 && track[cidx].t >= reqtime) cidx--;
        else cidx = this.binarySearchTrack(reqtime, cidx);
        return cidx;
      };
      this.lastidx = findIndex(this.lastidx, endt);
      this.firstidx = findIndex(this.firstidx, startt);
      return [this.firstidx, this.lastidx];
    }
  );
  getRenderData = memoize(
    (
      playTime: number,
      finishtime: number | undefined,
      starttime: number | undefined,
      liveGPSData: boolean,
      massStartTime: number | null,
      tailLength: number,
      track: TrackPoint[]
    ) => {
      let newendt = liveGPSData ? Date.now() + 1000 : playTime;
      if (finishtime && finishtime < newendt) newendt = finishtime;

      if (massStartTime) {
        const st = starttime || track[0].t;
        newendt += st - massStartTime;
      }
      let newstart = (liveGPSData ? newendt : playTime) - this.props.tailLength;
      if (tailLength === TrackTailLengths.Full)
        newstart = starttime || track[0].t;
      else if (newstart > newendt) newstart = newendt;
      if (starttime && newstart < starttime) newstart = starttime;

      const firstt = this.firstidx && this.props.track[this.firstidx].t;
      const lastt = this.firstidx && this.props.track[this.firstidx].t;
      return this.calcIndexes(newstart, newendt, firstt, lastt);
    }
  );

  render() {
    if (!this.props.track || this.props.track.length === 0) return null;
    const [firstidx, lastidx] = this.getRenderData(
      this.props.playTime,
      this.props.finishtime,
      this.props.starttime,
      this.props.liveGPSData,
      this.props.massStartTime,
      this.props.tailLength,
      this.props.track
    );
    if (
      (this.props.starttime &&
        this.props.track[lastidx].t < this.props.starttime) ||
      (this.props.finishtime &&
        this.props.track[firstidx].t > this.props.finishtime)
    ) {
      return null;
    }
    return (
      <>
        <TeamLocation
          track={this.props.track}
          map={mainMap}
          idx={lastidx}
          tid={this.props.tid}
        />
        <TrackTail
          map={mainMap}
          tid={this.props.tid}
          track={this.props.track}
          firstidx={firstidx}
          lastidx={lastidx}
        />
      </>
    );
  }
}

export default connector(Track);

/*
const mapState = (state: RootState, props: TrackOwnProps) => ({
  track: (state.tracks[props.tid] || {})[props.devid],
  starttime: state.teamsList[props.tid]?.starttime,
  finishtime: state.teamsList[props.tid]?.finishtime,
  tailLength: state.appState.trackTailLength,
  playTime: state.appState.playTime,
  color: state.teamsList[props.tid]?.col,
  teamname: state.teamsList[props.tid]?.name,
  massStartTime: state.appState.massStartTime,
  showTrackLabels: state.appState.showTrackLabels,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TrackOwnProps = {
  tid: string;
  devid: string;
};
type TrackProps = PropsFromRedux & TrackOwnProps;
type TrackState = {};

/* Maybe selectors would make better performance.
const endSelector = createSelector(
  (props: TrackProps) => props.playTime,
  (props: TrackProps) => props.finishtime,
  (playtime, finishtime) => {
    let newendt = playtime;
    if (finishtime && finishtime < newendt) newendt = finishtime;
    return newendt;
  }
);

class Track extends React.Component<TrackProps, TrackState> {
  static contextType = LeafletContext;
  state: TrackState = {};
  track: L.Polyline;
  trackpoints: L.LatLng[] = [];
  firstidx: number = 0;
  lastidx: number = 0;
  startt: number = 0;
  endt: number = 0;
  container?: L.Map;

  constructor(props: TrackProps) {
    super(props);
    let track: TrackPoint[] = [];
    if (props.track) {
      [this.firstidx, this.lastidx] = this.calculateNextIdx(props);
      track =
        this.firstidx !== -1
          ? props.track.slice(this.firstidx, this.lastidx + 1)
          : [];
    }
    this.track = new L.Polyline(track, {
      color: props.color || "black",
    });
  }

  componentDidMount() {
    this.container = this.context.layerContainer || this.context.map;
    this.container?.addLayer(this.track);
  }
  componentWillUnmount() {
    this.container?.removeLayer(this.track);
  }


  calculateNextIdx(prevProps: TrackProps) {
    if (this.endt || this.startt) {
      if (
        !prevProps.track ||
        this.lastidx > this.props.track.length ||
        this.props.track[this.lastidx].t !== prevProps.track[this.lastidx].t
      ) {
        // If track has some trastical change (probably while still inital loading)
        this.lastidx = 0;
        this.endt = 0;
        this.startt = 0;
      }
    }
    let newendt = this.props.playTime || Date.now() + 10000;
    if (this.props.finishtime && this.props.finishtime < newendt)
      newendt = this.props.finishtime;

    if (this.props.massStartTime) {
      const st = this.props.starttime || this.props.track[0].t;
      const diff = st - this.props.massStartTime;
      newendt += diff;
    }
    let newstart =
      (this.props.massStartTime ? newendt : this.props.playTime || newendt) -
      this.props.tailLength;
    if (this.props.tailLength === TrackTailLengths.Full)
      newstart = this.props.starttime || this.props.track[0].t;
    else if (newstart > newendt) newstart = newendt;
    if (this.props.starttime && newstart < this.props.starttime)
      newstart = this.props.starttime;

    let newlastidx = this.lastidx;
    let newfirstidx = this.firstidx;
    if (this.endt !== newendt) {
      if (newendt > this.endt && newendt - this.endt < 10000) {
        const last = this.props.track.length - 1;
        for (
          newlastidx = this.lastidx;
          newlastidx < last && newendt > this.props.track[newlastidx].t;
          newlastidx++
        );
      } else newlastidx = this.binarySearchTrack(newendt, this.lastidx);
      this.endt = newendt;
    }

    if (this.startt !== newstart) {
      if (newstart > this.startt && newstart - this.startt < 10000) {
        const last = this.props.track.length - 1;
        for (
          newfirstidx = this.firstidx;
          newfirstidx < last && newstart > this.props.track[newfirstidx].t;
          newfirstidx++
        );
      } else newfirstidx = this.binarySearchTrack(newstart, this.firstidx);
      this.startt = newstart;
    }
    return [newfirstidx, newlastidx];
  }

  componentDidUpdate(prevProps: TrackProps) {
    console.log("did update", this.props.playTime);
    if (!this.props.track) return;

    const [newfirst, newlast] = this.calculateNextIdx(prevProps);
    if (newfirst !== this.firstidx || newlast !== this.lastidx) {
      let trackLatLngs: TrackPoint[] | L.LatLng[] = [];
      if (newlast < this.firstidx) {
        this.firstidx = newfirst;
        this.lastidx = newlast;
        trackLatLngs = this.props.track.slice(this.firstidx, this.lastidx + 1);
      } else {
        trackLatLngs = this.track.getLatLngs() as L.LatLng[];

        while (this.lastidx < newlast) {
          trackLatLngs.push(L.latLng(this.props.track[this.lastidx++]));
        }
        while (this.lastidx > newlast) {
          trackLatLngs.pop();
          this.lastidx--;
        }
        while (this.firstidx < newfirst) {
          trackLatLngs.shift();
          this.firstidx++;
        }
        while (this.firstidx > newfirst) {
          trackLatLngs.unshift(L.latLng(this.props.track[--this.firstidx]));
        }
      }
      this.track.setLatLngs(trackLatLngs);
      this.forceUpdate(); // If we would put lastidx to state. We wouldn't need forceUpdate.
    }
    console.log("update end", this.lastidx);
  }
  render() {
    if (!this.props.track || this.props.track.length === 0) return null;
    const tp = this.props.track[this.lastidx];
    const tdiff =
      this.props.playTime > tp.t
        ? this.props.playTime - tp.t
        : tp.t - this.props.playTime;
    console.log(tdiff, this.props.tid, this.props.playTime, tp.t, this.lastidx);
    if (tdiff > 2000) return null;
    return (
      <ArrowMarker
        loc={tp}
        map={mainMap}
        color={this.props.color}
        label={this.props.showTrackLabels ? this.props.teamname : undefined}
      />
    );
  }
}

export default connector(Track);

*/
