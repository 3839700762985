import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "rx/store";
import {
  DatabaseReference,
  getDatabase,
  push,
  ref,
  onValue,
  onDisconnect,
  set,
  remove,
  update,
  OnDisconnect,
} from "firebase/database";
import { useSnackbar, SnackbarKey } from "notistack";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type ConnectionStateType = {
  uid?: string | null;
  eventId?: string | null;
  connected?: string;
};

export const EventPresence: React.FC<{eventId: string}> = ({eventId}) => {
  let [presenceRef, setPresenceRef] = useState<DatabaseReference | undefined>(
    undefined
  );
  const uid = useSelector((state: RootState) => state.user?.user?.uid || null);
  const [cstate, setCState] = useState<ConnectionStateType>({});

  useEffect(() => {
    let pref = push(ref(getDatabase(), `/eventsdata/${eventId}/watchers/`));
    setPresenceRef(pref);
    let canceldisconnect: OnDisconnect|undefined;
    let unsubconnected = onValue(
      ref(getDatabase(), ".info/connected"),
       (snapshot) => {
        if (snapshot.val() === true) {
          // Connected.
          const newstate: ConnectionStateType = {
            uid: store.getState().user.user?.uid || null,
            connected: dayjs().toISOString(),
          };
          set(pref, Object.assign({ agent: navigator.userAgent }, newstate));
          canceldisconnect = onDisconnect(pref);
          canceldisconnect.remove()
            .catch((err) => {
              if (err)
                console.error("could not establish onDisconnect event", err);
            });
          setCState(newstate);
        } else {
          setCState({ connected: undefined });
        }
      }
    );
    return () => {
      remove(pref);
      unsubconnected();
      canceldisconnect?.cancel();
    };
  }, [eventId]);

  useEffect(() => {
    if (presenceRef === undefined || !cstate.connected) return;
    const newstate: ConnectionStateType = {};
    if (cstate.uid !== uid) {
      newstate.uid = uid || null;
    }
    if (Object.keys(newstate).length > 0) {
      update(presenceRef, newstate);
      setCState(Object.assign({}, cstate, newstate));
    }
  }, [uid, cstate, presenceRef]);
  return null;
};

const FBPresence: React.FC = () => {
  let [presenceRef, setPresenceRef] = useState<DatabaseReference | undefined>(
    undefined
  );
  const uid = useSelector((state: RootState) => state.user?.user?.uid || null);
  const eventId = useSelector((state: RootState) => state.eventId);
  const [cstate, setCState] = useState<ConnectionStateType>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    let pref = push(ref(getDatabase(), "connectedClients/web"));
    setPresenceRef(pref);
    let isconnected: boolean | undefined;
    let snackbarKey: SnackbarKey | undefined;
    let unsubconnected = onValue(
      ref(getDatabase(), ".info/connected"),
      function (snapshot) {
        if (snapshot.val() === true) {
          // Connected.
          if (isconnected === false) {
            if (snackbarKey) {
              closeSnackbar(snackbarKey);
            }
            enqueueSnackbar(t("connection.resumed"), {
              persist: false,
              variant: "success",
            });
          }
          const newstate: ConnectionStateType = {
            uid: store.getState().user.user?.uid || null,
            eventId: store.getState().eventId || null,
            connected: dayjs().toISOString(),
          };
          set(pref, Object.assign({ agent: navigator.userAgent }, newstate));
          onDisconnect(pref)
            .remove()
            .catch((err) => {
              if (err)
                console.error("could not establish onDisconnect event", err);
            });
          setCState(newstate);
          isconnected = true;
        } else {
          // Could set that we have an issue when not connected.
          if (isconnected) {
            // Don't show snackbar until we have been at least once in connected state.
            snackbarKey = enqueueSnackbar(t("connection.lost"), {
              persist: true,
              variant: "error",
              onClose: () => {
                snackbarKey = undefined;
              },
            });
          }
          if (isconnected === true) isconnected = false;
          setCState({ connected: undefined });
        }
      }
    );
    return () => {
      remove(pref);
      unsubconnected();
    };
  }, [closeSnackbar, enqueueSnackbar, t]);

  useEffect(() => {
    if (presenceRef === undefined || !cstate.connected) return;
    const newstate: ConnectionStateType = {};
    if (cstate.uid !== uid) {
      newstate.uid = uid || null;
    }
    if (cstate.eventId !== eventId) {
      newstate.eventId = eventId || null;
    }
    if (Object.keys(newstate).length > 0) {
      update(presenceRef, newstate);
      setCState(Object.assign({}, cstate, newstate));
    }
  }, [uid, eventId, cstate, presenceRef]);
  return null;
};

export default FBPresence;
