import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTSelector } from "rx/store";

const TrackTeam: React.FC<{ uid: string }> = React.memo(({ uid }) => {
  const team = useTSelector((s) => s.teamsList[uid]);

  console.log("RR");
  return (
    <Tooltip arrow title={uid} followCursor>
      <Typography>{team ? team.name : uid}</Typography>
    </Tooltip>
  );
});

const GpsStateContent: React.FC = () => {
  const tracks = useTSelector((s) => s.tracks);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Dev</TableCell>
            <TableCell>GPS points</TableCell>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell>Speed</TableCell>
            <TableCell>Acc</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(tracks).map(([k, v]) => {
            //console.log(k, v);
            const devs = Object.keys(v);
            return devs.map((dk, index) => {
              return (
                <TableRow>
                  {index === 0 && (
                    <TableCell rowSpan={devs.length}>
                      <TrackTeam uid={k} />
                    </TableCell>
                  )}
                  <TableCell>{dk}</TableCell>
                  <TableCell>{v[dk].length}</TableCell>
                  <TableCell>
                    {dayjs(v[dk][0].t).format("DD/MM/YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    {dayjs(v[dk][v[dk].length - 1].t).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </TableCell>
                  <TableCell>{v[dk][0].s}</TableCell>
                  <TableCell>{v[dk][0].a}</TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GpsStateContent;
