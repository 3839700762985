import React from "react";
import * as L from "leaflet";
import ArrowMarker from "./ArrowMarker";
import { TrackPoint } from "rx/tracksSlice";
import { isObjectEqual } from "react-base-table";
import memoizeOne from "memoize-one";

type TrackBrowserProps = {
  track: TrackPoint[];
  color: string;
  map: L.Map;
  currentTime: number | null;
  selectTime: (t: number) => void;
};
type TrackBrowserState = {};
class TrackBrowser extends React.Component<
  TrackBrowserProps,
  TrackBrowserState
> {
  state: TrackBrowserState = {};
  polyline?: L.Polyline;
  trackpoints: L.LatLng[] = [];

  createTrack() {
    const { map, track, color } = this.props;
    if (this.polyline) map.removeLayer(this.polyline);

    this.polyline = new L.Polyline(track, {
      color: color,
      weight: 5,
      lineCap: "square",
    });

    this.polyline.on("click", (event) => {
      const ev = event as L.LeafletMouseEvent;
      const target = ev.target as L.Polyline;
      let closestpoint: L.LatLng | undefined = undefined;
      let closestdistance: number = 0;
      let pointidx = 0;
      target.getLatLngs().forEach((ll: any, index: number) => {
        if (!(ll instanceof L.LatLng)) return;
        if (closestpoint === undefined) {
          closestpoint = ll;
          closestdistance = ll.distanceTo(ev.latlng);
        } else {
          const newdst = ll.distanceTo(ev.latlng);
          if (newdst < closestdistance) {
            pointidx = index;
            closestdistance = newdst;
            closestpoint = ll;
          }
        }
      });

      console.log("currentPointIdx", pointidx, track[pointidx]);

      this.props.selectTime(track[pointidx].t);
    });
    map.addLayer(this.polyline);
  }
  componentDidMount() {
    this.createTrack();
  }
  componentWillUnmount() {
    if (this.polyline) this.props.map.removeLayer(this.polyline);
  }

  componentDidUpdate(prevProps: TrackBrowserProps) {
    if (!isObjectEqual(this.props.track, prevProps.track)) this.createTrack();
  }
  render() {
    const memoized = memoizeOne((t: number | null) => {
      if (t === null) return -1;
      return this.props.track.findIndex((tp) => tp.t >= t);
    });
    const pointidx = memoized(this.props.currentTime);
    let enablearrow = pointidx !== -1;
    if (enablearrow && this.props.currentTime) {
      if (
        Math.floor(this.props.track[pointidx].t - this.props.currentTime) > 1000
      )
        enablearrow = false;
    }
    return (
      <>
        {enablearrow && (
          <ArrowMarker map={this.props.map} loc={this.props.track[pointidx]} />
        )}
      </>
    );
  }
}

export default TrackBrowser;
