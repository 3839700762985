import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  CssBaseline,
  Drawer,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import NutilogiDrawer from "components/NutilogiDrawer";
import DataListener from "./utils/DataListener";
import { useTSelector } from "rx/store";
import AppBarButtons from "components/AppBarButtons";
import ServerSaveStatus from "components/ServerSaveStatus";
import RouteSynchronizer from "utils/RouteSynchronizer";
import PlayControls from "components/PlayControls";
import {
  drawerMenuContentTypes,
  toggleContinousKPInsert,
} from "rx/appStateSlice";
import SpeedAreasDrawer from "components/SpeedAreasDrawer";
import MainMap from "components/MainMap";
import SpeedingDrawer from "components/SpeedingDrawer";
import SearchBox from "components/SearchBox";
import PrintingDrawer from "components/PrintingDrawer";
import { DrawerHeaderWithStyles } from "components/DrawerHeader";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  width: "100%",
  height: "100vh",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${theme.drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: `${theme.drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MapDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
const ControlsDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  zIndex: 1000,
  width: "100%",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: `${theme.drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TitleText = () => {
  const eventName = useTSelector((state) => state.event.name);
  return (
    <span>
      {eventName || "Nutilogi"}
      {global.usingemudb && " - emulated"}
    </span>
  );
};

const DrawerContents: React.FC<{ closeDrawer: () => void }> = (props) => {
  const menucontent = useTSelector((state) => state.appState.drawerMenuContent);

  switch (menucontent) {
    case drawerMenuContentTypes.Printing:
      return <PrintingDrawer {...props} />;
    case drawerMenuContentTypes.SpeedAreas:
      return <SpeedAreasDrawer {...props} />;
    case drawerMenuContentTypes.Speedings:
      return <SpeedingDrawer {...props} />;
    case drawerMenuContentTypes.SpeedPenalties:
      return <SpeedingDrawer {...props} viewOnly={true} />;
    default:
      return <NutilogiDrawer {...props} />;
  }
};

const ContinousKPInsert: React.FC = () => {
  const addingKP = useTSelector((state) => state.appState.addingKP);
  const iscontinous = useTSelector((state) => state.appState.continousKPInsert);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (!addingKP) return null;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={iscontinous}
          onChange={() => dispatch(toggleContinousKPInsert())}
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
          }}
        />
      }
      label={t("label.continouskpinsert")}
    />
  );
};
export default function NutilogiApp() {
  const [open, setOpen] = React.useState(false);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setOpen(true);
            }}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
            size="large"
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            <TitleText />
          </Typography>
          <ContinousKPInsert />
          <SearchBox />
          <ServerSaveStatus />
          <AppBarButtons />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: (theme) => theme.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: (theme) => theme.drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerContents
          closeDrawer={() => {
            setOpen(false);
          }}
        />
      </Drawer>
      <Main open={open} sx={{ display: "flex", flexDirection: "column" }}>
        <DrawerHeaderWithStyles />
        <MapDiv>
          <MainMap />
          <ControlsDiv open={open}>
            <PlayControls />
          </ControlsDiv>
        </MapDiv>
        <RouteSynchronizer />
        <DataListener />
      </Main>
    </Box>
  );
}
