import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useTSelector } from "rx/store";
import FBSwitch from "./FBSwitch";
import FBTextField from "./FBTextField";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  defaultorderrules,
  allorderrules,
  OrderRuleKeys,
} from "utils/orderrules";
import { useNL } from "utils/NLContext";
import { useTranslation } from "react-i18next";
import RaConfigCard from "./RaConfigCard";
import FirstBonusConfigCard from "./FirstBonusConfigCard";
import { FormControlLabel, Switch } from "@mui/material";
import { child, getDatabase, ref } from "firebase/database";
import KPGroupsConfigCard from "./KPGroupsConfigCard";

const RoadBookSwitch: React.FC<{ evdata: string }> = ({ evdata }) => {
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const resulttype = useTSelector((state) => state.event.resulttype);
  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dbref = child(ref(getDatabase(), evdata), "resulttype");
      if (event.target.checked) {
        fbSet(dbref, "roadbook", t("undo.eventresulttype.roadbook.set"));
      } else {
        fbRemove(dbref, t("undo.eventresulttype.roadbook.clear"));
      }
    },
    [evdata, fbRemove, fbSet, t]
  );
  return (
    <FormControlLabel
      control={
        <Switch
          checked={resulttype === "roadbook"}
          onChange={onChangeCallback}
        />
      }
      label={t(`label.switch.roadbook`)}
    />
  );
};

const DistanceResultSettings: React.FC<{ eventId: string }> = ({ eventId }) => {
  const showdistance = useTSelector((s) => s.event.distanceinresult);

  if (!showdistance) return null;

  const evdata = ref(getDatabase(), `/eventsdata/${eventId}/data/dstresult`);
  return (
    <>
      <Grid item xs={6} sm={4}>
        <FBTextField
          variant="outlined"
          aria-label="Target distance"
          id="targetdst"
          type="number"
          path={child(evdata, "targetdst")}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <FBTextField
          variant="outlined"
          aria-label="Distance steps"
          id="dststeps"
          type="number"
          path={child(evdata, "dststeps")}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <FBTextField
          variant="outlined"
          aria-label="First dst points"
          id="dstpoints"
          type="number"
          path={child(evdata, "dstpoints")}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <FBTextField
          variant="outlined"
          aria-label="Distance point steps"
          id="dstpointssteps"
          type="number"
          path={child(evdata, "dstpointssteps")}
        />
      </Grid>
    </>
  );
};

const eventswitches = [
  ["hideresults", "Hide results"],
  ["kpsinweb", "Show KP's"],
  ["hidewrongmarks", "Hide wrong responses count"],
  ["wrongpenaltyenabled", "Wrong penalty enabled"],
  ["answerscores", "Answer is always rewarded"],
  ["distanceinresult", "Distance in result"],
  ["questionsresult", "Result for Questions"],
];

const EventSettingsResult: React.FC<{ eventId: string }> = ({ eventId }) => {
  const event = useSelector((state: RootState) => state.event);

  const { fbSet, fbRemove } = useNL();
  const { t } = useTranslation();
  const evdata = `/eventsdata/${eventId}/data/`;

  let usedrules: OrderRuleKeys[] = event.orderrules
    ? (event.orderrules.split(",") as OrderRuleKeys[])
    : defaultorderrules;

  const onRuleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    let newrules: OrderRuleKeys[] = [];
    if (result.destination.droppableId === "unused") {
      newrules = usedrules.filter((v) => v !== result.draggableId);
    } else if (result.destination.droppableId === "used") {
      newrules = usedrules.filter((v) => v !== result.draggableId);
      newrules.splice(
        result.destination.index,
        0,
        result.draggableId as OrderRuleKeys
      );
    }
    if (newrules.length === 0) return;
    const orderrulespath = evdata + "orderrules";

    const newrulestring = newrules.join(",");
    if (newrulestring === defaultorderrules.join(",")) {
      fbRemove(orderrulespath, t("fbf.orderrules.changed"));
    } else {
      fbSet(orderrulespath, newrulestring, t("fbf.orderrules.changed"));
    }
  };
  return (
    <Grid container direction="column">
      <Grid
        container
        spacing={2}
        alignContent="center"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={6} sm={4} style={{ display: "none" }}>
          <RoadBookSwitch evdata={evdata} />
        </Grid>
        {eventswitches.map(([entid, entlabel]) => (
          <Grid item xs={6} sm={4} key={entid}>
            <FBSwitch
              aria-label={entlabel}
              id={entid}
              path={`${evdata}${entid}`}
            />
          </Grid>
        ))}
        <DistanceResultSettings eventId={eventId} />
        <Grid item xs={6} sm={4}>
          <FBTextField
            aria-label="points suffix"
            id="pointssuffix"
            path={`${evdata}pointssuffix`}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FBTextField
            disabled={!event.wrongpenaltyenabled}
            variant="outlined"
            aria-label="Wrong coeficent"
            id="wrongcoef"
            type="number"
            path={`${evdata}wrongcoef`}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <RaConfigCard />
        </Grid>
        <Grid item>
          <KPGroupsConfigCard />
        </Grid>
        <Grid item>
          <FirstBonusConfigCard />
        </Grid>
        <Grid item>
          <DragDropContext onDragEnd={onRuleDragEnd}>
            <Card>
              <CardHeader title={t("orderrules.cardtitle")} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item>
                    <Droppable droppableId="used">
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <List>
                            <ListSubheader>
                              {t("orderrules.used")}
                            </ListSubheader>
                            {usedrules.map((item, idx) => (
                              <Draggable
                                key={item}
                                draggableId={item}
                                index={idx}
                              >
                                {(provided) => (
                                  <ListItem
                                    key={item}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ListItemText
                                      primary={t(`orderrules.title.${item}`)}
                                      secondary={t(`orderrules.desc.${item}`)}
                                    />
                                  </ListItem>
                                )}
                              </Draggable>
                            ))}
                          </List>
                          {provided.placeholder}
                        </Paper>
                      )}
                    </Droppable>
                  </Grid>
                  <Grid item>
                    <Droppable droppableId="unused">
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <List>
                            <ListSubheader>
                              {t("orderrules.unused")}
                            </ListSubheader>
                            {(Object.keys(allorderrules) as OrderRuleKeys[])
                              .filter((r) => !usedrules.includes(r))
                              .map((item, idx) => (
                                <Draggable
                                  key={item}
                                  draggableId={item}
                                  index={idx}
                                >
                                  {(provided) => (
                                    <ListItem
                                      key={item}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <ListItemText
                                        primary={t(`orderrules.title.${item}`)}
                                        secondary={t(`orderrules.desc.${item}`)}
                                      />
                                    </ListItem>
                                  )}
                                </Draggable>
                              ))}
                          </List>
                          {provided.placeholder}
                        </Paper>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DragDropContext>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventSettingsResult;
