import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Check, Delete, Done, RadioButtonUnchecked } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";
import FBTextField from "./FBTextField";

const RaConfigCard: React.FC = () => {
  const rakoef = useTSelector((state) => state.event.rakoef);
  const sharedrakoefs =
    useTSelector((state) => state.event.sharedrakoef)?.split(",") || [];
  const eventId = useTSelector((state) => state.eventId);
  const [editconf, setEditConf] = useState(null as string | null);
  const { fbSet } = useNL();
  const { t } = useTranslation();
  const rakoefpath = `eventsdata/${eventId}/data/rakoef/`;
  return (
    <Card>
      <CardHeader title={t("ra.title")} />
      <CardContent>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            let v = 1;
            while (rakoef && rakoef[v]) v++;
            fbSet(rakoefpath + v.toString(), v * 3 - (v - 1));
          }}
        >
          {t("ra.add")}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("ra.headlabel.ra")}</TableCell>
              <TableCell>{t("ra.headlabel.value")}</TableCell>
              <TableCell>{t("ra.headlabel.shared")}</TableCell>
              <TableCell>{t("ra.headlabel.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rakoef || {}).map(([ra, value]) => (
              <TableRow key={ra}>
                <TableCell>{ra}</TableCell>
                <TableCell onClick={() => setEditConf(ra)}>
                  {editconf === ra ? (
                    <ClickAwayListener onClickAway={() => setEditConf(null)}>
                      <div>
                        <FBTextField
                          style={{ width: "14ch" }}
                          type="number"
                          id="rakoef"
                          path={rakoefpath + ra}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="complete ra entry"
                                  onClick={(event) => {
                                    setEditConf(null);
                                    event.stopPropagation();
                                  }}
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  size="large">
                                  <Done />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    value
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newkoefs = (sharedrakoefs.includes(ra)
                        ? sharedrakoefs.filter((testra) => testra !== ra)
                        : sharedrakoefs.concat(ra)
                      ).join(",");

                      fbSet(
                        `eventsdata/${eventId}/data/sharedrakoef`,
                        newkoefs
                      );
                    }}
                  >
                    {sharedrakoefs.includes(ra) ? (
                      <Check />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => fbSet(rakoefpath + ra, null)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default RaConfigCard;
