import React, { useEffect } from "react";
import { playBeep } from "./SmallUtils";

const StartBeeper: React.FC<{ nextstartms: number }> = ({ nextstartms }) => {
  useEffect(() => {
    let ct = new Date().getTime() + global.useTimeOffset;

    const timers: NodeJS.Timeout[] = [];

    const timetostart = nextstartms - ct;

    for (let t = timetostart - 4000; t <= timetostart; t += 1000) {
      if (t < 0) continue;
      timers.push(
        setTimeout(() => {
          if (timetostart - t) {
            playBeep(1000, 0.1);
          } else {
            playBeep(1000, 0.5);
          }
        }, t)
      );
    }

    return () => {
      timers.forEach((t) => {
        clearTimeout(t);
      });
    };
  }, [nextstartms]);
  return null;
};

export default StartBeeper;
