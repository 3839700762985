import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setEditTeamId } from "rx/appStateSlice";
import { useTSelector } from "rx/store";

const RoadBookResult: React.FC<{ klass: string | undefined }> = ({ klass }) => {
  const teams = useTSelector((state) => state.teamsList);
  const speedareas = useTSelector((state) => state.eventDoc?.speedareas);
  const timedareas = useMemo(() => {
    return Object.entries(speedareas || {}).filter(([aid, area]) => area.timed);
  }, [speedareas]);
  const dispatch = useDispatch();
  console.log("teams: ", teams);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Team",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    ...timedareas.map(([aid, area]) => {
      return {
        field: "area-" + aid,
        headerName: area.name,
        flex: 0.2,
      };
    }),
    {
      field: "total",
      headerName: "Total",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      renderCell(params) {
        if (params.row.dsq) return "DSQ";
        else if (params.row.katk) return "KATK";
        else if (params.value === undefined) return "";
        return dayjs.duration(params.value).format("HH:mm:ss");
      },
    },
  ];
  const rows = Object.entries(teams)
    .map(([tid, teamdata]) => {
      let total: number | undefined = undefined;
      if (teamdata.starttime && teamdata.finishtime) {
        total = teamdata.finishtime - teamdata.starttime;
      }
      return { id: tid, ...teamdata, total: total };
    })
    .sort((l, r) => {
      const f = () => {
        if (l.dsq && !r.dsq) return 1;
        else if (!l.dsq && r.dsq) return -1;
        else if (r.dsq && l.dsq === r.dsq) return 0;
        else if (l.katk && !r.katk) return 1;
        else if (!l.katk && r.katk) return -1;
        else if (r.katk && l.katk === r.katk) return 0;
        else if (l.total !== undefined && r.total !== undefined)
          return l.total - r.total;
        else if (l.total === undefined && r.total === undefined) return 0;
        else if (r.total === undefined) return -1;
        else return 1;
      };
      const v = f();
      console.log("cmp", l, r, v);
      return v;
    });
  return (
    <Box style={{ height: "95vh", width: "80vw" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onCellClick={(params, event, details) => {
          if (params.field === "name") {
            dispatch(setEditTeamId(params.row.id));
          }
          console.log("cell click:", params, event, details);
        }}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
      />
    </Box>
  );
};

export default RoadBookResult;
