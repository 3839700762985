import { useEffect } from "react";
import { useMap } from "react-leaflet";
import * as L from "leaflet";
import { useLeafletContext } from "@react-leaflet/core";

const LeafLetProviderLayer: React.FC<{ conf?: string }> = ({ conf }) => {
  const map = useMap();
  const leafletContext = useLeafletContext();

  const [providerName, variantName] = conf?.split(".") || [
    "OpenStreetMap",
    undefined,
  ];

  const finalName = conf || "OpenStreetMap";

  useEffect(() => {
    if (
      (providerName === "NASAGIBS" ||
        providerName === "OpenWeatherMap" ||
        providerName === "WaymarkedTrails" ||
        providerName === "OpenSnowMap") &&
      (!variantName || variantName === "unset")
    )
      return undefined;
      const options: {
    apikey?: string;
    apiKey?: string;
    key?: string;
    accessToken?: string;
  } = {};
  if (providerName === "Thunderforest") {
    options.apikey = "aa11f87a757c463a908e624747b4c55d";
  } else if (providerName === "Jawg") {
    options.accessToken =
      "NMDMm7YbP3YNH9uMZyLW1ex85RGDRqjvdS47D5z2tpzfLbEHi9InYzsdDVOv2Hgo";
  } else if (providerName === "MapBox") {
    options.accessToken =
      "pk.eyJ1IjoiYXJnb3Zlc3NtYW5uIiwiYSI6ImNrb2QybHc1bjAyeHgzM28xNm9haHp1YzMifQ.ZQPqIZUUkcBVq5IF-ri57A";
  } else if (providerName === "MapTiler") {
    options.key = "MpA3PDarVONT4q4B8Usr";
  } else if (providerName === "TomTom") {
    options.apikey = "tbj19q8Hqv8A7CHKe0X3p8EPxklp7U8c";
  } else if (providerName === "HEREv3") {
    options.apiKey = "-K07BAJkm3AxOSxsZDgihpdieYkzJF5VFUiwbJ7htkQ";
  } else if (providerName === "OpenWeatherMap") {
    options.apiKey = "66c7ffebeabc1d7ddfe4fd6f6d9ec6ef";
  }
    const layer = (L.tileLayer as any).provider(finalName, options) as L.Layer;
    if (leafletContext.layerContainer) {
      leafletContext.layerContainer.addLayer(layer);
    } else layer.addTo(map);
    return () => {
      if (leafletContext.layerContainer) {
        leafletContext.layerContainer.removeLayer(layer);
      }
      layer.remove();
    };
  }, [map, finalName, leafletContext.layerContainer, providerName, variantName]);
  return null;
};

export default LeafLetProviderLayer;
