import { Action, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import reducerRoot from "./reducerRoot";
import { ThunkAction } from "redux-thunk";
import { dataAvailabilitySlice, fbReducerMap } from "./fbListSlices";

const store = configureStore({
  reducer: reducerRoot,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export const useTSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<any>>;

// use useFBSlice with slices that can be delay loaded (Firebase database request is done only when data is really requested)
export const useFBSliceSelector = <T extends keyof typeof fbReducerMap, TS>(
  slice: T,
  fn: (s: RootState[T]) => TS
): TS => {
  const dispatch = useDispatch();
  if (store.getState().dataAvailability[slice] === undefined) {
    dispatch(dataAvailabilitySlice.actions.requestData(slice));
  }
  return useTSelector((s) => fn(s[slice]));
};

// Can provide an array of valued derived from state. And will updated only if any one in array will be changed
// Sadly this loses the type of returned values.
type LeafTypes<T> = T extends object
  ? { [K in keyof T]: LeafTypes<T[K]> }[keyof T]
  : T;
type ResultType = LeafTypes<RootState>;

export const useMSelector = (
  fields: (s: RootState) => ResultType[]
): ResultType[] => {
  return useTSelector(fields, (l, r) => !l.some((lv, idx) => lv !== r[idx]));
};