import React from "react";
import { Marker } from "react-leaflet";

import "utils/NutiMap.css";
import * as L from "leaflet";

// TODO This marker seems to be used only in StartLocation. Should replace that with KPMarker2
const KPMarker: React.FC<{
  position: L.LatLngExpression;
  draggable?: boolean;
  eventHandlers?: L.LeafletEventHandlerFnMap;
}> = ({ position, draggable, eventHandlers }) => {
  /* This is a sample of creating icon just from svg. Currently don't know how to add color and translation.
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" class="${classes.testicon}" viewBox="0 0 24 24" width="24" height="24"><path key="1" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>`;
  var iconUrl = "data:image/svg+xml;base64," + btoa(svg);

  var icon2 = L.icon({
    iconUrl: iconUrl,
    iconAnchor: [10, 10],
    className: classes.testicon,
  });
  */

  let paths =
    '<path key="1" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />';
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" class="kpmarker-svg-defeault" viewBox="0 0 24 24" width="24" height="24">${paths}</svg>`;

  const icon = L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 0],
    html: svg,
  });

  // Intresting plugin. Maybe it's easier to make kp marker with this: https://github.com/coryasilva/Leaflet.ExtraMarkers
  return (
    <Marker
      eventHandlers={eventHandlers}
      autoPan
      draggable={draggable}
      riseOnHover={true}
      icon={icon}
      position={position}
    ></Marker>
  );
};

export default KPMarker;
