import { PointData } from "@nutilogi/nutilogitypes";

function binarySearchPointWithLessTime(
  arr: TimeType[],
  t: number,
  idx: number
) {
  var eidx = arr.length;
  let midx = 0;
  while (eidx > idx) {
    midx = Math.floor((idx + eidx) / 2);
    if (t > arr[midx].t) idx = midx + 1;
    else if (t < arr[midx].t) eidx = midx;
    else {
      return midx;
    }
  }
  return idx;
}

type TimeType = { t: number };
export const timeArrayInsert = <TargetType extends TimeType>(
  target: TargetType[],
  points: PointData[],
  insertPoints: (
    target: TargetType[],
    insertPoint: number,
    points: PointData[]
  ) => void
) => {
  if (target.length === 0 || target[target.length - 1].t < points[0].t) {
    insertPoints(target, target.length, points);
  } else {
    let pidx = 0;
    let aidx = 0;
    while (pidx < points.length) {
      aidx = binarySearchPointWithLessTime(target, points[pidx].t, aidx);
      if (aidx < target.length && target[aidx].t === points[pidx].t) {
        while (
          aidx < target.length &&
          pidx < points.length &&
          target[aidx].t === points[pidx].t
        ) {
          aidx++;
          pidx++;
        }
        if (pidx >= points.length) break;
        if (aidx === target.length) continue;
        if (target[aidx].t < points[pidx].t) aidx--;
      }
      if (aidx < target.length) {
        let count = 0;
        while (
          pidx + count < points.length &&
          points[pidx + count].t < target[aidx].t
        )
          count++;
        insertPoints(target, aidx, points.slice(pidx, pidx + count));
        pidx += count;
        aidx += count;
      } else {
        insertPoints(target, aidx, points.slice(pidx));
        pidx += points.length - pidx;
      }
    }
  }
};
