import { connectFirestoreEmulator, getFirestore } from "@firebase/firestore/lite";
import { connectDatabaseEmulator, getDatabase, onValue, ref } from "@firebase/database";
import { initializeApp } from "firebase/app"
import firebase from 'firebase/compat/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

if (
  process.env.JEST_WORKER_ID !== undefined &&
  process.env.FORCE_JEST_ENVIRONMENT_FIREBASE_USAGE === undefined
) {
  throw new Error(
    "This code should not be run in test environment, to protect accidential database breakings!"
  );
}

var config = {
  apiKey: "AIzaSyDIGG0DLBM_HOzM9gubl9ckeYZT05slo58",
  authDomain: "nutilogi.firebaseapp.com",
  databaseURL: "https://nutilogi.firebaseio.com",
  projectId: "nutilogi",
  storageBucket: "nutilogi.appspot.com",
  messagingSenderId: "891410042817",
  appId: "1:891410042817:web:43553af7c8de15d15b2940",
  measurementId: "G-QT3RZP47K9",
};
const app = initializeApp(config);

// react-firebaseui needs this. If it gets fixed not to use firebase compat we should be able to remove this.
firebase.initializeApp(config);

if (process.env.NODE_ENV === "development")
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;/* eslint-disable-line no-restricted-globals */
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Lcn5WEhAAAAABeMmZZw4fQzE92eTu9pEE2hk9sh'),
  isTokenAutoRefreshEnabled: true
});

const disableEmulator = true;
/* Start emulator and can use this */
if (!disableEmulator && window.location.hostname === "localhost") {
  global.usingemudb = true;
  connectDatabaseEmulator(getDatabase(), "localhost", 9000)
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
}

/*
const perf = firebase.performance();
perf.instrumentationEnabled = false;
perf.dataCollectionEnabled = false;
*/

global.fbOffsetRef = 0;
onValue(ref(getDatabase(), ".info/serverTimeOffset"), (s => {
  global.fbOffsetRef = s.val();
  global.useTimeOffset = s.val();
}));
