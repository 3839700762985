import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MyLocation } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import store from "rx/store";
import { unhideTrack } from "rx/appStateSlice";
import dayjs from "dayjs";
import { NutiId } from "./ArrowMarker";

export const FlyToLoc: React.FC<{
  loc?: L.LatLngExpression;
  zoom: number;
  done?: () => void;
}> = (props) => {
  return <FlyToLocOnMap {...props} map={useMap()} />;
};
export const FlyToLocOnMap: React.FC<{
  loc?: L.LatLngExpression;
  zoom: number;
  map: L.Map;
  done?: () => void;
}> = ({ loc, zoom, map, done }) => {
  useEffect(() => {
    if (loc) map.flyTo(loc, zoom); // 14
    if (done) done();
  }, [map, loc, done, zoom]);
  return null;
};

export const ZoomLevelListener: React.FC<{
  children: (zoom: number | undefined) => React.ReactElement | null;
}> = (props) => {
  const [zoom, setZoom] = useState(undefined as number | undefined);
  const map = useMapEvent("zoomend", (e) => {
    setZoom(map.getZoom());
  });
  if (zoom === undefined) setZoom(map.getZoom());
  return props.children(zoom);
};

const StyledLeafletControl = styled("div")(({ theme }) => ({
  marginBottom: "70px !important",
}));

export const CurrentLocationControl: React.FC = () => {
  const map = useMap();
  return (
    <div className={"leaflet-bottom leaflet-right"}>
      <StyledLeafletControl className={"leaflet-control"}>
        <IconButton
          aria-label="current-location"
          onClick={() => {
            map.locate({ setView: true });
          }}
          size="large"
        >
          <MyLocation />
        </IconButton>
      </StyledLeafletControl>
    </div>
  );
};

const flyToTeamError = {
  nodevices: "team.nodevices",
  notracks: "team.notracks",
  notstarted: "team.notyetstarted",
} as const;

export const flyToTeam = (
  tid: string
): typeof flyToTeamError[keyof typeof flyToTeamError] | null => {
  let found = false;
  mainMap.eachLayer((l) => {
    const nutiid = (l as any).nutiid as NutiId | undefined;
    if (!nutiid) return;
    if (nutiid.id === tid) {
      mainMap.flyTo((l as L.Marker).getLatLng());
      found = true;
    }
  });
  if (found) return null;

  const state = store.getState();
  if (tid in state.appState.hiddenTracks) {
    store.dispatch(unhideTrack(tid));
  }
  const eventended =
    state.event.endtime && dayjs().isAfter(state.event.endtime);
  const team = state.teamsList[tid];

  let t =
    !eventended && state.appState.liveGPSData
      ? Date.now()
      : state.appState.playTime +
      (state.appState.massStartTime && team.starttime
        ? team.starttime - state.appState.massStartTime
        : 0);
  if (team.finishtime && t > team.finishtime) {
    t = team.finishtime;
  }
  const alltracks = state.tracks[tid];
  if (!alltracks) return flyToTeamError.notracks;
  const devs = Object.keys(alltracks);
  if (devs.length === 0) {
    return flyToTeamError.nodevices;
  }
  const track =
    team.defaultdev && team.defaultdev in alltracks
      ? alltracks[team.defaultdev]
      : alltracks[devs[0]];
  let idx = track.length - 1;
  while (idx > 0 && track[idx].t > t) idx--;
  if (team.starttime && track[idx].t < team.starttime)
    return flyToTeamError.notstarted;
  mainMap.flyTo(track[idx]);
  return null;
};
