import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { withTranslation, WithTranslation } from "react-i18next";
import EventSettingsGeneral from "./EventSettingsGeneral";
import { ExpandMore } from "@mui/icons-material";
import EventSettingsStart from "./EventSettingsStart";
import EventSettingsSpeed from "./EventSettingsSpeed";
import EventSettingsClasses from "./EventSettingsClasses";
import EventSettingsResult from "./EventSettingsResult";
import EventSettingsTimes from "./EventSettingsTimes";
import { closeDialog } from "rx/dialogsSlice";
import EventSettingsNewFeatures from "./EventSettingsNewFeatures";
import { TFunction } from "i18next";
import EventSettingsMap from "./EventSettingsMap";
import EventSettingsTexts from "./EventSettingsTexts";
import EventSettingsLy from "./EventSettingsLy";
import EventSettingsApp from "./EventSettingsApp";
import EventSettingsRegistration from "./EventSettingsRegistration";

export const generateEventConfigurations = (
  t: TFunction,
  eventId: string
): GroupConfiguration[] => {
  return [
    {
      id: "gentab",
      title: t("evset.title.gen"),
      subtitle: t("evset.title.gensub"),
      content: <EventSettingsGeneral key="gen" eventId={eventId} />,
    },
    {
      id: "regtab",
      title: t("evset.title.reg"),
      subtitle: t("evset.title.regsub"),
      content: <EventSettingsRegistration key="reg" eventId={eventId} />,
    },
    {
      id: "msgtab",
      title: t("evset.title.appmsg"),
      subtitle: t("evset.title.appmsgsub"),
      content: <EventSettingsTexts key="msgs" eventId={eventId} />,
    },
    {
      id: "starttab",
      title: t("evset.title.start"),
      subtitle: t("evset.title.startsub"),
      content: <EventSettingsStart key="start" />,
    },
    {
      id: "timestab",
      title: t("evset.title.times"),
      subtitle: t("evset.title.timessub"),
      content: <EventSettingsTimes key="times" eventId={eventId} />,
    },
    {
      id: "speedtab",
      title: t("evset.title.speed"),
      subtitle: t("evset.title.speedsub"),
      content: <EventSettingsSpeed key="speed" />,
    },
    {
      id: "app",
      title: t("evset.title.app"),
      subtitle: t("evset.title.appsub"),
      content: <EventSettingsApp key="app" eventId={eventId} />,
    },
    {
      id: "resultsettingstab",
      title: t("evset.title.result"),
      subtitle: t("evset.title.resultsub"),
      content: <EventSettingsResult key="result" eventId={eventId} />,
    },
    {
      id: "klassidtab",
      title: t("evset.title.classes"),
      subtitle: t("evset.title.classessub"),
      content: <EventSettingsClasses key="klassid" eventId={eventId} />,
    },
    {
      id: "maptab",
      title: t("evset.title.map"),
      subtitle: t("evset.title.mapsub"),
      content: <EventSettingsMap key="map" eventId={eventId} />,
    },
    {
      id: "lytab",
      title: t("evset.title.ly"),
      subtitle: t("evset.title.lysub"),
      content: <EventSettingsLy key="ly" eventId={eventId} />,
    },
    {
      id: "newftab",
      title: t("evset.title.newfeatures"),
      subtitle: t("evset.title.newfeaturessub"),
      content: <EventSettingsNewFeatures key="newft" eventId={eventId} />,
    },
  ];
};

type SettingsManagerProps = {
  groups: GroupConfiguration[];
  value: string | undefined;
  changeValue: (nv: string | undefined) => void;
};
const SettingsWithAccordion: React.FC<SettingsManagerProps> = ({
  groups,
  value,
  changeValue,
}) => (
  <>
    {groups.map((g) => (
      <Accordion
        expanded={value === g.id}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(_event, expanded) =>
          changeValue(expanded ? g.id : undefined)
        }
        key={g.id}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id={g.id}
          aria-controls={g.id + "-content"}
        >
          <Typography
            sx={{
              typography: "title",
              fontWeight: "medium",
              flexBasis: "33.33%",
              flexShrink: 0,
            }}
          >
            {g.title}
          </Typography>
          <Typography
            sx={{
              typography: "subtitle",
            }}
          >
            {g.subtitle}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>{g.content}</AccordionDetails>
      </Accordion>
    ))}
  </>
);

interface TabPanelProps {
  children?: React.ReactNode;
  id: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`simple-tabpanel-${id}`}
      aria-labelledby={`simple-tab-${id}`}
      {...other}
    >
      {value === id && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const SettingsWithTabs: React.FC<SettingsManagerProps> = ({
  groups,
  value,
  changeValue,
}) => (
  <>
    <AppBar position="static">
      <Tabs
        indicatorColor="secondary"
        textColor="inherit"
        value={value || groups[0].id}
        onChange={(_, newValue) => changeValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Event settings tab"
      >
        {groups.map((g) => (
          <Tab key={g.id} label={g.title} value={g.id} />
        ))}
      </Tabs>
    </AppBar>
    {groups.map((g) => (
      <TabPanel key={g.id} value={value || groups[0].id} id={g.id}>
        {g.content}
      </TabPanel>
    ))}
  </>
);

const mapState = (state: RootState) => ({
  hasAccess: state.user.eventAccess,
});
const mapDispatch = {
  closeDialog: () => closeDialog("eventSettings"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type GroupConfiguration = {
  id: string;
  title: string;
  subtitle: string;
  content: JSX.Element;
};
type EventSettingsProps = WithTranslation &
  PropsFromRedux & { eventId: string };
type EventSettingsState = {
  currentGroup: string | undefined;
  useTabs: boolean;
};
class EventSettings extends React.Component<
  EventSettingsProps,
  EventSettingsState
> {
  state: EventSettingsState = {
    currentGroup: undefined,
    useTabs: false,
  };
  groups: GroupConfiguration[];
  constructor(props: EventSettingsProps) {
    super(props);
    this.groups = generateEventConfigurations(props.t, props.eventId);
  }

  render() {
    const { t, hasAccess } = this.props;

    if (!hasAccess) return null;
    // <SettingsWithAccordion classes={classes} groups={groups} />
    /*
    <SettingsWithTabs
            classes={classes}
            groups={groups}
            value={this.state.currentGroup}
            changeValue={(v) => this.setState({ currentGroup: v })}
          />
          */
    let propsForGrouper: SettingsManagerProps = {
      groups: this.groups,
      value: this.state.currentGroup,
      changeValue: (v) => this.setState({ currentGroup: v }),
    };
    return (
      <Dialog
        open={true}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth="lg"
        disableEnforceFocus
      >
        <Box>
          <DialogTitle>{t("evset.title.main")}</DialogTitle>
          <FormControlLabel
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(2),
            }}
            control={
              <Switch
                checked={this.state.useTabs}
                onChange={(event) =>
                  this.setState({ useTabs: event.target.checked })
                }
              />
            }
            label={t("evset.usetabs")}
          />
        </Box>

        <DialogContent>
          {this.state.useTabs ? (
            <SettingsWithTabs {...propsForGrouper} />
          ) : (
            <SettingsWithAccordion {...propsForGrouper} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.closeDialog()}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(EventSettings));
