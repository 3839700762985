import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "@firebase/firestore/lite";
import { getDoc } from "firebase/firestore/lite";

const mapState = (state: RootState) => ({
  teamsList: state.teamsList,
  eventId: state.eventId,
  maybeTid: state.dialog.sendMsg,
});
const mapDispatch = {
  closeDialog: () => closeDialog("sendMsg"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SendMsgDialogProps = PropsFromRedux &
  WithTranslation & {
    open?: boolean;
    customClose?: Function;
  };

type MessageTarget = "team" | "everyone";
type MessageType = "command" | "message";
type CommandString = "sendLog" | "sendGPX" | "sendDB";
type SendMsgDialogState = {
  target: MessageTarget;
  targetTeam: string;
  type: MessageType;
  command: CommandString;
  title: String;
  msg: String;
};
class SendMsgDialog extends React.Component<
  SendMsgDialogProps,
  SendMsgDialogState
> {
  constructor(props: SendMsgDialogProps) {
    super(props);
    const targettid =
      typeof this.props.maybeTid === "string" ? this.props.maybeTid : undefined;
    this.state = {
      target: targettid ? "team" : "everyone",
      targetTeam: targettid ?? "unset",
      type: "message",
      command: "sendLog",
      title: "",
      msg: "",
    };
  }

  sendMessage = async () => {
    let queryparams: String[] = [];
    if (this.state.target === "team" && this.state.targetTeam !== undefined) {
      const userdoc = await getDoc(
        doc(getFirestore(), "users", this.state.targetTeam)
      );
      const userdata = userdoc.data();
      if (!userdoc.exists || userdata === undefined) {
        console.error("Required user does not exist");
        return;
      }
      const tokens = userdata["tokens"];
      queryparams.push("token=" + tokens.join(","));
    } else {
      queryparams.push(
        "topic=event-" +
          this.props.eventId?.replace(RegExp("[^a-zA-Z0-9-_.~%]"), "")
      );
    }
    if (this.state.type === "command") {
      queryparams.push("cmnd=" + this.state.command);
    } else {
      if (this.state.title) queryparams.push("title=" + this.state.title);
      if (this.state.msg) queryparams.push("body=" + this.state.msg);
    }

    console.log("query params: ", queryparams.join("&"));
    getAuth()
      .currentUser?.getIdToken()
      .then((token) => {
        fetch(
          "https://europe-west1-nutilogi.cloudfunctions.net/api/sendmsg?" +
            queryparams.join("&"),
          {
            headers: {
              Authorization: "Bearer " + token,
              "X-EventId": this.props.eventId,
            } as HeadersInit,
          }
        )
          .then((data) => {
            console.log("fetch complete");
            return data.json();
          })
          .then((data) => {
            if (data.successCount === 0 && data.failureCount > 0) {
              let msgtext = "";
              data.results.forEach((res: any) => {
                if (!res.error) return;
                msgtext += res.error.message;
              });
              console.log("Failed to send message", msgtext);
              //this.props.showAlert('Failed to send message', msgtext);
            } else {
              console.log("send message: ", data);
            }
            console.log(data);
          })
          .catch((e) => {
            console.log("got error on fetch");
          });
      });
    //      this.doClose();
  };

  doClose = () => {
    if (this.props.customClose) this.props.customClose();
    else this.props.closeDialog();
  };
  render() {
    const { teamsList } = this.props;
    return (
      <>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container direction="column" spacing={1}>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <FormLabel component="legend">Target</FormLabel>
                  <RadioGroup
                    aria-label="target"
                    name="target"
                    value={this.state.target}
                    onChange={(event) =>
                      this.setState({
                        target: event.target.value as MessageTarget,
                      })
                    }
                  >
                    <Grid container direction="column" spacing={1}>
                      <Grid item sm={10}>
                        <Grid container spacing={1}>
                          <Grid item sm={6}>
                            <FormControlLabel
                              value="team"
                              control={<Radio />}
                              label="Team"
                            />
                          </Grid>
                          <Grid item sm={6}>
                            <FormControl
                              disabled={this.state.target !== "team"}
                            >
                              <InputLabel id="targetTeamLabel">
                                Team:
                              </InputLabel>
                              <Select
                                labelId="targetTeamLabel"
                                id="targetTeam"
                                value={this.state.targetTeam}
                                onChange={(event) =>
                                  this.setState({
                                    targetTeam: event.target.value as string,
                                  })
                                }
                              >
                                {Object.entries(teamsList).map(
                                  ([tid, team]) => (
                                    <MenuItem key={tid} value={tid}>
                                      {team.name}
                                    </MenuItem>
                                  )
                                )}
                                <MenuItem key="unset" value="unset">
                                  {this.props.t("sendmsg.pickteam")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControlLabel
                          value="everyone"
                          control={<Radio />}
                          label="Everyone"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item sm={6}>
                  <FormLabel component="legend">Message type</FormLabel>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={this.state.type}
                    onChange={(event) =>
                      this.setState({ type: event.target.value as MessageType })
                    }
                  >
                    <Grid container direction="column" spacing={1}>
                      <Grid item sm={10}>
                        <Grid container spacing={1}>
                          <Grid item sm={6}>
                            <FormControlLabel
                              value="command"
                              control={<Radio />}
                              label="Command"
                            />
                          </Grid>
                          <Grid item sm={6}>
                            <FormControl
                              disabled={this.state.type !== "command"}
                            >
                              <InputLabel id="commandLabel">
                                Command:
                              </InputLabel>
                              <Select
                                labelId="commandLabel"
                                id="command"
                                value={this.state.command}
                                onChange={(event) =>
                                  this.setState({
                                    command: event.target
                                      .value as CommandString,
                                  })
                                }
                              >
                                <MenuItem value={"sendLog"}>
                                  Saada Logi
                                </MenuItem>
                                <MenuItem value={"sendGPX"}>Saada GPX</MenuItem>
                                <MenuItem value={"sendDB"}>
                                  Saada andmebaas
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControlLabel
                          value="message"
                          control={<Radio />}
                          label="Message"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid>
                <TextField
                  disabled={this.state.type !== "message"}
                  name="title"
                  type="text"
                  label="Title"
                  variant="outlined"
                  margin="normal"
                  value={this.state.title}
                  onChange={(event) => {
                    this.setState({ title: event.target.value });
                  }}
                />
                <TextField
                  disabled={this.state.type !== "message"}
                  name="message"
                  type="text"
                  label="Message"
                  variant="outlined"
                  maxRows={10}
                  rows={20}
                  margin="normal"
                  fullWidth
                  multiline
                  value={this.state.msg}
                  onChange={(event) => {
                    this.setState({ msg: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.sendMessage}>
            Send
          </Button>
          <Button onClick={this.doClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default connector(withTranslation()(SendMsgDialog));
