import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Done } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";
import { FBValueToArray } from "utils/SmallUtils";
import FBTextField from "./FBTextField";

const FirstBonusConfigCard: React.FC = () => {
  const firstbonusvalue = useTSelector((state) => state.event.firstbonus);
  const firstbonusismultiplier = useTSelector(
    (state) => state.event.firstbonusismultiplier
  );
  const eventId = useTSelector((state) => state.eventId);
  const [editconf, setEditConf] = useState(null as number | null);
  const { fbSet } = useNL();
  const { t } = useTranslation();
  const bonuspath = `eventsdata/${eventId}/data/firstbonus/`;
  const [firstbonus, hasbadvalue] = FBValueToArray<number>(firstbonusvalue);
  if (hasbadvalue) {
    return (
      <Card>
        <CardContent>
          t{"firstbouns.broken"}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              fbSet(bonuspath, null, t("firstbonus.undoclear"));
            }}
          >
            {t("firstbonus.clear")}
          </Button>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader title={t("firstbonus.title")} />
      <CardContent>
        <RadioGroup
          aria-label="firstbonusttype"
          value={firstbonusismultiplier ? "multiplier" : "add"}
          onChange={(event, value) => {
            fbSet(
              `eventsdata/${eventId}/data/firstbonusismultiplier/`,
              value === "multiplier" ? true : false
            );
            console.log(event, value);
          }}
        >
          <FormControlLabel
            value="add"
            control={<Radio />}
            label={t("firstbonus.isadd")}
          />
          <FormControlLabel
            value="multiplier"
            control={<Radio />}
            label={t("firstbonus.ismultiplier")}
          />
        </RadioGroup>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            let newidx = 1;
            let lastvalue = 6;
            while (firstbonus[newidx] !== undefined) {
              lastvalue = firstbonus[newidx];
              newidx++;
            }
            console.log(newidx);
            fbSet(bonuspath + newidx.toString(), lastvalue - 1);
          }}
        >
          {t("firstbonus.add")}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("firstbonus.headlabel.index")}</TableCell>
              <TableCell>{t("firstbonus.headlabel.value")}</TableCell>
              <TableCell>{t("firstbonus.headlabel.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {firstbonus.map((bonusvalue, idx) => (
              <TableRow key={idx}>
                <TableCell>{idx}</TableCell>
                <TableCell align="center" onClick={() => setEditConf(idx)}>
                  {editconf === idx ? (
                    <ClickAwayListener onClickAway={() => setEditConf(null)}>
                      <div>
                        <FBTextField
                          style={{ width: "14ch" }}
                          type="number"
                          id="rakoef"
                          path={bonuspath + idx.toString()}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="save first bonus"
                                  onClick={(event) => {
                                    setEditConf(null);
                                    event.stopPropagation();
                                  }}
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  size="large">
                                  <Done />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    bonusvalue
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => fbSet(bonuspath + idx.toString(), null)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default FirstBonusConfigCard;
