import { Component } from "react";
import { connect } from "react-redux";
import "./EventResult.css";
import SimpleTextInput from "./SimpleTextInput";
import { makeGetResult, calculateTimeResult } from "../utils/ValikResultCalc";
import { getDatabase, ref, set } from "firebase/database";
import { styled } from "@mui/material";

const Root = styled("tr")({
  "& .middlekokku": {
    fontWeight: "bold",
  },
  "& .kokku": {
    fontWeight: "bold",
    fontSize: "1.5em",
  }
})
class LyCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doedit: false,
    };
  }
  lyClicked = (event) => {
    this.setState({ doedit: true });
    event.stopPropagation();
  };
  handleClose = (val) => (event) => {
    event.stopPropagation();
    if (val !== null) {
      let setval = Number(val);
      if (setval === 0 || isNaN(setval)) setval = null;
      set(ref(getDatabase(), "/teams/" + this.props.eventId + "/list/" + this.props.teamid + "/lypoints/" + this.props.lyid), setval);
    }
    this.setState({ doedit: false });
  };
  render() {
    const { lyid, changes, value, teamName, ly, eventAccess } = this.props;
    let ck = changes.filter((c) => c === "ly/" + lyid).length;
    let classes = "";
    if (ck > 0) classes += " highlightchange" + (ck % 2);

    return (
      <td
        key={lyid}
        className={classes}
        onClick={eventAccess ? this.lyClicked : null}
      >
        {value}
        {this.state.doedit && (
          <SimpleTextInput
            desc={"Lisaylessande " + ly.name + " punktid teamile " + teamName}
            type="number"
            handleClose={this.handleClose}
            value={value}
          />
        )}
      </td>
    );
  }
}

class ValikTeamResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
      changes: [],
      editly: null,
    };
  }

  componentDidUpdate(prevProps) {
    let changes = [];
    if (Object.keys(prevProps.result).length !== 0) {
      changes = this.findChanges(this.props.result, prevProps.result);
      //console.log('found changs', changes, this.props.result, prevProps.result);
      if (changes.length === 0) return;
    }
    if (changes.length > 0) {
      this.setState((prevState) => {
        return {
          changes: prevState.changes.concat(changes),
        };
      });
    }
  }
  componentWillUnmount() {
    if (this.timeupdatetimer) clearTimeout(this.timeupdatetimer);
  }

  findChanges(a, b, prefix = "") {
    let changes = [];
    if (prefix === "data/") return changes;
    for (let k of Object.keys(a)) {
      if (typeof a[k] === "object")
        changes = changes.concat(this.findChanges(a[k], b[k], k + "/"));
      else if (b === undefined || !Object.is(a[k], b[k]))
        changes.push(prefix + k);
    }
    return changes;
  }

  displayKoht(koht) {
    if (koht === 1) return <b>I</b>;
    else if (koht === 2) return <b>II</b>;
    else if (koht === 3) return <b>III</b>;
    return koht;
  }

  render() {
    const {
      koht,
      rakoef,
      result,
      havekiirustrahvid,
      distanceinresult,
      havepospenaltys,
      penaltypos,
      onClick,
      ly,
      eventAccess,
      firstkpvisitbonus,
      katk,
      dsq,
      pointssuffix,
      hidewrongmarks,
      targetdst
    } = this.props;
    if (result.sum === undefined) return null;

    let newresult = { data: result.data };
    let nextchange = calculateTimeResult(
      newresult,
      result.data.starttime,
      result.data.finishtime,
      result.data.ajad
    );
    if (newresult.timepenaltytotal !== result.timepenaltytotal) {
      console.log("not eq");
      result.timepenaltys.forEach((oldtp, idx) => {
        if (oldtp !== newresult.timepenaltys[idx])
          this.state.changes.push("timepenaltys/" + idx);
      });
      Object.assign(result, newresult);
      result.sum = result.kpkokku - result.timepenaltytotal - result.spdpenalty;
      this.state.changes.push("sum");
      this.state.changes.push("spenttime");
      this.state.changes.push("timepenaltytotal");
      this.props.newResult(result);
    }
    if (nextchange) {
      if (this.timeupdatetimer) clearTimeout(this.timeupdatetimer);
      this.timeupdatetimer = setTimeout(() => {
        this.forceUpdate();
      }, nextchange * 1000 + 10);
    }

    //console.log('Render team', this.props.tid, this.state.changes);

    let htd = (e, k, cn = "", suf = undefined) => {
      let ck = this.state.changes.filter((c) => c === k).length;
      if (ck > 0) cn += " highlightchange" + (ck % 2);
      if (e === 0 || e === "0") e = "";
      return (
        <td
          key={k}
          className={cn}
          onAnimationEnd={() => {
            this.setState((prevState, prop) => {
              return { changes: prevState.changes.filter((c) => c !== k) };
            });
          }}
        >
          {e}
          {e.toString().length > 0 && suf && suf}
        </td>
      );
    };
    let sumval = result.sum;
    if (dsq) sumval = "DSQ(" + sumval + ")";
    else if (katk) sumval = "KATK(" + sumval + ")";
    else if (result.data.overtime) sumval = "AEG(" + sumval + ")";
    return (
      <Root onClick={onClick}>
        <td>{this.displayKoht(koht)}</td>
        <td>{this.props.teamName}</td>
        {[...rakoef.keys()]
          .filter((i) => rakoef[i])
          .map((ra) => htd(result.rasums[ra] + ((result.firstbonus && result.firstbonus[ra] > 0) ? "+" + result.firstbonus[ra] : ""), "rasums/" + ra, "", pointssuffix))}
        {firstkpvisitbonus && htd(result.firstkpsbonus, "firstkpsbonus")}
        {htd(result.okcount, "okcount")}
        {!hidewrongmarks && htd(result.wrongcount, "wrongcount")}
        {htd(result.kppenalty, "kppenalty", "", pointssuffix)}
        {htd(result.kpkokku, "kpkokku", "middlekokku", pointssuffix)}
        {result.timepenaltys.map((t, idx) => htd(t, "timepenaltys/" + idx))}
        {result.timepenaltys.length > 1 &&
          htd(result.timepenaltytotal, "timepenaltytotal")}
        {Object.keys(ly)
          .filter(
            (lyid) =>
              this.props.ly[lyid].forclasses === undefined ||
              this.props.ly[lyid].forclasses.includes(this.props.klass)
          )
          .map((lyid) => (
            <LyCell
              key={lyid}
              changes={this.state.changes}
              value={result.ly[lyid]}
              onAnimationEnd={() => {
                this.setState((prevState, prop) => {
                  return {
                    changes: prevState.changes.filter(
                      (c) => c !== "ly/" + lyid
                    ),
                  };
                });
              }}
              lyid={lyid}
              teamid={this.props.tid}
              teamName={this.props.teamName}
              eventId={this.props.eventId}
              ly={ly[lyid]}
              eventAccess={eventAccess}
            />
          ))}
        {havekiirustrahvid && htd(result.spdpenalty, "spdpenalty")}
        {havepospenaltys && htd(penaltypos, "penaltypos")}
        {htd(result.spenttime, "spenttime")}
        {distanceinresult && (<>{htd(result.dst, "dst")}
          {Boolean(targetdst) && htd(result.dstpoints ?? 0, "dstpoints")}</>)}
        {htd(sumval, "sum", "kokku", pointssuffix)}
      </Root>
    );
  }
}

/* Replace createSelector with createMyEqualSelector for rerendering investigation
const createMyEqualSelector = createSelectorCreator(
    defaultMemoize,
    (a, b) => {
        console.log('my eqaul', a, b, a == b);
        return a == b;
    }
)
*/

const makeMapStateToProps = () => {
  const getResult = makeGetResult();
  const mapStateToProps = (state, props) => {
    return {
      eventId: state.eventId,
      eventAccess: Boolean(state.user.eventAccess),
      hidewrongmarks: state.event.hidewrongmarks,
      teamName: state.teamsList[props.tid].name,
      katk: state.teamsList[props.tid].katk,
      dsq: state.teamsList[props.tid].dsq,
      penaltypos: state.teamsList[props.tid].penaltypos,
      rakoef: state.event.rakoef,
      firstkpvisitbonus: state.event.firstkpvisitbonus,
      havekiirustrahvid: props.havekiirustrahvid,
      distanceinresult: state.event.distanceinresult,
      targetdst: state.event.dstresult?.targetdst,
      ly: state.event.ly || {},
      result: getResult(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ValikTeamResult);
