import { ListItemButton } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import {
  getDatabase,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
} from "firebase/database";
import React, { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { onOpenNewEvent } from "rx/appStateSlice";
import { setEventId } from "rx/eventIdSlice";

export type EventEntry = {
  id: string;
  name: string;
  starttime: number;
};
type GroupedEvents = {
  [key: string]: EventEntry[];
};

const OpenEventDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [events, setEvents] = useState<GroupedEvents>();
  const [filteredEvents, setFilteredEvents] = useState<GroupedEvents>({});
  const dispatch = useDispatch();

  useEffect(() => {
    //const q = query(ref(getDatabase(), "events"), orderByChild("starttime"));
    const q = ref(getDatabase(), "events");
    var sourceevents: GroupedEvents = {};
    let evcount = 0;
    const unsubChildAdded = onChildAdded(
      q,
      (snap) => {
        const entry: EventEntry = snap.val();
        if (snap.key === null || typeof entry !== "object") {
          console.warn("Bad entry in event list:", snap.key, entry);
          return;
        }
        entry.id = snap.key;
        const groupid = dayjs(entry.starttime).format("YYYY MM");
        if (sourceevents[groupid] === undefined) sourceevents[groupid] = [];
        sourceevents[groupid].push(entry);
        evcount++;
        // if (evcount > 5) setEvents(Object.assign({}, sourceevents));
        if (evcount > 5) setEvents(sourceevents);
      },
      (error) => {
        console.error("Got cancel callback", error);
      }
    );
    const unsubChildChanged = onChildChanged(q, (snap) => {
      const entry: EventEntry = snap.val();
      if (snap.key === null) return;
      entry.id = snap.key;
      const groupid = dayjs(entry.starttime).format("YYYY MM");
      const idx = sourceevents[groupid].findIndex((v) => v.id === snap.key);
      if (idx !== -1) {
        sourceevents[groupid][idx] = entry;
        setEvents(Object.assign({}, sourceevents));
      }
    });
    const unsubChildRemoved = onChildRemoved(q, (snap) => {
      const entry: EventEntry = snap.val();
      if (snap.key === null) return;
      entry.id = snap.key;
      const groupid = dayjs(entry.starttime).format("YYYY MM");
      const idx = sourceevents[groupid].findIndex((v) => v.id === snap.key);
      if (idx !== -1) {
        sourceevents[groupid].splice(idx, 1);
        setEvents(Object.assign({}, sourceevents));
      }
    });
    return () => {
      unsubChildAdded();
      unsubChildChanged();
      unsubChildRemoved();
    };
  }, []);
  useEffect(() => {
    let sc = searchString.toLowerCase();
    var fe: GroupedEvents = {};
    if (events === undefined) {
      setFilteredEvents({});
      return;
    }
    Object.entries(events).forEach(([k, v]) => {
      const ge = v.filter(
        (ev) => ev.name && ev.name.toLowerCase().includes(sc)
      );
      if (ge.length > 0) fe[k] = ge.sort((l, r) => r.starttime - l.starttime);
    });
    setFilteredEvents(fe);
  }, [events, searchString, setFilteredEvents]);

  const handleSearchStringChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchString(event.target.value);
  };
  const handleOpen = (eventid: string) => () => {
    dispatch(onOpenNewEvent());
    dispatch(setEventId(eventid));
    window.history.pushState(null, "Title for page", "/" + eventid);
    onClose();
  };
  return (
    <Dialog open={true} maxWidth="sm" fullWidth={true} fullScreen={isMobile}>
      <DialogTitle id="open-event-title">{t("openevent.title")}</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        {events === undefined ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                aria-label="Search event"
                id="searchev"
                label={t("openevent.search.label")}
                placeholder={t("openevent.search.placeholder")}
                variant="outlined"
                autoFocus={true}
                onChange={handleSearchStringChange}
                value={searchString}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <List
                sx={{
                  position: "relative",
                  overflow: "auto",
                  height: "60vh",
                }}
                subheader={<div />}
              >
                {Object.keys(filteredEvents)
                  .sort()
                  .reverse()
                  .map((k) => (
                    <Fragment key={k}>
                      <ListSubheader sx={{ fontSize: 24, fontWeight: 700 }}>
                        {dayjs(filteredEvents[k][0].starttime).format(
                          "YYYY MMMM"
                        )}
                      </ListSubheader>
                      {filteredEvents[k].map((ev) => (
                        <ListItemButton key={ev.id} onClick={handleOpen(ev.id)}>
                          <ListItemText
                            primary={ev.name}
                            secondary={dayjs(ev.starttime).format("D MMM")}
                          />
                        </ListItemButton>
                      ))}
                    </Fragment>
                  ))}
              </List>
            </Grid>
          </Grid>
        )}
        <DialogActions>
          <Button
            aria-label="close"
            onClick={onClose}
            color="primary"
            variant="contained"
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default OpenEventDialog;
