import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";

const FBSelect: React.FC<
  SelectProps & {
    path: string;
    id: string;
    items: { value: string; desc: string }[];
    defaultvalue: string;
  }
> = (props) => {
  const [fbvalue, setFbValue] = useFirebaseDb(props.path);
  const { t } = useTranslation();

  return (
    <NLTooltipWrapper tkey={`fbf.${props.id}.tooltip` as any}>
      <SkeletonWrapper condition={fbvalue.value === undefined}>
        <FormControl>
          <InputLabel>{t(`fbf.${props.id}.label` as any)}</InputLabel>
          <Select
            value={fbvalue.value || props.defaultvalue || props.items[0].value}
            onChange={(event, child) => {
              setFbValue(
                props.id,
                t(`fbf.${props.id}.changed` as any),
                event.target.value
              );
              if (props.onChange) {
                props.onChange(event, child);
              }
            }}
          >
            {props.items.map((element) => (
              <MenuItem key={element.value} value={element.value}>
                {element.desc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};
export default FBSelect;
