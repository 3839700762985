import React, { ReactElement } from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Box,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { AreaObjectType } from "rx/simpleFirstoreDocSlice";
import { AddCircleRounded } from "@mui/icons-material";
import { execWithProgress } from "rx/saveInProgressSlice";
import DrawerHeader from "./DrawerHeader";
import SpeedAreasEditor from "./SpeedAreasEditor";
import SpeedArea from "./SpeedArea";
import * as L from "leaflet";
import { grey } from "@mui/material/colors";
import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

const mapState = (state: RootState) => ({
  eventId: state.eventId,
  eventAreas: state.eventDoc?.speedareas || {},
});
const mapDispatch = {
  execWithProgress: (f: () => Promise<any>) => execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SpeedAreasDrawerProps = PropsFromRedux &
  WithTranslation & {
    closeDrawer: () => void;
  };
type SpeedAreasDrawerState = {
  filterAreas: string;
  allAreas: {
    [auid: string]: {
      [aid: string]: AreaObjectType;
    };
  };
  showAllAreas: boolean;
};
class SpeedAreasDrawer extends React.Component<
  SpeedAreasDrawerProps,
  SpeedAreasDrawerState
> {
  state: SpeedAreasDrawerState = {
    filterAreas: "",
    allAreas: {},
    showAllAreas: false,
  };
  unsubscribeSpeedareas?: Unsubscribe;
  componentDidMount() {
    this.unsubscribeSpeedareas = onSnapshot(
      collection(getFirestore(), "speedareas"),
      (qsnap) => {
        let newAreas: {
          [auid: string]: {
            [aid: string]: AreaObjectType;
          };
        } = {};
        qsnap.forEach((snap) => {
          const data = snap.data();
          if (!data.speedareas) return;
          newAreas[snap.id] = data.speedareas;
        });
        this.setState({ allAreas: newAreas });
      }
    );
    /*
    this.spdocunlisten = spdocref.onSnapshot((snap) => {
      const data = snap.data();
      if (data && data.speedareas) this.setState({ allAreas: data.speedareas });
    });
    */
  }
  componentWillUnmount() {
    this.unsubscribeSpeedareas && this.unsubscribeSpeedareas();
  }

  addAreaToEvent = (auid: string, aid: string) => async () => {
    this.props.execWithProgress(() => {
      return updateDoc(doc(getFirestore(), "events", this.props.eventId!), {
        ["speedareas." + auid + ":" + aid]: this.state.allAreas[auid][aid],
      });
    });
  };
  render() {
    const { t } = this.props;

    let areaitems: ReactElement[] = [];
    const filterstr = this.state.filterAreas.toLowerCase();
    Object.entries(this.state.allAreas).forEach(([auid, areas]) => {
      Object.entries(areas).forEach(([aid, area]) => {
        const key = auid + ":" + aid;
        if (key in this.props.eventAreas) return;
        if (
          filterstr.length > 0 &&
          !(
            area.name.toLowerCase().includes(filterstr) ||
            area.limit?.toString().includes(filterstr)
          )
        )
          return;

        areaitems.push(
          <ListItem key={key}>
            <ListItemText
              secondary={
                area.restricted
                  ? t("speedarea.restricted")
                  : area.limit + " km/h"
              }
            >
              {area.name}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton onClick={this.addAreaToEvent(auid, aid)} size="large">
                <AddCircleRounded />
              </IconButton>
            </ListItemSecondaryAction>
            {this.state.showAllAreas && (
              <SpeedArea
                map={mainMap}
                points={area.area.map(
                  (p) => new L.LatLng(p.latitude, p.longitude)
                )}
                color={grey[800]}
                enableEdit={false}
              />
            )}
          </ListItem>
        );
      });
    });

    /*
                      {this.state.allAreas &&
            Object.entries(this.props.eventAreas).map(([aid, area]) => (
              <ListItem key={aid}>{area.name}</ListItem>
            ))}
            */
    return (
      <>
        <DrawerHeader
          closeDrawer={this.props.closeDrawer}
          title={t("menu.title.speedareas")}
        >
          <Box display="flex" justifyContent="center">
            <FormControlLabel
              label={t("speedarea.showall")}
              control={
                <Switch
                  checked={this.state.showAllAreas}
                  onChange={(event) =>
                    this.setState({ showAllAreas: event.target.checked })
                  }
                />
              }
            />
          </Box>

          <SpeedAreasEditor />
        </DrawerHeader>
        <List
          sx={{
            position: "relative",
            overflow: "auto",
          }}
          dense
          subheader={
            <ListSubheader
              color="primary"
              component="div"
              sx={{
                fontSize: 24,
                fontWeight: 700,
              }}
            ></ListSubheader>
          }
        >
          <ListSubheader color="primary">
            {t("speedareas.all")}
            <TextField
              label={t("speedareas.filter")}
              value={this.state.filterAreas}
              onChange={(event) =>
                this.setState({ filterAreas: event.target.value })
              }
            />
          </ListSubheader>
          {areaitems}
        </List>
      </>
    );
  }
}

export default connector(withTranslation()(SpeedAreasDrawer));
