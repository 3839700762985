import { Divider, IconButton, styled, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch } from "react-redux";
import {
  drawerMenuContentTypes,
  setDrawerMenuContent,
} from "rx/appStateSlice";
import { Close } from "@mui/icons-material";

export const DrawerHeaderWithStyles = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const StickyDrawerHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "0px",
  backgroundColor: "white",
  zIndex: theme.zIndex.drawer,
}));

const DrawerHeader: React.FC<{
  closeDrawer: () => void;
  title?: string;
  children?: React.ReactNode;
}> = (props) => {
  const dispatch = useDispatch();

  return (
    <StickyDrawerHeader>
      <DrawerHeaderWithStyles>
        {props.title && (
          <>
            <IconButton
              onClick={() =>
                dispatch(setDrawerMenuContent(drawerMenuContentTypes.Default))
              }
              size="large"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
              {props.title}
            </Typography>
          </>
        )}
        <IconButton
          aria-label="close drawer"
          onClick={props.closeDrawer}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeaderWithStyles>
      <Divider />
      {props.children}
    </StickyDrawerHeader>
  );
};

export default DrawerHeader;
