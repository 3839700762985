import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { TrackDecoder } from "@nutilogi/trzip";
import { PointData } from "@nutilogi/nutilogitypes";

export const loadTrakcsFromStorage = async (
  eventId: string,
  addPointsCb: (tid: string, devid: string, points: PointData[]) => void
) => {
  const listing = await listAll(
    ref(getStorage(), `/nutilogi/events/${eventId}`)
  );
  for (let lit of listing.items) {
    //console.log(lit, lit.fullPath, lit.name);
    if (!lit.name.endsWith("_ws.data")) {
      continue;
    }
    console.log("donwoadl", lit.name);
    await getDownloadURL(lit)
      .then((url) => fetch(url))
      .then((r) => r.arrayBuffer())
      .then((abuf) => {
        console.log(lit.name, "completed");
        let decodeddata = TrackDecoder.decode(Buffer.from(abuf));
        Object.entries(decodeddata).forEach(([tid, tdata]) => {
          Object.entries(tdata).forEach(([devid, points]) => {
            if (points.length > 0) addPointsCb(tid, devid, points);
          });
        });
      });
  }
};
