import React, { useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFirestore } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import { useNL } from "utils/NLContext";

const FSTextField: React.FC<
  TextFieldProps & { id: string; path: string; field: string }
> = (props) => {
  const [fsvalue] = useFirestore(props.path);
  const { t, i18n } = useTranslation();
  const { fsUpdate } = useNL();
  const [state, setState] = useState({
    value: undefined,
    fbchanges: 0,
    timer: null as NodeJS.Timeout | null,
  });
  if (
    fsvalue.doc &&
    state.fbchanges < fsvalue.changecount &&
    state.timer === null
  ) {
    setState(
      Object.assign({}, state, {
        value: fsvalue.doc.get(props.field) || "",
        fbchanges: fsvalue.changecount,
      })
    );
  }

  return (
    <NLTooltipWrapper tkey={`fbf.${props.id}.tooltip` as any}>
      <SkeletonWrapper condition={state.value === undefined}>
        <TextField
          value={state.value}
          label={t(`fbf.${props.id}.label` as any)}
          {...props}
          onChange={(event) => {
            if (state.timer) {
              clearTimeout(state.timer);
            }
            state.timer = setTimeout(() => {
              setState((state) => Object.assign({}, state, { timer: null }));
              fsUpdate(
                props.path,
                {
                  [props.field]:
                    event.target.type === "number"
                      ? Number(event.target.value)
                      : event.target.value,
                },
                t(`fbf.${props.id}.changed` as any),
                props.id
              );
            }, 300);
            setState(Object.assign({}, state, { value: event.target.value }));
          }}
          helperText={
            props.helperText || i18n.exists(`fbf.${props.id}.helper`)
              ? t(`fbf.${props.id}.helper` as any, {
                  count: Number(state.value),
                  value: state.value,
                })
              : undefined
          }
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FSTextField;
