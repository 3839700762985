import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { TeamList } from "rx/fbListSlices";
import { useSnackbar } from "notistack";
import { child, get, getDatabase, ref, remove, set } from "firebase/database";

export type DeletedTeamsEntry = {
  deletetime: number;
  tid: string;
  list: TeamList;
};
const RestoreDeletedTeams: React.FC<{
  onClose: () => void;
  teams: { [key: string]: DeletedTeamsEntry };
  eventId: string;
}> = ({ onClose, teams, eventId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const restoreTeam = (delid: string, team: DeletedTeamsEntry) => {
    const db = getDatabase();
    const teamsref = ref(db, `/teams/${eventId}`);
    const fromref = ref(db, `/eventsdata/${eventId}/deletedteams/${delid}`);
    let promlist: PromiseLike<any>[] = [];
    [
      "list",
      "data",
      "answerresult",
      "kpanswers",
      "spdtickets",
      "track",
      "trackstat",
    ].forEach((key) => {
      const keyref = child(fromref, key);
      promlist.push(
        get(keyref).then((snap) => {
          return set(child(teamsref, key + "/" + team.tid), snap.val());
        })
      );
    });
    Promise.all(promlist).then(() => {
      remove(fromref).then(() => {
        enqueueSnackbar("Restored team: " + team.list.name, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
    });
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t("restoredeletedteam.title")}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("deletedteam.teamname")}</TableCell>
              <TableCell>{t("deletedteam.deletetime")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(teams).map(([entryid, team]) => (
              <TableRow key={entryid}>
                <TableCell>{team.list.name}</TableCell>
                <TableCell>
                  {dayjs(team.deletetime).format("DD MMM YYYY HH:mm:ss")}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => restoreTeam(entryid, team)}
                  >
                    {t("button.restore")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreDeletedTeams;
