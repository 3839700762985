import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LayersControl, FeatureGroup, useMap } from "react-leaflet";
import { useTSelector } from "rx/store";
import LeafletRectangle from "utils/LeafletRectangle";
import { useNL } from "utils/NLContext";
import NutiMap from "utils/NutiMap";
import FBSelect from "./FBSelect";
import * as L from "leaflet";
import "leaflet-providers";
import { useTranslation } from "react-i18next";
import KPMarker from "utils/KPMarker2";
import { ZoomLevelListener } from "utils/MapUtils";
import { MaaametVariants } from "utils/MaaAmetLayers";

const InitialZoomToBounds: React.FC<{ bounds?: L.LatLngBounds }> = ({
  bounds,
}) => {
  const map = useMap();
  const latestBounds = useRef(bounds);
  useEffect(() => {
    latestBounds.current = bounds;
  });
  useEffect(() => {
    if (latestBounds.current) map.fitBounds(latestBounds.current);
  }, [map]);
  return null;
};
const LeafletConfiguration: React.FC<{ eventId: string }> = ({ eventId }) => {
  const { fbSet } = useNL();
  const { t } = useTranslation();
  const leafletProviders = (L.TileLayer as any).Provider.providers;
  const conf = useTSelector((state) => state.event.map?.default?.conf);
  const [provider, variant] = conf?.split(".") || ["OpenStreetMap", undefined];
  console.log("provval", provider, variant, leafletProviders);
  return (
    <>
      <FormControl>
        <InputLabel>{t(`leaflet.label.provider`)}</InputLabel>
        <Select
          value={provider}
          onChange={(event) => {
            console.log("setting value", event.target.value);
            fbSet(
              "eventsdata/" + eventId + "/data/map/default/conf",
              event.target.value
            );
          }}
        >
          {Object.keys(leafletProviders).map((lp) => (
            <MenuItem key={lp} value={lp}>
              {lp}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {provider &&
        Object.keys(leafletProviders[provider].variants || {}).length > 0 && (
          <FormControl>
            <InputLabel>{t(`leaflet.label.variant`)}</InputLabel>
            <Select
              value={variant || "--"}
              onChange={(event) => {
                console.log("got changed", provider + "." + event.target.value);
                fbSet(
                  "eventsdata/" + eventId + "/data/map/default/conf",
                  provider + "." + event.target.value
                );
              }}
            >
              {Object.keys(leafletProviders[provider].variants)
                .concat("--")
                .map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
    </>
  );
};

const KPFeatureGroup: React.FC = () => {
  const kplist = useTSelector((state) => state.kpList);
  const kpData = useTSelector((state) => state.kpData);
  return (
    <ZoomLevelListener>
      {(zoom) => {
        return (
          <FeatureGroup pathOptions={{ color: "red" }}>
            {kplist &&
              kpData &&
              Object.entries(kplist).map(([kpid, kp]) => (
                <KPMarker
                  key={kpid}
                  kp={kp}
                  kpdata={kpData[kpid]}
                  atzoom={zoom}
                />
              ))}
          </FeatureGroup>
        );
      }}
    </ZoomLevelListener>
  );
};

const EventBoundsRect: React.FC<{
  eventBounds: L.LatLngBounds | undefined;
  changedBounds: L.LatLngBounds | null;
  eventId: string;
  setChangedBounds: (bounds: L.LatLngBounds) => void;
}> = ({ eventBounds, changedBounds, eventId, setChangedBounds }) => {
  const map = useMap();
  const { fbSet } = useNL();

  return (
    <LeafletRectangle
      bounds={changedBounds || eventBounds}
      map={map}
      onCreated={(bounds: L.LatLngBounds) => {
        fbSet("eventsdata/" + eventId + "/data/eventbounds", {
          northEast: bounds.getNorthEast(),
          southWest: bounds.getSouthWest(),
        });
      }}
      onChanged={(bounds: L.LatLngBounds) => {
        setChangedBounds(bounds);
      }}
    />
  );
};

const EventSettingsMap: React.FC<{ eventId: string }> = ({ eventId }) => {
  const { fbSet } = useNL();
  const bounds = useTSelector((state) => state.event.eventbounds);
  const currentMapLayer = useTSelector(
    (state) => state.event.map?.default?.type
  );
  const [changedBounds, setChagnedBounds] = useState(
    null as L.LatLngBounds | null
  );
  const [enabledraw, setEnableDraw] = useState(false);
  const { t } = useTranslation();

  const eventBounds = bounds
    ? L.latLngBounds(bounds.southWest, bounds.northEast)
    : undefined;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <FBSelect
          id="defaultmap"
          path={"eventsdata/" + eventId + "/data/map/default/type"}
          items={[
            { value: "osm", desc: "OpenStreet Maps" },
            { value: "maaamet", desc: "Maaamet Maps" },
            { value: "nutimeri", desc: "Nutimeri" },
            { value: "leaflet", desc: "Leaflet Providers" },
            { value: "google", desc: "Google Maps" },
            { value: "bing", desc: "Bing Maps" },
          ]}
          defaultvalue="osm"
          onChange={() => {
            fbSet("eventsdata/" + eventId + "/data/map/default/conf", null);
          }}
        />
      </Grid>
      {currentMapLayer === "leaflet" && (
        <Grid item>
          <LeafletConfiguration eventId={eventId} />
        </Grid>
      )}
      {currentMapLayer === "google" && (
        <Grid item>
          <FBSelect
            id="googlevariant"
            path={"eventsdata/" + eventId + "/data/map/default/conf"}
            items={[
              { value: "roadmap", desc: "Roadmap" },
              { value: "satellite", desc: "Satellite" },
              { value: "terrain", desc: "Terrain" },
              { value: "hybrid", desc: "Hybrid" },
            ]}
            defaultvalue="roadmap"
          />
        </Grid>
      )}
      {currentMapLayer === "maaamet" && (
        <Grid item>
          <MaaametVariants eventId={eventId} />
        </Grid>
      )}
      {currentMapLayer === "bing" && (
        <Grid item>
          <FBSelect
            id="mapvariant"
            path={"eventsdata/" + eventId + "/data/map/default/conf"}
            items={[
              { value: "Aerial", desc: "Aerial" },
              { value: "AerialWithLabels", desc: "Aerial with roads" },
              {
                value: "AerialWithLabelsOnDemand",
                desc: "Aerial with ondemand roads",
              },
              { value: "CanvasDark", desc: "Dark roads" },
              { value: "CanvasLight", desc: "Lighter roads" },
              { value: "CanvasGray", desc: "Gray roads" },
              { value: "Road", desc: "Plain roads" },
              { value: "RoadOnDemand", desc: "Plain roads on denamd" },
            ]}
            defaultvalue="CanvasLight"
          />
        </Grid>
      )}
      {!eventBounds && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEnableDraw(!enabledraw)}
          >
            {enabledraw ? t("eventbounds.canceladd") : t("eventbounds.add")}
          </Button>
        </Grid>
      )}
      {changedBounds && (
        <>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                fbSet("eventsdata/" + eventId + "/data/eventbounds", {
                  northEast: changedBounds.getNorthEast(),
                  southWest: changedBounds.getSouthWest(),
                });
                setChagnedBounds(null);
              }}
            >
              {t("button.savechanges")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setChagnedBounds(null)}
            >
              {t("button.discardchanges")}
            </Button>
          </Grid>
        </>
      )}
      <Grid key="g1" item xs={12}>
        <div
          style={{
            height: "40vh",
            width: "100%",
          }}
        >
          <NutiMap disableLayersControl>
            <LayersControl position="topright">
              <LayersControl.Overlay checked name={t("map.layaers.showkps")}>
                <KPFeatureGroup />
              </LayersControl.Overlay>
            </LayersControl>
            <InitialZoomToBounds bounds={eventBounds} />
            {(eventBounds || enabledraw) && (
              <EventBoundsRect
                eventBounds={eventBounds}
                changedBounds={changedBounds}
                eventId={eventId}
                setChangedBounds={setChagnedBounds}
              />
            )}
          </NutiMap>
        </div>
      </Grid>
    </Grid>
  );
};

export default EventSettingsMap;
