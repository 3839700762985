import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { SlideUpTransition } from "utils/DialogUtils";

export type AlertDialogProps = {
  content?: string | JSX.Element;
  title?: string;
  buttons?: { id: string; label: string }[];
};
const AlertDialog: React.FC<
  AlertDialogProps & { open: boolean; onClose: (reason: string) => void }
> = (props) => {
  const { t } = useTranslation();
  let buttons = props.buttons || [
    { id: "ok", label: t("button.ok") },
    { id: "cancel", label: t("button.cancel") },
  ];
  return (
    <Dialog
      open={props.open}
      TransitionComponent={SlideUpTransition}
      onClose={(_, reason) => props.onClose(reason)}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        {buttons.map((b) => (
          <Button
            key={b.id}
            variant="contained"
            color="primary"
            onClick={() => {
              props.onClose(b.id);
            }}
          >
            {b.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
