import React, { useState } from "react";

import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import {
  NLTooltipWrapper,
  SkeletonWrapper,
  useUntypedTranslation,
} from "utils/SmallUtils";
import NLTimeField, { NLTimeFieldProps } from "./NLTimeField";

const FBNLTimeField: React.FC<
  Pick<NLTimeFieldProps, "style"> & {
    id: string;
    path: string;
  }
> = (props) => {
  const [fbvalue, setFbValue] = useFirebaseDb(props.path);
  const { t } = useUntypedTranslation();
  const [state, setState] = useState({
    value: undefined as number | undefined,
    fbchanges: 0,
    timer: null as NodeJS.Timeout | null,
  });
  if (
    fbvalue.value !== undefined &&
    state.fbchanges !== fbvalue.changecount &&
    state.timer === null
  ) {
    setState(
      Object.assign({}, state, {
        value: fbvalue.value || 0,
        fbchanges: fbvalue.changecount,
      })
    );
  }

  return (
    <NLTooltipWrapper tkey={`fbf.${props.id}.tooltip` as any}>
      <SkeletonWrapper condition={state.value === undefined}>
        <NLTimeField
          {...props}
          variant="standard" // TODO: Better to expose it from this component and use it in EventSettingsTimes (normaalaeg)
          value={state.value || 0}
          label={t(`fbf.${props.id}.label`)}
          onChange={(strvalue, value) => {
            let newState = Object.assign({}, state, { value: value });

            if (state.timer) {
              clearTimeout(state.timer);
            }
            state.timer = setTimeout(() => {
              setState((instate) =>
                Object.assign({}, instate, { timer: null })
              );
              setFbValue(
                props.id || "dummy",
                t(`fbf.${props.id}.changed`),
                newState.value
              );
            }, 300);
            setState(newState);
          }}
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FBNLTimeField;
