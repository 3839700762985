import React, { useMemo, useRef } from "react";
import { Editor as EditorReact } from "@tinymce/tinymce-react";
import { isMobile } from "react-device-detect";
import { useNL } from "utils/NLContext";
import { useTranslation } from "react-i18next";
import { Editor as TinyMCEEditor } from "tinymce";
import { getLangCodeList, getLangNameFromCode } from "language-name-map";
import { Ui } from "tinymce";


type EditorSetupCallbacks = {
  setLanguage?: (lng: string) => void;
  doSave: (data: string) => void;
  setSaveableContent: (v: boolean) => void;
  getUsedLanguages?: () => Promise<{ [lng: string]: string }>;
};

const Editor: React.FC<{
  initialValue?: string;
  value?: string;
  height?: number;
  disableLanguages?: boolean;
  onEditorChange?: (a: string, editor: TinyMCEEditor) => void;
  onSetup?: (editor: TinyMCEEditor, callbacks: EditorSetupCallbacks) => void;
  onInit?: (evt: any, editor: TinyMCEEditor) => void;
  templates?: { title: string; description: string; content: string }[];
}> = ({
  initialValue,
  value,
  height,
  disableLanguages,
  onEditorChange,
  onSetup,
  onInit,
  templates,
}) => {
  const { alertDialog } = useNL();
  const editorRef = useRef<TinyMCEEditor>();
  const { t, i18n } = useTranslation();

  const lng = useMemo(() => {
    const lng = i18n.language;
    const lngmap: { [from: string]: string } = {
      "et-EE": "et",
    };
    if (lngmap[lng]) return lngmap[lng];
    return lng;
  }, [i18n.language]);

  return (
    <EditorReact
      value={value}
      onEditorChange={onEditorChange}
      onInit={(evt, editor) => {
        if (onInit) onInit(evt, editor);
        editorRef.current = editor;
      }}
      apiKey="ue7gyw5kn9kdxndrag2ajf3bcwk8fzopll5uw3p12lccvmid"
      initialValue={initialValue}
      init={{
        deprecation_warnings: false,
        promotion: false,
        language: lng,
        language_url: "tinymce/langs/et.js",
        plugins:
          "preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
        editimage_cors_hosts: ["picsum.photos"],
        menubar: "file edit view insert format tools table help",
        menu: {
          file: {
            title: "File",
            items: `useold | open | save | restoredraft | preview | savebutton export print `,
          },
        },
        toolbar:
          "savebutton openbutton contentinfo msglangbutton2 | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor",

        setup: (editor) => {
          let saveButtonUpdate: (() => void) | null = null;
          let hasSaveableContent = false;

          const updateLanguageButton = (lng: string) => {
            const lbut = document.querySelector(
              'button[title="language of content"]'
            )?.childNodes[0];
            if (lbut) {
              if (lng === "def") lbut.textContent = "Default";
              else
                lbut.textContent =
                  getLangNameFromCode(lng)?.native || "Unknown";
            }
          };
          const callbacks: EditorSetupCallbacks = {
            doSave: () => {
              console.warn("Save not implemented in caller");
            },
            setSaveableContent: (v: boolean) => {
              hasSaveableContent = v;
            },
          };
          if (onSetup) onSetup(editor, callbacks);
          if (!disableLanguages)
            editor.ui.registry.addMenuButton("msglangbutton2", {
              text: "Default",
              tooltip: "language of content",
              fetch: async (callback) => {
                const usedlngs = callbacks.getUsedLanguages
                  ? await callbacks.getUsedLanguages()
                  : {};
                callback([
                  {
                    type: "menuitem",
                    text: "Default",
                    onAction: () => {
                      updateLanguageButton("def");
                      if (callbacks.setLanguage) callbacks.setLanguage("def");
                      //loadContent(null, k);
                    },
                  },
                  ...getLangCodeList().map((k) => {
                    return {
                      type: "menuitem",
                      icon: usedlngs[k] ? "checkmark" : undefined,
                      text:
                        k === "def"
                          ? "Default"
                          : getLangNameFromCode(k)?.native,
                      onAction: () => {
                        updateLanguageButton(k);
                        if (callbacks.setLanguage) callbacks.setLanguage(k);
                        //loadContent(null, k);
                      },
                    } as Ui.Menu.NestedMenuItemContents;
                  }),
                ]);
              },
            });
          editor.ui.registry.addMenuItem("save", {
            text: "Save",
            onAction: () => {
              if (hasSaveableContent) doSave(editor.getContent());
            },
          });

          const doSave = (data: string) => {
            callbacks.doSave(data);
            editor.setDirty(false);
            editor.undoManager.clear();
            if (saveButtonUpdate) saveButtonUpdate();
          };
          editor.addShortcut("Meta+S", "", () => {
            doSave(editor.getContent());
          });
          editor.ui.registry.addButton("savebutton", {
            icon: "save",
            enabled: false,
            onAction: () => {
              doSave(editor.getContent());
            },
            onSetup: (api) => {
              const editorEventCallback = () => {
                api.setEnabled(hasSaveableContent && editor.isDirty());
              };
              saveButtonUpdate = () => {
                api.setEnabled(hasSaveableContent && editor.isDirty());
              };

              editor.on("dirty", editorEventCallback);

              return () => editor.off("dirty", editorEventCallback);
            },
          });
          editor.on("SaveContent", async (sc) => {
            if (!hasSaveableContent || !editor.isDirty()) return;
            const data = editor.getContent();
            const resp = await alertDialog({
              content: t("editor.unsaveddata"),
              title: t("editor.unsavedtitle"),
              buttons: [
                { id: "save", label: t("button.save") },
                { id: "ignore", label: t("button.ignore") },
              ],
            });
            if (resp === "save") doSave(data);
          });
        },
        toolbar_sticky: true,
        toolbar_sticky_offset: isMobile ? 102 : 108,
        image_advtab: true,
        link_list: [
          { title: "Tulemused", value: "https://resultlink" },
          { title: "Rajad", value: "https://trackslink" },
          { title: "Minu tulemus", value: "https://myresult" },
        ],
        importcss_append: true,
        file_picker_callback: (callback, value, meta) => {
          // TODO: Following is only valid for images. For links should do something different.
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e: any) => {
            const file = e.target?.files[0] as any;

            const reader = new FileReader();
            reader.addEventListener("load", () => {
              /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
              const id = "blobid" + new Date().getTime();
              const blobCache = editorRef.current?.editorUpload.blobCache;
              const readeresult = reader.result;
              if (blobCache && typeof readeresult === "string") {
                const blobInfo = blobCache.create(
                  id,
                  file,
                  readeresult.split(",")[1]
                );
                blobCache.add(blobInfo);
                /* call the callback and populate the Title field with the file name */
                callback(blobInfo.blobUri(), { title: file.name });
              }
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
        templates: [...(templates ?? [])],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: height || 600,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage",
        noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image table",
        skin: false ? "oxide-dark" : "oxide",
        content_css: false ? "dark" : "default",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
      }}
    />
  );
};

export default Editor;
