import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 2l5.5 9h-11z m 2.217 3.662 Q 13.277 9.576 17.5 11"/>
    <circle cx="17.5" cy="17.5" r="4.5"/>
    <path d="M3 13.5h8v8H3z"/>
    <path d="M24 8h-3v3h-3v-3h-3v-3h3v-3h3v3h3v3z"/>
  </>,
  "AddKP"
);
