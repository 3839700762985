import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LatLngLiteral } from "leaflet";
import CheapRuler from "cheap-ruler";
import { timeArrayInsert } from "utils/timeArrayInsert";
import { PointData } from "@nutilogi/nutilogitypes";

export type TrackPoint = LatLngLiteral & {
  t: number; // Timestamp
  d: number; // Distance
  s: number; // Speed
  a: number; // Accuracy ??
  g: number; // Angle/Heading ??
};
export const pointsToTrack = (pointsrc: PointData[]): TrackPoint[] => {
  return pointsrc.map((p) => {
    return { lat: p.y, lng: p.x, t: p.t, d: 0, s: p.s, a: p.a, g: p.g };
  });
};
interface TracksSlice {
  [tid: string]: {
    [devid: string]: TrackPoint[];
  };
}

const initialState: TracksSlice = {};

const insertPoints = (arr: TrackPoint[], at: number, pointsrc: PointData[]) => {
  const points = pointsToTrack(pointsrc.filter((p) => p.a < 300)); // Dropping points with bad accuracy
  if (points.length === 0) return;

  const maxtrackslice = 65534;
  if (points.length > maxtrackslice) {
    let si = 0;
    /* Border cases here probably need testing */
    while (si < points.length) {
      arr.splice(at + si, 0, ...points.slice(si, si + maxtrackslice));
      si += maxtrackslice;
    }
  } else arr.splice(at, 0, ...points);
  const ruler = new CheapRuler(arr[0].lat, "meters");
  if (at === 0) {
    arr[0].d = 0;
    at++;
  }
  for (let i = at; i < arr.length; i++) {
    arr[i].d =
      arr[i - 1].d +
      ruler.distance(
        [arr[i - 1].lng, arr[i - 1].lat],
        [arr[i].lng, arr[i].lat]
      );
  }
};

export const tracksSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    addPoints: (
      state,
      action: PayloadAction<{ tid: string; devid: string; points: PointData[] }>
    ) => {
      const { tid, devid, points } = action.payload;
      if (!state[tid]) {
        state[tid] = {};
      }
      if (!state[tid][devid]) {
        state[tid][devid] = [];
      }
      let parray = state[tid][devid];

      timeArrayInsert(parray, points, insertPoints);
    },
  },
});

export const { addPoints } = tracksSlice.actions;

export default tracksSlice.reducer;
