import { Component, useEffect, FC } from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { setEventAccess, setUser } from "rx/userSlice";
import {
  DatabaseReference,
  getDatabase,
  onValue,
  ref,
} from "firebase/database";
import { getAuth, Unsubscribe } from "firebase/auth";

const mapState = (state: RootState) => ({
  eventId: state.eventId,
  uid: state.user.user?.uid,
});
const mapDispatch = {
  setUser: (user: Object | undefined) => setUser(user),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type AccessListenerProps = {
  eventId: string | null;
  uid?: string;
};

/*
class AccessListener extends Component<AccessListenerProps> {
  authsubscription?: firebase.Unsubscribe;
  componentDidMount() {
    let self = this;
    //console.log("auth:", firebase.auth());
    this.authsubscription = firebase.auth().onAuthStateChanged(function (user) {
      self.props.setUser(user?.toJSON());
    });
  }

  componentWillUnmount() {
    if (this.authsubscription) this.authsubscription();
  }

  render() {
    return <AccessListener eventId={this.props.eventId} uid={this.props.uid} />;
  }
}
*/

const AccessListener: FC<AccessListenerProps> = (
  props: AccessListenerProps
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!(props.eventId && props.uid)) {
      dispatch(setEventAccess(null));
      return;
    }
    let fbref = ref(
      getDatabase(),
      "/events/" + props.eventId + "/admins/" + props.uid
    );

    const unsub = onValue(fbref, (snap) => {
      dispatch(setEventAccess(snap.exists() ? snap.val() : null));
    });
    return unsub; // TODO test if unsub works correctly.
  }, [props.eventId, props.uid, dispatch]);
  return null;
};

declare global {
  var connectedRef: DatabaseReference;
}

class AuthStateListener extends Component<PropsFromRedux> {
  authsubscription?: Unsubscribe;
  componentDidMount() {
    let self = this;
    //console.log("auth:", firebase.auth());
    this.authsubscription = getAuth().onAuthStateChanged(function (user) {
      self.props.setUser(user?.toJSON());
    });
  }

  componentWillUnmount() {
    this.authsubscription && this.authsubscription();
  }

  render() {
    return <AccessListener eventId={this.props.eventId} uid={this.props.uid} />;
  }
}

export default connector(AuthStateListener);
