import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const eventIdSlice = createSlice({
  name: "eventid",
  initialState: null as string | null,
  reducers: {
    setEventId: (_, action) => {
      return action.payload;
    },
  },
});

export const { setEventId } = eventIdSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectEventId = (state: RootState) => state.eventId;

export default eventIdSlice.reducer;
