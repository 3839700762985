import NoSleepUtil from "nosleep.js";

import React, { useEffect } from "react";

const NoSleep: React.FC = () => {
  useEffect(() => {
    const noSleep = new NoSleepUtil();
    noSleep.enable();
    return () => {
      noSleep.disable();
    };
  }, []);
  return null;
};

export default NoSleep;
