import React from "react";
import * as L from "leaflet";
import "leaflet-draw";
import { red } from "@mui/material/colors";

type SpeedAreaProps = {
  map: L.Map;
  points: L.LatLng[];
  enableEdit: boolean;
  color?: string;
  onChange?: (points: L.LatLng[]) => void;
  onClick?: () => void;
};
type SpeedAreaState = {};
class SpeedArea extends React.Component<SpeedAreaProps, SpeedAreaState> {
  state: SpeedAreaState = {};
  polygon: L.Polygon;

  constructor(props: SpeedAreaProps) {
    super(props);
    this.polygon = new L.Polygon(props.points, {
      color: props.color || red[300],
    });
    this.polygon.on("click", () => {
      if (this.props.onClick) this.props.onClick();
    });
  }

  areaEdited = (levent: L.LeafletEvent) => {
    const event = levent as L.DrawEvents.EditVertex;
    if (this.props.onChange)
      this.props.onChange(event.poly.getLatLngs()[0] as L.LatLng[]);
  };

  componentDidMount() {
    /*
    (this.polygon as any).editing.setOptions({
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon",
      }),
    });
    */
    this.polygon.addTo(this.props.map);
    if (this.props.enableEdit) (this.polygon as any).editing.enable();
    this.props.map.on(L.Draw.Event.EDITVERTEX, this.areaEdited);
  }

  componentDidUpdate(prevProps: SpeedAreaProps) {
    /* When doing these updates, leaflet/drawer gets confused.
    
    if (!isObjectEqual(prevProps.points, this.props.points)) {
      console.log("updating points of area");
      this.polygon.setLatLngs(this.props.points);
    }
    if (prevProps.map !== this.props.map) {
      console.log("switching map");
      this.polygon.removeFrom(prevProps.map);
      this.polygon.addTo(this.props.map);
    }
    if (prevProps.enableEdit !== this.props.enableEdit) {
      if (this.props.enableEdit) (this.polygon as any).editing.enable();
      else (this.polygon as any).editing.disable();
    }
    */
  }
  componentWillUnmount() {
    this.polygon.removeFrom(this.props.map);
    this.props.map.off(L.Draw.Event.EDITVERTEX, this.areaEdited);
  }
  render() {
    return null;
  }
}

export default SpeedArea;
