import React, { useEffect, useState } from "react";
import { useTSelector } from "rx/store";
import { useDispatch } from "react-redux";
import {
  dataAvailabilitySlice,
  DataAvailabilityType,
  eventSlice,
  FBSlice,
  fbslices,
} from "rx/fbListSlices";
import { eventDocSlice, FireStoreSlice } from "rx/simpleFirstoreDocSlice";
import { useSnackbar } from "notistack";
import TracksDataListener from "./TracksDataListener";
import OldTracksDataListener from "./OldTracksDataListener";
import { ArchiveReader } from "./ArchiveReader";
import dayjs from "dayjs";
import { onSnapshot, getFirestore, doc } from "firebase/firestore";
import {
  DataSnapshot,
  getDatabase,
  ref,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
} from "firebase/database";
import { EventPresence } from "./FBPresence";
import SocketIoListener from "./SocketIoListener";
import { useIsTabActive } from "./SmallUtils";

const FireStoreListener: React.FC<{
  eventId: string;
  slice: FireStoreSlice<any>;
}> = ({ eventId, slice }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let docref = doc(getFirestore(), slice.path + "/" + eventId);
    let stopListen = onSnapshot(
      docref,
      (snapshot) => {
        const data = snapshot.data();
        dispatch(slice.actions.setDoc(data || null));
      },
      (error) => {
        console.log(error);
        enqueueSnackbar("Firestore error: " + error.message, {
          variant: "error",
        });
      }
    );
    return stopListen;
  });
  return null;
};

type FirebaseListenerProps = {
  eventId: string;
  slice: FBSlice<any>;
  slicekey: keyof DataAvailabilityType;
  fbpath?: string;
  onChildAddedCb?: (snap: DataSnapshot, initialComplete: boolean) => void;
};

const DelayedFirebaseListener: React.FC<FirebaseListenerProps> = React.memo(
  (props) => {
    //const fullconf2 = useFBSlice2("eventConf", (s => s.wheeofnames?.id))
    const availab = useTSelector((s) => s.dataAvailability[props.slicekey]);
    if (availab === undefined) {
      return null;
    } else {
      return <FirebaseListener {...props} />;
    }
  }
);

const FirebaseListener: React.FC<FirebaseListenerProps> = React.memo(
  ({ slice, eventId, fbpath, onChildAddedCb, slicekey }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      const activekeys: string[] = [];
      const useref = ref(
        getDatabase(),
        fbpath || `${slice.group}/${eventId}/${slice.path}`
      );
      const unsubscribeChildAdded = onChildAdded(useref, (s) => {
        if (onChildAddedCb) onChildAddedCb(s, slice.initialComplete);
        if (!slice.initialComplete || s.key === null) return;
        activekeys.push(s.key);
        dispatch(slice.actions.onChildAdded({ [s.key]: s.val() }));
      });
      const unsubscribeChildChanged = onChildChanged(useref, (s) => {
        if (!slice.initialComplete || s.key === null) return;
        dispatch(slice.actions.onChildChanged({ [s.key]: s.val() }));
      });
      const unsubscribeChildRemoved = onChildRemoved(useref, (s) => {
        if (!slice.initialComplete || s.key === null) return;
        dispatch(slice.actions.onChildRemoved(s.key));
      });
      const unsubscribeValue = onValue(
        useref,
        (snap) => {
          let action = slice.replaceOnValue
            ? slice.actions.replaceValue
            : slice.actions.combineValue;
          if (snap.exists()) activekeys.push(...Object.keys(snap.val()));
          dispatch(action(snap.val()));
          dispatch(dataAvailabilitySlice.actions.setDataAvailable(slicekey));
          slice.initialComplete = true;
        },
        { onlyOnce: true }
      );
      return () => {
        if (slice.replaceOnValue) dispatch(slice.actions.clearState());
        else dispatch(slice.actions.clearKeys(activekeys));
        unsubscribeChildAdded();
        unsubscribeChildChanged();
        unsubscribeChildRemoved();
        unsubscribeValue();
      };
    }, [dispatch, eventId, fbpath, onChildAddedCb, slice, slicekey]);
    return null;
  }
);

const SocketIODataListener: React.FC<{ eventId: string }> = ({ eventId }) => {
  const isTabActive = useIsTabActive();
  const [, setHideTime] = useState(0);
  useEffect(() => {
    if (isTabActive !== "visible") {
      setHideTime(Date.now());
    } else {
      setHideTime((ht) => {
        if (ht === 0) return 0;
        // TODO
        console.log("Should requry times in perioud: ", ht, Date.now());
        return 0;
      });
    }
  }, [isTabActive]);
  if (isTabActive !== "visible") return null;
  return (
    <>
      <EventPresence eventId={eventId} />
      <SocketIoListener eventId={eventId} />
    </>
  );
};

const EventDataListenerForEvent: React.FC<{
  eventId: string;
  resultOnly?: boolean;
}> = React.memo(({ eventId, resultOnly }) => {
  const hasAccess = useTSelector((state) => Boolean(state.user.eventAccess));
  const eventended = useTSelector((state) =>
    Boolean(state.event.endtime && dayjs().isAfter(state.event.endtime))
  );

  // TODO Will memoization of elements help here ?
  let elements = [];

  elements.push(
    <FirebaseListener
      key="evd"
      eventId={eventId}
      slice={eventSlice}
      slicekey="eventData"
    />
  );

  if (!resultOnly) {
    elements.push(
      <FireStoreListener key="evdoc" eventId={eventId} slice={eventDocSlice} />
    );

    const enabletrackdatalistener = true;
    const enableoldtrackdatalistener = true;
    const enablesocketiodatalistener = true;
    if (enablesocketiodatalistener && !eventended) {
      elements.push(<SocketIODataListener key="sockdata" eventId={eventId} />);
    }
    if (enabletrackdatalistener)
      elements.push(<TracksDataListener key="tracks" eventId={eventId} />);
    if (enableoldtrackdatalistener)
      elements.push(
        <OldTracksDataListener key="oldtracks" eventId={eventId} />
      );
  }

  Object.entries(fbslices).forEach(([k, slice]) => {
    if (
      !(eventended && slice.freeAfterEventEnd) &&
      slice.needAdmin &&
      !hasAccess
    )
      return;
    if (!slice.autoListen) {
      elements.push(
        <DelayedFirebaseListener
          key={k}
          eventId={eventId}
          slice={slice}
          slicekey={k as keyof typeof fbslices}
        />
      );
    } else {
      elements.push(
        <FirebaseListener
          key={k}
          eventId={eventId}
          slice={slice}
          slicekey={k as keyof typeof fbslices}
        />
      );
    }
  });

  return <>{elements}</>;
});

const FirstEventListener: React.FC<{
  eventId: string;
  resultOnly?: boolean;
}> = React.memo(({ eventId, resultOnly }) => {
  const eventname = useTSelector((state) => state.event.name);
  const archived = useTSelector((state) => state.event.arch);
  return (
    <>
      <FirebaseListener
        eventId={eventId}
        slice={eventSlice}
        slicekey="events"
        fbpath={"/events/" + eventId}
      />
      {eventname &&
        (archived ? (
          <ArchiveReader
            archive={archived}
            eventId={eventId}
            resultOnly={resultOnly}
          />
        ) : (
          <EventDataListenerForEvent
            eventId={eventId}
            resultOnly={resultOnly}
          />
        ))}
    </>
  );
});

const EventDataListener: React.FC<{ resultOnly?: boolean }> = React.memo(
  ({ resultOnly }) => {
    const eventId = useTSelector((state) => state.eventId);
    if (eventId)
      return (
        <>
          <FirstEventListener
            key={eventId}
            eventId={eventId}
            resultOnly={resultOnly}
          />
        </>
      );
    else return null;
  }
);

export default EventDataListener;
