import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeDialog } from "rx/dialogsSlice";
import { restoreDeleted } from "utils/archive";
import { execWithProgress } from "rx/saveInProgressSlice";
import { setEventId } from "rx/eventIdSlice";
import { getAuth } from "firebase/auth";
import { getStorage, listAll, ref as storageRef } from "firebase/storage";

const RestoreEvent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [restorelist, setRestoreList] = useState([] as string[]);
  const [restorestarted, setRestoreStarted] = useState(false);
  const uid = getAuth().currentUser?.uid;

  useEffect(() => {
    if (!uid) return;
    const archref = storageRef(getStorage(), `deletedevents/${uid}`);
    listAll(archref).then((list) => {
      const rlist: string[] = [];
      list.prefixes.forEach((it) => {
        rlist.push(it.name);
      });
      setRestoreList(rlist);
    });
  }, [uid]);
  return (
    <Dialog open={true} onClose={() => dispatch(closeDialog("restoreEvent"))}>
      <DialogTitle>{t("restoreev.title")}</DialogTitle>
      <DialogContent>
        {restorestarted ? (
          <Box display="flex" justifyContent="center" flexGrow={1}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {restorelist.map((ent) => (
              <ListItem
                key={ent}
                button
                onClick={() => {
                  setRestoreStarted(true);
                  dispatch<any>(
                    execWithProgress(() =>
                      restoreDeleted("deletedevents/" + uid, ent).then(() => {
                        dispatch(setEventId(ent));
                        window.history.pushState(
                          null,
                          "Title for page",
                          "/" + ent
                        );
                        dispatch(closeDialog("restoreEvent"));
                        // TODO open event
                      })
                    )
                  );

                  console.log("restore", ent);
                }}
              >
                <ListItemText primary={ent} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      {!restorestarted && (
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(closeDialog("restoreEvent"))}
          >
            {t("button.cancel")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default RestoreEvent;
