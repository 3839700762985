import React, { useMemo } from "react";
import { LayersControl, FeatureGroup, Circle } from "react-leaflet";
import { useTSelector } from "rx/store";
import MarkLocation, { MarkFullData } from "./MarkLocation";

const KPRanges: React.FC = () => {
  const kplist = useTSelector((state) => state.kpList);
  const kpData = useTSelector((state) => state.kpData);
  const eventdata = useTSelector((state) => state.event);

  return (
    <FeatureGroup>
      {Object.entries(kplist).map(([kpid, kp]) => {
        const dist = kp.maxanswerdistance || eventdata.maxmarkingdistance;
        const loc = (kpData[kpid] || {}).loc;
        if (!dist || !loc) return null;
        return <Circle key={kpid} center={loc} radius={dist} />;
      })}
    </FeatureGroup>
  );
};
const MarksGroup: React.FC<{ marks: MarkFullData[]; color: string }> = ({
  marks,
  color,
}) => {
  return (
    <FeatureGroup>
      {marks
        .filter((m) => m.lat !== undefined)
        .map((m) => (
          <MarkLocation key={m.id} mark={m} color={color} />
        ))}
    </FeatureGroup>
  );
};
const MarksStatistics: React.FC = () => {
  const showMarks = useTSelector((state) => state.appState.showMarks);
  const marks = useTSelector((state) => state.marks);
  const kpanswers = useTSelector((state) => state.kpAnswers);
  const hiddenTracks = useTSelector((state) => state.appState.hiddenTracks);
  const allmarks = useMemo(() => {
    let resp: MarkFullData[] = [];
    Object.keys(marks).forEach((teamid) => {
      Object.keys(marks[teamid]).forEach((devid) => {
        resp.push(
          ...Object.entries(marks[teamid][devid]).map(([id, ent]) =>
            Object.assign(
              {
                id: id,
                tid: teamid,
                devid: devid,
                correct: kpanswers[ent.kpid] === ent.answer,
              },
              ent
            )
          )
        );
      });
    });
    return resp;
  }, [marks, kpanswers]);

  const marksarray = allmarks.filter((m) => !hiddenTracks[m.tid]);

  if (!showMarks) return null;
  return (
    <LayersControl position="topright">
      <LayersControl.Overlay checked name="Correct Marks">
        <MarksGroup marks={marksarray.filter((e) => e.correct)} color="green" />
      </LayersControl.Overlay>
      <LayersControl.Overlay checked name="Wrong Marks">
        <MarksGroup marks={marksarray.filter((e) => !e.correct)} color="red" />
      </LayersControl.Overlay>
      <LayersControl.Overlay name="Rejected Marks">
        <MarksGroup marks={marksarray.filter((e) => e.reject)} color="blue" />
      </LayersControl.Overlay>
      <LayersControl.Overlay checked name="Marking ranges">
        <KPRanges />
      </LayersControl.Overlay>
    </LayersControl>
  );
};

export default MarksStatistics;
