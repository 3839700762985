import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { RegData } from "./EventSettingsRegistration";
import { EventType, TeamList } from "rx/fbListSlices";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  child,
  getDatabase,
  onValue,
  ref,
  set,
  update,
} from "firebase/database";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";
import { openDialog } from "rx/dialogsSlice";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next/typescript/t";
import { ContentCopy } from "@mui/icons-material";

// TODOsss
// + Sign in button.
// + switch user button
// + already registrered page
// + registered list on reg page + Allow/disallow it
// + sort reg list by reg date.
// + Adminnile registered page.
// - Serveri poolne dev entry genereerimine.
// + Sorteeri valjad mida naidatakse sisestatuna.
// - Settings
//   + Gruppeerida predefined fields
//   + custom fields
//   + public field settings.
//   + required fieldi configureerimine
// - Appi fixid kui devi ei ole
//   - nutilogi 2
//   - android old
//   - iphone vana kas yldse saab fixida.
//   - registration ended support in app
// - translations
//   - Regamine luabtud muuta Registreerumine nutilogi keskkonnas

type RegListEntry = {
  id: string;
  teamname: string;
  regtime: number;
} & {
  [k: string]: string;
};

const SignInGridItem: React.FC<{ isSwitch: boolean }> = ({ isSwitch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return <>
    {navigator.userAgent.includes('wv') && <WebViewUserAgent />}
    <Grid2 display="flex" justifyContent="center" xs={12} >

      <Button variant="contained" onClick={() => {
        if (isSwitch) getAuth().signOut();
        dispatch(openDialog("signin"));
      }}>{t(isSwitch ? 'button.switchuser' : 'button.signin')}</Button>
    </Grid2>
  </>
}

const WebViewUserAgent: React.FC = () => {
  const { t } = useTranslation();
  return <>
    <Grid2 display="flex" justifyContent="center" xs={12} >
      <Typography padding={2}>
        {t('message.badbrowswerforgoogle')}
      </Typography>
    </Grid2><Grid2 display="flex" justifyContent="center" alignItems="center" xs={12} >

      <Typography>
        {window.location.href}
      </Typography>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
        }}
        size="large"
      >
        <ContentCopy />
      </IconButton>
    </Grid2>
  </>
}

const RegEnded: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box justifyContent="center" display="flex" sx={{ padding: 3 }}>
      <Typography>{t("reg.ended.message" as any)}</Typography>
    </Box>
  );
};
const RegToEventPage: React.FC = () => {
  const { t } = useTranslation();
  const { evid } = useParams<{ evid: string }>();
  const regdata = useRFirebaseDb<RegData>(
    `/eventsdata/${evid}/data/registration`
  );

  return (
    <>
      <Box justifyContent="center" display="flex" padding={2}>
        <Typography variant="h3">{t("title.registration")}</Typography>
      </Box>
      {regdata &&
        (regdata.regend === undefined || regdata.regend > Date.now() ? (
          <RegToEvent regdata={regdata} evid={evid!} />
        ) : (
          <RegEnded />
        ))}
      {regdata?.publicreglist && (
        <AllRegistreredTeams regdata={regdata} evid={evid!} />
      )}
    </>
  );
};

const CurrentRegistrationData: React.FC<{
  regdata: RegData;
  evid: string;
  uid: string;
}> = ({ regdata, evid, uid }) => {
  const data = useRFirebaseDb<{ [key: string]: string }>(
    `/teams/${evid}/data/${uid}/regdata`
  );
  const { t, i18n } = useTranslation();
  if (!data) return null;
  return (
    <Card>
      <Table>
        <TableBody>
          {Object.entries(fieldsFromRegData(regdata, t))
            .filter(([f, v]) => data[f])
            .sort(([_l, l], [_r, r]) => (l.s || 100) - (r.s || 100))
            .map(([f, fv]) => (
              <TableRow key={f}>
                <TableCell>
                  {i18n.exists(`reg.field.${f}.label`)
                    ? t(`reg.field.${f}.label`, f)
                    : f}
                </TableCell>
                <TableCell>{data[f]}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
};

const AllRegistreredTeams: React.FC<{ regdata: RegData; evid: string }> = ({
  regdata,
  evid,
}) => {
  const list = useRFirebaseDb<{ [tid: string]: TeamList }>(
    `/teams/${evid}/list`
  );
  const [regList, setRegList] = useState<RegListEntry[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(
      `https://europe-west1-nutilogi.cloudfunctions.net/api/registeredteams?eventid=${evid}`,
      { signal }
    )
      .then((resp) => resp.json())
      .then((data: RegListEntry[]) => {
        data.forEach((e) => {
          if (e.regtime === 0) e.regtime = Number.MAX_VALUE;
        });
        setRegList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    return () => {
      controller.abort("Discarded element");
    };
  }, [evid, list]);
  if (!list) return null;

  const columns: GridColDef[] = [
    ...Object.entries(fieldsFromRegData(regdata, t))
      .filter(([f, v]) => v.public)
      .sort(([_l, l], [_r, r]) => (l.s || 100) - (r.s || 100))
      .map(([f, v]) => {
        return { field: f, headerName: v.label };
      }),
  ];

  return (
    <Box sx={{ height: 400, width: "90%" }} padding={5}>
      <Card>
        <CardHeader title={t("title.registeredteams")} />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((c) => (
                  <TableCell key={c.field}>{c.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {regList
                .sort((l, r) => {
                  return l.regtime - r.regtime;
                })
                .map((r, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{idx + 1}</TableCell>
                    {columns.map((c) => (
                      <TableCell key={c.field}>{r[c.field]}</TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Box>
  );
};



export const fieldsFromRegData = (regdata: RegData, t: TFunction<"translation", undefined>) => {
  let fields = regdata?.customfields || {};
  fields["teamname"] = {
    hint: t('reg.field.teamname.hint'),
    label: t('reg.field.teamname.label'),
    required: true,
    public: true,
    s: 5
  }
  if (regdata?.email) {
    fields["email"] = {
      hint: t('reg.field.email.hint'),
      label: t('reg.field.email.label'),
      type: "email",
      public: regdata.emailpublic,
      s: 10
    }
  }
  if (regdata?.car) {
    fields["car"] = {
      hint: t('fbf.carreg.hint'),
      label: t('fbf.carreg.label'),
      public: regdata.carpublic,
      s: 30
    }
  }
  if (regdata?.phone) {
    fields["phone"] = {
      hint: t('reg.field.phone.hint'),
      label: t('fbf.phonereg.label'),
      type: "tel",
      public: regdata.phonepublic,
      s: 20
    }
  }
  return fields;
}

type FieldsType = Exclude<RegData['customfields'], undefined>;
type FieldType = FieldsType[string]

const SelectFromField: React.FC<{ name: string, f: FieldType, multiple?: boolean }> = ({ name, f, multiple }) => {
  const [value, setValue] = useState<string[] | string>(f.defaultvalue !== undefined && typeof (f.defaultvalue) !== "boolean" ? (multiple ? f.defaultvalue.split(",") : f.defaultvalue) : multiple ? [] : "");

  return <FormControl sx={{ m: 1, minWidth: 210 }} >
    <InputLabel>{f.label}</InputLabel>
    <Select id={name} name={name}
      multiple={Boolean(multiple)}
      displayEmpty
      label={f.label}
      value={value}
      onChange={(event) => setValue(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
      renderValue={(selected) => {
        if (selected.length === 0)
          return <em>{f.hint}</em>;
        if (Array.isArray(selected)) {
          return selected.join(", ")
        }
        return selected;
      }}
    >
      <MenuItem disabled value="">{f.hint}</MenuItem>
      {(f.selectvalues || '').split(",").map(e => <MenuItem key={e} value={e}>{multiple && <Checkbox checked={Array.isArray(value) && value.indexOf(e) > -1} />}<ListItemText primary={e} /></MenuItem>)}
    </Select>
  </FormControl>
}
const RegToEvent: React.FC<{ regdata: RegData, evid: string }> = ({ regdata, evid }) => {
  const user = useTSelector((state) => state.user.user);
  const { t } = useTranslation();
  const event = useRFirebaseDb<EventType>(`/events/${evid}`);
  const [hasreg, setHasReg] = useState<boolean>();

  useEffect(() => {
    if (user?.uid === undefined) return;
    return onValue(ref(getDatabase(), `/teams/${evid}/list/${user.uid}`), (s) => {
      setHasReg(s.exists());
    })
  }, [user?.uid, evid]);
  let fields = fieldsFromRegData(regdata, t);

  if (!user) {
    return <Grid2 container spacing={2}>
      <SignInGridItem isSwitch={false} />
    </Grid2>
  }
  if (hasreg === undefined) {
    return <Skeleton></Skeleton>;
  }
  if (hasreg) {
    return <Grid2 container spacing={2}>
      <SignInGridItem isSwitch />
      <Grid2 display="flex" justifyContent="center" xs={12}>
        {t('messages.registered', { evname: event?.name })}
      </Grid2>
      <Grid2 display="flex" justifyContent="center" xs={12}>
        <CurrentRegistrationData regdata={regdata} evid={evid} uid={user.uid} />
      </Grid2>
    </Grid2>;
  }
  return (
    <>
      <Grid2 container spacing={2}>
        <SignInGridItem isSwitch />
        <Grid2 display="flex" justifyContent="center" xs={12}>{
          t('reg.usertitle', { user: user.displayName, evname: event?.name })
        }</Grid2>
        <RegForm fields={fields} onSubmit={(data) => {
          const db = getDatabase();
          const userarea = ref(db, `/teams/${evid}/data/${user.uid}`);
          let userdata: { [k: string]: string | boolean } = {}
          if (user.displayName) userdata.displayName = user.displayName;
          if (user.email) userdata.email = user.email;
          if (user.phoneNumber) userdata.phoneNumber = user.phoneNumber;
          userdata.emailVerified = user.emailVerified;
          if (user.providerData.length > 0 && user.providerData[0].photoURL)
            userdata.photoURL = user.providerData[0].photoURL;
          else if (user.photoURL)
            userdata.photoURL = user.photoURL;

          set(userarea, userdata);
          set(child(userarea, "regdata"), data);
          update(child(userarea, "regdata"), { regfromweb: true, regtime: dayjs().valueOf() });
          set(ref(db, `/teams/${evid}/list/${user.uid}/name`), data["teamname"]);
        }} />

        <Grid2 display="flex" justifyContent="center" xs={12}>
          <Button variant="contained" onClick={() => {
            const form = document.getElementById("regform") as HTMLFormElement | null;
            if (form) form.requestSubmit();
          }
          }>{t('button.submit' as any)}</Button>
        </Grid2>
      </Grid2>
    </>
  );
};

type FormDataType = { [k: string]: string };

export const RegForm: React.FC<{ onSubmit: (data: FormDataType) => void, fields: FieldsType }> = ({ onSubmit, fields }) => {

  return <form
    id="regform"
    style={{ width: "100%" }}
    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      let data: FormDataType = {};
      formData.forEach((v, k) => {
        if (v !== '') data[k] = v.toString();
      })
      onSubmit(data);
    }}
  >
    {Object.entries(fields).sort(([_l, l], [_r, r]) => (l.s || 100) - (r.s || 100)).map(([f, d]) =>
      <Grid2 key={f} display="flex" justifyContent="center" xs={12}>
        {d.muitype === "single" && <SelectFromField f={d} name={f} />}
        {d.muitype === "multiple" && <SelectFromField f={d} name={f} multiple />}
        {d.muitype === "boolean" && <FormControlLabel
          name={f}
          control={<Checkbox defaultChecked={typeof (d.defaultvalue) === "boolean" && d.defaultvalue} />}
          label={d.label}
        />}
        {(d.muitype === undefined || d.muitype === "text") && <TextField label={d.label} defaultValue={d.defaultvalue} required={d.required} placeholder={d.hint} id={f} name={f} type="" />}
      </Grid2>)}

  </form>
}

export default RegToEventPage;
