import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { setInvestigateTrack } from "rx/appStateSlice";
import Draggable from "react-draggable";
import { TrackDecoder } from "@nutilogi/trzip";
import { TrackPoint } from "rx/tracksSlice";
import dayjs from "dayjs";
import SpeedTrack from "utils/SpeedTrack";
import { FileCopy } from "@mui/icons-material";
import { defaultLocSendHost } from "utils/TracksDataListener";
import TrackInvestigationContentsTable from "./TrackInvestigationContentsTable";
import { getDatabase, ref, get } from "firebase/database";
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref as storageRef,
} from "firebase/storage";
import { PointData } from "@nutilogi/nutilogitypes";

const gpxToPoints = (data: string) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(data, "text/xml");
  let author = doc.getElementsByTagName("author");

  const points: PointData[] = [];
  let trkpts = doc.getElementsByTagName("trkpt");
  for (let i = 0; i < trkpts.length; i++) {
    const trkpt = trkpts[i];
    let p: PointData = {
      t: 0,
      a: 0,
      s: 0,
      l: 0,
      g: 0,
      x: Number(trkpt.getAttribute("lon")),
      y: Number(trkpt.getAttribute("lat")),
    };
    trkpt.childNodes.forEach((n) => {
      if (!n.textContent) return;
      else if (n.nodeName === "time")
        p.t = Date.parse(n.textContent.replace("Z", ""));
      else if (n.nodeName === "speed") p.s = Number(n.textContent);
      else if (n.nodeName === "course") p.g = Number(n.textContent);
      else if (n.nodeName === "ele") p.l = Number(n.textContent);
    });
    points.push(p);
  }
  return { devid: author[0].textContent, points: points };
};
let wscount = 1;
let colindex = 1;
export type TrackSource = {
  name: string;
  shortname: string;
  devid: string;
  points: PointData[];
  color?: string;
};
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const mapState = (
  state: RootState,
  props: TrackInvestigationDialogOwnProps
) => ({
  inv: state,
  team: state.teamsList[props.tid],
  event: state.event,
  eventId: state.eventId,
});
const mapDispatch = {
  closeDialog: () => setInvestigateTrack(undefined),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TrackInvestigationDialogOwnProps = {
  tid: string;
};
type TrackInvestigationDialogProps = PropsFromRedux &
  WithTranslation &
  TrackInvestigationDialogOwnProps;
type TrackInvestigationDialogState = {
  sources: TrackSource[];
  track?: TrackPoint[];
  pointstable: boolean;
  usedSources: string[];
  colors: string[];
};
class TrackInvestigationDialog extends React.Component<
  TrackInvestigationDialogProps,
  TrackInvestigationDialogState
> {
  state: TrackInvestigationDialogState = {
    pointstable: true,
    sources: [],
    usedSources: [],
    colors: [],
  };

  declocdata = (buf: Uint8Array) => {
    let idx = 0;
    let jend = 0;
    let entries = [];

    while ((jend = buf.indexOf(0, idx)) !== -1) {
      const h = JSON.parse(
        new TextDecoder("utf-8").decode(buf.slice(idx, jend))
      );
      //console.log(jend);
      //console.log(h)
      idx = jend + 1;
      h.tidx = idx + h.tstart;
      h.sidx = idx + h.sstart;
      h.aidx = idx + h.astart;
      h.lidx = idx + h.lstart;
      h.gidx = idx + h.gstart;
      h.lonidx = idx + h.lonstart;
      h.latidx = idx + h.latstart;
      let getnum = (f: string) => {
        let b = 0;
        let v = 0;
        let pos = 0;
        do {
          b = buf[h[f + "idx"]];
          if (b !== 0) h[f + "idx"]++;
          v = v | ((b & 0x7f) << (7 * pos));
          pos++;
        } while (b & 0x80);
        if (v === 0) {
          const zc = (buf[h[f + "idx"] + 1] -= 1);
          if (zc === 0) h[f + "idx"] += 2;
        }
        if (v & 0x1) v = ~v;
        v = v >> 1;
        //console.log(v/h[f+'prec']);
        return v / h[f + "prec"];
      };
      let objects = [];
      let prevob: PointData = {
        x: 0,
        y: 0,
        t: Number(h.firstt - 1000),
        s: 0,
        a: 0,
        g: 0,
        l: 0,
      };
      for (let i = 0; i < h.count; i++) {
        const nob = {
          y: prevob.y + getnum("lat"),
          x: prevob.x + getnum("lon"),
          t: prevob.t + getnum("t") + 1000,
          s: prevob.s + getnum("s"),
          a: prevob.a + getnum("a"),
          g: prevob.g + getnum("g"),
          l: prevob.l + getnum("l"),
        };
        objects.push(nob);
        prevob = nob;
      }
      idx = h.lonidx;

      objects.forEach((p) => (p.s = (p.s * 3600) / 1000));
      entries.push({ tid: h.tid, devid: h.devid, points: objects });
    }
    return entries;
  };

  componentDidMount() {
    get(ref(getDatabase(), "/conf/colors")).then((snap) => {
      let colors = snap.val() as string[];
      this.state.sources.forEach(
        (src) => (src.color = colors[colindex++ % colors.length])
      );
      this.setState({ colors: colors });
    });
    fetch("https://" + defaultLocSendHost + "/points?tid=" + this.props.tid)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        let newsource = [...this.state.sources];
        let enabledsources = [...this.state.usedSources];

        let decodeddata = TrackDecoder.decode(Buffer.from(data));
        Object.entries(decodeddata).forEach(([tid, tdata]) => {
          Object.entries(tdata).forEach(([devid, points]) => {
            if (points.length > 0) {
              let name = "db poitns";
              newsource.push({
                name: name,
                shortname: "DB",
                devid: devid,
                points: points,
                color: "red",
              });
              enabledsources.push(name);
            }
          });
        });
        this.setState({ sources: newsource, usedSources: enabledsources });
      });
    listAll(storageRef(getStorage(), `/gpx/${this.props.tid}`)).then(
      (listing) => {
        listing.items.forEach((lit) => {
          if (lit.name.endsWith(".gpx")) {
            getDownloadURL(lit)
              .then((url) => fetch(url))
              .then((r) => r.text())
              .then((data) => {
                let newsource = [...this.state.sources];
                let enabledsources = [...this.state.usedSources];
                const gpx = gpxToPoints(data);
                newsource.push({
                  name: lit.name,
                  shortname: "GPX",
                  devid: gpx.devid || "--",
                  points: gpx.points,
                  color:
                    this.state.colors.length > 0
                      ? this.state.colors[colindex++ % this.state.colors.length]
                      : undefined,
                });
                enabledsources.push(lit.name);
                this.setState({
                  sources: newsource,
                  usedSources: enabledsources,
                });
              });
          }
        });
      }
    );
    listAll(storageRef(getStorage(), `tracks/${this.props.eventId}`)).then(
      (listing) => {
        listing.items.forEach((lit) => {
          if (lit.name.endsWith("-finished.data")) {
            if (!lit.name.includes(this.props.tid)) return;
            getDownloadURL(lit)
              .then((url) => fetch(url))
              .then((r) => r.arrayBuffer())
              .then((abuf) => {
                let fview = new Uint8Array(abuf);
                const locdata = this.declocdata(fview);
                let newsource = [...this.state.sources];
                let enabledsources = [...this.state.usedSources];

                locdata.forEach((ent) => {
                  if (ent.tid !== this.props.tid) return;
                  newsource.push({
                    name: lit.name,
                    shortname: "Old finished",
                    devid: ent.devid,
                    points: ent.points,
                    color:
                      this.state.colors.length > 0
                        ? this.state.colors[
                            colindex++ % this.state.colors.length
                          ]
                        : undefined,
                  });
                  enabledsources.push(lit.name);
                });
                this.setState({
                  sources: newsource,
                  usedSources: enabledsources,
                });
              });
          }
        });
      }
    );
    listAll(
      storageRef(getStorage(), `nutilogi/events/${this.props.eventId}`)
    ).then((listing) => {
      listing.items.forEach((lit) => {
        // const found = tpart.name.match(/(.*)-([0-9]+).data/);
        if (!lit.name.includes(this.props.tid)) return;
        if (lit.name.match(".*_ws.*.data")) {
          getDownloadURL(lit)
            .then((url) => fetch(url))
            .then((r) => r.arrayBuffer())
            .then((abuf) => {
              let newsource = [...this.state.sources];
              let enabledsources = [...this.state.usedSources];

              let decodeddata = TrackDecoder.decode(Buffer.from(abuf));
              Object.entries(decodeddata).forEach(([tid, tdata]) => {
                if (tid !== this.props.tid) return;
                Object.entries(tdata).forEach(([devid, points]) => {
                  if (points.length > 0) {
                    newsource.push({
                      name: lit.name,
                      shortname: "WS s" + wscount++,
                      devid: devid,
                      points: points,
                      color:
                        this.state.colors.length > 0
                          ? this.state.colors[
                              colindex++ % this.state.colors.length
                            ]
                          : undefined,
                    });
                    enabledsources.push(lit.name);
                  }
                });
              });
              this.setState({
                sources: newsource,
                usedSources: enabledsources,
              });
            });
        }
      });
    });
  }

  showTrack = (source: TrackSource) => () => {
    console.log("request to show track");
    const points = source.points.map((p) => {
      return { lat: p.y, lng: p.x, t: p.t, d: 0, s: p.s, a: p.a, g: p.g };
    });
    this.setState({ track: points });
  };
  render() {
    const { team } = this.props;
    if (this.state.track) console.log("track len", this.state.track.length);
    const sources = this.state.sources.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    // TODO Add notification that archived events are not supported for investigation
    return (
      <>
        <Dialog
          open={true}
          hideBackdrop
          PaperComponent={PaperComponent}
          sx={{
            pointerEvents: "none",
            "& .MuiDialog-paper": {
              pointerEvents: "auto",
            },
          }}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Track inveestigation of {team.name}
          </DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.pointstable}
                  onChange={() =>
                    this.setState({ pointstable: !this.state.pointstable })
                  }
                />
              }
              label="Contents"
            />
            <Select
              variant="standard"
              multiple
              value={this.state.usedSources}
              renderValue={(selected) => {
                const value = selected as string[];
                if (value.length > 3) {
                  return value.length + " sources";
                }
                let resp: string[] = [];
                value.forEach((s) => {
                  let sf = this.state.sources.find((src) => src.name === s);
                  if (sf) resp.push(sf.shortname);
                });
                return resp.join(",");
              }}
              onChange={(event) => {
                this.setState({ usedSources: event.target.value as string[] });
              }}
            >
              {sources.map((s) => (
                <MenuItem key={s.name} value={s.name}>
                  <ListItemText primary={s.name} />
                </MenuItem>
              ))}
            </Select>
            {this.state.pointstable ? (
              <TrackInvestigationContentsTable
                sources={sources.filter((s) =>
                  this.state.usedSources.includes(s.name)
                )}
                tid={this.props.tid}
              />
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell>Devid</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>First time</TableCell>
                    <TableCell>Last time</TableCell>
                    <TableCell>Query device</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sources.map((source) => (
                    <TableRow
                      key={source.name}
                      onClick={this.showTrack(source)}
                    >
                      <TableCell>{source.name}</TableCell>
                      <TableCell>{source.devid}</TableCell>
                      <TableCell>{source.points.length}</TableCell>
                      <TableCell>
                        {source.points.length > 0 &&
                          dayjs(source.points[0].t).format(
                            "YYYY-MM-DD HH:mm:ssZ[Z]"
                          )}
                      </TableCell>
                      <TableCell>
                        {source.points.length > 0 &&
                          dayjs(
                            source.points[source.points.length - 1].t
                          ).format("YYYY-MM-DD HH:mm:ssZ[Z]")}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "select datetime(t/1000, 'unixepoch'), * FROM gps where devid=(select id from devices where devname='" +
                                source.devid +
                                "') and t > " +
                                this.props.event.starttime +
                                " AND t <" +
                                this.props.event.endtime
                            );
                          }}
                          size="large"
                        >
                          <FileCopy />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.closeDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.track && (
          <SpeedTrack track={this.state.track} map={mainMap} />
        )}
      </>
    );
  }
}

export default connector(withTranslation()(TrackInvestigationDialog));
