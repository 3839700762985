import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Add, Check, Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";

const EventSettingsLy: React.FC<{ eventId: string }> = ({ eventId }) => {
  const lylist = useTSelector((state) => state.event.ly);
  const klassid = useTSelector((state) => state.event.klassid);
  const [newLyName, setNewLyName] = useState("");
  const [editlyid, setEditLyId] = useState<string | undefined>(undefined);
  const [editLyName, setEditLyName] = useState("");
  const { fbSet, fbRemove } = useNL();
  const { t } = useTranslation();
  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="addLy"
          value={newLyName}
          onChange={(event) => {
            setNewLyName(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Add Ly"
                  disabled={newLyName.trim().length === 0}
                  onClick={() => {
                    let lyid = newLyName;
                    while (lyid in (lylist || {})) {
                      lyid = lyid + "x";
                    }
                    fbSet(
                      `/eventsdata/${eventId}/data/ly/${lyid}/name`,
                      newLyName,
                      t("ly.lyadded")
                    );
                    setNewLyName("");
                  }}
                  size="large">
                  <Add />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("ly.name")}</TableCell>
                <TableCell>{t("ly.forclasses")}</TableCell>
                <TableCell>{t("ly.ispenalty")}</TableCell>
                <TableCell>{t("ly.delete")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(lylist || {}).map(([lyid, ly]) => (
                <TableRow key={lyid}>
                  <TableCell>
                    {editlyid === lyid ? (
                      <TextField
                        id="editlyname"
                        value={editLyName}
                        onChange={(event) => {
                          setEditLyName(event.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Edit ly name"
                                disabled={editLyName.trim().length === 0}
                                onClick={() => {
                                  fbSet(
                                    `/eventsdata/${eventId}/data/ly/${lyid}/name`,
                                    editLyName,
                                    t("ly.namechanged")
                                  );
                                  setEditLyName("");
                                  setEditLyId(undefined);
                                }}
                                size="large">
                                <Check />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <>
                        {ly.name}
                        <IconButton
                          onClick={() => {
                            setEditLyName(ly.name);
                            setEditLyId(lyid);
                          }}
                          size="large">
                          <Edit />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {klassid &&
                      Object.keys(klassid).map((kl) => (
                        <FormControlLabel
                          key={kl}
                          label={kl}
                          control={
                            <Checkbox
                              checked={
                                ly.forclasses === undefined ||
                                ly.forclasses.includes(kl)
                              }
                              onChange={() => {
                                const kkeys = Object.keys(klassid);
                                let val = ly.forclasses || kkeys;
                                if (val.includes(kl))
                                  val = val.filter((k) => k !== kl);
                                else val = val.concat(kl);
                                val = val.filter((k) => kkeys.includes(k));
                                fbSet(
                                  `/eventsdata/${eventId}/data/ly/${lyid}/forclasses`,
                                  val
                                );
                              }}
                            />
                          }
                        />
                      ))}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={ly.ispenalty}
                      onChange={() => {
                        fbSet(
                          `/eventsdata/${eventId}/data/ly/${lyid}/ispenalty`,
                          !Boolean(ly.ispenalty)
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        fbRemove(
                          `/eventsdata/${eventId}/data/ly/${lyid}`,
                          t("ly.removed")
                        );
                      }}
                      size="large">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  </>;
};

export default EventSettingsLy;
