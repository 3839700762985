import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { execWithProgress } from "rx/saveInProgressSlice";
import DrawerHeader from "./DrawerHeader";

const mapState = (state: RootState) => ({
  eventId: state.eventId,
});
const mapDispatch = {
  execWithProgress: (f: () => Promise<any>) => execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PrintingDrawerProps = PropsFromRedux &
  WithTranslation & {
    closeDrawer: () => void;
  };
type PrintingDrawerState = {};
class PrintingDrawer extends React.Component<
  PrintingDrawerProps,
  PrintingDrawerState
> {
  state: PrintingDrawerState = {};
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    const { t } = this.props;

    return (
      <>
        <DrawerHeader
          closeDrawer={this.props.closeDrawer}
          title={t("menu.title.printing")}
        ></DrawerHeader>
      </>
    );
  }
}

export default connector(withTranslation()(PrintingDrawer));
