import { Button, IconButton, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  decrementProgressCount,
  execWithProgress,
  incrementProgressCount,
} from "rx/saveInProgressSlice";
import { connect, ConnectedProps } from "react-redux";
import AlertDialog, { AlertDialogProps } from "components/AlertDialog";
import {
  DatabaseReference,
  get,
  getDatabase,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import {
  UpdateData,
  DocumentData,
  getFirestore,
  doc,
  getDoc,
  deleteDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import store from "rx/store";

const enableLogging = true;

type execWithProgressAndUndoType = (
  f: () => Promise<any>,
  doUndo?: () => void,
  undoMsg?: string
) => void;
type addUndoType = (
  id: string,
  msg: string,
  doUndo: () => void,
  oldvalue?: string
) => void;
type fbSetType = (
  ref: DatabaseReference | string,
  value: any,
  undoMsg?: string,
  oldValue?: string
) => Promise<any>;
type fbRemoveType = (
  ref: DatabaseReference | string,
  undoMsg?: string,
  undoId?: string
) => Promise<any>;
type openLinkType = (pathname: string, search: string, hash: string) => void;
type fsUpdateType = (
  docpath: string,
  data: UpdateData<any>,
  undoMsg?: string,
  undoId?: string
) => Promise<void>;
type fsSetType = (
  docpath: string,
  data: DocumentData,
  undoMsg?: string,
  undoId?: string
) => Promise<void>;
type AlertDialogType = (props: AlertDialogProps) => Promise<string>; // Can we do with promise?

export interface NLContextType {
  execWithProgressAndUndo: execWithProgressAndUndoType;
  addUndo: addUndoType;
  fbSet: fbSetType;
  fbUpdate: fbSetType;
  fbRemove: fbRemoveType;
  openLink: openLinkType;
  fsUpdate: fsUpdateType;
  fsSet: fsSetType;
  alertDialog: AlertDialogType;
}

const NLContext = React.createContext<Partial<NLContextType>>({});

const mapDispatch = {
  incrementProgressCount: () => incrementProgressCount(),
  decrementProgressCount: () => decrementProgressCount(),
  execWithProgress: (f: () => Promise<any>) => execWithProgress(f),
};

const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type NLProps = { children: React.ReactNode } & PropsFromRedux;
type NLState = {
  contextValue: NLContextType;
  undoStack: UndoStackObject[];
  lastmsgtime: number;
  snackbarTimeout: number;
  undoSnackOpen: boolean;
  alertDialogOpen: boolean;
  alertDialogProps?: AlertDialogProps;
};

type UndoStackObject = {
  id: string;
  msg: string;
  doUndo: () => void;
  oldvalue?: string;
};

export class NLProviderInternal extends React.Component<NLProps, NLState> {
  state: NLState;
  constructor(props: any) {
    super(props);
    this.state = {
      contextValue: {
        execWithProgressAndUndo: this.execWithProgressAndUndo,
        addUndo: this.addUndo,
        fbSet: this.fbSet,
        fbUpdate: this.fbUpdate,
        fbRemove: this.fbRemove,
        openLink: this.openLink,
        fsUpdate: this.fsUpdate,
        fsSet: this.fsSet,
        alertDialog: this.alertDialog,
      },
      undoStack: [],
      lastmsgtime: 0,
      snackbarTimeout: 600000,
      undoSnackOpen: false,
      alertDialogOpen: false,
    };
  }

  openLink: openLinkType = (pathname, search, hash) => {
    console.log(
      "request to open pathname:",
      pathname,
      "search: ",
      search,
      "hash: ",
      hash
    );
  };

  cancelLastUndo = () => {
    this.setState((prevState) => {
      prevState.undoStack.pop();
      return {
        undoSnackOpen: false,
        undoStack: prevState.undoStack,
      };
    });
  };
  fsSet: fsSetType = async (docpath, data, undoMsg, undoId) => {
    const docref = doc(getFirestore(), docpath);
    let olddoc = await getDoc(docref);
    this.props.incrementProgressCount();
    if (undoMsg) {
      this.addUndo(undoId || docref.path, undoMsg, () => {
        if (olddoc.exists()) {
          const olddata = olddoc.data();
          if (olddata) setDoc(docref, olddata);
        } else deleteDoc(docref);
      });
    }
    return setDoc(docref, data)
      .then(() => {
        this.props.decrementProgressCount();
      })
      .catch((error) => {
        this.cancelLastUndo();
        this.props.decrementProgressCount();
        enqueueSnackbar("FAILED: " + error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
  };
  fsUpdate: fsUpdateType = async (docpath, data, undoMsg, undoId) => {
    const docref = doc(getFirestore(), docpath);
    let olddoc = await getDoc(docref);
    if (!olddoc.exists) {
      await setDoc(docref, {});
    }
    this.props.incrementProgressCount();
    if (undoMsg) {
      this.addUndo(undoId || docref.path, undoMsg, () => {
        Object.keys(data).forEach((k) => {
          updateDoc(docref, k, olddoc.get(k));
        });
        /*
        const olddata = olddoc.data();
        if (olddata)
          docref.set(olddata, {merge: false});
        else
          docref.set({}, {merge: false})
          */
      });
    }
    return updateDoc(docref, data)
      .then(() => {
        this.props.decrementProgressCount();
      })
      .catch((error) => {
        this.cancelLastUndo();
        this.props.decrementProgressCount();
        enqueueSnackbar("FAILED: " + error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
  };

  logfb = (obj: object) => {
    const evid = store.getState().eventId || "noevent";
    push(ref(getDatabase(), `log/${evid}`), obj);
  };

  fbCompletedCallback = (err: Error | null) => {
    this.props.decrementProgressCount();
    if (err) {
      this.cancelLastUndo();
      enqueueSnackbar(err.message, {
        variant: "error",
        persist: true,
      });
    }
  };
  fbRemove: fbRemoveType = async (target, undoMsg, undoId) => {
    const db = getDatabase();
    let reftarget = typeof target === "string" ? ref(db, target) : target;
    if (enableLogging) {
      this.logfb({
        uid: getAuth().currentUser?.uid || "Unknown",
        path: target.toString(),
        value: (await get(reftarget)).val(),
        removed: true,
      });
    }
    this.props.incrementProgressCount();
    if (undoMsg) {
      const oldValue = await get(reftarget);
      this.addUndo(undoId || reftarget.toString(), undoMsg, () => {
        this.doFbUpdateSet(false, reftarget, oldValue.val());
      });
    }
    // TODO Test if catch works same way as onCOmplete callback that was avaiable in firebase 8
    return remove(reftarget)
      .then(() => this.fbCompletedCallback(null))
      .catch((e) => this.fbCompletedCallback(e));
  };
  removeUndefinedInOb = (o: Object) => {
    if (o === null || typeof o !== "object") {
      return;
    }

    Object.keys(o).forEach((k) => {
      if ((o as any)[k] === undefined) delete (o as any)[k];
      else if (typeof (o as any)[k] === "object")
        this.removeUndefinedInOb((o as any)[k]);
    });
  };
  doFbUpdateSet = async (
    isupdate: boolean,
    target: DatabaseReference,
    value: any,
    oldvalue?: any
  ) => {
    if (enableLogging) {
      let logvalue =
        typeof value === "number" && Number.isNaN(value) ? "NaN" : value;
      let logoldvalue = oldvalue || (await get(target)).val();
      this.removeUndefinedInOb(logvalue);
      this.logfb({
        uid: getAuth().currentUser?.uid || "Unknown",
        path: target.toString(),
        value: logvalue,
        oldvalue: logoldvalue || null,
      });
    }
    this.props.incrementProgressCount();
    const func = isupdate ? update : set;
    return func(target, value)
      .then(() => this.fbCompletedCallback(null))
      .catch((e) => this.fbCompletedCallback(e));
  };
  fbSet: fbSetType = async (target, value, undomsg, oldvalue) => {
    return this.fbUpdateSet(false, target, value, undomsg, oldvalue);
  };
  fbUpdate: fbSetType = async (target, value, undomsg, oldvalue) => {
    return this.fbUpdateSet(true, target, value, undomsg, oldvalue);
  };

  fbUpdateSet = async (
    isupdate: boolean,
    target: DatabaseReference | string,
    value: any,
    undomsg?: string,
    oldvalue?: string
  ) => {
    // TODO: After creating test for this rewrite undo like fbRemove
    let reftarget =
      typeof target === "string" ? ref(getDatabase(), target) : target;
    if (!undomsg) {
      return this.doFbUpdateSet(isupdate, reftarget, value);
    }
    const oldValue = oldvalue || (await get(reftarget)).val();
    this.addUndo(target.toString(), undomsg, () => {
      this.doFbUpdateSet(false, reftarget, oldValue, value);
    });
    return await this.doFbUpdateSet(isupdate, reftarget, value, oldValue);
  };

  execWithProgressAndUndo: execWithProgressAndUndoType = (
    f,
    doUndo,
    undoMsg
  ) => {
    this.props.execWithProgress(f);
    if (doUndo) {
      const randomid =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
      this.addUndo(randomid, undoMsg ?? "Undo last task", doUndo, undefined);
    }
  };
  addUndo: addUndoType = (id, msg, doUndo, oldvalue) => {
    let newEntry = {
      id: id,
      msg: msg,
      doUndo: doUndo,
      oldvalue: oldvalue,
    };
    this.setState((prevState) => {
      const idx = prevState.undoStack.length;
      if (idx > 0 && prevState.undoStack[idx - 1].id === id) {
        prevState.undoStack[idx - 1].msg = msg;
        prevState.undoStack[idx - 1].oldvalue = oldvalue;
      } else prevState.undoStack.push(newEntry);
      return {
        undoSnackOpen: true,
        undoStack: prevState.undoStack,
        lastmsgtime: new Date().getTime(),
      };
    });
  };

  handleUndo = (idx: number) => () => {
    if (idx <= this.state.undoStack.length) {
      this.state.undoStack[idx].doUndo();
      this.state.undoStack.pop();
    }
    if (this.state.undoStack.length > 0) {
      this.setState({ lastmsgtime: new Date().getTime() });
    } else this.setState({ undoSnackOpen: false });
  };

  handleClose = (_event: any, reason: any = null) => {
    if (reason === "timeout") {
      let tdiff = new Date().getTime() - this.state.lastmsgtime;
      if (tdiff < this.state.snackbarTimeout) {
        setTimeout(
          () => this.handleClose(null, reason),
          this.state.snackbarTimeout - tdiff
        );
        return;
      }
    }
    this.setState({ undoSnackOpen: false });
  };

  alertPromiseResolve?: (value: string | PromiseLike<string>) => void;
  //alertPromiseReject?: (reason?: any) => void;
  alertDialog = (props: AlertDialogProps) => {
    this.setState({ alertDialogOpen: true, alertDialogProps: props });
    return new Promise<string>((resolve) => {
      this.alertPromiseResolve = resolve;
      //this.alertPromiseReject = reject;
    });
  };

  render() {
    const { undoSnackOpen, undoStack, snackbarTimeout } = this.state;
    const idx = undoStack.length - 1;
    const entry = idx >= 0 ? undoStack[idx] : null;
    return (
      <NLContext.Provider value={this.state.contextValue}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          key={(entry || {}).id || "sn"}
          open={undoSnackOpen}
          autoHideDuration={snackbarTimeout}
          onClose={this.handleClose}
          message={(entry || {}).msg}
          action={
            <React.Fragment>
              <Button
                aria-label="undo"
                color="secondary"
                size="small"
                onClick={this.handleUndo(idx)}
              >
                UNDO
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <AlertDialog
          {...this.state.alertDialogProps}
          open={this.state.alertDialogOpen}
          onClose={(reason) => {
            if (this.alertPromiseResolve) this.alertPromiseResolve(reason);
            /*
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              if (this.alertPromiseReject) this.alertPromiseReject(reason);
            } else {
              if (this.alertPromiseResolve) this.alertPromiseResolve(reason);
            }
            */
            this.setState({ alertDialogOpen: false });
          }}
        />
        {this.props.children}
      </NLContext.Provider>
    );
  }
}

export const NLProvider = connector(NLProviderInternal);

export type WithNLProps = NLContextType;

export const useNL = () => {
  const context = useContext(NLContext);
  if (!context.addUndo) {
    throw Error("Missing provider");
  }
  return context as NLContextType;
};

// Google search string : with P extends context typescript
// from https://stackoverflow.com/questions/50612299/react-typescript-consuming-context-via-hoc/50613946
export function withNL<P extends NLContextType>(
  Component: React.ComponentType<P>
) {
  return function ThemedComponent(
    props: Pick<P, Exclude<keyof P, keyof NLContextType>>
  ) {
    return (
      <NLContext.Consumer>
        {(context) => (
          <Component
            {...(props as P)}
            execWithProgressAndUndo={context.execWithProgressAndUndo}
            addUndo={context.addUndo}
            fbSet={context.fbSet}
            fbUpdate={context.fbUpdate}
            fbRemove={context.fbRemove}
            fsSet={context.fsSet}
            alertDialog={context.alertDialog}
          />
        )}
      </NLContext.Consumer>
    );
  };
}
