import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import EventSettings from "components/EventSettings";
import KPStatsDialog from "components/KPStatsDialog";
import EditKP from "components/EditKP";
import EditTeamKP from "components/EditTeamKP";
import EditTeams from "components/EditTeams";
import IssuesDialog from "components/IssuesDialog";
import ConfigureEvent from "components/ConfigureEvent";
import TrackInvestigationDialog from "components/TrackInvestigationDialog";
import ValikTeamResultDialog from "components/ValikTeamResultDialog";
import EventsManagerOld from "components/EventsManagerOld";
import RestoreEvent from "components/RestoreEvent";
import AccessCodesDialog from "components/AccessCodesDialog";
import TeamsAllow from "components/TeamsAllow";
import NextGenDialogFactory from "./NextGenDialogFactory";
import DownloadsDialog from "components/DownloadsDialog";

const mapState = (state: RootState) => ({
  dialogs: state.dialog,
  editTeamKP: state.appState.editTeamKP,
  editKPId: state.appState.editKPId,
  eventId: state.eventId,
  investigateTrack: state.appState.investigateTrack,
  viewTeamResult: state.appState.viewTeamResult,
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type DialogFactoryProps = PropsFromRedux & {};
class DialogFactory extends React.Component<DialogFactoryProps> {
  render() {
    const { eventId, editKPId, editTeamKP, investigateTrack, viewTeamResult } =
      this.props;
    const {
      eventSettings,
      kpStats,
      teamsData,
      issueSolver,
      configureEvent,
      eventsManagerold,
      restoreEvent,
      accessCodes,
      teamsAllow,
      downloads,
    } = this.props.dialogs;
    let dialogs: React.ReactNode[] = [];
    if (eventId) {
      if (eventSettings)
        dialogs.push(<EventSettings key="evset" eventId={eventId} />);
      if (teamsData) dialogs.push(<EditTeams key="kpdata" eventId={eventId} />);
      if (editKPId)
        dialogs.push(<EditKP key="kpdit" eventId={eventId} kpId={editKPId} />);
      if (editTeamKP)
        dialogs.push(
          <EditTeamKP
            key="edittmkp"
            eventId={eventId}
            kpid={editTeamKP.kpid}
            tid={editTeamKP.tid}
          />
        );
      if (issueSolver) dialogs.push(<IssuesDialog key="issues" />);
      if (configureEvent)
        dialogs.push(<ConfigureEvent eventId={eventId} key="confEvent" />);
      if (investigateTrack)
        dialogs.push(
          <TrackInvestigationDialog tid={investigateTrack} key="tinvest" />
        );
      if (viewTeamResult)
        dialogs.push(
          <ValikTeamResultDialog teamid={viewTeamResult} key="teamresult" />
        );
      if (teamsAllow)
        dialogs.push(<TeamsAllow key="tallow" eventId={eventId} />);

      if (downloads) dialogs.push(<DownloadsDialog key="dwnl" />);
    }
    if (eventsManagerold) dialogs.push(<EventsManagerOld key="start" />);
    if (restoreEvent) dialogs.push(<RestoreEvent key="restoreev" />);
    if (accessCodes) dialogs.push(<AccessCodesDialog key="acccodes" />);
    dialogs.push(<NextGenDialogFactory key="nextgdf" />);

    return [...dialogs, kpStats && <KPStatsDialog key="kpstats" />];
  }
}

export default connector(DialogFactory);
