import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { issueType, IssueTypes, solveIssue } from "rx/appStateSlice";
import { closeDialog } from "rx/dialogsSlice";
import { RootState } from "rx/store";
import { useNL } from "utils/NLContext";

const issueSecondaryText = (issue: issueType) => {
  if (issue.type === IssueTypes.GPSForUnknownDev) {
    return issue.data.devid;
  }
  return "";
};

const IssueUnknownGpsDev: React.FC<{
  issue: issueType;
  completed: () => void;
}> = ({ issue, completed }) => {
  const { t } = useTranslation();
  const teamsList = useSelector((state: RootState) => state.teamsList);
  const eventId = useSelector((state: RootState) => state.eventId);
  const { fbSet } = useNL();

  const possibleteam = teamsList[issue.data.uid];
  return (
    <div>
      <Typography>{t("issues.unknowngpsdev.cmnt")}</Typography>
      {Boolean(possibleteam) && (
        <Typography>
          {t("issues.unknowngpsdev.possibleteam", { name: possibleteam.name })}
        </Typography>
      )}
      <List>
        {Object.entries(teamsList).map(([tid, team]) => (
          <ListItem
            key={team.name}
            button
            onClick={() => {
              fbSet(
                `/teams/${eventId}/list/${tid}/devs/${issue.data.devid}`,
                "X"
              );
              completed();
            }}
          >
            {tid}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const IssuesDialog: React.FC = () => {
  const { t } = useTranslation();
  const [solveissue, setSolveIssue] = useState(
    undefined as issueType | undefined
  );
  const issues = useSelector((state: RootState) => state.appState.issues);
  const dispatch = useDispatch();

  let content: ReactElement;
  if (solveissue) {
    const completed = () => {
      dispatch(solveIssue(solveissue));
      setSolveIssue(undefined);
    };
    if (solveissue.type === IssueTypes.GPSForUnknownDev)
      content = <IssueUnknownGpsDev issue={solveissue} completed={completed} />;
    else content = <Typography>Solve it</Typography>;
  } else {
    if (issues.length > 0)
      content = (
        <List>
          {issues.map((i) => (
            <ListItem key={i.id} button onClick={() => setSolveIssue(i)}>
              <ListItemText
                primary={i.title}
                secondary={issueSecondaryText(i)}
              />
            </ListItem>
          ))}p
        </List>
      );
    else content = <Typography>{t("issues.nomoreissues")}</Typography>;
  }
  return (
    <Dialog open={true}>
      <DialogTitle>Issues</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => dispatch(closeDialog("issueSolver"))}
        >
          <Trans>button.close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IssuesDialog;
