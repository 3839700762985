import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import dayjs from "dayjs";
import { useTSelector } from "rx/store";
import { fetchUidData } from "rx/uidmapSlice";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useAppDispatch } from "./EventsManager";

var PUSH_CHARS =
  "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
function decodeFBId(id: string) {
  id = id.substring(0, 8);
  var timestamp = 0;
  for (var i = 0; i < id.length; i++) {
    var c = id.charAt(i);
    timestamp = timestamp * 64 + PUSH_CHARS.indexOf(c);
  }
  return timestamp;
}
/*
var key = prompt("Enter Firebase push ID");
if (key) {
  var timestamp = decode(key);
  console.log(timestamp+"\n"+new Date(timestamp));
  alert(timestamp+"\n"+new Date(timestamp));
}*/

type LogEntryAdminDrawer = {
  enableadmin: boolean;
  uid: string;
};
type LogEntryPathChange = {
  path: string;
  value?: string;
  oldvalue?: string;
  uid: string;
};

type LogEntryTrackingTime = {
  devid: string;
  endtime: number;
  locationtime: number;
  stoptracking: true;
};

type LogEntry = LogEntryAdminDrawer | LogEntryPathChange | LogEntryTrackingTime;

type OldLogDataType = {
  [year: string]: {
    [month: string]: {
      [day: string]: {
        [ekey: string]: LogEntry;
      };
    };
  };
}; // TODO delete old log data type when old data is goen. Needs new app delivery
type LogDataType =
  | OldLogDataType
  | {
      [ekey: string]: LogEntry;
    };

const ChangesLogDialogContents: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const eventId = useTSelector((state) => state.eventId);
  const logdata = useRFirebaseDb<LogDataType>(`log/${eventId}`);
  const dispatch = useAppDispatch();
  const uidmap = useTSelector((state) => state.uidmap);

  let tabledata: {
    id: string;
    uid: string;
    content: string;
  }[] = [];
  const addEntry = (ekey: string, e: LogEntry) => {
    if (typeof e !== "object") return;
    let uidvalue: string = "unknown";
    let content: string = JSON.stringify(e);
    if ("enableadmin" in e) {
      content = e.enableadmin
        ? "Enabled admin drawer"
        : "Disabled admin drawer";
    } else if ("path" in e) {
      const s = e.path.split("/");
      content = ".../" + s[s.length - 1] + ", " + e.oldvalue + " => " + e.value;
    } else if ("stoptracking" in e) {
      content = `devid: ${e.devid}, diff: ${e.endtime - e.locationtime},  endtime: ${dayjs(e.endtime).toISOString()}`;
    }
    if ("uid" in e) {
      uidvalue = e.uid;
    }

    tabledata.push({
      id: ekey,
      uid: uidvalue,
      content: content,
    });
  };
  if (logdata) {
    for (let it of Object.keys(logdata)) {
      const ent = logdata[it];
      let lkey = parseInt(it); // If it is number between 2000 and 3000 its old lo
      if (!isNaN(lkey) && lkey > 2000 && lkey < 3000) {
        const oldlogdata = logdata as OldLogDataType;
        for (let year of Object.keys(oldlogdata)) {
          for (let month of Object.keys(oldlogdata[it])) {
            for (let day of Object.keys(oldlogdata[it][month])) {
              for (let [ekey, entry] of Object.entries(
                oldlogdata[year][month][day]
              )) {
                addEntry(ekey, entry);
              }
            }
          }
        }
      } else {
        addEntry(it, ent as LogEntry);
      }
    }
  }
  const columns: GridColDef<(typeof tabledata)[number]>[] = [
    {
      field: "id",
      headerName: "Time",
      width: 180,
      valueGetter: (_, row) => {
        return dayjs(decodeFBId(row.id)).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    {
      field: "uid",
      headerName: "User",
      width: 130,
      valueGetter: (_, row) => {
        const uentry = uidmap[row.uid];
        if (uentry)
          return (
            uentry.displayName ||
            uentry.email ||
            uentry.phoneNumber ||
            "empty user"
          );
        dispatch(fetchUidData({ uids: [row.uid], eventid: "--" }));
        return row.uid;
      },
    },
    {
      field: "content",
      headerName: "Content?",
      flex: 1,
    },
  ];
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={tabledata}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default ChangesLogDialogContents;
