import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import React, { useRef } from "react";
import { LatLngToString, StringToLatLng } from "utils/SmallUtils";
import FBTextField from "./FBTextField";

const FBLatLng: React.FC<{ path: string } & TextFieldProps> = ({
  path,
  ...restProps
}) => {
  const coordsinput = useRef<HTMLInputElement>(null);
  return (
    <FBTextField
      inputRef={coordsinput}
      path={path}
      {...restProps}
      parsefbvalue={(v) => {
        return LatLngToString(v);
      }}
      convertofbvalue={(v) => {
        return StringToLatLng(v);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Copy  coordinates"
              onClick={() => {
                if (coordsinput.current)
                  navigator.clipboard.writeText(coordsinput.current.value);
              }}
              edge="end"
              size="large">
              <FileCopy />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

/*
                  <FormControl>
                    <InputLabel htmlFor="kploc">
                      {t("fbf.kploc.label")}
                    </InputLabel>
                    <FBFilledInput
                      id="kploc"
                      variant="outlined"
                      inputRef={this.state.coordsinput}
                      path={`/eventsdata/${eventId}/kpdata/${kpId}/loc`}
                      formatValue={(value) => LatLngToString(value, 6)}
                      parseToValue={StringToLatLng}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Copy startloc coordinates"
                              onClick={() => {
                                if (this.state.coordsinput?.current)
                                  navigator.clipboard.writeText(
                                    this.state.coordsinput.current.value
                                  );
                              }}
                              edge="end"
                            >
                              <FileCopy />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  */
export default FBLatLng;
