import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useRef } from "react";
import { Marker, Popup } from "react-leaflet";

import "utils/NutiMap.css";
import * as L from "leaflet";
import { RootState, useTSelector } from "rx/store";
import "./kpmarkerstyles.css";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export type MarkFullData = RootState["marks"][string][string][string] & {
  id: string;
  tid: string;
  devid: string;
  correct: boolean;
};

const SimpleObjectMap: React.FC<{ ob: object }> = ({ ob }) => {
  return (
    <table>
      {Object.entries(ob).map(([k, v]) => {
        return (
          <tr>
            <th align="right">{k}</th>
            <td>{v}</td>
          </tr>
        );
      })}
    </table>
  );
};

const MarkLocation: React.FC<{
  mark: MarkFullData;
  color?: string;
}> = ({ mark, color }) => {
  const marker = useRef<L.Marker>(null);
  const kp = useTSelector((state) => state.kpList[mark.kpid]);
  const team = useTSelector((state) => state.teamsList[mark.tid]);
  const kpdata = useTSelector((state) => state.kpData[mark.kpid]);
  const teamsdata = useTSelector((state) => state.teamsData[mark.tid]);
  const device = teamsdata.devs?.[mark.devid] ?? {};
  const { t } = useTranslation();
  let size = 40;
  const markercolor = color || "red";
  const icon = L.divIcon({
    className: "my-custom-pin",
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    html: `<svg xmlns="http://www.w3.org/2000/svg" fill="${markercolor}" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z"/</svg>`,
  });
  const loc = new L.LatLng(mark.lat, mark.lng);
  const devname =
    device.systemName === "iOS"
      ? device.name
      : device.brand + " " + device.model;
  return (
    <Marker autoPan riseOnHover={true} icon={icon} position={loc} ref={marker}>
      <Popup className={"kpmarker"}>
        <Card
          elevation={0}
          style={{
            backgroundColor: "white",
            margin: "1px !important",
            borderRadius: "12px",
          }}
        >
          <CardContent>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">
                    {t("marklocatin.label.kpnr")}
                  </TableCell>
                  <TableCell>{kp.nr}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {t("marklocatin.label.team")}
                  </TableCell>
                  <TableCell> {team?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {t("marklocatin.label.answer")}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {kpdata?.responses
                      ? kpdata.responses[mark.answer]
                      : mark.answer}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {t("marklocatin.label.aeg")}
                  </TableCell>
                  <TableCell>
                    {dayjs(mark.aeg).format("DD-MM hh:mm:ss")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {t("marklocatin.label.dev")}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={<SimpleObjectMap ob={device} />}>
                      <div>{devname}</div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Popup>
    </Marker>
  );
};

export default MarkLocation;
