import EventResult from "components/EventResult";
import React from "react";
import EventDataListener from "utils/DataListener";

const ResultOnly: React.FC = () => {
  return (
    <div>
      <EventResult />
      <EventDataListener resultOnly={true} />
    </div>
  );
};

export default ResultOnly;
