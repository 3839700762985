import { EmojiEmotions } from "@mui/icons-material";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {
  child,
  getDatabase,
  onValue,
  ref,
  remove,
  set,
} from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { argouid, SkeletonWrapper } from "utils/SmallUtils";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";

// TODO ... convert to use uidmapSlice
export type UserEntry = {
  uid: string;
  email?: string;
  displayName?: string;
  phoneNumber?: string;
};

export type GetUsersResponse = {
  users: UserEntry[];
  notFound: { uid: string }[];
};

type GetUserDataQueryType = { uids?: string[]; s?: string; eventid: string };
type UserEntryData = {
  [uid: string]: UserEntry;
};

class GetUserData {
  static inProgressUIDS: string[] = [];
  static lastPromise: Promise<UserEntryData> | undefined;
  static cached: UserEntryData = {};

  static makecall = async (query: GetUserDataQueryType) => {
    const getusers = httpsCallable<GetUserDataQueryType, GetUsersResponse>(
      getFunctions(undefined, "europe-west1"),
      "api/getusers"
    );
    try {
      const r = await getusers(query);
      const resp: UserEntryData = {};
      r.data.users.forEach((e) => {
        resp[e.uid] = e;
        this.cached[e.uid] = e;
      });
      return resp;
    } catch (error) {
      console.warn("fb function returned error:", error);
      return {};
    }
  };
  static getUsersByUID = (
    uids: string[],
    eventid: string
  ): Promise<UserEntryData> => {
    if (uids.filter((u) => !this.cached[u]).length === 0)
      return Promise.resolve(this.cached);
    if (
      uids.filter((u) => !this.inProgressUIDS.includes(u)).length === 0 &&
      this.lastPromise
    )
      return this.lastPromise;
    this.inProgressUIDS = uids;
    this.lastPromise = this.makecall({ uids: uids, eventid: eventid }).finally(
      () => {
        this.lastPromise = undefined;
        this.inProgressUIDS = [];
      }
    );
    return this.lastPromise;
  };

  static searchUsers = async (
    s: string,
    eventid: string
  ): Promise<UserEntryData> => {
    return this.makecall({ s: s, eventid: eventid });
  };
}

const AdminsAutocomplete: React.FC<{
  adminpath: string;
  singleEntry?: boolean;
  label?: string;
  onChange?: (
    uid: string,
    val: string | undefined,
    reason: AutocompleteChangeReason
  ) => void;
}> = ({ adminpath, singleEntry, label, onChange }) => {
  const eventid = useTSelector((state) => state.eventId || "BadEventId");
  const [users, setUsers] = useState<{ [uid: string]: UserEntry }>({});
  const [admins, setAdmins] = useState<string[]>();
  const [owner, setOwner] = useState<string>();
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const adminref = ref(getDatabase(), adminpath);
    const unsub = onValue(adminref, (s) => {
      if (s.exists()) {
        let owner = "";
        const adminsentrie = s.val();
        if (typeof adminsentrie === "string") {
          setAdmins([adminsentrie]);
          return;
        }
        const admins = Object.entries(adminsentrie)
          .filter(([k, v]) => {
            if (v === "owner") owner = k;
            return v !== "owner";
          })
          .map(([k, _]) => k);
        setAdmins(admins);
        setOwner(owner);
      } else {
        setAdmins([]);
      }
    });
    return unsub;
  }, [adminpath]);

  const value = useMemo(() => {
    const value = admins?.map((e) => e);
    if (value && owner) value.unshift(owner);
    return value;
  }, [admins, owner]);

  useEffect(() => {
    let isActive = true;

    if (value)
      GetUserData.getUsersByUID(value, eventid).then((v) => {
        if (isActive) setUsers((state) => Object.assign({}, state, v));
      });
    return () => {
      isActive = false;
    };
  }, [value, setUsers, eventid]);

  useEffect(() => {
    let isActive = true;
    const to = setTimeout(() => {
      if (inputValue !== "") {
        GetUserData.searchUsers(inputValue, eventid).then((v) => {
          if (isActive) setUsers((state) => Object.assign({}, state, v));
        });
      }
    }, 1000);
    return () => {
      isActive = false;
      clearTimeout(to);
    };
  }, [inputValue, eventid]);

  const labelForItem = useCallback(
    (option: string) => {
      const u = users[option];
      return u === undefined
        ? "*** Unknown ***"
        : u.displayName
        ? u.displayName +
          " / " +
          (u.email || "") +
          " / " +
          (u.phoneNumber || "")
        : u.phoneNumber
        ? u.phoneNumber
        : u.email || "";
    },
    [users]
  );

  const handleChange = useCallback(
    (_: any, value: string[], reason: AutocompleteChangeReason) => {
      const adminref = ref(getDatabase(), adminpath);
      if (reason === "selectOption") {
        if (singleEntry) {
          set(adminref, value[0]);
          return;
        }
        value.forEach((v) => {
          const u =
            users[v] ||
            (v === argouid ? { uid: argouid, displayName: "Argo" } : undefined);
          if (u === undefined) return;
          let val = u.displayName || u.email || u.phoneNumber;
          if (!admins?.includes(v) && v !== owner) {
            set(child(adminref, v), val);
            if (onChange) onChange(v, val, reason);
          }
        });
        setInputValue("");
      } else if (reason === "removeOption" || reason === "clear") {
        if (singleEntry) {
          remove(adminref);
          return;
        }
        admins?.forEach((a) => {
          if (reason === "clear" || !value.includes(a)) {
            remove(child(adminref, a));
            if (onChange) onChange(a, undefined, reason);
          }
        });
      } else {
        console.error("unhandled:", reason, value);
      }
    },
    [adminpath, admins, onChange, owner, singleEntry, users]
  );

  return (
    <SkeletonWrapper condition={value === undefined}>
      <Autocomplete
        multiple
        id="admin-users"
        disableClearable
        options={Object.keys(users)} // Add sort
        isOptionEqualToValue={(option, value) => {
          return option === value;
        }}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option) => labelForItem(option)}
        inputValue={inputValue}
        filterSelectedOptions
        onInputChange={(_, value) => setInputValue(value)}
        renderTags={(value: readonly string[], getTagProps) => {
          return value.map((option: string, index: number) => {
            const rmDeleteProp =
              option === owner ? { onDelete: undefined } : {};
            const oplabel = labelForItem(option);
            return (
              <Chip
                avatar={<EmojiEmotions />}
                variant="filled"
                label={oplabel}
                {...getTagProps({ index })}
                {...rmDeleteProp}
              />
            );
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label || t("admin.edit.label")}
          />
        )}
      />
    </SkeletonWrapper>
  );
};

export default AdminsAutocomplete;
