import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "rx/store";

import "./setupfirebase";
import "./i18n";
import AuthStateListener from "utils/AuthStateListener";
import { SnackbarProvider } from "notistack";
import { NLProvider } from "utils/NLContext";
import FBPresence from "utils/FBPresence";
import { IconButton } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { AdapterDayjs as DayjsUtils } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

declare module "@mui/material/styles" {
  interface Theme {
    drawerWidth: number;
  }
  interface ThemeOptions {
    drawerWidth: number;
  }
}

const theme = createTheme({
  drawerWidth: 240,
  direction: "ltr",
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "8px !important", // https://github.com/mui/material-ui/issues/26795, If it get fixts this can be removed
        },
      },
    },
    /* Don't remembr why it was required. But it creates problem with map intera
tction. For example cant choose map layer
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1050, // If i could override only of trackdrawer zindex, this wouldn't be needed
        },
      },
    },*/
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 420,
          border: "1px solid #dadde9",
        },
      },
    },
  },
});

const notistackRef = React.createRef<SnackbarProvider>();

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DayjsUtils}>
          <Provider store={store}>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              action={(key) => (
                <IconButton
                  onClick={() => {
                    notistackRef.current?.closeSnackbar(key);
                  }}
                  size="large"
                >
                  <Close />
                </IconButton>
              )}
            >
              <NLProvider>
                <QueryClientProvider client={new QueryClient()}>
                  <App />
                  <AuthStateListener />
                  <FBPresence />
                </QueryClientProvider>
              </NLProvider>
            </SnackbarProvider>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
