import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridToolbar } from "@mui/x-data-grid/components/toolbar/GridToolbar";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid/models/gridRows";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { GridCellExpand, RenderCellExpand } from "utils/RenderCellExpanded";
import Box from "@mui/material/Box";
import { green, red } from "@mui/material/colors";
import { CheckCircleOutlineTwoTone } from "@mui/icons-material";

const ResponsesResultTable: React.FC<{ eventId: string }> = ({ eventId }) => {
  const { t } = useTranslation();
  const teams = useTSelector((state) => state.teamsList);
  const questions = useTSelector((state) => state.kpData);
  const kplist = useTSelector((state) => state.kpList);
  const teamAnswers = useTSelector((state) => state.teamAnswers);
  const kpAnswers = useTSelector((state) => state.kpAnswers);
  const rows: GridRowsProp = [
    ...Object.entries(questions)
      .filter(([qid, q]) => kplist[qid].nr !== "F")
      .map(([qid, q]) => {
        let r = {
          id: qid,
          question: q.longdesc,
          answer: q.responses ? q.responses[kpAnswers[qid]] : "",
          nr: Number(kplist[qid].nr),
        } as GridValidRowModel;
        Object.entries(teamAnswers).forEach(([tid, ta]) => {
          if (!q.responses) return;
          let tanswer = ta[qid]?.answer;
          if (!tanswer) return;
          if (ta[qid])
            r[tid] = {
              isok: kpAnswers[qid] === tanswer,
              r: q.responses[tanswer],
            };
        });

        return r;
      }),
  ];

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "nr",
      headerName: t("header.kpnr" as any),
      flex: 0.15,
      type: "number",
      hideable: false,
    },
    {
      field: "question",
      headerName: t("header.question" as any),
      flex: 1,
      hideable: false,
      renderCell: RenderCellExpand,
    },
    {
      field: "answer",
      headerName: t("header.answer" as any),
      flex: 1,
      renderCell: RenderCellExpand,
    },
    ...Object.entries(teams).map(([tid, t]) => {
      return {
        field: tid,
        headerName: t.name,
        flex: 0.8,
        align: "center",
        cellClassName: (p) => {
          if (!p.value) return "";
          return p.value.isok ? "okresp" : "badresp";
        },
        valueFormatter: (value, row, column, apiRef) => {
          if (!value) return null;
          return row[tid].r;
        },
        renderCell: (p) => {
          if (p.value?.isok) return <CheckCircleOutlineTwoTone />;
          if (!p.value) return null;
          return (
            <GridCellExpand value={p.value.r} width={p.colDef.computedWidth} />
          );
        },
      } as GridColDef;
    }),
  ];
  return (
    <Box
      sx={{
        height: "100%",
        "& .okresp": {
          backgroundColor: green[100],
          color: "#1a3e72",
        },
        "& .badresp": {
          backgroundColor: red[100],
          color: "#1a3e72",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: {
              fileName: "Nutilogi-event-questionresult.csv",
            },
          },
        }}
      />
    </Box>
  );
};

export default ResponsesResultTable;
