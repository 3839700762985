import React, { useState } from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import { Alert } from "@mui/material";
import { createSelector } from "reselect";
import { setEditKPId } from "rx/appStateSlice";
import { Delete } from "@mui/icons-material";
import { execWithProgress } from "rx/saveInProgressSlice";
import RestoreDeletedKPs from "./RestoreDeletedKPs";
import KPDataExportHandler from "./KPDataExportHandler";
import KPDataImportHandler from "./KPDataImportHandler";
import FBTextField from "./FBTextField";
import FBSwitch from "./FBSwitch";
import {
  child,
  DatabaseReference,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  Unsubscribe,
} from "firebase/database";
import { withNL, WithNLProps } from "utils/NLContext";
import { NextKPNr } from "utils/NextKPNr";
import { isMobile } from "react-device-detect";
import { DeletedKPEntry, kpnrcompare } from "./KPData";

interface KPTableData {
  nr: string;
  indev: string;
  response: string;
  desc: string;
  ra: string;
  maxa: string;
}
type KPCellRenderer = (
  kpid: string,
  editable?: boolean
) => JSX.Element | string | number;

const KPCell: React.FC<{
  kpid: string;
  renderer: KPCellRenderer;
}> = ({ kpid, renderer }) => {
  const [editable, setEditable] = useState(false);
  return (
    <TableCell onClick={() => setEditable(!editable)}>
      {renderer(kpid, editable)}
    </TableCell>
  );
};

const mapState = (state: RootState) => ({
  kpList: state.kpList,
  kpData: state.kpData,
  kpAnswer: state.kpAnswers,
  event: state.event,
  archived: Boolean(state.event.arch),
});
const mapDispatch = {
  closeDialog: () => closeDialog("kpData"),
  setEditKPId: (kpid: string) => setEditKPId(kpid),
  execWithProgress: (f: () => Promise<void>) => execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type KPDataProps = PropsFromRedux &
  WithNLProps &
  WithTranslation & {
    eventId: string;
    switchToNextGen: () => void;
  };

type KPDataState = {
  orderBy: keyof KPTableData;
  order: Exclude<SortDirection, false>;
  dorestorekp: boolean;
  filterStr: string;
  inlineediting: boolean;
  deletedkps: {
    [deleteid: string]: DeletedKPEntry;
  };
  doExport: boolean;
  doImport: boolean;
};

class KPData extends React.Component<KPDataProps, KPDataState> {
  state: KPDataState = {
    orderBy: "nr",
    order: "desc",
    dorestorekp: false,
    filterStr: "",
    inlineediting: false,
    deletedkps: {},
    doExport: false,
    doImport: false,
  };
  columns: { id: keyof KPTableData; renderer: KPCellRenderer }[] = [
    {
      id: "nr",
      renderer: (kpid: string) => {
        return this.props.kpList[kpid].nr;
      },
    },
    {
      id: "indev",
      renderer: (kpid: string) => {
        return this.props.kpList[kpid].inwork ? "Yes" : "";
      },
    },
    {
      id: "response",
      renderer: (kpid: string) => {
        let answer = this.props.kpAnswer[kpid];
        const responses = this.props.kpData[kpid]?.responses;
        if (responses) {
          if (answer in responses) answer = responses[answer];
          else
            return (
              <Alert severity="error">
                <Trans>kpdata.badresponse</Trans>
              </Alert>
            );
        }
        return answer;
      },
    },
    {
      id: "desc",
      renderer: (kpid: string) => {
        return this.props.kpData[kpid]?.desc || "";
      },
    },
    {
      id: "ra",
      renderer: (kpid: string) => {
        return this.props.kpList[kpid].ra || 1;
      },
    },
  ];

  unsubscribeDeletedKPs?: Unsubscribe;
  eventref: DatabaseReference;
  constructor(props: KPDataProps) {
    super(props);
    this.eventref = ref(getDatabase(), "/eventsdata/" + props.eventId);
  }

  componentDidMount() {
    this.unsubscribeDeletedKPs = onValue(
      child(this.eventref, "deletedkps"),
      (s) => {
        this.setState({ deletedkps: s.val() });
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribeDeletedKPs && this.unsubscribeDeletedKPs();
  }

  handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof KPTableData
  ) => {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({
      order: isDesc ? "asc" : "desc",
      orderBy: property,
    });
  };

  sortedkps = createSelector(
    (state: KPDataState) => state.orderBy,
    (state: KPDataState) => state.order,
    (state: KPDataState, props: KPDataProps) => props.kpList,
    (state: KPDataState, props: KPDataProps) => props.kpData,
    (state: KPDataState, props: KPDataProps) => props.kpAnswer,
    (orderBy, order, kpList, kpData, kpAnswer) => {
      return Object.keys(kpList).sort((l, r) => {
        if (orderBy === "nr") {
          let diff = kpnrcompare(kpList[l].nr, kpList[r].nr);
          return order === "desc" ? diff : -diff;
        } else if (orderBy === "response") {
          let aval = (v: string) => {
            let answer = kpAnswer[v];
            const responses = kpData[v]?.responses;
            if (responses) {
              if (answer in responses) answer = responses[answer];
              else return null;
            }
            return answer;
          };
          let a = aval(l);
          let b = aval(r);
          if (!a || !b) {
            return !a && b ? -1 : a && !b ? 1 : 0;
          }
          return order === "desc" ? a.localeCompare(b) : b.localeCompare(a);
        } else if (orderBy === "desc") {
          let a = kpData[l]?.desc || "";
          let b = kpData[r]?.desc || "";
          return order === "desc" ? a.localeCompare(b) : b.localeCompare(a);
        } else if (orderBy === "ra") {
          let diff = Number(kpList[l].ra) - Number(kpList[r].ra);
          return order === "desc" ? diff : -diff;
        } else if (orderBy === "indev") {
          const lb = Boolean(kpList[l].inwork);
          const rb = Boolean(kpList[r].inwork);
          let resp = 0;
          if (lb && !rb) resp = 1;
          else if (!lb && rb) resp = -1;
          return order === "desc" ? resp : -resp;
        }
        return 0;
      });
    }
  );

  deleteKP = (kpid: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    this.props.execWithProgress(async () => {
      const savekp: DeletedKPEntry = {
        id: kpid,
        kp: this.props.kpList[kpid],
      };

      if (this.props.kpAnswer[kpid]) savekp.answer = this.props.kpAnswer[kpid];
      if (this.props.kpData[kpid]) savekp.data = this.props.kpData[kpid];

      // TODO add undo here.
      this.props.execWithProgressAndUndo(async () => {
        await push(child(this.eventref, "deletedkps"), savekp);
        await remove(child(this.eventref, `kp/${kpid}`));
        await remove(child(this.eventref, `kpanswer/${kpid}`));
        await remove(child(this.eventref, `kpdata/${kpid}`));
      });
    });
  };

  render() {
    const { t, kpList, kpData, kpAnswer, archived } = this.props;
    const { orderBy, order, filterStr, inlineediting } = this.state;
    const filter = this.state.filterStr.toLowerCase();
    const columns = this.columns.concat(
      Object.values(kpList).find((kp) => kp.maxanswerdistance)
        ? [
            {
              id: "maxa",
              renderer: (kpid: string) => {
                return this.props.kpList[kpid].maxanswerdistance || "";
              },
            },
          ]
        : []
    );
    const kps = this.sortedkps(this.state, this.props).filter((kpid) => {
      if (filter.length === 0) return true;
      if (kpid.includes(filter)) return true;
      if (kpList[kpid].nr.toString().includes(filter)) return true;
      if (kpData[kpid].desc?.toLowerCase().includes(filter)) return true;
      let hasresponse = false;
      Object.values(kpData[kpid].responses || {}).forEach((v) => {
        if (!hasresponse && v.toLowerCase().includes(filter))
          hasresponse = true;
      });
      if (hasresponse) return true;
      if (
        !kpData[kpid].responses &&
        kpAnswer[kpid]?.toLowerCase()?.includes(filterStr)
      )
        return true;
      return false;
    });

    return (
      <>
        <Dialog
          open={true}
          fullWidth
          fullScreen={isMobile}
          maxWidth="lg"
          sx={{
            minHeight: "90vh",
          }}
        >
          <DialogTitle>
            <div>{t("kpdata.title")}</div>
            <Box>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    <input type="file" style={{ display: "none" }} />
                    <Grid item>
                      {!archived && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            push(child(this.eventref, "kp"), {
                              inwork: true,
                              nr: NextKPNr(kpList),
                            }).then((newkpid) =>
                              this.props.setEditKPId(
                                newkpid.key || "failedpush"
                              )
                            );
                          }}
                        >
                          {t("kpdata.addkp")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!archived && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => this.setState({ doImport: true })}
                        >
                          {t("kpdata.import")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {!archived && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => this.setState({ doExport: true })}
                          draggable={true}
                          onDragStart={() => {
                            console.log(
                              "We could theoretically allow tragging for export"
                            );
                          }}
                        >
                          {t("kpdata.export")}
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <FBTextField
                        id="globmaxanswerdistance"
                        type="number"
                        path={`/eventsdata/${this.props.eventId}/data/maxmarkingdistance`}
                      />
                    </Grid>
                    <Grid item>
                      <FBSwitch
                        aria-label="Show KP's in web"
                        id="showkpsinweb"
                        path={`/eventsdata/${this.props.eventId}/data/kpsinweb`}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        focused
                        autoFocus
                        label={t("kpdata.filter")}
                        value={filterStr}
                        onChange={(event) =>
                          this.setState({ filterStr: event.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {Object.keys(this.state.deletedkps || {}).length !== 0 && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.setState({ dorestorekp: true });
                      }}
                    >
                      {t("kpdata.restore")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <DialogContent>
            <TableContainer
              sx={{
                maxHeight: "70vh",
              }}
            >
              <Table stickyHeader aria-label="KP Table">
                <TableHead>
                  <TableRow>
                    {columns.map((c) => (
                      <TableCell
                        key={c.id}
                        sortDirection={orderBy === c.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === c.id}
                          direction={orderBy === c.id ? order : "desc"}
                          onClick={(event) =>
                            this.handleRequestSort(event, c.id)
                          }
                        >
                          {t(`kpdata.header.${c.id}` as const)}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>{t("kpdata.header.delete")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kps.map((kpid) => (
                    <TableRow
                      key={kpid}
                      hover
                      onClick={
                        inlineediting
                          ? undefined
                          : () => {
                              this.props.setEditKPId(kpid);
                            }
                      }
                    >
                      {inlineediting &&
                        columns.map((c) => (
                          <KPCell
                            key={c.id}
                            kpid={kpid}
                            renderer={c.renderer}
                          />
                        ))}
                      {!inlineediting &&
                        columns.map((c) => (
                          <TableCell key={c.id}>{c.renderer(kpid)}</TableCell>
                        ))}
                      <TableCell align="center">
                        {!archived && (
                          <IconButton
                            onClick={this.deleteKP(kpid)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {this.state.dorestorekp && (
              <RestoreDeletedKPs
                deletedkps={this.state.deletedkps}
                eventref={this.eventref}
                onClose={() => {
                  this.setState({ dorestorekp: false });
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.props.switchToNextGen}>
              Switch to new version
            </Button>
            <Box sx={{ flex: 1 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.closeDialog}
            >
              {t("button.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <KPDataImportHandler
          trigger={this.state.doImport}
          handleClose={() => this.setState({ doImport: false })}
        />
        <KPDataExportHandler
          open={this.state.doExport}
          handleClose={() => this.setState({ doExport: false })}
        />
      </>
    );
  }
}

export default connector(withTranslation()(withNL(KPData)));
