import React, { useEffect, useMemo, useState } from "react";
import { Marker, useMap } from "react-leaflet";
import { Map, latLngBounds, LatLngBounds, LatLng, divIcon } from "leaflet";
import NutiMap, { ClickHandler } from "utils/NutiMap";
import TracksManager from "utils/TracksManager";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { KPManager } from "utils/KPManager";
import store, { useTSelector } from "rx/store";
import MinimapControl from "utils/MiniMap";
import { CurrentLocationControl } from "utils/MapUtils";
import MarksStatistics from "utils/MarksStatistics";
import { enableAddKP, setEditKPId } from "rx/appStateSlice";
import { useDispatch } from "react-redux";
import { child, getDatabase, push, ref, set } from "firebase/database";
import { NextKPNr } from "utils/NextKPNr";

declare global {
  var mainMap: Map;
}
var zoomedevent: string | null;

const MyLocation: React.FC<{ loc: LatLng }> = ({ loc }) => {
  const size = 40;
  const icon = divIcon({
    className: "my-custom-pin",
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg>`,
  });
  return <Marker riseOnHover={true} icon={icon} position={loc} />;
};
const MyLocationManager: React.FC = () => {
  const trackme = useTSelector((state) => state.appState.trackMe);
  const [currentloc, setCurrentLoc] = useState<LatLng | null>(null);
  const map = useMap();
  useEffect(() => {
    if (trackme) map.locate({ watch: true, setView: true });
    return () => {
      if (trackme) map.stopLocate();
    };
  }, [trackme, map]);
  useEffect(() => {
    const onfunc = (ev: L.LocationEvent) => {
      setCurrentLoc(ev.latlng);
      console.log("Got location found", ev.latlng);
    };
    map.on("locationfound", onfunc);
    return () => {
      map.off("locationfound", onfunc);
    };
  }, [map]);
  if (!trackme || !currentloc) return null;
  return <MyLocation loc={currentloc} />;
};
const ZoomToEvent: React.FC<{ bounds: LatLngBounds }> = ({ bounds }) => {
  const eventId = useTSelector((state) => state.eventId);
  const map = useMap();

  useMemo(() => {
    if (zoomedevent === eventId) return;
    zoomedevent = eventId;
    map.fitBounds(bounds, { animate: false });
  }, [eventId, bounds, map]);
  return null;
};
const EvBoundsHandler: React.FC = () => {
  const bounds = useTSelector((state) => state.event.eventbounds);
  const showminimap = useTSelector((state) => state.appState.showMiniMap);

  if (!bounds) return null;
  const evbounds = latLngBounds(bounds.southWest, bounds.northEast);
  return (
    <>
      <ZoomToEvent bounds={evbounds} />
      {showminimap && <MinimapControl evbounds={evbounds} />}
    </>
  );
};

const AddKPHandler: React.FC = () => {
  const addingKP = useTSelector((state) => state.appState.addingKP);
  const iscontinous = useTSelector((state) => state.appState.continousKPInsert);
  const dispatch = useDispatch();
  if (!addingKP) return null;
  return (
    <ClickHandler
      onClick={(ll) => {
        const state = store.getState();
        if (!state.eventId) {
          alert("Trying to add kp without event");
          return;
        }
        const eventref = ref(getDatabase(), "/eventsdata/" + state.eventId);
        const kpList = state.kpList;
        push(child(eventref, "kp"), {
          inwork: true,
          ra: 1,
          nr: NextKPNr(kpList),
        }).then((newkpid) => {
          set(child(eventref, `kpdata/${newkpid.key}/loc`), ll);
          if (!iscontinous) dispatch(setEditKPId(newkpid.key || "failedpush"));
        });
        if (!iscontinous) dispatch(enableAddKP(false));
      }}
    />
  );
};

const SetMainMap: React.FC = () => {
  const map = useMap();
  if (map) global.mainMap = map;
  return null;
};
const MainMap: React.FC = () => {
  return (
    <NutiMap>
      <SetMainMap />
      <MarksStatistics />
      <EvBoundsHandler />
      <KPManager />
      <TracksManager />
      <MyLocationManager />
      <CurrentLocationControl />
      <AddKPHandler />
    </NutiMap>
  );
};

export default MainMap;
