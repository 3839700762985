import { ContentCopy, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { WheelOfNamesConfig, WheelOfNamesRequest, WheelOfNamesResponse } from "@nutilogi/nutilogitypes";
import dayjs from "dayjs";
import { getDatabase, query, ref, remove, set, orderByChild, startAfter, onValue, child } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventType, TeamList } from "rx/fbListSlices";
import { useFBSliceSelector, useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";
import { SearchField } from "./SearchField";

const emptyConfig = {
  title: "",
  description: "",
  entries: [],
};

type BooleanWheelConfigs = {
  [K in keyof WheelOfNamesConfig]-?: WheelOfNamesConfig[K] extends
  | boolean
  | undefined
  ? K
  : never;
}[keyof WheelOfNamesConfig];


const EventPicker: React.FC<{ triggerClose: () => void }> = ({ triggerClose }) => {
  const evstarttime = useTSelector(s => s.event.starttime);
  const alreadyeventids = useFBSliceSelector("eventConf", s => s.wheelofnames?.events) ?? {}
  const uid = useTSelector(s => s.user.user?.uid) ?? "-";
  const eventId = useTSelector(s => s.eventId) ?? "badevid";
  const fbquery = useMemo(() => {
    const st = dayjs(evstarttime).subtract(1, 'weeks').valueOf();
    return query(ref(getDatabase(), '/events'), orderByChild('starttime'), startAfter(st));
  }, [evstarttime]);
  const events = useRFirebaseDb<EventType[]>(fbquery);
  const showEvents = Object.entries(events ?? {}).filter(([eid, e]) => eid !== eventId && e.admins?.[uid] && !alreadyeventids[eid]);
  if (events !== undefined && showEvents.length === 0) triggerClose();
  return <List>
    {showEvents.map(([eid, e]) => <ListItemButton key={eid} onClick={() => {
      set(ref(getDatabase(), '/eventsdata/' + eventId + '/conf/wheelofnames/events/' + eid), true);
    }}>

      <ListItemText primary={e.name} /></ListItemButton>)}
  </List>;
}
const AddEventHandler: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  return <Grid>
    <Button variant="contained" onClick={() => setDialogOpen(true)}>Add event</Button>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogContent>
        <EventPicker triggerClose={() => setDialogOpen(false)} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setDialogOpen(false)}>{t('button.cancel')}</Button>
      </DialogActions>
    </Dialog>
  </Grid>
}

const AditionalEventList: React.FC<{ eventId: string }> = ({ eventId }) => {

  const otherevents = useFBSliceSelector("eventConf", s => s.wheelofnames?.events) ?? {};
  return <Grid >
    <Table>
      <TableBody>
        {Object.keys(otherevents).map(evid => <TableRow key={evid}><TableCell>{evid}</TableCell><TableCell><IconButton onClick={() => {
          remove(ref(getDatabase(), `/eventsdata/${eventId}/conf/wheelofnames/events/${evid}`))
        }}>
          <Delete /></IconButton></TableCell></TableRow>)}
      </TableBody>
    </Table>
  </Grid>
}
const ConfigSwitch: React.FC<{
  field: BooleanWheelConfigs;
  currentConfig?: WheelOfNamesConfig;
  existingConfig?: WheelOfNamesConfig;
  defaultvalue: boolean;
  save: (fn: (s?: WheelOfNamesConfig) => WheelOfNamesConfig) => void;
  label: string;
}> = ({ field, save, currentConfig, existingConfig, defaultvalue, label }) => (
  <FormControlLabel
    control={
      <Switch
        checked={
          currentConfig?.[field] ?? existingConfig?.[field] ?? defaultvalue
        }
        onChange={(event) =>
          save((s) => ({
            ...(s ?? emptyConfig),
            [field]: event.target.checked,
          }))
        }
      />
    }
    label={label}
  />
);
const useTeamNamesForWheel = () => {
  const othereventids = useTSelector(s => s.eventConf.wheelofnames?.events ?? {});
  const currentevteams = useTSelector((s) => s.teamsList);
  const [otherteams, setOtherTeams] = useState<{ [evid: string]: { [tid: string]: TeamList } }>({})

  useEffect(() => {
    const teamsref = ref(getDatabase(), '/teams');
    const unsubs = Object.keys(othereventids).map(evid => onValue(child(teamsref, evid + "/list"), (snap) => {
      if (!snap.exists()) {
        setOtherTeams(s => {
          const newState = { ...s };
          delete newState[evid];
          return newState;
        })
      };
      setOtherTeams(s => ({
        ...s,
        [evid]: snap.val()
      }))
    }));

    return () => {
      Object.keys(othereventids).forEach(evid => {
        setOtherTeams(s => {
          const newState = { ...s };
          delete newState[evid];
          return newState;
        })
      })
      unsubs.forEach(u => u());
    }
  }, [othereventids]);

  const allteams = useMemo(() => {
    const allteams = Object.values(currentevteams).map(t => t)
    Object.values(otherteams).forEach(oev => {
      Object.values(oev).forEach(oteam => {
        allteams.push(oteam)
      })
    });
    return allteams;

  }, [currentevteams, otherteams]);

  return allteams;
}

const TeamNamesList: React.FC<{ teams: TeamList[], ignnames: string[] }> = ({ teams, ignnames }) => {
  const evid = useTSelector(s => s.eventId) ?? "badevid";
  const [filteredTeams, setFilteredTeams] = useState(teams);
  const { t } = useTranslation();
  const { fbSet } = useNL();
  const setNewList = useCallback((fteams: TeamList[]) => {
    setFilteredTeams(fteams)
  }, [setFilteredTeams]);
  return <>
    <SearchField variant="standard" items={teams} field="name" setNewList={setNewList} label={t("teams.filter")} />
    <Box sx={{ maxHeight: 200, overflow: 'auto' }}>

      <List dense>
        {filteredTeams.map((t, idx) => <ListItem>
          <ListItemButton onClick={() => {
            if (!t.name) return;
            const setnames = (ignnames.includes(t.name)) ? ignnames.filter(v => v !== t.name) : ignnames.concat(t.name);
            fbSet('/eventsdata/' + evid + '/conf/wheelofnames/ignnames', setnames);
          }
          }>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={!Boolean(t.name && ignnames.includes(t.name))}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={t.name} />
          </ListItemButton>
        </ListItem>)}
      </List>
    </Box>
  </>
}

const WheelOfNamesContent: React.FC = () => {

  const evname = useTSelector((s) => s.event.name);
  const evid = useTSelector((s) => s.eventId) ?? "badevivd";
  const wheelid = useFBSliceSelector("eventConf", s => s.wheelofnames?.id);
  const allteams = useTeamNamesForWheel();
  const ignnames = useTSelector(s => s.eventConf.wheelofnames?.ignnames);
  const [teamsUpToDate, setTeamsUpToDate] = useState<Boolean>();
  const [configUpToDate, setConfigUpToDate] = useState<Boolean>();
  const [existingConfig, setExistingConfig] = useState<WheelOfNamesConfig>();
  const [currentConfig, setCurrentConfig] = useState<WheelOfNamesConfig>();
  const [disablecreatebutton, setDisableCreateButton] = useState(false);
  const inputfile = useRef<HTMLInputElement>(null);

  const wreq = useCallback(() => {
    return httpsCallable<WheelOfNamesRequest, WheelOfNamesResponse>(
      getFunctions(undefined, "europe-west1"),
      "api/wheelofnames"
    );
  }, []);

  const useteams = useMemo(() => {
    return allteams.filter(t => !(t.name && ignnames?.includes(t.name)))
  }, [allteams, ignnames]);
  const createWheel = useCallback(async (teams: TeamList[]) => {
    if (!currentConfig) return;
    setDisableCreateButton(true);
    if (wheelid && (wheelid?.length || 0) > 0) {
      await wreq()({ eventid: evid, request: "delete", path: wheelid });
    }
    wreq()({
      request: "create",
      eventid: evid,
      config: Object.assign(currentConfig, {
        title: evname ?? "missing name",
        entries: teams.map(t => {
          return { text: t.name }
        }),
      }),
    }).then((resp) => {
      const rdata = resp.data.data;
      const error = (resp.data as any).error
      if (error !== undefined) {
        console.error("Wheel request failed", error)
      }
      if (rdata === undefined || !('path' in rdata)) return;
      if (rdata.path && rdata.path.length > 0) {
        set(
          ref(getDatabase(), `/eventsdata/${evid}/conf/wheelofnames/id`),
          rdata.path
        );
      }
      setDisableCreateButton(false);
    });
  }, [currentConfig, evid, evname, wheelid, wreq]);

  useEffect(() => {
    if (wheelid === undefined) {
      setCurrentConfig(emptyConfig);
      return;
    }
    // TODO Make it into getwheel
    wreq()({ eventid: evid, request: "getwheels" }).then((resp) => {
      if (resp.data.data === undefined || !('wheels' in resp.data.data)) {
        console.error("Bad response from server");
        return;
      }
      const existing = resp.data.data.wheels.find((w) => w.path === wheelid);
      if (existing === undefined) {
        setExistingConfig(undefined);
        setCurrentConfig(emptyConfig);
        console.warn("Missing wheel delete path locallly");
        remove(ref(getDatabase(), `/eventsdata/${evid}/conf/wheelofnames/id`));
        return;
      }
      setExistingConfig(existing.config);
      setCurrentConfig(existing.config);
    });
  }, [evid, wheelid, wreq]);

  useEffect(() => {
    // check if teams list is the same.
    if (!existingConfig?.entries) {
      setTeamsUpToDate(false);
      return;
    }
    const isSame =
      existingConfig.entries.length === useteams.length &&
      existingConfig.entries.every((e) => useteams.find((t) => t.name === e.text));
    setTeamsUpToDate(isSame);
  }, [useteams, existingConfig?.entries]);

  useEffect(() => {
    if (!existingConfig || !currentConfig) {
      setConfigUpToDate(false);
      return;
    }
    let testfields: [keyof WheelOfNamesConfig, boolean | string][] = [
      ["displayWinnerDialog", true],
      ["description", ""],
      ["animateWinner", false],
      ["showTitle", true],
      ["winnerMessage", "We have a winner!"],
      ["title", evname || ""],
      ["autoRemoveWinner", false],
      ["launchConfetti", false],
    ];
    const isNotSame = testfields.some(([fname, defaultvalue]) => {
      return (
        (existingConfig[fname] ?? defaultvalue) !==
        (currentConfig[fname] ?? defaultvalue)
      );
    });
    setConfigUpToDate(!isNotSame);
  }, [evname, currentConfig, existingConfig]);

  const link =
    wheelid && wheelid.length > 0 ? `https://wheelofnames.com/${wheelid}` : undefined;
  return (
    <DialogContent>
      {link && (
        <div>
          <a target="wheelofnames" href={link}>
            {link}
          </a>
          <span style={{ paddingLeft: 8 }}>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(link);
              }}
              size="large"
            >
              <ContentCopy />
            </IconButton>
          </span>
        </div>
      )}
      <Grid container sx={{ paddingBottom: 2 }} spacing={2}>
        <Grid>
          <Card>
            <CardContent>
              <Grid container sx={{ paddingBottom: 2, paddingTop: 2 }}>
                <AddEventHandler />
                <Grid xs={12} />
                <AditionalEventList eventId={evid} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card>
            <CardContent>
              <TeamNamesList teams={allteams} ignnames={ignnames ?? []} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Grid container>
            <Grid>
              <ConfigSwitch
                defaultvalue={true}
                field="displayWinnerDialog"
                label="Display Winner Dialog"
                currentConfig={currentConfig}
                existingConfig={existingConfig}
                save={setCurrentConfig}
              />
            </Grid>
            <Grid>
              <ConfigSwitch
                defaultvalue={false}
                field="animateWinner"
                label="Animate Winner"
                currentConfig={currentConfig}
                existingConfig={existingConfig}
                save={setCurrentConfig}
              />
            </Grid>
            <Grid>
              <ConfigSwitch
                defaultvalue={false}
                field="autoRemoveWinner"
                label="Auto Remove Winner"
                currentConfig={currentConfig}
                existingConfig={existingConfig}
                save={setCurrentConfig}
              />
            </Grid>
            <Grid>
              <ConfigSwitch
                defaultvalue={true}
                field="showTitle"
                label="Show Title"
                currentConfig={currentConfig}
                existingConfig={existingConfig}
                save={setCurrentConfig}
              />
            </Grid>
            <Grid>
              <ConfigSwitch
                defaultvalue={false}
                field="launchConfetti"
                label="Launch Confetti"
                currentConfig={currentConfig}
                existingConfig={existingConfig}
                save={setCurrentConfig}
              />
            </Grid>
            <Grid>
              <TextField
                label="Description"
                value={currentConfig?.description}
                onChange={(event) => {
                  setCurrentConfig((s) => ({
                    ...(s ?? emptyConfig),
                    description: event.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {teamsUpToDate !== undefined && configUpToDate !== undefined && (
        <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            onClick={() => {
              inputfile?.current?.click();
            }}
          >
            Load conf
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            disabled={
              disablecreatebutton ||
              Object.keys(allteams).length === 0 ||
              (teamsUpToDate && configUpToDate) === true
            }
            variant="contained"
            onClick={() => createWheel(useteams)}
          >
            {wheelid && wheelid.length > 0 ? "Recreate" : "Create"}
          </Button>
        </Box>
      )}
      <input
        ref={inputfile}
        type="file"
        style={{ display: "none" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files) return;
          var files = e.target.files;
          var reader = new FileReader();
          reader.onload = function (ev) {
            if (!ev.target) return;
            var contents = ev.target.result;
            if (!contents || typeof contents !== "string") return;
            setCurrentConfig(JSON.parse(contents));
          };
          reader.readAsText(files[0]);
        }}
      />
    </DialogContent>
  );
};

export default WheelOfNamesContent;
