import {
  ClientToServerEvents,
  PointData,
  ServerToClientEvents,
} from "@nutilogi/nutilogitypes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IssueTypes, addIssue } from "rx/appStateSlice";
import store from "rx/store";
import { addPoints } from "rx/tracksSlice";
import { Socket, io } from "socket.io-client";

// TODO Why cant use NutilogiClientSocket directory from original declaration
type NutilogiClientSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

const SocketIoListener: React.FC<{ eventId: string }> = ({ eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const socket: NutilogiClientSocket = io("https://lnext.nutilogi.ee");
    let devToTeamMap = new Map<string, string>();

    const onConnect = () => {
      socket.emit("listener", eventId); // TODO Listen only for current event.
    };
    socket.on("connect", onConnect);

    const onLastLoc = (devid: string, point: PointData) => {
      console.log("Got last location", devid, point);
      const state = store.getState();
      if (!devToTeamMap.has(devid)) {
        const team = Object.entries(state.teamsList).find(
          ([tid, teamdata]) => teamdata.devs && devid in teamdata.devs
        );
        if (team !== undefined) {
          devToTeamMap.set(devid, team[0]);
        }
      }
      const tid = devToTeamMap.get(devid);
      if (tid === undefined) {
        const issues = state.appState.issues;
        const isReported = issues.find(
          (i) =>
            i.type === IssueTypes.GPSForUnknownDev && i.data.devid === devid
        );
        if (isReported) return;
        dispatch(
          addIssue({
            id: devid,
            title: "issues.unknowngpsdev.label",
            type: IssueTypes.GPSForUnknownDev,
            data: { devid: devid },
          })
        );
        console.log("location for team that we dont know about, skipping.");
        return;
      }
      dispatch(
        addPoints({
          tid,
          devid,
          points: [point],
        })
      );
    };
    socket.on("lastloc", onLastLoc);

    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("lastloc", onLastLoc);
    };
  }, [dispatch, eventId]);

  return null;
};

export default SocketIoListener;
