import React from "react";
import * as L from "leaflet";
import "leaflet-draw";

type LeafletRectangleProps = {
  bounds?: L.LatLngBoundsExpression;
  map: L.Map;
  onChanged?: (bounds: L.LatLngBounds) => void;
  onCreated?: (bounds: L.LatLngBounds) => void;
};
type LeafletRectangleState = {};
class LeafletRectangle extends React.Component<
  LeafletRectangleProps,
  LeafletRectangleState
> {
  state: LeafletRectangleState = {};

  rect?: L.Rectangle;
  drawer?: L.Draw.Rectangle;

  componentDidUpdate(prevProps: LeafletRectangleProps) {
    if (this.props.bounds) {
      if (this.rect) {
        if (!this.rect.getBounds().equals(this.props.bounds)) {
          (this.rect as any).editing.disable();
          this.rect.setBounds(this.props.bounds);
          (this.rect as any).editing.enable();
        }
      } else {
        this.createFeature();
      }
    } else {
      if (this.rect) {
        this.props.map.removeLayer(this.rect);
        this.rect = undefined;
        this.createFeature();
      }
    }
  }
  componentDidMount() {
    this.createFeature();
    this.props.map.on(L.Draw.Event.EDITMOVE, this.onEdited);
    this.props.map.on(L.Draw.Event.EDITRESIZE, this.onEdited);
    this.props.map.on(L.Draw.Event.CREATED, this.onCreated);
  }
  createFeature = () => {
    if (this.props.bounds) {
      this.rect = new L.Rectangle(this.props.bounds);
      (this.rect as any).editing.enable();
      this.props.map.addLayer(this.rect);
    } else {
      let options = {
        showArea: true,
        showRadius: true,
        metric: true,
      };
      this.drawer = new L.Draw.Rectangle(this.props.map as L.DrawMap, options);
      this.drawer.enable();
    }
  };
  componentWillUnmount() {
    this.props.map.off(L.Draw.Event.CREATED, this.onCreated);
    this.props.map.off(L.Draw.Event.EDITMOVE, this.onEdited);
    this.props.map.off(L.Draw.Event.EDITRESIZE, this.onEdited);
    if (this.rect) this.props.map.removeLayer(this.rect);
    if (this.drawer) this.drawer.disable();
  }
  onEdited = () => {
    if (this.rect && this.props.onChanged)
      this.props.onChanged(this.rect.getBounds());
    return;
  };

  onCreated = (levent: L.LeafletEvent) => {
    const event = levent as L.DrawEvents.Created;
    if (event.layer instanceof L.Rectangle) {
      if (this.props.onCreated) this.props.onCreated(event.layer.getBounds());
    }
  };
  render() {
    return null;
  }
}

export default LeafletRectangle;
