// TODO This should be only temporary solution... Let's try to convert classes which use it to hooks.
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
  export default function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

 export type RouteComponentProps = {
     router: {
      location: any,
      navigate: any,
      params: any
     }
  }