import React from "react";

import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import { useNL } from "utils/NLContext";

const FBCheckbox: React.FC<
  CheckboxProps & { path: string; id?: string; readOnly?: boolean }
> = (props) => {
  const { fbSet } = useNL();
  const [fbvalue] = useFirebaseDb(props.path);
  const { t } = useTranslation();

  const checkbox = (
    <Checkbox
      {...props}
      checked={
        props.checked !== undefined ? props.checked : Boolean(fbvalue.value)
      }
      onChange={
        props.readOnly
          ? undefined
          : (_event, checked) => fbSet(props.path, checked)
      }
    />
  );
  const label = props.id ? t(`fbf.${props.id}.label` as any) : undefined;

  return (
    <NLTooltipWrapper
      tkey={props.id ? (`fbf.${props.id || "noid"}.tooltip` as any) : undefined}
    >
      <SkeletonWrapper condition={fbvalue.value === undefined}>
        {label ? (
          <FormControlLabel
            control={checkbox}
            label={props.id ? t(`fbf.${props.id}.label` as any) : undefined}
          />
        ) : (
          checkbox
        )}
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FBCheckbox;
