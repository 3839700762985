import { Button, DialogContent, Grid } from "@mui/material";
import dayjs from "dayjs";
import { get, getDatabase, ref } from "firebase/database";
import React from "react";
import { useTranslation } from "react-i18next";
import store from "rx/store";
import { genArchiveDoc } from "utils/archive";
import { download } from "utils/SmallUtils";
import { getArchiveFile } from "utils/ArchiveReader";

type PrintItemType = {
  limit?: string;
  loc: {
    lat: number;
    lng: number;
  };
  name: string;
  type: "limit" | "cross" | "noentry" | "warning";
};
const downloadPrintItems = async () => {
  const st = store.getState();
  const eventId = st.eventId;
  const evname = st.event.name;
  if (eventId === null) return;
  const items = (
    await get(ref(getDatabase(), `/eventsdata/${eventId}/printitems`))
  ).val() as { [k: string]: PrintItemType };
  const header = ["lat", "lng", "type", "name", "limit"];
  const data = Object.entries(items).map(([k, v]) => {
    var values = [v.loc.lat, v.loc.lng, v.type, v.name, v.limit];

    return values.join(";");
  });
  download(
    header.join(";") + "\n" + data.join("\n"),
    `${evname}-printitems.csv`,
    "applicaiton/csv"
  );
};

const downloadKPAjadJSON = () => {
  const result: any = {};
  const state = store.getState();
  result["teams"] = state.teamsList;
  result["teamAnswers"] = state.teamAnswers;
  result["kpData"] = state.kpData;
  result["kpList"] = state.kpList;
  result["ajad"] = {};
  Object.keys(state.teamAnswers).forEach((tid) => {
    const tajad: any = {};
    tajad["teamname"] = state.teamsList[tid].name;
    tajad["kpd"] = {};
    Object.keys(state.teamAnswers[tid]).forEach((kpid) => {
      tajad["kpd"][kpid] = Object.assign(
        {
          kpnr: state.kpList[kpid].nr,
          desc: state.kpData[kpid].desc,
          aeghms: dayjs(state.teamAnswers[tid][kpid].aeg).format("HH:mm:ss"),
        },
        state.teamAnswers[tid][kpid]
      );
    });
    result["ajad"][tid] = tajad;
  });
  download(JSON.stringify(result), "kpajad.json", "applicaiton/json");
};

export const donwloadEvent = async () => {
  const eventId = store.getState().eventId;
  if (eventId === null) return;
  const evname = store.getState().event.name ?? "nutilogievent";
  const arch = store.getState().event.arch;
  let doc;
  if (arch) {
    doc = await getArchiveFile(eventId, arch, "evdata.json");
  } else doc = JSON.stringify(await genArchiveDoc(eventId, () => {}));

  download(doc, `${evname}.json`, "applicaiton/json");
};

const DownloadsDialogContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button variant="contained" onClick={() => downloadKPAjadJSON()}>
            KP ajad JSON
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => downloadPrintItems()}>
            Print elemendid (CSV)
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => donwloadEvent()}>
            {t("button.eventdownload")}
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default DownloadsDialogContent;
