import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useTSelector } from "rx/store";
import FBSwitch from "./FBSwitch";
import { useNL } from "utils/NLContext";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import FBKeyboardTimePicker from "./FBKeyboardTimePicker";
import { useTranslation } from "react-i18next";
import NutiMap from "utils/NutiMap";
import KPMarker from "utils/KPMarker";
import FBTextField from "./FBTextField";
import NLTooltip from "./NLTooltip";
import FBLatLng from "./FBLatLng";
import { child, getDatabase, ref, set } from "firebase/database";

enum StartType {
  Server,
  Join,
  Client,
  Mass,
}

const EventSettingsStart: React.FC = () => {
  const eventId = useTSelector((state) => state.eventId);
  const event = useTSelector((state) => state.event);
  const kplist = useTSelector((state) => state.kpList);
  const kpdata = useTSelector((state) => state.kpData);
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const evdata = `/eventsdata/${eventId}/data/`;

  let startTimeType = StartType.Server;
  if (event.joinstartinterval) startTimeType = StartType.Join;
  else if (event.clientstart) startTimeType = StartType.Client;
  else if (event.massstarttimems) startTimeType = StartType.Mass;

  const changeStartTimeManagment = (ev: SelectChangeEvent<StartType>) => {
    const evref = ref(getDatabase(), evdata);

    if (ev.target.value === StartType.Join) {
      if (event.massstarttimems) fbRemove(child(evref, "massstarttimems"));
      if (event.clientstart) fbSet(child(evref, "clientstart"), false);
      set(child(evref, "joinstartinterval"), 60000);
    } else if (ev.target.value === StartType.Mass) {
      if (event.joinstartinterval) fbRemove(child(evref, "joinstartinterval"));
      fbSet(child(evref, "massstarttimems"), event.starttime);
      if (event.clientstart) fbSet(child(evref, "clientstart"), false);
    } else if (ev.target.value === StartType.Client) {
      fbSet(child(evref, "clientstart"), true);
      if (event.joinstartinterval) fbRemove(child(evref, "joinstartinterval"));
      if (event.massstarttimems) fbRemove(child(evref, "massstarttimems"));
    } else if (ev.target.value === StartType.Server) {
      if (event.massstarttimems) fbRemove(child(evref, "massstarttimems"));
      if (event.joinstartinterval) fbRemove(child(evref, "joinstartinterval"));
      if (event.clientstart) fbSet(child(evref, "clientstart"), false);
    }
  };
  const finishkp = Object.entries(kplist || {}).find(
    ([_kpid, kp]) => kp.nr === "F"
  );
  const finishloc = finishkp ? kpdata[finishkp[0]] : undefined;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        {event.type !== "paevak" && (
          <FormControl>
            <InputLabel shrink htmlFor="stardiajad-id">
              {t("fbf.stardiajad.label")}
            </InputLabel>
            <Select
              variant="outlined"
              value={startTimeType}
              onChange={changeStartTimeManagment}
              label={t("fbf.stardiajad.label")}
              inputProps={{
                id: "stardiajad-id",
              }}
            >
              <MenuItem value={StartType.Server}>{t("startt.server")}</MenuItem>
              <MenuItem value={StartType.Join}>{t("startt.join")}</MenuItem>
              <MenuItem value={StartType.Client}>{t("startt.client")}</MenuItem>
              <MenuItem value={StartType.Mass}>{t("startt.mass")}</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
      {startTimeType === StartType.Mass && (
        <Grid item>
          <FBKeyboardDateTimePicker
            id="massstarttime"
            ampm={false}
            path={evdata + "massstarttimems"}
            format="DD/MM/YYYY HH:mm"
            slotProps={{
              actionBar: {
                actions: ["today"],
              },
            }}
          />
        </Grid>
      )}
      {startTimeType === StartType.Join && (
        <Grid item>
          <FBKeyboardTimePicker
            id="joinstartinterval"
            views={["minutes", "seconds"]}
            ampm={false}
            path={evdata + "joinstartinterval"}
            format="mm:ss"
          />
        </Grid>
      )}
      <Grid item md={6}>
        <FBSwitch
          id="joinrequiresconfirmation"
          path={`/eventsdata/${eventId}/data/joinrequiresconfirmation`}
        />
      </Grid>
      {startTimeType === StartType.Client && [
        <Grid key="g1" item>
          <div
            style={{
              height: "40vh",
              width: "60vh",
            }}
          >
            <NutiMap
              center={event.startloc}
              onClick={
                event.startloc
                  ? undefined
                  : (ll) => {
                    fbSet(evdata + "/startloc", ll);
                    // TODO add undo
                  }
              }
            >
              {event.startloc && (
                <KPMarker
                  eventHandlers={{
                    dragend: (ll) => {
                      fbSet(
                        evdata + "/startloc",
                        ll.target.getLatLng(),
                        t("startloc.moved")
                      );
                      console.log("drag end", ll.target.getLatLng());
                    },
                  }}
                  draggable={true}
                  position={event.startloc}
                />
              )}
            </NutiMap>
          </div>
        </Grid>,

        <Grid key="g2" item>
          <List>
            <ListItem>
              <FBKeyboardDateTimePicker path={`${evdata}/allowstartafter`} id={"startafter"}
                format="DD/MM/YYYY HH:mm"
                ampm={false}
                slotProps={{
                  actionBar: {
                    actions: ["clear", "today", "accept", "cancel"],
                  },
                }} />
            </ListItem>
            <ListItem>
              <FormControl variant="filled">
                <FBLatLng id="startloccoord" path={evdata + "/startloc"} />
              </FormControl>
            </ListItem>
            {event.startloc && (
              <ListItem>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    fbSet(evdata + "/startloc", null, t("startloc.removed"));
                  }}
                >
                  {t("button.deletestartloc")}
                </Button>
              </ListItem>
            )}
            {finishloc && !event.startloc && (
              <ListItem>
                <NLTooltip message={t("tooltip.usefinishforstart")}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      fbSet(evdata + "/startloc", finishloc.loc);
                    }}
                  >
                    {t("button.usefinishforstart")}
                  </Button>
                </NLTooltip>
              </ListItem>
            )}
            <ListItem>
              <NLTooltip message={t("tooltip.maxstartdistance")}>
                <FBTextField
                  id="maxstartdistance"
                  path={evdata + "/maxstartdistance"}
                  type="number"
                  margin="normal"
                  helperText={
                    (event.maxstartdistance || event.maxmarkingdistance || 0) >
                      0 && event.startloc
                      ? (event.maxstartdistance || event.maxmarkingdistance) +
                      t("helpertext.startdistance.withvalue")
                      : t("helpertext.startdistance.novalue")
                  }
                />
              </NLTooltip>
            </ListItem>
          </List>
        </Grid>,
      ]}
    </Grid>
  );
};

export default EventSettingsStart;
