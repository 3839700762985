import { CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "rx/store";

const ServerSaveStatus: React.FC = () => {
  const saveInProgreess = useSelector(
    (state: RootState) => state.saveInProgress
  );
  return saveInProgreess > 0 ? <CircularProgress color="inherit" /> : null;
};

export default ServerSaveStatus;
