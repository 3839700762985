import { Box, Button, Dialog, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import { getAuth } from "firebase/auth";
import {
  ref,
  child,
  DatabaseReference,
  getDatabase,
  get,
} from "firebase/database";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";
import CupJoinTeamsDialogContent from "./CupJoinTeamsDialogContent";
import {
  CupClassResultType,
  CupDataType,
  CupTeamsType,
  EventsData,
} from "./CupResult";
import { ExportContentTypes, ExportType, download } from "utils/SmallUtils";
import { stringify } from "csv-stringify/browser/esm";

export type CupListEntry = {
  growingpoints?: boolean;
  missvalue?: number | string;
  countingevents?: number;
  name?: string;
};

const HaveSameEventResults = (
  result: CupClassResultType,
  evids: string[],
  tids: GridRowSelectionModel
) => {
  for (let evid of evids) {
    const evr = result[evid];
    if (!evr) continue;
    if (tids.filter((v) => evr[v] !== undefined).length > 1) return true;
  }
  return false;
};

type CupObject = {
  name: string;
  events: { evid: string; name: string }[];
  klassid: {
    name: string;
    data: {
      koht: number;
      name: string;
      displayName: string;
      email: string;
      points: number[];
      total: number;
    }[];
  }[];
};

const cupDataAsObj = async (cupId: string, evdata: EventsData) => {
  const cupData: CupDataType = (
    await get(ref(getDatabase(), `cups/data/${cupId}`))
  ).val();
  const cupconf: CupListEntry = (
    await get(ref(getDatabase(), `cups/list/${cupId}`))
  ).val();
  let resp: CupObject = { name: cupconf.name || "", events: [], klassid: [] };

  if (!cupData?.result) {
    console.error("Missing cup data");
    return resp;
  }
  const sortedcupids = Object.keys(evdata).sort(
    (l, r) => (evdata[l].starttime || 0) - (evdata[r].starttime || 0)
  );
  for (const cid of sortedcupids) {
    resp.events.push({ evid: cid, name: evdata[cid].name || "-" });
  }
  Object.entries(cupData.result).forEach(
    ([klassid, klassresult], _idx, arr) => {
      const alen = resp.klassid.push({
        name: klassid,
        data: [],
      });
      let kl = resp.klassid[alen - 1];
      const rows = cupResultRows(
        cupconf || ({} as CupListEntry),
        klassresult,
        sortedcupids,
        cupData.teams || {}
      );
      Object.values(rows).forEach((e) => {
        kl.data.push({
          koht: e.koht,
          name: e.name || "",
          displayName: e.displayName,
          email: e.email,
          points: sortedcupids.map((evid) => e.ev[evid]),
          total: e.total,
        });
      });
    }
  );
  return resp;
};

export const downloadCup = async (
  cupId: string,
  evdata: EventsData,
  type: ExportType
) => {
  const cup = await cupDataAsObj(cupId, evdata);

  if (type === "csv") {
    const csvpromise = new Promise((resolve) => {
      const stringifier = stringify({ bom: true }, (_, data) => {
        resolve(data);
      });
      let elements = cup.events.length + 5;
      stringifier.write([cup.name, ...Array(elements - 1)]);
      for (const ev of cup.events) {
        stringifier.write([ev.name, ...Array(elements - 1)]);
      }
      for (const kl of cup.klassid) {
        stringifier.write([kl.name, ...Array(elements - 1)]);
        for (const r of kl.data) {
          stringifier.write([
            r.koht,
            r.name,
            r.displayName,
            r.email,
            ...r.points,
            r.total,
          ]);
        }
      }
      stringifier.end();
    });
    const csvdata = await csvpromise;
    download(csvdata, `${cup.name}.csv`, ExportContentTypes.csv);
    return `${cup.name}.csv`;
  } else {
    throw new Error(`Don't know how to export cup to ${type}`);
  }
};

export const cupResultRows = (
  cupconf: CupListEntry,
  result: CupClassResultType,
  evids: string[],
  teams: CupTeamsType
) => {
  const perteamresult: {
    [tid: string]: {
      ev: {
        [evid: string]: number;
      };
      unusedevents: string[];
      total: number;
    };
  } = {};

  Object.entries(result).forEach(([evid, evresult]) => {
    Object.entries(evresult).forEach(([teamid, points]) => {
      if (perteamresult[teamid] === undefined)
        perteamresult[teamid] = { total: 0, unusedevents: [], ev: {} };
      perteamresult[teamid].ev[evid] = points;
      //perteamresult[teamid].total += points;
    });
  });
  Object.entries(perteamresult).forEach(([tid, teamresult]) => {
    Object.keys(result).forEach((evid) => {
      if (teamresult.ev[evid] === undefined) {
        teamresult.ev[evid] = Number(cupconf?.missvalue || 0);
        //teamresult.total += teamresult.ev[evid];
      }
    });
  });
  Object.entries(perteamresult).forEach(([tid, teamresult]) => {
    const sortedpoints = Object.entries(teamresult.ev).sort(
      ([_l, lpoints], [_r, rpoints]) =>
        cupconf?.growingpoints ? lpoints - rpoints : rpoints - lpoints
    );
    if (cupconf?.countingevents) {
      while (sortedpoints.length > cupconf.countingevents) {
        const unused = sortedpoints.pop();
        if (unused) {
          teamresult.unusedevents.push(unused[0]);
        }
      }
    }
    teamresult.total = sortedpoints
      .slice(0, 5)
      .reduce((prev, [_, v]) => prev + v, 0);
  });
  const dircoef = cupconf?.growingpoints ? -1 : 1;
  return Object.entries(perteamresult)
    .sort(([_ltid, lresult], [_rtid, rresult]) => {
      if (rresult.total !== lresult.total)
        return (rresult.total - lresult.total) * dircoef;
      let lastevid = evids[evids.length - 1];
      return (rresult.ev[lastevid] - lresult.ev[lastevid]) * dircoef;
    })
    .map(([tid, tresult], idx) =>
      Object.assign(tresult, {
        id: tid,
        name: teams[tid].name,
        email: teams[tid].userdata.email,
        displayName:
          teams[tid].userdata.displayName || teams[tid].userdata.phoneNumber,
        koht: idx + 1,
      })
    );
};

const CupResultTable: React.FC<{
  klassresultref: DatabaseReference;
  enablejoin?: boolean;
  evids: string[];
  cupid: string;
  result: CupClassResultType;
  teams: CupTeamsType;
  title?: string;
  showEmail?: boolean;
  showDisplayName?: boolean;
}> = ({
  enablejoin,
  evids,
  cupid,
  result,
  teams,
  title,
  klassresultref,
  showEmail,
  showDisplayName,
}) => {
  const { t } = useTranslation();
  const cupconf = useRFirebaseDb<CupListEntry>(`cups/list/${cupid}`);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [joinEnabled, setJoinEnabled] = useState(false);
  const { fbSet } = useNL();
  const isAdmin = useRFirebaseDb<string | null>(
    `/cups/list/${cupid}/admins/${getAuth().currentUser?.uid || "nouid"}`
  );

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldrow: GridRowModel) => {
      for (let [nevid, points] of Object.entries(newRow.ev)) {
        if (oldrow.ev[nevid] !== points) {
          fbSet(
            child(klassresultref, nevid + "/" + newRow.id),
            points,
            "Changed single points"
          );
        }
      }
      return newRow;
    },
    [fbSet, klassresultref]
  );
  const rows = useMemo(() => {
    if (cupconf === undefined || cupconf === null) return [];
    return cupResultRows(cupconf, result, evids, teams);
  }, [cupconf, result, evids, teams]);
  if (cupconf === undefined) return null;
  const columns: GridColDef[] = [
    {
      field: "koht",
      headerName: t("cup.columns.place"),
      width: 80,
      align: "center",
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: t("cup.columns.team"),
      flex: 1.2,
      sortable: Boolean(isAdmin),
      headerAlign: "center",
    },
    ...(showEmail
      ? [
          {
            field: "email",
            headerName: t("cup.columns.email"),
            flex: 1.2,
            sortable: Boolean(isAdmin),
            headerAlign: "center",
          } as GridColDef,
        ]
      : []),
    ...(showDisplayName
      ? [
          {
            field: "displayName",
            headerName: t("cup.columns.displayName"),
            flex: 1.2,
            sortable: Boolean(isAdmin),
            headerAlign: "center",
          } as GridColDef,
        ]
      : []),
    ...evids.map((evid, idx) => {
      return {
        field: "ev" + evid,
        headerName: `${idx + 1}.`,
        flex: 0.5,
        sortable: Boolean(isAdmin),
        headerAlign: "center",
        align: "center",
        editable: Boolean(isAdmin),
        cellClassName: (param) => {
          return param.row.unusedevents.includes(evid) ? "unusedpoints" : null;
        },
        valueGetter: (_, row) => {
          return row.ev[evid];
        },
        valueSetter: (params) => {
          const newev = { ...params.row.ev };
          newev[evid] = Number(params.value);
          return { ...params.row, ev: newev };
        },
      } as GridColDef; // I get an error without this as for headerAlign
    }),
    {
      field: "total",
      headerName: t("cup.columns.total"),
      flex: 1,
      sortable: Boolean(isAdmin),
      headerAlign: "center",
      align: "center",
    },
  ];
  if (enablejoin) {
    columns.unshift({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
      renderHeader: () => {
        return (
          <Button
            disabled={
              selectionModel.length !== 2 ||
              HaveSameEventResults(result, evids, selectionModel)
            }
            onClick={() => {
              setJoinEnabled(true);
              for (let k of selectionModel) {
                console.log(k);
              }
            }}
          >
            Join
          </Button>
        );
      },
    });
  }

  return (
    <>
      {title && <Typography variant="h5">{title}</Typography>}
      <div style={{ height: 700, width: "100%", padding: "20px" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <Box
            style={{ flexGrow: 1 }}
            sx={{
              "& .unusedpoints": {
                backgroundColor: grey[200],
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              editMode="cell"
              checkboxSelection={enablejoin}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              rowSelectionModel={selectionModel}
              processRowUpdate={processRowUpdate}
            />
          </Box>
        </div>
      </div>
      <Dialog
        maxWidth="xl"
        fullWidth
        open={joinEnabled && selectionModel.length === 2}
      >
        <CupJoinTeamsDialogContent
          teamids={selectionModel}
          result={result}
          teams={teams}
          evids={evids}
          klassresultref={klassresultref}
          onClose={(cancel) => {
            if (!cancel) {
              setSelectionModel([]);
            }
            setJoinEnabled(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default CupResultTable;
