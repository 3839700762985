import { DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CupResult, { CupResultTitle } from "components/CupResult";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const CupOnly: React.FC = () => {
  const { cupid } = useParams<{ cupid: string }>();
  const [joinEnabled, setJoinEnabled] = useState(false);
  const [cupResultCb, setCupResultCb] = useState<Function>();
  if (!cupid) return null;
  return (
    <Dialog fullScreen open={true}>
      <DialogTitle>
        <CupResultTitle
          cupid={cupid}
          joinEnabled={joinEnabled}
          setJoinEnabled={setJoinEnabled}
          cupResultCb={cupResultCb}
        />
      </DialogTitle>
      <DialogContent>
        <CupResult
          cupId={cupid}
          joinEnabled={false}
          setCupResultCb={setCupResultCb}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CupOnly;
