import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import * as L from "leaflet";
import { LeafletContext } from "@react-leaflet/core";
import ArrowMarker from "./ArrowMarker";
import { FlyToLocOnMap } from "./MapUtils";
import SpeedTrack from "./SpeedTrack";
import memoize from "memoize-one";
import { TrackPoint } from "rx/tracksSlice";

const mapState = (state: RootState, props: TeamSpeedTrackOwnProps) => ({
  track: (state.tracks[props.tid] || {})[props.devid],
  tickets: state.spdTickets[props.tid][props.devid],
  starttime: state.teamsList[props.tid]?.starttime,
  finishtime: state.teamsList[props.tid]?.finishtime,
  ticketindex: ((state.tracks[props.tid] || {})[props.devid] || []).findIndex(
    (i) => i.t >= props.tickettime
  ),
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TeamSpeedTrackOwnProps = {
  tid: string;
  devid: string;
  map: L.Map;
  tickettime: number;
};
type TeamSpeedTrackProps = PropsFromRedux & TeamSpeedTrackOwnProps;
type TeamSpeedTrackState = {
  currentPointIdx?: number;
};
class TeamSpeedTrack extends React.Component<
  TeamSpeedTrackProps,
  TeamSpeedTrackState
> {
  static contextType = LeafletContext;
  state: TeamSpeedTrackState = {};
  polylines: L.Polyline[] = [];
  trackpoints: L.LatLng[] = [];
  firstidx: number = -1;
  lastidx: number = -1;

  getIndexes = memoize(
    (
      startt: number | undefined,
      endt: number | undefined,
      track: TrackPoint[]
    ) => {
      let firstidx = 0;
      while (startt && firstidx < track.length && track[firstidx].t <= startt)
        firstidx++;
      let lastidx = track.length - 1;
      while (endt && lastidx > 0 && track[lastidx].t > endt) lastidx--;
      return [firstidx, lastidx + 1];
    }
  );
  render() {
    if (!this.props.track) return null;
    const ticketpoint =
      this.props.ticketindex && this.props.ticketindex !== -1
        ? this.props.track[this.props.ticketindex]
        : undefined;
    const [firstidx, lastidx] = this.getIndexes(
      this.props.starttime,
      this.props.finishtime,
      this.props.track
    );
    return (
      <>
        {ticketpoint && (
          <FlyToLocOnMap loc={ticketpoint} map={mainMap} zoom={14} />
        )}
        {ticketpoint && (
          <ArrowMarker map={this.props.map} color="red" loc={ticketpoint} />
        )}
        <SpeedTrack
          map={this.props.map}
          track={this.props.track.slice(firstidx, lastidx)}
          tickets={this.props.tickets}
          currentTicketIndex={this.props.ticketindex - firstidx}
        />
      </>
    );
  }
}

export default connector(TeamSpeedTrack);
