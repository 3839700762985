import React from "react";
import { KPdata } from "rx/fbListSlices";
import * as L from "leaflet";
import { useMap } from "react-leaflet";
import { withNL, WithNLProps } from "utils/NLContext";
import "leaflet-editable";
import "leaflet-arrowheads";

type LineKPProps = WithNLProps & {
  kp: KPdata;
  kpdatapath: string;
  map: L.Map;
};
type LineKPState = {};
class LineKPHandler extends React.Component<LineKPProps, LineKPState> {
  state: LineKPState = {};
  polyline: L.Polyline;
  arrowline: L.Polyline;

  constructor(props: LineKPProps) {
    super(props);
    const points: L.LatLngExpression[] = [];
    this.arrowline = L.polyline(points);
    (this.arrowline as any).arrowheads({
      size: "40%",
      fill: true,
      color: "black",
      fillColor: "green",
    });
    if (props.kp.a && props.kp.loc) {
      points.push(props.kp.a);
      points.push(props.kp.loc);
      this.polyline = L.polyline(points);
    } else {
      this.polyline = this.props.map.editTools.startPolyline(
        props.kp.loc ? L.latLng(props.kp.loc) : undefined
      );
    }
  }

  updateArrow = () => {
    const rotatePoints = (
      center: L.LatLngTuple,
      points: L.LatLngTuple[],
      yaw: number
    ) => {
      const res = [];
      const centerPoint = this.props.map.latLngToLayerPoint(center);
      const angle = yaw * (Math.PI / 180);
      for (let i = 0; i < points.length; i++) {
        const p = this.props.map.latLngToLayerPoint(points[i]);
        // translate to center
        const p2 = new L.Point(p.x - centerPoint.x, p.y - centerPoint.y);
        // rotate using matrix rotation
        const p3 = new L.Point(
          Math.cos(angle) * p2.x - Math.sin(angle) * p2.y,
          Math.sin(angle) * p2.x + Math.cos(angle) * p2.y
        );
        // translate back to center
        let p4 = new L.Point(p3.x + centerPoint.x, p3.y + centerPoint.y);
        // done with that point
        res.push(this.props.map.layerPointToLatLng(p4));
      }
      return res;
    };
    var getCentroid = function (arr: L.LatLngTuple[]) {
      return arr.reduce(
        function (x, y) {
          return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
        },
        [0, 0]
      );
    };

    const points = this.polyline.getLatLngs() as L.LatLng[];
    const ptuples = points.map((ll) => [ll.lat, ll.lng] as L.LatLngTuple);
    ptuples[0] = getCentroid(ptuples);
    ptuples[1] = getCentroid(ptuples);
    this.arrowline.setLatLngs(rotatePoints(ptuples[0], ptuples, 90));
  };

  editHandler = (ev: L.VertexEvent) => {
    const name = ev.vertex.getIndex() === 0 ? "a" : "loc";
    this.props.fbSet(
      this.props.kpdatapath + "/" + name,
      (({ lat, lng }) => ({ lat, lng }))(ev.vertex.getLatLng()),
      "Line KP changed"
    );
  };
  createdHandler = (ev: L.VertexEvent) => {
    if (ev.vertex.getIndex() !== 1) return;
    this.polyline.disableEdit();
    const points = this.polyline.getLatLngs() as L.LatLng[];
    this.props.fbUpdate(this.props.kpdatapath, {
      a: points[1],
      loc: points[0],
    });
  };
  setPoint = (pos: 0 | 1, p: L.LatLng) => {
    const ll = this.polyline.getLatLngs() as L.LatLng[];
    ll[pos] = p;
    this.polyline.setLatLngs(ll);
  };
  savePoint = (pos: "a" | "loc") => {
    const ll = this.polyline.getLatLngs() as L.LatLng[];
    this.props.fbSet(
      this.props.kpdatapath + "/" + pos,
      ll[pos === "a" ? 0 : 1],
      "Line KP changed"
    );
  };

  componentDidUpdate(prevprops: LineKPProps) {
    const points = this.polyline.getLatLngs() as L.LatLng[];
    if (this.props.kp.a) {
      if (
        points.length === 2 &&
        (this.props.kp.a?.lat !== points[0].lat ||
          this.props.kp.a?.lng !== points[0].lng)
      ) {
        points[0] = L.latLng(this.props.kp.a);
        this.polyline.setLatLngs(points);
      }
    } else {
      if (this.polyline.editEnabled()) this.polyline.disableEdit();
      this.polyline.setLatLngs([]);
    }
    if (this.props.kp.loc) {
      if (
        points.length === 2 &&
        (this.props.kp.loc?.lat !== points[1].lat ||
          this.props.kp.loc?.lng !== points[1].lng)
      ) {
        points[1] = L.latLng(this.props.kp.loc);
        this.polyline.setLatLngs(points);
      }
    } else {
      if (this.polyline.editEnabled()) this.polyline.disableEdit();
      this.polyline.setLatLngs([]);
    }
    if (points.length === 0 && this.props.kp.loc && this.props.kp.a) {
      const np: L.LatLngExpression[] = [];
      np.push(this.props.kp.a);
      np.push(this.props.kp.loc);
      this.polyline.setLatLngs(np);
    }
    this.updateArrow();
  }
  componentDidMount() {
    this.polyline.on("click", (ev) => {
      L.DomEvent.stopPropagation(ev);

      if (this.polyline.editEnabled()) {
        this.polyline.disableEdit();
      } else {
        this.polyline.enableEdit();
      }
    });
    this.props.map.addLayer(this.polyline);
    this.props.map.addLayer(this.arrowline);
    if (
      this.polyline.getLatLngs().length > 1 &&
      this.polyline.getBounds().isValid()
    ) {
      this.props.map.fitBounds(this.polyline.getBounds());
    }
    this.props.map.on("editable:vertex:drag", this.updateArrow);
    this.props.map.on("editable:vertex:dragend", this.editHandler);
    this.props.map.on("editable:vertex:new", this.createdHandler);
    this.updateArrow();
  }
  componentWillUnmount() {
    this.props.map.removeLayer(this.polyline);
    this.props.map.removeLayer(this.arrowline);
    this.props.map.off("editable:vertex:drag", this.updateArrow);

    this.props.map.off("editable:vertex:dragend", this.editHandler);
    this.props.map.off("editable:vertex:new", this.createdHandler);
  }
  render() {
    return null;
  }
}

const LineKP: React.FC<Omit<LineKPProps, "map">> = (props) => {
  const map = useMap();
  return <LineKPHandler {...props} map={map} />;
};

export default withNL(LineKP);
