import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { DialogNames, openDialog } from "rx/dialogsSlice";
import { setEventId } from "rx/eventIdSlice";
import { setEditKPId, setEditTeamId } from "rx/appStateSlice";
import withRouter, { RouteComponentProps } from "./withRouter";

const mapState = (state: RootState) => ({
  dialogs: state.dialog,
  editKPId: state.appState.editKPId,
  editTeamId: state.appState.editTeamId,
});
const mapDispatch = {
  openDialog: (dia: DialogNames) => openDialog(dia),
  setEventId: (evid: string) => setEventId(evid),
  setEditKPId: (kpid: string) => setEditKPId(kpid),
  setEditTeamId: (kpid: string) => setEditTeamId(kpid),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type RouteSynchronizerProps = PropsFromRedux & RouteComponentProps & {};
type RouteSynchronizerState = {};
class RouteSynchronizer extends React.Component<
  RouteSynchronizerProps,
  RouteSynchronizerState
> {
  state: RouteSynchronizerState = {};

  usedialog: { [route: string]: DialogNames } = {
    kpdata: "kpData",
    joincodes: "joinCodes",
    editteams: "teamsData",
    eventsettings: "eventSettings",
    start: "startOrder",
    news: "news",
    regadm: "registered",
  };
  componentDidMount() {
    const search = new URLSearchParams(this.props.router.location.search);
    if (this.props.router.params.evid)
      this.props.setEventId(this.props.router.params.evid);

    console.log("hmmm", search);
    search.forEach((value, key) => {
      console.log('key', key, 'value', value);
      if (key in this.usedialog) this.props.openDialog(this.usedialog[key]);
      if (key === "editkpid") this.props.setEditKPId(value);
      if (key === "editteamid") this.props.setEditTeamId(value);
    });
  }

  componentDidUpdate() {
    const { dialogs, editKPId, editTeamId } = this.props;
    let searchitems = [];
    Object.entries(this.usedialog).forEach(([route, dialog]) => {
      if (dialogs[dialog]) searchitems.push(route);
    });
    if (editKPId) searchitems.push("editkpid=" + editKPId);
    if (editTeamId) searchitems.push("editteamid=" + editTeamId);

    const search = searchitems.length > 0 ? "?" + searchitems.join("&") : "";

    //window.history.replaceState(null, "", location.pathname + search);
    if (this.props.router.location.search !== search)
      this.props.router.navigate(window.location.pathname + search, {
        replace: true,
      });
  }
  render() {
    return null;
  }
}

export default withRouter(connector(RouteSynchronizer));
