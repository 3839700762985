import React from "react";
import ValikEventResult from "./ValikEventResult";
import RaceResult from "./RaceResult";
import { useTSelector, RootState } from "rx/store";
import RoadBookResult from "./RoadBookResult";
import { Container } from "@mui/material";

type EventTypes = RootState['event']['type'];
type EventResultTypes = RootState['event']['resulttype'];

const SingleClassResult: React.FC<{klass?: string, type: EventTypes|EventResultTypes}> = ({klass, type}) => {
  if (type === "race")
    return <RaceResult klass={klass} />
  else if (type === "roadbook")
    return <Container maxWidth="xl" sx={{paddingBlock:2}}><RoadBookResult klass={klass}/></Container>
  else
    return <ValikEventResult klass={klass} />
}


const EventResult: React.FC = () => {
  const klassid = useTSelector(state => state.event.klassid);
  const eventtype = useTSelector(state => state.event.resulttype || state.event.type);
  const eventId = useTSelector(state => state.eventId);
  if (!eventId || !eventtype) {
    return null;
  }
  if (!klassid) {
    return <SingleClassResult type={eventtype} />;
  }
  return (
    <>
    {Object.entries(klassid).map(([tahis, name]) => (
          <div key={tahis}>
            {Object.keys(klassid).length > 1 && (
              <h1>{typeof name === "string" ? name : tahis}</h1>
            )}
            <SingleClassResult type={eventtype} klass={tahis} />
          </div>
        ))}
    </>
  )
}

export default EventResult;