import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { Box } from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import BaseTable, {
  Column,
  ColumnShape,
  AutoResizer,
  SortOrder,
} from "react-base-table";
import { secToHHMMSS } from "utils/SmallUtils";

const mapState = (state: RootState) => ({
  teamsList: state.teamsList,
  kpList: state.kpList,
  teamAnswers: state.teamAnswers,
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type RaceResultProps = PropsFromRedux &
  WithTranslation & {
    klass?: string;
  };
type RaceResultState = {
  sortBy: {
    key: string | number;
    order: SortOrder;
  };
};
class RaceResult extends React.Component<RaceResultProps, RaceResultState> {
  state: RaceResultState = {
    sortBy: {
      key: "finish",
      order: "asc",
    },
  };

  render() {
    const { klass, teamsList, kpList, teamAnswers } = this.props;
    let columns: ColumnShape[] = [];
    columns.push({
      key: "team",
      title: "Team",
      dataKey: "teamname",
      frozen: Column.FrozenDirection.LEFT,
      width: 150,
    });
    Object.entries(kpList)
      .filter(([_, kp]) => kp.nr !== "F")
      .forEach(([kpid, kp]) => {
        columns.push({
          key: kpid,
          title: "KP " + kp.nr,
          sortable: true,
          dataKey: kpid,
          width: 100,
          align: "center",
          cellRenderer: ({ cellData }) => {
            if (cellData) return secToHHMMSS(cellData / 1000, true);
            return "";
          },
        });
      });
    columns.push({
      key: "finish",
      title: "Finish",
      dataKey: "finish",
      width: 100,
      align: "center",
      sortable: true,
      frozen: Column.FrozenDirection.RIGHT,
      cellRenderer: ({ cellData }) => {
        if (cellData) return secToHHMMSS(cellData / 1000);
        return "";
      },
    });

    let data: any[] = [];
    Object.entries(teamsList)
      .filter(([_, team]) => {
        if (klass) {
          return team.klassid[klass];
        } else {
          return true;
        }
      })
      .forEach(([tid, team]) => {
        let entrie: { [key: string]: string | number | undefined } = {
          teamname: team.name,
        };
        Object.keys(kpList).forEach((kpid) => {
          if (team.starttime && teamAnswers[tid] && teamAnswers[tid][kpid]) {
            const aeg = teamAnswers[tid][kpid].aeg;
            if (aeg) {
              entrie[kpid] = aeg - team.starttime; //secToHHMMSS(spent / 1000, true);
            }
          }
        });
        if (!team.starttime) {
          entrie.finish = "no start";
        } else {
          if (team.finishtime) entrie.finish = team.finishtime - team.starttime;
        }
        data.push(entrie);
      });
    data.sort((a, b) => {
      let k = this.state.sortBy.key;
      if (typeof (k) === "bigint") k = Number(k);
      return a[k] - b[k];
    });
    return (
      <Box
        height="100%"
        maxWidth="lg"
        style={{ backgroundColor: "red", minHeight: "70vh", minWidth: "90vh" }}
      >
        <AutoResizer>
          {({ width, height }) => (
            <BaseTable
              fixed
              width={width}
              height={height}
              columns={columns}
              data={data}
              sortBy={this.state.sortBy}
              onColumnSort={({ key }) => {
                this.setState({ sortBy: { key: key, order: "asc" } });
              }}
            />
          )}
        </AutoResizer>
      </Box>
    );
  }
}

export default connector(withTranslation()(RaceResult));
