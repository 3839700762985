import React, { useCallback, useState } from "react";
import { EvListFromEvIds, useCupEventIds } from "./CupMgmt";
import { EventType } from "rx/fbListSlices";
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import store, { RootState, useTSelector } from "rx/store";
import { MoreVert, PlaylistAdd } from "@mui/icons-material";
//import { stringToColor } from "utils/SmallUtils";
import { calcOrder, makeGetResult } from "utils/ValikResultCalc";
import {
  allorderrules,
  defaultorderrules,
  OrderRuleKeys,
} from "utils/orderrules";
import { getDatabase, ref, get, set, runTransaction } from "firebase/database";
import CupResultTable, { downloadCup } from "./CupResultTable";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { SkeletonWrapper } from "utils/SmallUtils";
import { useTranslation } from "react-i18next";
import { AppStateDialog } from "utils/NextGenDialogFactory";
import { getAuth } from "firebase/auth";
import { enqueueSnackbar } from "notistack";

export type CupClassResultType = {
  [evid: string]: {
    [teamid: string]: number;
  };
};
type CupResultType = {
  [klass: string]: CupClassResultType;
};
// CupTeams has also userdata and devs
export type CupTeamsType = {
  [tid: string]: {
    name?: string;
    allnames?: string[];
    userdata: {
      displayName: string;
      email: string;
      phoneNumber: string;
    };
  };
};
export type CupDataType = {
  result?: CupResultType;
  teams?: CupTeamsType;
};
export type EventsData = { [k: string]: EventType };

export const CupResultDialog: React.FC = () => {
  //console.log("Render CupResultDial");
  const [joinEnabled, setJoinEnabled] = useState(false);
  const [cupResultCb, setCupResultCb] = useState<Function>();
  return (
    <AppStateDialog
      appkey="resultCupId"
      maxWidth="xl"
      fullWidth
      titleContents={(v: string) =>
        CupResultTitle({
          cupid: v,
          joinEnabled: joinEnabled,
          setJoinEnabled: setJoinEnabled,
          cupResultCb: cupResultCb,
        })
      }
    >
      {(v) => (
        <CupResult
          cupId={v}
          joinEnabled={joinEnabled}
          setCupResultCb={setCupResultCb}
        />
      )}
    </AppStateDialog>
  );
};

const addEventResultToCup = (evid: string, cupId: string) => {
  const getResult = makeGetResult() as any;
  const state: RootState = store.getState();
  let results: any = {};
  Object.keys(state.teamsList).forEach((tid) => {
    if (!state.teamsList[tid].disabled)
      results[tid] = getResult(state, { tid: tid, havekiirustrahvid: true });
  });
  const klassid = state.event.klassid
    ? Object.keys(state.event.klassid)
    : [null];
  const klasspromises: Promise<any>[] = [];
  klassid.forEach((kl) => {
    const [orderedresult] = calcOrder(
      Object.entries(state.teamsList)
        .filter(([_, team]) => !team.disabled)
        .filter(([_, team]) => {
          return kl === null ? true : team.klassid && team.klassid[kl];
        })
        .map(([tid, team]) => Object.assign({}, team, { id: tid })),
      results,
      (
        (state.event.orderrules?.split(",") as OrderRuleKeys[]) ||
        defaultorderrules
      ).map((r) => allorderrules[r])
    );
    klasspromises.push(
      get(ref(getDatabase(), "/cups/list/" + cupId)).then((s) => {
        const growingpoints = Boolean(s.child("growingpoints").val());
        const atleast1 = Boolean(s.child("atleast1").val());
        let points = s.child("points").val() as number[];
        if (!growingpoints) {
          if (points === null) {
            throw new Error("Points values are unconfigured");
          }
          if (!Array.isArray(points)) {
            points = Object.values(points).map((v) => parseInt(v as string));
          }
        }

        console.log("Write down", orderedresult, points, state.teamsList);
        let resultvalue: { [tid: string]: number } = {};

        let pvalue = 1;
        for (const rtid of orderedresult) {
          const p = growingpoints ? pvalue++ : points.shift();
          const team = state.teamsList[rtid];
          resultvalue[rtid] =
            p || (atleast1 && !team.dsq && !team.katk ? 1 : 0);
        }
        set(
          ref(
            getDatabase(),
            `/cups/data/${cupId}/result/${kl || "klass"}/${evid}`
          ),
          resultvalue
        );
        runTransaction(
          ref(getDatabase(), "/cups/data/" + cupId + "/teams"),
          (currdata) => {
            if (currdata === null) currdata = {};
            for (const rtid of orderedresult) {
              if (currdata[rtid] === undefined) currdata[rtid] = {};
              if (currdata[rtid].name === undefined)
                currdata[rtid].name = state.teamsList[rtid].name;
              if (currdata[rtid].allnames === undefined)
                currdata[rtid].allnames = [];
              if (!currdata[rtid].allnames.includes(state.teamsList[rtid].name))
                currdata[rtid].allnames.push(state.teamsList[rtid].name);
              if (currdata[rtid].devs === undefined) currdata[rtid].devs = {};
              const devs = state.teamsData[rtid]?.devs;
              if (devs !== undefined) {
                Object.entries(devs).forEach(
                  ([devid, dev]) => (currdata[rtid].devs[devid] = dev)
                );
              }
              currdata[rtid].userdata = Object.assign(
                {},
                state.teamsData[rtid]
              );
              if (currdata[rtid].userdata.devs)
                currdata[rtid].userdata.devs = null;
            }
            return currdata;
          }
        );
      })
    );
  });
  return Promise.all(klasspromises);
};

const findCupDuplicates = (cupid: string) => {
  console.log("finding duplicates 7", cupid);
};

export const CupResultTitle: React.FC<{
  cupid: string;
  joinEnabled: boolean;
  setJoinEnabled: (v: boolean) => void;
  cupResultCb: Function | undefined;
}> = ({
  cupid,
  joinEnabled,
  setJoinEnabled,
  cupResultCb,
}): React.ReactElement | null => {
  const cupname = useRFirebaseDb<string>(`/cups/list/${cupid}/name`);
  const isAdmin = useRFirebaseDb<string | null>(
    `/cups/list/${cupid}/admins/${getAuth().currentUser?.uid || "nouid"}`
  );
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log("cupr result title render");
  return (
    <>
      {cupname}
      {isAdmin && (
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu onClose={handleClose} open={open} anchorEl={anchorEl}>
            <MenuItem
              onClick={() => {
                findCupDuplicates(cupid);
                handleClose();
              }}
            >
              {t("cup.action.findduplicates")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setJoinEnabled(!joinEnabled);
                handleClose();
              }}
            >
              {t("cup.action.join")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (cupResultCb) cupResultCb("toggleDisplayName");
                handleClose();
              }}
            >
              {t("cup.action.toggledisplayname")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (cupResultCb) cupResultCb("toggleEmail");
                handleClose();
              }}
            >
              {t("cup.action.toggleemail")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (cupResultCb) cupResultCb("downloadCSV");
                handleClose();
              }}
            >
              {t("cup.action.downloadcsv")}
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

const CupDuplicatesResolver: React.FC = () => {
  //const duplicates = useTSelector((state) => state.appState.cupDuplicates);
  return null;
};

const CupResult: React.FC<{
  cupId: string;
  joinEnabled: boolean;
  setCupResultCb: (cb: Function) => void;
}> = ({ cupId, joinEnabled, setCupResultCb }) => {
  const cupData = useRFirebaseDb<CupDataType>(`cups/data/${cupId}`);
  const [cupevids] = useCupEventIds(cupId);
  const curevid = useTSelector((state) => state.eventId);
  const [evdata, setEvData] = useState<EventsData>({});
  const [showEmail, setShowEmail] = useState(false);
  const [showDisplayName, setShowDisplayName] = useState(false);
  const { t } = useTranslation();
  const isAdmin = useRFirebaseDb<string | null>(
    `/cups/list/${cupId}/admins/${getAuth().currentUser?.uid || "nouid"}`
  );

  const cupResultCb = useCallback(
    (cmnd: string) => {
      if (cmnd === "toggleEmail") setShowEmail((s) => !s);
      else if (cmnd === "toggleDisplayName") setShowDisplayName((s) => !s);
      else if (cmnd === "downloadCSV") {
        downloadCup(cupId, evdata, "csv")
          .then((f) => {
            enqueueSnackbar(`Download of "${f}" started`, {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar(`${err}`, {
              variant: "error",
            });
          });
      } else console.log("Got command", cmnd);
    },
    [cupId, evdata]
  );
  setCupResultCb(() => cupResultCb);
  const evdataCallback = useCallback(
    (data: EventsData) => {
      setEvData(data);
    },
    [setEvData]
  );

  const evAddCallback = useCallback(() => {
    if (curevid) {
      addEventResultToCup(curevid, cupId).catch((err) => {
        console.log("Error while adding event", err);
        enqueueSnackbar(err.message, {
          variant: "error",
          persist: true,
        });
      });
    }
  }, [cupId, curevid]);
  const sortedcupids = Object.keys(evdata).sort(
    (l, r) => (evdata[l].starttime || 0) - (evdata[r].starttime || 0)
  );
  return (
    <>
      <Grid container>
        <Grid item>
          <List dense disablePadding>
            {sortedcupids.map((evid, idx) => (
              <ListItem
                key={evid}
                secondaryAction={
                  isAdmin && evid === curevid ? (
                    <Tooltip title={t("cup.tooltip.addeventtocup")}>
                      <IconButton onClick={evAddCallback} edge="end">
                        <PlaylistAdd />
                      </IconButton>
                    </Tooltip>
                  ) : null
                }
              >
                <ListItemText
                  primary={idx + 1 + ". " + (evdata[evid].name || "")}
                />
              </ListItem>
            ))}
          </List>
          <CupDuplicatesResolver />
        </Grid>
      </Grid>
      <Divider />
      <EvListFromEvIds evids={cupevids} onData={evdataCallback} />
      <SkeletonWrapper condition={!Boolean(cupData?.result)}>
        <>
          {cupData?.result &&
            Object.entries(cupData.result).map(
              ([klassid, klassresult], _idx, arr) => (
                <CupResultTable
                  enablejoin={joinEnabled}
                  key={klassid}
                  klassresultref={ref(
                    getDatabase(),
                    `/cups/data/${cupId}/result/${klassid}`
                  )}
                  evids={sortedcupids}
                  cupid={cupId}
                  result={klassresult}
                  teams={cupData.teams || {}}
                  title={arr.length > 1 ? klassid : undefined}
                  showEmail={showEmail}
                  showDisplayName={showDisplayName}
                />
              )
            )}
        </>
      </SkeletonWrapper>
    </>
  );
};

export default CupResult;
