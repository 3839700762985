import { TrackPoint } from "rx/tracksSlice";
import { htmlEntities } from "./SmallUtils";

export function trackToGPX(
  tracks: { [devid: string]: TrackPoint[] },
  tid: string,
  teamname: string
) {
  var gpxdoc = document.implementation.createDocument(null, "gpx");
  var gpx = gpxdoc.children[0];
  gpx.setAttribute("version", "1.1");
  gpx.setAttribute("creator", "Nutilogi Web");
  var gpxmetadata = gpxdoc.createElement("metadata");
  var element = gpxdoc.createElement("name");
  element.innerHTML = htmlEntities(teamname);
  gpxmetadata.appendChild(element);
  gpx.appendChild(gpxmetadata);
  for (let devid of Object.keys(tracks)) {
    const trk = gpxdoc.createElement("trk");
    const trkname = gpxdoc.createElement("name");
    trkname.innerHTML = tid;
    trk.appendChild(trkname);
    const trksrc = gpxdoc.createElement("src");
    trksrc.innerHTML = devid;
    trk.appendChild(trksrc);
    const trkseg = gpxdoc.createElement("trkseg");
    for (let p of tracks[devid]) {
      const trkpt = gpxdoc.createElement("trkpt");
      trkpt.setAttribute("lat", p.lat.toString());
      trkpt.setAttribute("lon", p.lng.toString());
      const time = gpxdoc.createElement("time");
      time.innerHTML = new Date(p.t).toISOString();
      const magvar = gpxdoc.createElement("magvar");
      magvar.innerHTML = p.g.toString();
      const hdop = gpxdoc.createElement("hdop");
      hdop.innerHTML = p.a.toString();
      /*
      const ele = gpxdoc.createElement("ele");
      ele.innerHTML = p.l.toString();
      */
      const extensions = gpxdoc.createElement("extensions");
      const speed = gpxdoc.createElement("speed");
      speed.innerHTML = p.s.toString();
      extensions.appendChild(speed);
      trkpt.appendChild(time);
      trkpt.appendChild(magvar);
      trkpt.appendChild(hdop);
      trkpt.appendChild(extensions);
      trkseg.appendChild(trkpt);
    }
    trk.appendChild(trkseg);
    gpx.appendChild(trk);
    var serializer = new XMLSerializer();
    return serializer.serializeToString(gpx);
  }
}
