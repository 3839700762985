import React, { useState } from "react";

import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { TextFieldProps as MuiTextFieldPropsType } from "@mui/material/TextField";
import { DatabaseReference } from "firebase/database";

const FBKeyboardTimePicker: React.FC<
  Omit<TimePickerProps<Dayjs>, "value" | "onChange"> & {
    path: string|DatabaseReference;
    useTimeWithDate?: boolean;
    tkey?: string;
    forcedate?: Date;
    id?: string;
    inputProps?: MuiTextFieldPropsType;
    disableTooltip?: boolean;
  }
> = (props) => {
  const { useTimeWithDate, ...restprops } = props;
  const [fbvalue, setFbValue] = useFirebaseDb(props.path);
  const { t } = useTranslation();
  const [state, setState] = useState({
    value: undefined as number | null | undefined,
    fbchanges: 0,
    timer: null as NodeJS.Timeout | null,
  });
  if (
    fbvalue.value !== undefined &&
    state.fbchanges !== fbvalue.changecount &&
    state.timer === null
  ) {
    setState(
      Object.assign({}, state, {
        value: fbvalue.value,
        fbchanges: fbvalue.changecount,
      })
    );
  }

  /*
  let dv = dayjs();
  if (state.value) {
    dv = dv.set("hour", Number(state.value) / 3600000);
    dv = dv.set("minute", Number(state.value) / 60000);
    dv = dv.set("second", (Number(state.value) / 1000) % 60);
  }
  */

  const tooltipkey =
    !props.disableTooltip && props.tkey ? `fbf.${props.tkey}.tooltip` : null;
  return (
    <NLTooltipWrapper tkey={tooltipkey as any}>
      <SkeletonWrapper condition={state.value === undefined}>
        <TimePicker
          value={state.value === null ? null : dayjs(state.value)}
          label={props.tkey ? t(`fbf.${props.tkey}.label` as any) : undefined}
          {...restprops}
          onChange={(date, context) => {
            let newState = Object.assign({}, state);

            if (context.validationError) {
              if (state.timer) {
                clearTimeout(state.timer);
                newState.timer = null;
              }
              setState(newState);
              return;
            }

            newState.value = date ? date.valueOf() : null;

            if (state.timer) {
              clearTimeout(state.timer);
            }
            newState.timer = setTimeout(() => {
              setState((instate) =>
                Object.assign({}, instate, { timer: null })
              );
              setFbValue(
                props.tkey || "dummy",
                t(`fbf.${props.tkey}.changed` as any),
                newState.value
              );
            }, 300);
            setState(newState);
          }}
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FBKeyboardTimePicker;
