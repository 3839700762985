import { Dialog } from "@mui/material";
import React from "react";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  PhoneAuthProvider,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui"; // Cannot be convertd to default import
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { useTSelector } from "rx/store";
import { useDispatch } from "react-redux";
import { closeDialog } from "rx/dialogsSlice";
import { SlideUpTransition } from "utils/DialogUtils";

const SingInContent: React.FC = () => {
  const dispatch = useDispatch();

  return <StyledFirebaseAuth
    uiConfig={{
      signInFlow: "popup",
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
        PhoneAuthProvider.PROVIDER_ID,
        "apple.com",
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          dispatch(closeDialog("signin"));
          return false;
        },
      },
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    }}
    firebaseAuth={firebase.auth()}
  />
}


const SignInDialog: React.FC = () => {
  const isOpen = useTSelector((state) => state.dialog.signin);
  const dispatch = useDispatch();

  return <Dialog TransitionComponent={SlideUpTransition}
    open={isOpen} onClose={() => dispatch(closeDialog("signin"))}>
    <SingInContent />
  </Dialog>
};

export default SignInDialog;
