import {
  DatabaseReference,
  getDatabase,
  onValue,
  Query,
  ref,
  Unsubscribe,
} from "firebase/database";
import {
  doc,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNL, withNL, WithNLProps } from "utils/NLContext";

export type useFirestoreState = {
  doc: DocumentSnapshot | undefined;
  changecount: number;
};
export const useFirestore = (path: string): [useFirestoreState] => {
  const [state, setState] = useState({
    doc: undefined,
    changecount: 0,
  } as useFirestoreState);
  useEffect(() => {
    let docref = doc(getFirestore(), path);
    return onSnapshot(docref, {
      next: (docsnap) => {
        setState((oldstate) =>
          Object.assign({}, oldstate, {
            doc: docsnap,
            changecount: oldstate.changecount + 1,
          })
        );
      },
      error: (error) => {
        console.log("error from firestore", error);
      },
    });
  }, [path]);

  return [state];
};

export type useFirebaseDbState = {
  value: any;
  changecount: number;
};

// Maybe add here runtime type checks:
// https://learning-notes.mistermicheels.com/javascript/typescript/runtime-type-checking/#:~:text=TypeScript%20only%20performs%20static%20type,know%20anything%20about%20the%20types.
export function useRFirebaseDb<T>(path: string | Query) {
  const [state, setState] = useState<T | null>();
  useEffect(() => {
    // console.log(path);
    let pathref = typeof path === "string" ? ref(getDatabase(), path) : path;
    const unsub = onValue(pathref, (snap) => {
      setState(snap.val());
    });
    return unsub;
  }, [path]);
  return state;
}

export const useFirebaseDb = (
  path: string | DatabaseReference
): [useFirebaseDbState, (id: string, msg: string, v: any) => void] => {
  const { addUndo, fbSet } = useNL();
  const [state, setState] = useState({
    value: undefined,
    changecount: 0,
  } as useFirebaseDbState);
  useEffect(() => {
    let pathref = typeof path === "string" ? ref(getDatabase(), path) : path;
    const unsub = onValue(pathref, (snap) => {
      setState((state) =>
        Object.assign({}, state, {
          value: snap.val(),
          changecount: state.changecount + 1,
        })
      );
    });
    return unsub;
  }, [path]);
  const storeToDb = (id: string, msg: string, setval: any) => {
    if (!path || (typeof path === "string" && path.length === 0)) {
      console.log("path should not be empty");
      return;
    }
    fbSet(path, setval);
    addUndo(id + new Date().toISOString(), msg, () => {
      fbSet(path, state.value);
    });
  };
  return [state, storeToDb];
};

// Deprected - use useRFirebaseDb
export const FirebaseDbHook = (path: string) => {
  const [val, setValue] = useState(undefined);
  useEffect(() => {
    let pathref = ref(getDatabase(), path);
    return onValue(pathref, (snap) => {
      console.log("in callback");
      setValue(snap.val());
    });
  });
  return val;
};

export const FirebaseDbHelper = (path: string, comp: React.Component) => {
  let pathref = ref(getDatabase(), path);
  return onValue(pathref, (snap) => {
    console.log("got value");
    comp.setState({ fbdata: snap.val() });
  });
};

type FirebaseDbWrapperProps = {
  path: string;
  children: React.ReactNode;
} & WithNLProps;
type FirebaseDbWrapperState = {
  data: object | null;
};
class FirebaseDbWrapper extends React.Component<
  FirebaseDbWrapperProps,
  FirebaseDbWrapperState
> {
  state: FirebaseDbWrapperState = {
    data: null,
  };

  fbref: DatabaseReference;
  unsub?: Unsubscribe;
  constructor(props: FirebaseDbWrapperProps) {
    super(props);
    this.fbref = ref(getDatabase(), this.props.path);
  }

  componentDidMount() {
    this.startFirebaseListen();
  }

  startFirebaseListen = () => {
    this.unsub && this.unsub();
    this.unsub = onValue(this.fbref, (snap) => {
      this.setState({ data: snap.val() });
    });
  };

  componentDidUpdate(prevprops: Readonly<FirebaseDbWrapperProps>) {
    if (this.props.path !== prevprops.path) {
      this.fbref = ref(getDatabase(), this.props.path);
      this.startFirebaseListen();
    }
  }

  componentWillUnmount() {
    this.unsub && this.unsub();
  }

  setFbValue = (v: any) => {
    this.props.fbSet(this.fbref, v);
    console.log("save into fb", v);
  };

  render() {
    /*
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            value: this.state.data,
          });
        }
        return child;
      }
    );
    */

    return (
      <div>
        {(this.props.children as any)(this.state.data, this.setFbValue)}
      </div>
    );
  }
}

export default withNL(FirebaseDbWrapper);
