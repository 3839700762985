import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";

const mapState = (state: RootState) => ({});
const mapDispatch = {
  closeDialog: () => closeDialog("accessCodes"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type AccessCodesDialogProps = PropsFromRedux & WithTranslation & {};
type AccessCodesDialogState = {};
class AccessCodesDialog extends React.Component<
  AccessCodesDialogProps,
  AccessCodesDialogState
> {
  state: AccessCodesDialogState = {};
  render() {
    const { t } = this.props;
    return (
      <Dialog open={true}>
        <DialogTitle>{t("accesscode.title")}</DialogTitle>
        <DialogActions>
          <Button onClick={this.props.closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(AccessCodesDialog));
