import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { withTranslation, WithTranslation } from "react-i18next";
import dayjs from "dayjs";
import { setEditTeamKP } from "rx/appStateSlice";

const mapState = (state: RootState, props: SingleKPStatisticsOwnProps) => ({
  teamAnswers: state.teamAnswers,
  teamsList: state.teamsList,
  kpEntry: state.kpList[props.kpId],
  kpData: state.kpData[props.kpId] || {},
  correctAnswer: state.kpAnswers[props.kpId],
});
const mapDispatch = {
  setEditTeamKP: (kpid: string, tid: string) => setEditTeamKP(kpid, tid),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SingleKPStatisticsOwnProps = {
  eventId: string;
  kpId: string;
};
type SingleKPStatisticsProps = PropsFromRedux &
  WithTranslation &
  SingleKPStatisticsOwnProps;
type SingleKPStatisticsState = {};
class SingleKPStatistics extends React.Component<
  SingleKPStatisticsProps,
  SingleKPStatisticsState
> {
  state: SingleKPStatisticsState = {};
  render() {
    const { t, teamAnswers, teamsList, kpId, kpData, kpEntry } = this.props;
    const teams = Object.keys(teamsList)
      .filter((tid) => tid in teamAnswers && teamAnswers[tid][kpId])
      .sort(
        (a, b) =>
          (teamAnswers[a][kpId].aeg || 0) - (teamAnswers[b][kpId].aeg || 0)
      );
    const days = teams
      .map((tid) => dayjs(teamAnswers[tid][kpId].aeg).date())
      .sort();

    const timeformat =
      days.length && days[0] === days[days.length - 1]
        ? "HH:mm:ss"
        : "DD.MM.YYYY HH:mm:ss";
    return (
      <TableContainer
        sx={{
          height: 400,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("kpstat.table.team")}</TableCell>
              <TableCell>{t("kpstat.table.time")}</TableCell>
              <TableCell>{t("kpstat.table.answer")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((tid) => (
              <TableRow key={tid}>
                <TableCell>{teamsList[tid].name}</TableCell>
                <TableCell>
                  {teamAnswers[tid][kpId].aeg
                    ? dayjs(teamAnswers[tid][kpId].aeg).format(timeformat)
                    : "--:--:--"}
                </TableCell>
                <TableCell onClick={() => this.props.setEditTeamKP(kpId, tid)}>
                  <Alert
                    severity={
                      teamAnswers[tid][kpId].answer === this.props.correctAnswer
                        ? "success"
                        : "error"
                    }
                    icon={false}
                  >
                    {kpData.a || kpEntry.allok
                      ? "OK"
                      : kpData.responses
                      ? kpData.responses[teamAnswers[tid][kpId].answer || ""]
                      : teamAnswers[tid][kpId].answer}
                  </Alert>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default connector(withTranslation()(SingleKPStatistics));
