import "./App.css";
import {
  BrowserRouter as Router,
  useParams,
  useRoutes,
} from "react-router-dom";
import NutilogiApp from "NutilogiApp";
import { useDispatch } from "react-redux";
import { setEventId } from "rx/eventIdSlice";
import { useEffect } from "react";
import ResultOnly from "ResultOnly";
import DialogFactory from "utils/DialogFactory";
import CupOnly from "CupOnly";
import RegToEventPage from "components/RegToEvent";
import AccountDelete from "components/AccountDelete";

declare global {
  var usingemudb: boolean;
}

const SaveEvIdToStore: React.FC = () => {
  const { evid } = useParams<{ evid: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setEventId(evid));
  });
  return null;
};

const OpenResult = () => {
  return (
    <>
      <SaveEvIdToStore />
      <ResultOnly />
    </>
  );
};

const RoutedApp = () =>
  useRoutes([
    { path: "/", element: <NutilogiApp /> },
    { path: "/accountdelete", element: <AccountDelete /> },
    { path: "/cup/:cupid", element: <CupOnly /> },
    { path: "/:evid/result", element: <OpenResult /> },
    { path: "/:evid/tulemus", element: <OpenResult /> },
    { path: "/:evid/reg", element: <RegToEventPage /> },
    { path: "/:evid/:sub1", element: <NutilogiApp /> },
    { path: "/:evid", element: <NutilogiApp /> },
  ]);

function App() {
  return (
    <Router>
      <RoutedApp />
      <DialogFactory />
    </Router>
  );
}

export default App;
