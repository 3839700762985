import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default class SimpleTextInput extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      inputvalue: props.value || "",
    };
  }

  handleChange = (e) => {
    this.setState({ inputvalue: e.target.value });
  };
  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.handleClose(null)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.desc}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="input"
              type={this.props.type}
              value={this.state.inputvalue}
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose(null)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.props.handleClose(this.state.inputvalue)}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
