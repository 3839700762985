import { Edit } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import FilledInput from "@mui/material/FilledInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { DatabaseReference } from "firebase/database";
import { useState } from "react";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";

const FBManualTextField: React.FC<{
  path: string | DatabaseReference;
  undoMsg?: string;
}> = ({ path, undoMsg }) => {
  const value = useRFirebaseDb<String>(path);
  const [editenabld, setEditEnabled] = useState(false);
  const [editvalue, setEditValue] = useState(value);
  const { fbSet } = useNL();
  return editenabld ? (
    <FilledInput
      value={editvalue}
      title={"Teeam name"}
      onChange={(event) => setEditValue(event.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              fbSet(path, editvalue, undoMsg);
              setEditEnabled(false);
            }}
          >
            <Icon color="success">
              <Check />
            </Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              setEditEnabled(false);
            }}
          >
            <Icon color="error">
              <Close />
            </Icon>
          </IconButton>
        </InputAdornment>
      }
    />
  ) : (
    <span>
      {value}
      <IconButton
        onClick={() => {
          setEditValue(value);
          setEditEnabled(true);
        }}
      >
        <Icon>
          <Edit />
        </Icon>
      </IconButton>
    </span>
  );
};

export default FBManualTextField;
