import React from "react";
import QrCode from "qrcode.react";
import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";

/* Fun ideas for styling
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
*/

const QrCodeDialog: React.FC<{ message: string; onClose: () => void }> = ({
  message,
  onClose,
}) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{message}</DialogTitle>
      <DialogContent sx={{ padding: 4 }}>
        <Box display="flex" justifyContent="center">
          <QrCode size={256} level="L" renderAs="canvas" value={message} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QrCodeDialog;
