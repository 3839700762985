import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DialogsSlice {
  eventSettings: boolean;
  kpStats: boolean;
  kpData: boolean;
  teamsData: boolean;
  teamsAllow: boolean;
  issueSolver: boolean;
  createEvent: boolean;
  configureEvent: boolean;
  accessRights: boolean;
  startOrder: boolean;
  eventsManager: boolean;
  eventsManagerold: boolean;
  debuggerTools: boolean;
  restoreEvent: boolean;
  accessCodes: boolean;
  sendMsg: boolean | string; // can be teamid
  cupMgmt: boolean;
  cupResult: boolean;
  downloads: boolean;
  versiondist: boolean;
  joinCodes: boolean;
  translatorTool: boolean;
  accounting: boolean;
  news: boolean;
  signin: boolean;
  registered: boolean;
  wheelofnames: boolean;
  deletedkponteam: boolean;
}

const initialState: DialogsSlice = {
  eventSettings: false,
  kpStats: false,
  kpData: false,
  teamsData: false,
  teamsAllow: false,
  issueSolver: false,
  createEvent: false,
  configureEvent: false,
  accessRights: false,
  startOrder: false,
  eventsManager: false,
  eventsManagerold: false,
  debuggerTools: false,
  restoreEvent: false,
  accessCodes: false,
  sendMsg: false,
  cupMgmt: false,
  cupResult: false,
  downloads: false,
  versiondist: false,
  joinCodes: false,
  translatorTool: false,
  accounting: false,
  news: false,
  signin: false,
  registered: false,
  wheelofnames: false,
  deletedkponteam: false,
};

export const dialogsSclie = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<keyof DialogsSlice>) => {
      state[action.payload] = true;
    },
    openDialogWithParam: (
      state,
      action: PayloadAction<{
        dialog: keyof DialogsSlice;
        val: DialogsSlice[keyof DialogsSlice];
      }>
    ) => {
      // Would be nice if this can be made more generic and dont have to specify sendMsg directly here.
      if (action.payload.dialog === "sendMsg")
        state[action.payload.dialog] = action.payload.val;
    },
    closeDialog: (state, action: PayloadAction<keyof DialogsSlice>) => {
      state[action.payload] = false;
    },
    openEventSettings: (state, _action) => {
      state.eventSettings = true;
    },
    closeEventSettings: (state, _action) => {
      state.eventSettings = false;
    },
  },
});

export const {
  openDialog,
  openDialogWithParam,
  closeDialog,
  openEventSettings,
  closeEventSettings,
} = dialogsSclie.actions;
export type DialogNames = keyof DialogsSlice;
export type NextGenDialogNames = keyof Pick<
  DialogsSlice,
  | "eventsManager"
  | "cupMgmt"
  | "accessRights"
  | "versiondist"
  | "startOrder"
  | "downloads"
  | "debuggerTools"
  | "joinCodes"
  | "translatorTool"
  | "news"
  | "createEvent"
  | "sendMsg"
  | "accounting"
  | "registered"
  | "wheelofnames"
  | "deletedkponteam"
  | "kpData"
>;

export default dialogsSclie.reducer;
