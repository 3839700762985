import React, { useCallback } from "react";
import { RootState, useTSelector } from "rx/store";
import { useDispatch } from "react-redux";
import AccountCircle from "@mui/icons-material/AccountCircleTwoTone";
import MoreIcon from "@mui/icons-material/MoreVertTwoTone";
import MailIcon from "@mui/icons-material/MailTwoTone";
import NotificationsIcon from "@mui/icons-material/NotificationsTwoTone";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportProblem, Translate } from "@mui/icons-material";
import { argouid, download } from "utils/SmallUtils";
import { openDialog } from "rx/dialogsSlice";
import { getAuth, User } from "firebase/auth";

const UserAvatar: React.FC<{ user: User }> = ({ user }) => {
  const alttext = user.displayName || user.email || "";
  let photoURL: string | undefined = undefined;
  for (let entry of user.providerData) {
    if (!entry?.photoURL) continue;
    photoURL = entry?.photoURL;
  }
  if (!photoURL && user.photoURL) photoURL = user.photoURL;
  return (
    <Avatar alt={alttext} src={photoURL}>
      {!photoURL &&
        (alttext.length === 0 && user.phoneNumber ? <PhoneIcon /> : alttext[0])}
    </Avatar>
  );
};

type AppStateKeys = keyof RootState["appState"];
// Could use - typeof state.appState
const AppBarButton: React.FC<{
  field: AppStateKeys;
  icon: React.ReactElement;
  size?: "small" | "large";
  wrapper?: (children: React.ReactElement) => React.ReactElement;
  onClick?: () => void;
}> = React.memo(({ field, icon, onClick, size, wrapper }) => {
  const nvalue = useTSelector((state) => state.appState[field]);
  const count = Array.isArray(nvalue) ? nvalue.length : nvalue;
  if (typeof count !== "number") return null;
  if (count > 0) {
    const element = (
      <IconButton
        aria-label="show issues"
        color="inherit"
        onClick={onClick}
        size={size || "large"}
      >
        <Badge badgeContent={count} color="secondary">
          {icon}
        </Badge>
      </IconButton>
    );
    if (wrapper) {
      return wrapper(element);
    } else {
      return element;
    }
  }
  return null;
});

const AccountButton: React.FC<{
  user: User | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: "small" | "large";
}> = ({ user, onClick, size }) => (
  <IconButton
    aria-label="user account"
    aria-controls={user ? "menu-desktop" : undefined}
    aria-haspopup={Boolean(user)}
    color="inherit"
    onClick={onClick}
    size={size || "large"}
  >
    {user ? <UserAvatar user={user} /> : <AccountCircle />}
  </IconButton>
);

// TODO should be appbarbuttons
export default function AppBarButtons() {
  const mobileMenuId = "primary-search-account-menu-mobile";
  const menuId = "primary-search-account-menu";
  const user = useTSelector((state) => state.user.user);
  const { i18n, t } = useTranslation();
  const disaptch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSignOut = () => {
    getAuth().signOut();
    handleMenuClose();
  };

  const accountClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!user) {
        disaptch(openDialog("signin"));
      } else {
        handleProfileMenuOpen(event);
      }
    },
    [user, disaptch]
  );

  const issuesClick = () => {
    disaptch(openDialog("issueSolver"));
  };

  type AppBarTasks = {
    field: AppStateKeys;
    icon: React.ReactElement;
    text: string;
    onClick?: () => void;
  };
  const buttons: AppBarTasks[] = [
    {
      field: "issues",
      text: t("Issues"),
      icon: <ReportProblem />,
      onClick: issuesClick,
    },
    { field: "mailCount", text: t("Messages"), icon: <MailIcon /> },
    {
      field: "notificationCount",
      text: t("Notifications"),
      icon: <NotificationsIcon />,
    },
  ];

  const renderMenu = (
    <Menu
      sx={{ zIndex: (theme) => theme.zIndex.modal + 10 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSignOut}>{t("signout")}</MenuItem>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuList dense>
        {buttons.map((b) => (
          <AppBarButton
            key={b.field}
            field={b.field}
            icon={b.icon}
            wrapper={(element) => (
              <MenuItem
                onClick={() => {
                  if (b.onClick) {
                    b.onClick();
                    handleMobileMenuClose();
                  }
                }}
              >
                <ListItemIcon>{element}</ListItemIcon>
                <ListItemText>{b.text}</ListItemText>
              </MenuItem>
            )}
          />
        ))}
        <MenuItem onClick={accountClicked} dense>
          <AccountButton user={user} size="small" />
          <p>{user ? t("user") : t("login")}</p>
        </MenuItem>
      </MenuList>
    </Menu>
  );
  // TODO move to separate component.
  const triggerTranslationDownload = useCallback(() => {
    const data = i18n.store.data;
    const travelTranslation = (data: any, prefix: string) => {
      Object.entries(data).forEach(([key, entry]) => {
        if (typeof entry === "string") {
          if (entry === prefix + "." + key.replace(/_plural$/, "")) {
            delete data[key];
          }
        } else {
          travelTranslation(
            data[key],
            prefix.length ? prefix + "." + key : key
          );
          if (Object.keys(data[key]).length === 0) delete data[key];
        }
      });
    };

    // Delete untranslated entries
    Object.entries(data).forEach(([lng, nslist]) => {
      Object.entries(nslist).forEach(([ns, nsdata]) => {
        travelTranslation(nsdata, "");
        if (Object.keys(nslist[ns]).length === 0) delete nslist[ns];
      });
      if (Object.keys(data[lng]).length === 0) delete data[lng];
    });
    download(
      JSON.stringify(data, null, 2),
      "translation.json",
      "application/json"
    );
  }, [i18n]);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {user?.uid === argouid && (
          <IconButton
            aria-label="Language"
            color="inherit"
            onClick={triggerTranslationDownload}
            size="large"
          >
            <Translate />
          </IconButton>
        )}
        {buttons.map((b) => (
          <AppBarButton
            key={b.field}
            field={b.field}
            icon={b.icon}
            onClick={b.onClick}
          />
        ))}
        <AccountButton user={user} onClick={accountClicked} />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </Box>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}
