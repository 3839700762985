import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { ReactElement } from "react";
import { KP } from "rx/fbListSlices";

export const KPPaths: {
  [ra: number]: { double: string[]; single: string[] };
} = {
  "1": {
    double: [
      "M12 0c-6.63 0 -12 5.37 -12 12s5.37 12 12 12 12 -5.37 12 -12 -5.37 -12 -12 -12zm0 22c-5.52 0-10-4.48-10-10s4.48-10 10-10 10 4.48 10 10-4.48 10-10 10z",
      "M12 4c-4.42 0 -8 3.58 -8 8s3.58 8 8 8 8 -3.58 8 -8 -3.58 -8 -8 -8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z",
    ],
    single: [
      "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    ],
  },
  "2": {
    double: [
      "M12 4L18.925 15.998h-13.85L12 4zM12 0L1.61 17.998h20.78L12 0z",
      "M12 6.23L17 14.89h-10L12 6.23zM12 9.98L10.25 13.01h3.5L12 9.98z",
    ],
    single: ["M12 4L18.925 15.998h-13.85L12 4zM12 0L1.61 17.998h20.78L12 0z"],
  },
  "3": {
    double: [
      "M20 2H4c-1.1 0-2 0.9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-2 18h-12c-1.1 0-2-.9-2-2v-12c0 -1.1 0.9 -2 2-2h12c1.1 0 2 0.9 2 2v12c0 1.1  -0.9 2-2 2",
      "M16 6H8c-1.1 0-2 0.9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10H8V8h8v8z",
    ],
    single: [
      "M18 4H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H6V6h12v12z",
    ],
  },
};

const KPIcon: React.FC<
  {
    ra?: number;
    kp?: KP;
  } & SvgIconProps
> = ({ color, fontSize, ra, kp }) => {
  const forwardprops = {
    color: color,
    fontSize: fontSize,
  };
  let usera = ra ? ra : kp && kp.ra ? kp.ra : 1;
  const koh = kp && kp.kohustuslik;
  let svgelements: ReactElement[] = [];
  if (usera > 3 || usera < 1) usera = 1;
  let keyv = 1;
  KPPaths[usera][koh ? "double" : "single"].forEach((d) => {
    svgelements.push(<path key={keyv++} d={d} />);
  });

  return (
    <SvgIcon viewBox="0, 0, 24, 24" {...forwardprops}>
      {svgelements}
    </SvgIcon>
  );
};

export default KPIcon;
