import { Skeleton } from "@mui/material";
import { green } from "@mui/material/colors";
import NLTooltip from "components/NLTooltip";
import dayjs from "dayjs";
import { TFunction, TypeOptions } from "i18next";
import { LatLngLiteral } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";

type DefaultNamespace = TypeOptions["defaultNS"];

export const Pastel8 = [
  "#B3E2CD",
  "#FDCDAC",
  "#CBD5E8",
  "#F4CAE4",
  "#E6F5C9",
  "#FFF2AE",
  "#F1E2CC",
  "#CCCCCC",
];
const Pastel8Material = [
  "#C8E6C9", // Green 100
  "#FFCCBC", // Deep Orange 100
  "#BBDEFB", // Blue 100
  "#F8BBD0", // Pink 100
  "#DCEDC8", // Light Green 100
  "#FFF9C4", // Yellow 100
  "#D7CCC8", // Brown 100
  "#BDBDBD", // Grey 400
];
export const Set12 = [
  "#8DD3C7",
  "#FFFFB3",
  "#BEBADA",
  "#FB8072",
  "#80B1D3",
  "#FDB462",
  "#B3DE69",
  "#FCCDE5",
  "#D9D9D9",
  "#BC80BD",
  "#CCEBC5",
  "#FFED6F",
];
const Set12Material = [
  "#80CBC4", // Teal 200
  "#FFF9C4", // Yellow 100
  "#D1C4E9", // Deep Purple 100
  "#EF9A9A", // Red 200
  "#4FC3F7", // Light Blue 300
  "#FFCC80", // Orange 200
  "#AED581", // Light Green 300
  "#F8BBD0", // Pink 100
  "#E0E0E0", // Grey 300
  "#CE93D8", // Purple 200
  "#C8E6C9", // Green 100
  "#FFFF8D", // Yellow A100
];

export function LatLngToString(
  value: LatLngLiteral | null | undefined,
  fixed?: number
): string {
  if (!value) return "";
  if (fixed) return value.lat.toFixed(fixed) + ", " + value.lng.toFixed(fixed);
  else return value.lat + ", " + value.lng;
}

export function htmlEntities(str: string) {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
}

export type ExportType = "gpx" | "json" | "csv";
export const ExportContentTypes: Record<ExportType, string> = {
  gpx: "applicaiton/gpx+xml",
  json: "applicaiton/json",
  csv: "text/csv;charset=utf-8",
};

export function StringToLatLng(
  value: string
): LatLngLiteral | undefined | null {
  if (value === "") return null;
  let parts = value.split(",");
  if (parts.length !== 2) {
    parts = value.split(" ");
  }
  if (parts.length === 2) {
    const lat = Number(parts[0]);
    const lng = Number(parts[1]);
    if (isNaN(lat) || isNaN(lng)) return undefined;
    return { lat: lat, lng: lng };
  }
  return undefined;
}

export const Wrapper: React.FC<{
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
}> = ({ children, condition, wrapper }) =>
    condition ? wrapper(children) : children;

export const SkeletonWrapper: React.FC<{
  children: React.ReactElement;
  condition: boolean;
}> = ({ children, condition }) =>
    condition ? <Skeleton>{children}</Skeleton> : children;

type tkeytype = Parameters<TFunction<DefaultNamespace, undefined>>[0];

export const NLTooltipWrapper: React.FC<{
  children: React.ReactElement;
  followCursor?: boolean;
  tkey?: tkeytype;
}> = ({ children, followCursor, tkey }) => {
  const { t } = useTranslation();
  return tkey ? (
    <NLTooltip followCursor={followCursor} message={t(tkey as any) as string}>
      {children}
    </NLTooltip>
  ) : (
    children
  );
};

export const useEventEnded = () => {
  const endtime = useTSelector((state) => state.event.endtime);
  const timetoend = (endtime || 0) - dayjs().valueOf();
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (timetoend < 0) return;
    const timerid = setTimeout(() => {
      setValue(value + 1);
    }, timetoend);
    return () => {
      clearTimeout(timerid);
    };
  }, [timetoend, value]);

  if (endtime && dayjs().isAfter(endtime)) return true;
  return false;
};

export function useUntypedTranslation() {
  const { t } = useTranslation();
  return { t: (v: string) => t(v as any) };
}
export function download(data: any, filename: string, type: string) {
  var file = new Blob([data], { type: type });
  if ((window.navigator as any).msSaveOrOpenBlob)
    // IE10+
    (window.navigator as any).msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    }, 0);
  }
}

export const secToHHMMSS = (seconds: number, nozerohour: boolean = false) =>
  `${((v: number) => {
    if (nozerohour && v === 0) return "";
    return v.toString().padStart(2, "0") + ":";
  })(Math.floor(seconds / 3600))}${(
    Math.floor((seconds % 3600) / 60) + ""
  ).padStart(2, "0")}:${(Math.floor((seconds % 3600) % 60) + "").padStart(
    2,
    "0"
  )}`;
export const HHMMSSToSec = (str: string) => {
  let a = str.split(":");
  if (a.length === 2) {
    a.push("0");
  }
  return Number(a[0]) * 60 * 60 + Number(a[1]) * 60 + Number(a[2]);
};

let playContext: AudioContext | null = null;

export function playBeep(
  frequency: number = 440,
  duration: number = 1,
  type: OscillatorType = "sine"
) {
  if (!playContext) {
    window.AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    playContext = new window.AudioContext();
  }

  let currentTime = playContext.currentTime;
  let osc = playContext.createOscillator();
  let gain = playContext.createGain();

  osc.connect(gain);
  gain.connect(playContext.destination);

  let currentvalue = gain.gain.value;
  gain.gain.setValueAtTime(0.00001, currentTime);
  gain.gain.exponentialRampToValueAtTime(currentvalue, currentTime + 0.001);
  gain.gain.setValueAtTime(currentvalue, currentTime + duration - 0.01);
  gain.gain.exponentialRampToValueAtTime(0.00001, currentTime + duration);

  osc.onended = function () {
    gain.disconnect(playContext!.destination);
    osc.disconnect(gain);
  };

  osc.type = type;
  osc.frequency.value = frequency;
  osc.start(currentTime);
  osc.stop(currentTime + duration);
}

export function FBValueToArray<T>(v: any): [T[], boolean] {
  let resp: T[] = [];
  if (v !== undefined) {
    if (Array.isArray(v)) {
      resp = v;
    } else {
      Object.keys(v).forEach((k) => {
        const kn = Number(k);
        if (isNaN(kn)) {
          return [[], true];
        } else {
          resp[kn] = v[k];
        }
      });
    }
  }
  return [resp, false];
}

export class perfTimer {
  start: number = 0;
  constructor() {
    this.start = new Date().getTime();
  }
  elapsed() {
    return new Date().getTime() - this.start;
  }
}

export function fbkeytoms(key: string): number {
  let result = 0;

  const alphabet =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < 8; ++i) {
    const index = alphabet.indexOf(key.charAt(i));
    if (index === -1) {
      throw new Error(`Unexpected character '${key.charAt(i)}'.`);
    }
    result = result * 64 + index;
  }
  return result;
}

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export const formatDuration = (d: plugin.Duration) => {
  if (d.seconds() < 0) {
    return d.humanize(true);
  }
  return (
    (d.days() > 0 ? dayjs.duration(d.days(), "days").humanize() + " " : "") +
    d.format("HH:mm:ss")
  );
};

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(document.visibilityState);

  useEffect(() => {
    const handleVisibilityChange = () =>
      setIsTabVisible(document.visibilityState);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

// Helper function to investigate rerenderings caused by props change.
// add useTraceUpdate(props) to functional component
export const useTraceUpdate = (props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: { [k: string]: any }, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export const estoniaCoords = { center: { lat: 58.88, lng: 25.14 }, zoom: 7 };

export const argouid = "u31XLYs9Q0YjFmazxah3hlgholY2";

export const nutilogihostname = "nutilogi.web.app";
