import React from "react";
import { Tooltip } from "@mui/material";
import { useNL } from "utils/NLContext";

const NLTooltip: React.FC<{
  message: string;
  followCursor?: boolean;
  children: React.ReactNode;
}> = (props) => {
  const { openLink } = useNL();
  return (
    <Tooltip
      arrow
      followCursor={props.followCursor ?? false}
      enterNextDelay={2000}
      title={
        <React.Fragment>
          <div
            onClick={(event: React.MouseEvent) => {
              if (event.target instanceof HTMLAnchorElement) {
                openLink(
                  event.target.pathname,
                  event.target.search,
                  event.target.hash
                );
                event.preventDefault();
              }
            }}
            dangerouslySetInnerHTML={{ __html: props.message }}
          />
        </React.Fragment>
      }
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};

export default NLTooltip;
