import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum TrackStat {
  Speed,
  Distance,
}

export enum TrackTailLengths {
  Full = -1,
  NoTail = 0,
  Tail1m = 60000,
  Tail5m = 300000,
  Tail1d = 86400000,
}

export const IssueTypes = {
  GPSForUnknownDev: "GPSForUnknownDev",
} as const;
type IssueType = (typeof IssueTypes)[keyof typeof IssueTypes];

export type issueType = {
  id: string;
  title: string;
  type: IssueType;
  data?: any;
};

export enum drawerMenuContentTypes {
  Default,
  Speedings,
  SpeedAreas,
  Printing,
  SpeedPenalties,
}

type CupDuplicatesType = {
  [name: string]: {
    fuid: string;
  };
};

interface AppStateSlice {
  editKPId: string | undefined;
  addingKP: boolean;
  editTeamKP?:
    | {
        kpid: string;
        tid: string;
      }
    | undefined;
  editTeamId?: string;
  trackMenuStatType: TrackStat;
  hiddenTracks: {
    [tid: string]: boolean;
  };
  trackTailLength: TrackTailLengths;
  playTime: number;
  massStartTime: number | null;
  liveGPSData: boolean;
  issues: issueType[];
  mailCount: number;
  notificationCount: number;
  drawerMenuContent: drawerMenuContentTypes;
  showTrackLabels: boolean;
  showKPs: boolean;
  showMiniMap: boolean;
  showMarks: boolean;
  trackMe: boolean;
  investigateTrack?: string;
  viewTeamResult?: string;
  resultCupId?: string;
  cupDuplicates?: CupDuplicatesType;
  continousKPInsert: boolean;
  richEditorPath?: string;
}

const initialState: AppStateSlice = {
  editKPId: undefined,
  addingKP: false,
  hiddenTracks: {},
  trackMenuStatType: TrackStat.Speed,
  trackTailLength: TrackTailLengths.Tail1m,
  playTime: 0,
  massStartTime: null,
  liveGPSData: true,
  issues: [],
  mailCount: 0,
  notificationCount: 0,
  drawerMenuContent: drawerMenuContentTypes.Default,
  showTrackLabels: false,
  showKPs: false,
  trackMe: false,
  showMiniMap: true,
  showMarks: false,
  resultCupId: undefined,
  continousKPInsert: false,
};

export type AppStateKeys = keyof AppStateSlice;
export type AppStateKeysForDialogs = keyof Pick<
  AppStateSlice,
  "editKPId" | "editTeamId" | "resultCupId" | "richEditorPath"
>;

export const appStateSlice = createSlice({
  name: "eventid",
  initialState,
  reducers: {
    clearAppStateValue: (
      state,
      action: PayloadAction<AppStateKeysForDialogs>
    ) => {
      state[action.payload] = undefined;
    },
    showCupResult: (state, action: PayloadAction<string>) => {
      state.resultCupId = action.payload;
    },
    setEditKPId: (state, action) => {
      state.editKPId = action.payload;
    },
    enableAddKP: (state, action: PayloadAction<boolean>) => {
      state.addingKP = action.payload;
    },
    setEditTeamId: (state, action: PayloadAction<string | undefined>) => {
      state.editTeamId = action.payload;
    },
    setEditTeamKP: (state, action) => {
      if (Boolean(action.payload.tid) && Boolean(action.payload.kpid)) {
        state.editTeamKP = {
          kpid: action.payload.kpid,
          tid: action.payload.tid,
        };
      } else {
        state.editTeamKP = undefined;
      }
    },
    hideTrack: (state, action) => {
      if (typeof action.payload === "string") {
        state.hiddenTracks[action.payload] = true;
      } else if (Array.isArray(action.payload)) {
        action.payload.forEach((tid) => {
          state.hiddenTracks[tid] = true;
        });
      }
    },
    unhideTrack: (state, action) => {
      if (state.hiddenTracks[action.payload]) {
        state.hiddenTracks[action.payload] = false;
      }
    },
    unhideAllTracks: (state) => {
      state.hiddenTracks = {};
    },
    setTrackTailLength: (state, action) => {
      state.trackTailLength = action.payload;
    },
    setPlayTime: (state, action) => {
      state.playTime = action.payload;
    },
    incPlayTime: (state, action) => {
      state.playTime += action.payload;
    },
    setMassStartTime: (state, action) => {
      state.massStartTime = action.payload;
    },
    setLiveGPSData: (state, action) => {
      state.liveGPSData = action.payload;
    },
    addIssue: (state, action: PayloadAction<issueType>) => {
      state.issues.push(action.payload);
    },
    solveIssue: (state, action: PayloadAction<issueType>) => {
      state.issues = state.issues.filter((i) => i.id !== action.payload.id);
    },
    toggleShowTrackLabels: (state) => {
      state.showTrackLabels = !state.showTrackLabels;
    },
    setShowKPs: (state, action: PayloadAction<boolean>) => {
      state.showKPs = action.payload;
    },
    setShowMiniMap: (state, action: PayloadAction<boolean>) => {
      state.showMiniMap = action.payload;
    },
    setTrackMe: (state, action: PayloadAction<boolean>) => {
      state.trackMe = action.payload;
    },
    setViewTeamResult: (state, action: PayloadAction<string | undefined>) => {
      state.viewTeamResult = action.payload;
    },
    setRichEditPath: (state, action: PayloadAction<string | undefined>) => {
      console.log("ser rich path", action.payload);
      state.richEditorPath = action.payload;
    },
    setDrawerMenuContent: (
      state,
      action: PayloadAction<drawerMenuContentTypes>
    ) => {
      state.drawerMenuContent = action.payload;
    },
    setInvestigateTrack: (state, action: PayloadAction<string | undefined>) => {
      state.investigateTrack = action.payload;
    },
    toggleShowMarks: (state) => {
      state.showMarks = !state.showMarks;
    },
    onOpenNewEvent: (state) => {
      state.showMarks = false;
      state.editKPId = undefined;
      state.editTeamId = undefined;
      state.editTeamKP = undefined;
      state.hiddenTracks = {};
      state.playTime = 0;
      state.massStartTime = null;
      state.issues = [];
      state.mailCount = 0;
      state.notificationCount = 0;
      state.showKPs = false;
      state.investigateTrack = undefined;
      state.viewTeamResult = undefined;
    },
    setCupDuplicates: (
      state,
      action: PayloadAction<CupDuplicatesType | undefined>
    ) => {
      state.cupDuplicates = action.payload;
    },
    toggleContinousKPInsert: (state) => {
      state.continousKPInsert = !state.continousKPInsert;
    },
  },
});

export const {
  clearAppStateValue,
  showCupResult,
  setEditKPId,
  enableAddKP,
  setEditTeamId,
  hideTrack,
  unhideAllTracks,
  unhideTrack,
  setTrackTailLength,
  setPlayTime,
  incPlayTime,
  setMassStartTime,
  setLiveGPSData,
  addIssue,
  solveIssue,
  setDrawerMenuContent,
  toggleShowTrackLabels,
  setShowKPs,
  setShowMiniMap,
  setTrackMe,
  setInvestigateTrack,
  setViewTeamResult,
  setRichEditPath,
  toggleShowMarks,
  onOpenNewEvent,
  setCupDuplicates,
  toggleContinousKPInsert,
} = appStateSlice.actions;
export const setEditTeamKP = (kpid?: string, tid?: string) => {
  return appStateSlice.actions.setEditTeamKP({ kpid: kpid, tid: tid });
};

export default appStateSlice.reducer;
