import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import { EventType } from "rx/fbListSlices";
import BaseTable, {
  AutoResizer,
  ColumnShape,
  SortOrder,
} from "react-base-table";
import dayjs from "dayjs";
import { archiveEvent, clearEvent, restoreDeleted } from "utils/archive";
import { execWithProgress } from "rx/saveInProgressSlice";
import {
  child,
  getDatabase,
  onValue,
  ref,
  Unsubscribe,
} from "firebase/database";
import { withNL, WithNLProps } from "utils/NLContext";
import { getAuth } from "firebase/auth";
import { GetUsersResponse, UserEntry } from "./AdminsAutocomplete";

const mapState = (state: RootState) => ({});
const mapDispatch = {
  closeDialog: () => closeDialog("eventsManagerold"),
  execWithProgress: (f: () => Promise<any>) => execWithProgress(f),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EventsManagerProps = PropsFromRedux & WithNLProps & WithTranslation & {};
type EventsManagerState = {
  events: {
    [evid: string]: EventType;
  };
  sortBy: { key: string | number; order: SortOrder };
  uidmap: {
    [uid: string]: UserEntry;
  };
  unpaidevents: {
    [evid: string]: string;
  };
  progressOpen: boolean;
  progressValue: number;
  progressMsg: String;
  progressTitle: String;
};
class EventsManagerOld extends React.Component<
  EventsManagerProps,
  EventsManagerState
> {
  state: EventsManagerState = {
    events: {},
    sortBy: { key: "starttime", order: "asc" },
    uidmap: {},
    unpaidevents: {},
    progressOpen: false,
    progressValue: 0,
    progressMsg: "",
    progressTitle: "",
  };
  unsubscribeEvents?: Unsubscribe;
  unsubscribeUnpaid?: Unsubscribe;

  columns: ColumnShape<EventType & { evid: string }>[] = [
    {
      key: "name",
      dataKey: "name",
      title: "Event name",
      sortable: true,
      width: 200,
    },
    {
      key: "starttime",
      dataKey: "starttime",
      title: "Start date",
      sortable: true,
      cellRenderer: ({ cellData }) => {
        return dayjs(cellData).format("DD MMM YYYY");
      },
      width: 200,
    },
    {
      key: "owner",
      title: "Owner",
      width: 200,
      sortable: true,
      cellRenderer: ({ rowData }) => {
        const owner = Object.entries(rowData.admins || {}).find(
          ([uid, type]) => type === "owner"
        );
        if (!owner) return "No owner";
        if (owner[0] in this.state.uidmap)
          return this.state.uidmap[owner[0]].displayName;
        this.fetchUserInfo(owner[0]);
        return owner[0];
      },
    },
    {
      key: "archive",
      title: "Archive",
      width: 200,
      cellRenderer: ({ rowData }) => {
        const evid = rowData.evid;
        if (rowData.arch)
          return (
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                this.props.execWithProgress(async () => {
                  const r = await restoreDeleted("archive", evid, true);
                  if (r) this.props.fbRemove(`/events/${evid}/arch`);
                });
              }}
            >
              Unarchive
            </Button>
          );
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                progressOpen: true,
                progressValue: 0,
                progressMsg: "",
                progressTitle: `Archiving ${evid} ...`,
              });
              this.props.execWithProgress(async () =>
                archiveEvent(evid, "archive", this.updatProgress)
                  .then(async (wassuccess) => {
                    if (!wassuccess) return Promise.resolve();
                    this.setState({
                      progressTitle: `Cleaning ${evid} ...`,
                      progressValue: 0,
                    });
                    await clearEvent(evid, this.updatProgress, false);
                    return await this.props.fbSet(`/events/${evid}/arch`, "a1");
                  })
                  .then(() => this.setState({ progressOpen: false }))
              );
            }}
          >
            Archive
          </Button>
        );
      },
    },
    {
      key: "payment",
      title: "Payment",
      width: 200,
      cellRenderer: ({ rowData }) => {
        const unpaid = rowData.evid in this.state.unpaidevents;
        return (
          <Typography
            onClick={() => {
              const pref = child(
                ref(getDatabase(), "/admin/unpaid/"),
                rowData.evid
              );
              if (unpaid) {
                this.props.fbRemove(pref);
              } else {
                this.props.fbSet(pref, "Manual: " + dayjs().toISOString());
              }
            }}
            color={unpaid ? "red" : "green"}
          >
            {unpaid ? "Unpaid" : "Ok"}
          </Typography>
        );
      },
    },
  ];

  userfetchtimer?: NodeJS.Timeout;
  fetchuids: Set<string> = new Set();
  fetchUserInfo = (uid: string) => {
    if (this.userfetchtimer) {
      clearTimeout(this.userfetchtimer);
    }
    this.fetchuids.add(uid);
    this.userfetchtimer = setTimeout(() => {
      const uidsparam = Array.from(this.fetchuids).join(",");
      /*
      const currentUser = getAuth().currentUser;
      currentUser && getIdToken(currentUser)
      */
      getAuth()
        .currentUser?.getIdToken() // Can we still use this or should use getIdToken function
        .then((token) => {
          var url = new URL(
            "https://europe-west1-nutilogi.cloudfunctions.net/api/getusers"
          );

          url.searchParams.append("uids", uidsparam);
          fetch(url.toString(), {
            headers: {
              Authorization: "Bearer " + token,
              "X-EventId": "TallinaTest",
            },
          })
            .then((data) => {
              return data.json();
            })
            .then((anydata) => {
              if (anydata.users !== undefined) {
                const data = anydata as GetUsersResponse;
                if (data.notFound && data.notFound.length !== 0) {
                  console.warn(
                    "Not found users. Should never request for users that do not exist.",
                    data.notFound
                  );
                  let addusers: {
                    [uid: string]: UserEntry;
                  } = {};

                  data.notFound.forEach((ent) => {
                    addusers[uid] = {
                      displayName: "Unknown owner id:" + uid,
                      uid: uid,
                    };
                    console.log("not found-", ent.uid);
                  });
                  this.setState({
                    uidmap: Object.assign({}, this.state.uidmap, addusers),
                  });
                  return;
                }
                let addusers: { [uid: string]: UserEntry } = {};
                data.users.forEach((ent) => {
                  addusers[ent.uid] = ent;
                });
                this.setState({
                  uidmap: Object.assign({}, this.state.uidmap, addusers),
                });
              }
            })
            .catch((e) => {
              /* istanbul ignore next */
              console.error("got error on fetch", e);
            });
        });
      this.userfetchtimer = undefined;
      this.fetchuids = new Set();
    }, 1000);
  };
  updatProgress = (value: number, msg?: string) => {
    this.setState((oldstate) => {
      return {
        progressValue: value,
        progressMsg: msg
          ? msg + " - DONE\n" + oldstate.progressMsg
          : oldstate.progressMsg,
      };
    });
  };
  componentDidMount() {
    this.unsubscribeEvents = onValue(ref(getDatabase(), "events"), (snap) => {
      this.setState({ events: snap.val() });
      const uidset = new Set();
      Object.entries(snap.val()).forEach(([evid, event]) => {
        const ev = event as EventType;
        Object.keys(ev.admins || {}).forEach((uid) => uidset.add(uid));
      });
    });
    this.unsubscribeUnpaid = onValue(
      ref(getDatabase(), "admin/unpaid"),
      (snap) => {
        console.log("got unpaid events", snap.val());
        this.setState({ unpaidevents: snap.val() });
      }
    );
  }
  componentWillUnmount() {
    this.unsubscribeEvents && this.unsubscribeEvents();
    this.unsubscribeUnpaid && this.unsubscribeUnpaid();
  }
  render() {
    const { t } = this.props;
    const data = Object.entries(this.state.events)
      .map(([evid, event]) => Object.assign({}, event, { evid: evid }))
      .sort((a, b) => {
        let resp: number = 0;
        if (this.state.sortBy.key === "starttime")
          resp = (a.starttime || 0) - (b.starttime || 0);
        else if (this.state.sortBy.key === "name")
          resp = (a.name || "").localeCompare(b.name || "");
        return this.state.sortBy.order === "desc" ? resp : -resp;
      });
    return [
      <Dialog open={true} maxWidth="lg" fullWidth>
        <DialogTitle>{t("eventsmanager.title")}</DialogTitle>
        <DialogContent>
          <Container style={{ height: "70vh" }}>
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed
                  columns={this.columns}
                  width={width}
                  height={height}
                  sortBy={this.state.sortBy}
                  onColumnSort={(sortBy) => {
                    this.setState({
                      sortBy: { key: sortBy.key, order: sortBy.order },
                    });
                  }}
                  data={data}
                ></BaseTable>
              )}
            </AutoResizer>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.closeDialog}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>,
      <Dialog open={this.state.progressOpen} maxWidth="sm" fullWidth>
        <DialogTitle>{this.state.progressTitle}</DialogTitle>
        <DialogContent>
          <LinearProgress
            value={this.state.progressValue}
            variant="determinate"
          />
          <TextField
            fullWidth
            multiline
            value={this.state.progressMsg}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
      </Dialog>,
    ];
  }
}

export default connector(withTranslation()(withNL(EventsManagerOld)));
