import {
  Container,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  MenuItem,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { get, getDatabase, ref, remove, set } from "firebase/database";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { useNL } from "utils/NLContext";
import { SkeletonWrapper } from "utils/SmallUtils";
import AdminsAutocomplete from "./AdminsAutocomplete";
import { DisplayNameOfUid } from "./EventsManager";
import dayjs from "dayjs";
import FBKeyboardDateTimePicker from "./FBKeyboardDateTimePicker";
import { SlideDownTransition, SlideUpTransition } from "utils/DialogUtils";
import { ContentCopy, Download, Visibility } from "@mui/icons-material";
import { QRCodeCanvas } from "qrcode.react";

type CodeEntry = {
  evid: string;
  singleteam?: boolean;
  used?: string;
  valid?: number;
};

const CodeViewContent: React.FC<{
  code: string;
  dynlink: string;
  onClose: () => void;
}> = ({ code, dynlink, onClose }) => {
  const { t } = useTranslation();
  const evname = useTSelector((state) => state.event.name);
  return (
    <>
      <DialogTitle>Code: {code}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid>{dynlink}</Grid>
            <Grid>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(dynlink);
                }}
              >
                <ContentCopy />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <QRCodeCanvas id="jcodeid" size={256} level="M" value={dynlink} />
          </Grid>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                const canvas = document.getElementById(
                  "jcodeid"
                ) as HTMLCanvasElement;
                const pngUrl = canvas
                  .toDataURL("image/png")
                  .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = `joine_${evname}_with_${code}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }}
              size="large"
            >
              <Download />
            </IconButton>
            <IconButton
              onClick={() => {
                const canvas = document.getElementById(
                  "jcodeid"
                ) as HTMLCanvasElement;
                canvas.toBlob((blob) => {
                  if (blob === null) return;
                  const item = new ClipboardItem({ "image/png": blob });
                  navigator.clipboard.write([item]);
                });
              }}
              size="large"
            >
              <ContentCopy />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.close")}</Button>
      </DialogActions>
    </>
  );
};

const CodeOwnerDialogContent: React.FC<{
  code: string;
  onClose: () => void;
}> = ({ code, onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <DialogContent>
        <AdminsAutocomplete
          adminpath={"/eventcodes/" + code + "/used"}
          singleEntry
          label={t("joinc.userbox.label")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.close")}</Button>
      </DialogActions>
    </>
  );
};

const CodeTableEntry: React.FC<{ code: string; dynlink: string }> = ({
  code,
  dynlink,
}) => {
  const data = useRFirebaseDb<CodeEntry>(`/eventcodes/${code}`);
  const { t } = useTranslation();
  const { fbSet } = useNL();
  const [ownerDiaOpen, setOwnerDiaOpen] = useState(false);
  const [showCodeOpen, setShowCodeOpen] = useState(false);

  return (
    <>
      <SkeletonWrapper condition={!Boolean(data)}>
        <TableRow>
          <TableCell>
            {code}{" "}
            <IconButton onClick={() => setShowCodeOpen(true)}>
              <Visibility />
            </IconButton>
          </TableCell>
          <TableCell>
            <Checkbox
              color="primary"
              checked={data?.singleteam}
              onChange={(_, checked) => {
                fbSet(
                  `/eventcodes/${code}/singleteam`,
                  checked,
                  t("joinc.changed.isingle")
                );
              }}
              inputProps={{
                "aria-label": "single team code",
              }}
            />
          </TableCell>
          <TableCell
            onClick={() => {
              setOwnerDiaOpen(true);
            }}
          >
            {data?.used && <DisplayNameOfUid uid={data.used} />}
          </TableCell>
          <TableCell>
            <FBKeyboardDateTimePicker
              sx={{ width: 240 }}
              ampm={false}
              path={`/eventcodes/${code}/valid`}
              format="DD/MM/YYYY HH:mm:ss"
              id="codevalidtime"
            />
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                remove(ref(getDatabase(), `/eventcodes/${code}`));
              }}
            >
              {t("button.delete")}
            </Button>
          </TableCell>
        </TableRow>
      </SkeletonWrapper>
      <Dialog
        open={ownerDiaOpen}
        maxWidth="sm"
        fullWidth
        TransitionProps={{ unmountOnExit: true }}
        TransitionComponent={SlideUpTransition}
      >
        <CodeOwnerDialogContent
          code={code}
          onClose={() => {
            setOwnerDiaOpen(false);
          }}
        />
      </Dialog>
      <Dialog
        open={showCodeOpen}
        maxWidth="sm"
        fullWidth
        TransitionProps={{ unmountOnExit: true }}
        TransitionComponent={SlideDownTransition}
      >
        <CodeViewContent
          code={code}
          dynlink={dynlink}
          onClose={() => {
            setShowCodeOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const validTimeOptins = {
  "joinc.option.week": dayjs.duration({ weeks: 1 }).asMilliseconds(),
  "joinc.option.2weeks": dayjs.duration({ weeks: 2 }).asMilliseconds(),
  "joinc.option.month": dayjs.duration({ months: 1 }).asMilliseconds(),
  "joinc.option.2months": dayjs.duration({ months: 2 }).asMilliseconds(),
};
const CreateCodeButton: React.FC<{ eventId: string | null }> = ({
  eventId,
}) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [singleTeam, setSingleTeam] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<String | null>(null);
  const [codeLength, setCodeLength] = useState(6);
  const [validms, setValidms] = useState(
    dayjs.duration({ months: 1 }).asMilliseconds()
  );
  const { t } = useTranslation();

  const save = useCallback(async () => {
    const coderef = ref(getDatabase(), "/eventcodes/" + code);
    const snap = await get(coderef);
    if (snap.exists()) {
      setCodeError(t("joinc.error.codeexists"));
      return;
    }
    await set(coderef, {
      singleteam: singleTeam,
      evid: eventId,
      valid: dayjs()
        .hour(20)
        .minute(59)
        .second(0)
        .millisecond(0)
        .add(validms, "milliseconds")
        .valueOf(),
    });
    setCreateOpen(false);
    setCode("");
  }, [code, eventId, singleTeam, t, validms]);
  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setCreateOpen(true);
        }}
      >
        {t("button.createcode")}
      </Button>
      <Dialog
        open={createOpen}
        maxWidth="sm"
        fullWidth
        TransitionProps={{ unmountOnExit: true }}
        TransitionComponent={SlideDownTransition}
      >
        <DialogTitle>{t("title.createcode")}</DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid>
              <TextField
                label={t("joinc.label.code")}
                error={Boolean(codeError)}
                value={code}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
                helperText={codeError}
              />
            </Grid>
            <Grid>
              <Grid container alignItems="center" spacing={2}>
                <Grid xs={6} md="auto">
                  <Tooltip title={t("joinc.tooltip.chars")}>
                    <OutlinedInput
                      type="number"
                      value={codeLength}
                      onChange={(event) => {
                        setCodeLength(Number(event.target.value));
                      }}
                      sx={{ width: "15ch" }}
                      endAdornment={
                        <InputAdornment position="end">
                          {t("joinc.chars")}
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid xs={6} md="auto">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCode(generateRandomString(codeLength));
                    }}
                  >
                    {t("joinc.button.gen")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Tooltip title={t("joinc.tooltip.singleteam")}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={singleTeam}
                      onChange={(_, checked) => {
                        setSingleTeam(checked);
                      }}
                    />
                  }
                  label={t("joinc.label.singleteam")}
                />
              </Tooltip>
            </Grid>
            <Grid>
              <TextField
                select
                value={validms}
                label={t("joinc.label.validtime")}
                defaultValue={dayjs.duration({ months: 1 }).asMilliseconds()}
                onChange={(event) => {
                  setValidms(Number(event.target.value));
                }}
                helperText={t("joinc.helper.validtime")}
              >
                {Object.entries(validTimeOptins).map(([k, v]) => (
                  <MenuItem value={v}>{t(k as any)}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setCreateOpen(false);
            }}
          >
            {t("button.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              save();
            }}
          >
            {t("button.create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const JoinCodes: React.FC = () => {
  const { t } = useTranslation();
  const evid = useTSelector((s) => s.eventId);
  const data = useRFirebaseDb<{ [k: string]: string }>(
    "/eventcodehelper/" + evid
  );
  return (
    <>
      <Container>
        <CreateCodeButton eventId={evid} />
      </Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("joinc.header.code")}</TableCell>
              <TableCell>{t("joinc.header.issingle")}</TableCell>
              <TableCell>{t("joinc.header.used")}</TableCell>
              <TableCell>{t("joinc.header.valid")}</TableCell>
              <TableCell>{t("joinc.header.delete")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(data || {}).map(([k, v]) => (
              <CodeTableEntry code={k} dynlink={v} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
  /*
  const data = useRFirebaseDb<{ [k: string]: CodeEntry }>(
    query(ref(getDatabase(), "/eventcodes"))
  );
  const columns: GridColDef[] = [
    { field: "id", headerName: t("joinc.header.code" as any), width: 90 },
    {
      field: "singleteam",
      headerName: t("joinc.header.singleteam" as any),
      width: 150,
      editable: true,
    },
    {
      field: "team",
      headerName: t("joinc.header.team" as any),
      width: 150,
      editable: true,
    },
    {
      field: "validuntil",
      headerName: t("joinc.header.validuntil" as any),
      type: "number",
      width: 110,
      editable: true,
    },
  ];

  const rows = Object.entries(data || {}).map(([k, v]) =>
    Object.assign({ id: k }, v)
  );

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
  */
};

export default JoinCodes;
