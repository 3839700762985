import React, { useState } from "react";

import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import {
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { TextFieldProps as MuiTextFieldPropsType } from "@mui/material/TextField";
import { DatabaseReference } from "firebase/database";

const FBKeyboardDateTimePicker: React.FC<
  Omit<DateTimePickerProps<Dayjs>, "value" | "onChange"> & {
    path: string | DatabaseReference;
    id?: string;
    inputProps?: MuiTextFieldPropsType;
    disableTooltip?: boolean;
  }
> = (props) => {
  const [fbvalue, setFbValue] = useFirebaseDb(props.path);
  const { t } = useTranslation();
  const [state, setState] = useState({
    value: undefined as number | null | undefined,
    fbchanges: 0,
    timer: null as NodeJS.Timeout | null,
  });
  if (
    fbvalue.value !== undefined &&
    state.fbchanges !== fbvalue.changecount &&
    state.timer === null
  ) {
    setState(
      Object.assign({}, state, {
        value: fbvalue.value,
        fbchanges: fbvalue.changecount,
      })
    );
  }

  const tooltipkey =
    !props.disableTooltip && props.id ? `fbf.${props.id}.tooltip` : null;

  return (
    <NLTooltipWrapper tkey={tooltipkey as any}>
      <SkeletonWrapper condition={state.value === undefined}>
        <DateTimePicker
          value={dayjs(state.value)}
          label={props.id ? t(`fbf.${props.id}.label` as any) : undefined}
          {...props}
          onChange={(date, context) => {
            let newState = Object.assign({}, state, {
              value: date ? date.unix() * 1000 : null,
            });
            if (state.timer) {
              clearTimeout(state.timer);
            }
            newState.timer = setTimeout(() => {
              console.log("ssaving");
              setState((instate) =>
                Object.assign({}, instate, { timer: null })
              );
              setFbValue(
                props.id || "dummy",
                t(`fbf.${props.id}.changed` as any),
                newState.value
              );
            }, 500);
            setState(newState);
          }}
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FBKeyboardDateTimePicker;
