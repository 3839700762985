import { archiveprefix } from "components/DebuggerTools";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import fbslices, {
  dataAvailabilitySlice,
  DataAvailabilityType,
  eventSlice,
} from "rx/fbListSlices";
import { addPoints } from "rx/tracksSlice";
import { TrackDecoder } from "@nutilogi/trzip";

const fetchFromStorageArchive = async (eventId: string, file: string) => {
  const evsref = ref(getStorage(), `/archive/${eventId}`);
  return await getDownloadURL(ref(evsref, file))
    .then((url) => fetch(url))
    .then((r) => r.text());
};
export const getArchiveFile = async (
  eventId: string,
  arch: string,
  file: string
) => {
  if (arch === "a1") {
    return fetchFromStorageArchive(eventId, file);
  } else if (arch === "a2") {
    return fetch(`${archiveprefix}/${eventId}/${file}`).then((r) => r.text());
  } else {
    console.error("Bad archive: " + arch + " for event " + eventId);
    return null;
  }
};

export const ArchiveReader: React.FC<{
  archive: string;
  eventId: string;
  resultOnly?: boolean;
}> = ({ archive, eventId, resultOnly }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const applyEvData = (evdata: any) => {
      if (evdata.eventsdata?.data?.ajad) {
        Object.keys(evdata.eventsdata.data.ajad).forEach((ak) => {
          if (ak === "lisaajad") return;
          for (let i in evdata.eventsdata.data.ajad[ak]) {
            if (evdata.eventsdata.data.ajad[ak][i] === null) {
              delete evdata.eventsdata.data.ajad[ak][i];
            }
          }
        });
      }
      dispatch(eventSlice.actions.combineValue(evdata.eventsdata.data));
      Object.entries(fbslices).forEach(([k, slice]) => {
        if (evdata[slice.group]) {
          dispatch(slice.actions.replaceValue(evdata[slice.group][slice.path]));
        }
        dispatch(
          dataAvailabilitySlice.actions.setDataAvailable(
            k as keyof DataAvailabilityType
          )
        );
      });
      dispatch(dataAvailabilitySlice.actions.setDataAvailable("eventData"));
      dispatch(dataAvailabilitySlice.actions.setDataAvailable("events"));
    };

    const getDataFromUrl = (url: string, eventId: string) => {
      fetch(`${url}/${eventId}/evdata.json`)
        .then((r) => r.json())
        .then((d) => {
          applyEvData(d);
        })
        .catch((e) => {
          console.log("Failed", e);
        });
      if (!resultOnly) {
        fetch(`${url}/${eventId}/tracks.dat`)
          .then((r) => r.arrayBuffer())
          .then((buf) => {
            let decodeddata = TrackDecoder.decode(Buffer.from(buf));
            Object.entries(decodeddata).forEach(([tid, tdata]) => {
              Object.entries(tdata).forEach(([devid, points]) => {
                if (points.length > 0)
                  dispatch(addPoints({ tid, devid, points }));
              });
            });
          });
      }
    };

    if (archive === "a1") {
      const sref = ref(getStorage(), `/archive/${eventId}`);
      getDownloadURL(ref(sref, "evdata.json"))
        .then((url) => fetch(url))
        .then((r) => r.json())
        .then((evdata) => {
          applyEvData(evdata);
        });
      if (!resultOnly) {
        getDownloadURL(ref(sref, "tracks.dat"))
          .then((url) => fetch(url))
          .then((r) => r.arrayBuffer())
          .then((buf) => {
            let decodeddata = TrackDecoder.decode(Buffer.from(buf));
            Object.entries(decodeddata).forEach(([tid, tdata]) => {
              Object.entries(tdata).forEach(([devid, points]) => {
                if (points.length > 0)
                  dispatch(addPoints({ tid, devid, points }));
              });
            });
          });
      }
    } else if (archive === "a2") {
      getDataFromUrl(archiveprefix, eventId);
    }
  }, [archive, eventId, resultOnly, dispatch]);
  return null;
};
