import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { argouid } from "utils/SmallUtils";
import Editor from "./Editor";
import { Editor as TinyMCEEditor } from "tinymce";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNL } from "utils/NLContext";
import { getDatabase, ref } from "firebase/database";
import { Delete, Edit } from "@mui/icons-material";

const NewsTimeValue = React.forwardRef<
  dayjs.Dayjs,
  { defaultValue: dayjs.Dayjs }
>(({ defaultValue }, ref) => {
  const [dv, setDv] = useState(defaultValue);

  return (
    <DateTimePicker
      value={dv}
      onChange={(nv) => {
        if (nv) {
          setDv(nv);
          if (ref && typeof ref !== "function") ref.current = nv;
        }
      }}
    />
  );
});

type NewsEntry = {
  title?: string;
  content?: string;
};

const EditNewsContent: React.FC<{ id?: string; onClose: () => void }> = ({
  id,
  onClose,
}) => {
  const estTitle = useRef<TextFieldProps>();
  const engTitle = useRef<TextFieldProps>();
  const timeValue = useRef<dayjs.Dayjs>(
    id ? dayjs.unix(Number(id)) : dayjs(new Date().setUTCSeconds(0, 0))
  );
  const estText = useRef<TinyMCEEditor>();
  const engText = useRef<TinyMCEEditor>();
  const { fbSet } = useNL();
  const dbEng = useRFirebaseDb<NewsEntry>("/news/en/" + id ?? "noid");
  const dbEst = useRFirebaseDb<NewsEntry>("/news/et-EE/" + id ?? "noid");
  const { t } = useTranslation();

  useEffect(() => {
    if (dbEst && estTitle.current) estTitle.current.value = dbEst.title;
  }, [dbEst]);
  useEffect(() => {
    if (dbEng && engTitle.current) engTitle.current.value = dbEng.title;
  }, [dbEng]);
  const saveOne = useCallback(
    (lngpath: string, title: string, content: string) => {
      const path = `/news/${lngpath}/${timeValue.current?.unix()}`;
      fbSet(ref(getDatabase(), path), {
        title: title,
        content: content,
      });
    },
    [fbSet, timeValue]
  );
  const saveEst = useCallback(() => {
    saveOne(
      "et-EE",
      estTitle.current?.value as string,
      estText.current?.getContent() || ""
    );
  }, [saveOne]);
  const saveEng = useCallback(() => {
    saveOne(
      "en",
      engTitle.current?.value as string,
      engText.current?.getContent() || ""
    );
  }, [saveOne]);

  return (
    <>
      <DialogTitle>Add news</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={7}>
            <TextField
              placeholder="Tiitel"
              label="Eesti"
              inputRef={estTitle}
              defaultValue={dbEst?.title || ""}
            />
          </Grid>
          <Grid xs={5}>
            <NewsTimeValue defaultValue={timeValue.current} ref={timeValue} />
          </Grid>
          <Grid>
            <Editor
              initialValue={dbEst?.content}
              height={300}
              onInit={(_, editor) => {
                estText.current = editor;
              }}
              onSetup={(editor, callbacks) => {
                callbacks.setSaveableContent(true);
                callbacks.doSave = () => {
                  saveEst();
                };
              }}
              disableLanguages
            />
          </Grid>
          <Grid>
            <TextField
              placeholder="Title"
              label="Inglise"
              inputRef={engTitle}
              defaultValue={dbEng?.title}
            />
          </Grid>
          <Grid>
            <Editor
              height={300}
              initialValue={dbEng?.content}
              onInit={(_, editor) => {
                engText.current = editor;
              }}
              onSetup={(editor, callbacks) => {
                callbacks.setSaveableContent(true);
                callbacks.doSave = () => {
                  saveEng();
                };
              }}
              disableLanguages
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            saveEst();
            saveEng();
            estText.current?.setDirty(false);
            engText.current?.setDirty(false);
            onClose();
          }}
        >
          {t("button.save")}
        </Button>
        <Button
          onClick={() => {
            estText.current?.setDirty(false);
            engText.current?.setDirty(false);
            onClose();
          }}
        >
          {t("button.cancel")}
        </Button>
      </DialogActions>
    </>
  );
};

enum StoryEvent {
  Edit,
  Delete,
}

const Story: React.FC<{
  isArgo: boolean;
  title: string;
  content: string;
  time: string;
  onEvent: (ev: StoryEvent) => void;
}> = ({ isArgo, title, content, time, onEvent }) => {
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box sx={{ my: 3, mx: 2 }}>
        <Grid container alignItems="center">
          <Grid xs>
            <Typography gutterBottom variant="h5" component="div">
              {title}
              {isArgo && (
                <>
                  <IconButton onClick={() => onEvent(StoryEvent.Edit)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => onEvent(StoryEvent.Delete)}>
                    <Delete />
                  </IconButton>
                </>
              )}
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom variant="subtitle1" component="div">
              {dayjs.unix(Number(time)).format("DD MMMM YYYY hh:mm")}
            </Typography>
          </Grid>
        </Grid>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
    </Box>
  );
};
const NewsDialog: React.FC = () => {
  const { i18n } = useTranslation();
  const uid = useTSelector((s) => s.user.user?.uid);
  const [uselanguage, setLanguage] = useState(i18n.language);
  const news = useRFirebaseDb<{
    [t: string]: { title?: string; content?: string };
  }>(`/news/${uselanguage}`);
  const [editNews, setEditNews] = useState<string>();
  const isArgo = uid === argouid;

  if (news === null) {
    if (uselanguage !== "en") {
      setLanguage("en");
    }
  }
  return (
    <DialogContent>
      <Grid container alignContent="stretch">
        {isArgo && (
          <Grid xs={12}>
            <Button variant="contained" onClick={() => setEditNews("1")}>
              Add News
            </Button>
          </Grid>
        )}
      </Grid>
      {news && (
        <Stack direction="column-reverse" alignItems="stretch">
          {Object.entries(news).map(([t, data]) => (
            <Story
              isArgo={isArgo}
              key={t}
              title={data.title || ""}
              content={data.content || ""}
              time={t}
              onEvent={(ev) => {
                if (ev === StoryEvent.Edit) setEditNews(t);
              }}
            />
          ))}
        </Stack>
      )}
      <Dialog open={Boolean(editNews)} onClose={() => setEditNews(undefined)}>
        <EditNewsContent onClose={() => setEditNews(undefined)} />
      </Dialog>
    </DialogContent>
  );
};

export default NewsDialog;
