import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFunctions, httpsCallable } from "firebase/functions";
import { AppDispatch, RootState } from "./store";

export type UserEntry = {
  uid: string;
  email?: string;
  displayName?: string;
  phoneNumber?: string;
};

interface UidMapState {
  [uid: string]: Omit<UserEntry, "uid">;
}

const initialState: UidMapState = {};

export const uidmapSlice = createSlice({
  name: "uidmap",
  initialState,
  reducers: {
    addUsers: (state, action: PayloadAction<UserEntry[]>) => {
      action.payload.forEach((element) => {
        state[element.uid] = element;
      });
    },
  },
});

export const { addUsers } = uidmapSlice.actions;

type GetUserDataQueryType = { uids?: string[]; s?: string; eventid: string };

export type GetUsersResponse = {
  users: UserEntry[];
  notFound: { uid: string }[];
};

var getuidinprogress = false;
var waituids: GetUserDataQueryType | undefined = undefined;
var waitquerys: GetUserDataQueryType[] = [];
var workinguids: string[] = [];

export const fetchUidData =
  (query: GetUserDataQueryType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getuidinprogress) {
      if (query.s) {
        waitquerys.push(query);
        return;
      }
      if (!query.uids) {
        console.error("Bad UID query");
        return;
      }
      const state = getState();
      if (waituids === undefined) {
        waituids = { uids: [], eventid: query.eventid };
      }
      for (let uid of query.uids) {
        if (state.uidmap[uid]) continue;
        if (workinguids.includes(uid)) continue;
        if (waituids.uids?.includes(uid)) continue;
        waituids.uids?.push(uid);
      }
      return;
    }
    getuidinprogress = true;
    workinguids = query.uids || [];
    const getusers = httpsCallable<GetUserDataQueryType, GetUsersResponse>(
      getFunctions(undefined, "europe-west1"),
      "api/getusers"
    );
    //console.log("Run query", query);
    getusers(query).then((r) => {
      dispatch(uidmapSlice.actions.addUsers(r.data.users));
      getuidinprogress = false;
      if (waituids) {
        const copywaituids = waituids;
        waituids = undefined;
        if (copywaituids.uids!.length > 0) dispatch(fetchUidData(copywaituids));
      } else {
        const waitq = waitquerys.pop();
        if (waitq) dispatch(fetchUidData(waitq));
      }
    });
  };

export default uidmapSlice.reducer;
