import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";

const GoogleMapsLayer: React.FC<{ conf?: string }> = ({ conf }) => {
  const map = useMap();
  const leafletContext = useLeafletContext();

  useEffect(() => {
    const layer = (L.gridLayer as any).googleMutant({
      type: conf || "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
    });
    if (leafletContext.layerContainer) {
      leafletContext.layerContainer.addLayer(layer);
    } else layer.addTo(map);
    return () => {
      if (leafletContext.layerContainer) {
        leafletContext.layerContainer.removeLayer(layer);
      }
      layer.remove();
    };
  }, [map, conf, leafletContext.layerContainer]);
  return null;
};

export default GoogleMapsLayer;
