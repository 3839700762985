import { RootState } from "rx/store";

type KPListListType = RootState["kpList"];

export const NextKPNr = (kpList: KPListListType): Number => {
  const nrs = Object.values(kpList)
    .map((kp) => Number(kp.nr))
    .filter((it) => !isNaN(it));
  return nrs.length === 0 ? 1 : Math.max(...nrs) + 1;
};
