import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Popper, Paper } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridBooleanCell,
  GridColDef,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import { getDatabase, push, ref } from "firebase/database";
import React, { useCallback } from "react";
import { KPdata } from "rx/fbListSlices";
import store from "rx/store";

type KPResponses = NonNullable<KPdata["responses"]>;

type updateResponseCallback = (
  id: string,
  resp: string,
  iscorrect: boolean
) => void;
type addResponseCallback = (resp: string, iscorrect: boolean) => void;
type kpresponseOnlyIDCallback = (id: string) => void;

const EditKPResponses: React.FC<{
  responses: KPResponses;
  correctresponse: string | undefined;
  updateResponse: updateResponseCallback;
  addResponse: addResponseCallback;
  setCorrectResponse: kpresponseOnlyIDCallback;
  deleteResponse: kpresponseOnlyIDCallback;
}> = ({
  responses,
  correctresponse,
  updateResponse,
  addResponse,
  setCorrectResponse,
  deleteResponse,
}) => {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const rows = Object.entries(responses)
    .map(([rid, r]) => ({
      iscorrect: correctresponse === rid,
      id: rid,
      response: r,
    }))
    .concat({ iscorrect: false, id: "", response: "" });
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "iscorrect",
      type: "boolean",
      headerName: "correct",
      editable: true,
      cellClassName: "ifcorrect",
      renderCell(params) {
        if (params.row?.iscorrect) return <GridBooleanCell {...params} />;
        else return "";
      },
    },

    { field: "response", headerName: "response", flex: 1, editable: true },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          ...(id === ""
            ? []
            : [
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={() => {
                    deleteResponse(row.id);
                  }}
                  color="inherit"
                />,
              ]),
        ];
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-booleanCell[data-value="true"]': { color: "green" },
      }}
      rows={rows}
      columns={columns}
      editMode="row"
      disableColumnMenu={true}
      disableColumnSorting={true}
      hideFooter={true}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(params) => setRowModesModel(params)}
      onRowEditStop={(params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      }}
      processRowUpdate={(newRow, oldRow) => {
        if (oldRow.id === "") {
          addResponse(newRow.response, newRow.iscorrect);
        } else {
          if (oldRow.response !== newRow.response) {
            updateResponse(oldRow.id, newRow.response, newRow.iscorrect);
          } else if (oldRow.iscorrect !== newRow.iscorrect) {
            if (newRow.iscorrect) setCorrectResponse(oldRow.id);
          }
        }
        return newRow;
      }}
      isCellEditable={(params) => {
        if (params.field === "iscorrect" && params.row.iscorrect) return false;
        return params.colDef.editable ?? false;
      }}
    />
  );
};

function EditResponsesInGrid(
  props: GridRenderEditCellParams<any, KPResponses>
) {
  const { id, field, value, colDef } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const apiRef = useGridApiContext();

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const updateResponse = useCallback<updateResponseCallback>(
    (rid, response, iscorrect) => {
      const newValue = { ...value, ...{ [rid]: response } };
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
      });
      if (iscorrect) {
        apiRef.current.setEditCellValue({
          id,
          field: "correctresp",
          value: rid,
        });
      }
    },
    [apiRef, field, value, id]
  );

  const addResponse = useCallback<addResponseCallback>(
    (response, iscorrect) => {
      const eventId = store.getState().eventId ?? "musthave";
      const newkey = push(
        ref(getDatabase(), `/eventsdata/${eventId}/kpdata/${id}/responses`)
      ).key;
      if (newkey) updateResponse(newkey, response, iscorrect);
    },
    [id, updateResponse]
  );

  const setCorrectResponse = useCallback<kpresponseOnlyIDCallback>(
    (rid) => {
      apiRef.current.setEditCellValue({
        id,
        field: "correctresp",
        value: rid,
      });
      // Need to trigger updating of correct mark in current responses edit table
      apiRef.current.setEditCellValue({
        id,
        field,
        value: value,
      });
    },
    [apiRef, field, id, value]
  );

  const deleteResponse = useCallback<kpresponseOnlyIDCallback>(
    (rid) => {
      const newValue = { ...value };
      delete newValue[rid];
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
      });
      // TODO if this was correct answer ... change it
    },
    [apiRef, field, id, value]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{ zIndex: 9999 }}
        >
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <EditKPResponses
              correctresponse={props.row.correctresp}
              updateResponse={updateResponse}
              addResponse={addResponse}
              setCorrectResponse={setCorrectResponse}
              deleteResponse={deleteResponse}
              responses={value ?? {}}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export default EditResponsesInGrid;
