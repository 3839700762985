import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

const validate = (val: string) => {
  return /^[0-2][0-9]:[0-5][0-9](:[0-5][0-9](:[0-9][0-9][0-9])?)?(\s+[ap]m)?$/i.test(
    val
  );
};

const caret = {
  start: function start(el: HTMLInputElement) {
    return el.selectionStart;
  },
  end: function end(el: HTMLInputElement) {
    return el.selectionEnd;
  },
  set: function set(el: HTMLInputElement, start: number, end?: number) {
    el.setSelectionRange(start, end || start);
  },
};

var isSeparator = function isSeparator(char: string) {
  return /[:\s]/.test(char);
};

const getGroups = (str: string) => {
  return str.split(/[:\s+]/);
};

const isTwelveHourTime = (groups: string[]) => {
  return /[a-z]/i.test(groups[groups.length - 1]);
};

const stringify = (groups: string[]) => {
  if (isTwelveHourTime(groups))
    return groups.slice(0, -1).join(":") + " " + groups[groups.length - 1];
  return groups.join(":");
};
const toggle24Hr = (groups: string[]) => {
  var m = groups[groups.length - 1].toUpperCase();
  groups[groups.length - 1] = m === "AM" ? "PM" : "AM";
  return groups;
};

const getBase = (groupId: number, twelveHourTime: boolean) => {
  if (!groupId) return twelveHourTime ? 12 : 24;
  if (groupId < 3) return 60;
  return 1000;
};
const zeroPad = (val: string, digits: number) => {
  while (val.length < digits) {
    val = "0" + val;
  }
  return val;
};

const replace = (groups: string[], groupId: number, amount: number) => {
  var digits = groups[groupId].length;
  groups[groupId] = zeroPad(String(amount), digits);
  return groups;
};
const add = (
  groups: string[],
  groupId: number,
  amount: number,
  twelveHourTime: boolean
): string[] => {
  var base = getBase(groupId, twelveHourTime);
  if (!groupId && groups[groupId] === "12" && twelveHourTime)
    groups[groupId] = "00";
  var val = Number(groups[groupId]) + amount;
  groups = replace(groups, groupId, (val + base) % base);
  if (groupId && val >= base)
    return add(groups, groupId - 1, 1, twelveHourTime);
  if (groupId && val < 0) return add(groups, groupId - 1, -1, twelveHourTime);
  if (!groupId && twelveHourTime) {
    if (val >= base || val < 0) toggle24Hr(groups);
    if (groups[0] === "00") groups[0] = "12";
  }
  return groups;
};
const adder = (str: string, groupId: number, amount: number) => {
  var groups = getGroups(str);
  var twelveHourTime = isTwelveHourTime(groups);
  if (twelveHourTime && groupId === groups.length - 1)
    return stringify(toggle24Hr(groups));
  return stringify(add(groups, groupId, amount, twelveHourTime));
};

const getGroupId = (index: number) => {
  if (index < 3) return 0;
  if (index < 6) return 1;
  if (index < 9) return 2;
  if (index < 13) return 3;
  return 4;
};

const replaceCharAt = function (
  str: string,
  index: number,
  replacement: string
) {
  let pstr = str.split("");
  pstr[index] = replacement;
  return pstr.join("");
};

var SILHOUETTE = "00:00:00:000 AM";

export type NLTimeFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
  value: string | number;
  onChange?: (val: string, seconds: number) => void;
};
type NLTimeFieldState = {
  caretIndex?: number;
};
class NLTimeField extends React.Component<NLTimeFieldProps, NLTimeFieldState> {
  state: NLTimeFieldState = {};
  private input = React.createRef<HTMLInputElement>();

  componentDidUpdate = () => {
    var index = this.state.caretIndex;
    if (this.input.current && (index || index === 0))
      caret.set(this.input.current, index);
  };
  format = (val: string | number) => {
    if (typeof val === "number") {
      return `${(Math.floor(val / 3600) + "").padStart(2, "0")}:${(
        Math.floor((val % 3600) / 60) + ""
      ).padStart(2, "0")}:${(Math.floor((val % 3600) % 60) + "").padStart(
        2,
        "0"
      )}`;
    }
    return val;
  };
  silhouette = () => {
    return this.format(this.props.value).replace(/\d/g, function (val, i) {
      return SILHOUETTE.charAt(i);
    });
  };
  toSeconds = (val: string) => {
    let a = val.split(":");
    if (a.length === 2) {
      a.push("0");
    }
    return Number(a[0]) * 60 * 60 + Number(a[1]) * 60 + Number(a[2]);
  };
  onChange = (str: string, caretIndex: number) => {
    if (this.props.onChange)
      this.props.onChange(this.format(str), this.toSeconds(str));
    if (typeof caretIndex === "number")
      this.setState({ caretIndex: caretIndex });
  };
  handleTab = (event: React.KeyboardEvent) => {
    if (!this.input.current) return;
    var start = caret.start(this.input.current);
    if (!start) return;
    var value = this.format(this.props.value);
    var groups = getGroups(value);
    var groupId = getGroupId(start);
    if (event.shiftKey) {
      if (!groupId) return;
      groupId--;
    } else {
      if (groupId >= groups.length - 1) return;
      groupId++;
    }
    event.preventDefault();
    var index = groupId * 3;
    if (this.format(this.props.value).charAt(index) === " ") index++;
    this.setState({ caretIndex: index });
  };
  handleBackspace = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (!this.input.current) return;
    var start = caret.start(this.input.current);
    var value = this.format(this.props.value);
    var end = caret.end(this.input.current);
    if (!start || !end) return;
    var diff = end - start;
    var silhouette = this.silhouette();
    if (!diff) {
      if (value[start - 1] === ":") start--;
      value = replaceCharAt(value, start - 1, silhouette.charAt(start - 1));
      start--;
    } else {
      while (diff--) {
        if (value[end - 1] !== ":") {
          value = replaceCharAt(value, end - 1, silhouette.charAt(end - 1));
        }
        end--;
      }
    }
    if (value.charAt(start - 1) === ":") start--;
    this.onChange(value, start);
  };
  handleForwardSpace = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (!this.input.current) return;
    var start = caret.start(this.input.current);
    var value = this.format(this.props.value);
    var end = caret.end(this.input.current);

    if (!start || !end || value.length - 1) return;
    var diff = end - start;
    var silhouette = this.silhouette();
    if (!diff) {
      if (value[start] === ":") start++;
      value = replaceCharAt(value, start, silhouette.charAt(start));
      start++;
    } else {
      while (diff--) {
        if (value[end - 1] !== ":") {
          value = replaceCharAt(value, start, silhouette.charAt(start));
        }
        start++;
      }
    }
    if (value.charAt(start) === ":") start++;
    this.onChange(value, start);
  };
  handleBlur = () => {
    this.setState({ caretIndex: undefined });
  };

  handleEscape = (event: React.KeyboardEvent) => {
    this.input.current?.blur();
  };
  handleArrows = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (!this.input.current) return;
    var start = caret.start(this.input.current);
    if (start === null) return;
    var value = this.format(this.props.value);
    var amount = event.key === "ArrowUp" ? 1 : -1;
    if (event.shiftKey) {
      amount *= 2;
      if (event.metaKey) amount *= 2;
    }
    value = adder(value, getGroupId(start), amount);
    this.onChange(value, start);
  };
  keyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "Tab":
        return this.handleTab(event);
      case "Backspace":
        return this.handleBackspace(event);
      case "Delete":
        return this.handleForwardSpace(event);
      case "Escape":
        return this.handleEscape(event);
      case "ArrowUp":
      case "ArrowDown":
        return this.handleArrows(event);
      default:
        break;
    }
  };
  handleChange = (event: React.ChangeEvent) => {
    if (!this.input.current) return;
    var value = this.format(this.props.value);
    var newValue = this.input.current.value;
    var diff = newValue.length - value.length;
    var end = caret.start(this.input.current);
    if (!end) return;
    var insertion = "";
    var start = end - Math.abs(diff);
    event.preventDefault();
    if (diff > 0) {
      insertion = newValue.slice(end - diff, end);
      while (diff--) {
        var oldChar = value.charAt(start);
        var newChar = insertion.charAt(0);
        if (isSeparator(oldChar)) {
          if (isSeparator(newChar)) {
            insertion = insertion.slice(1);
            start++;
          } else {
            start++;
            diff++;
            end++;
          }
        } else {
          value = replaceCharAt(value, start, newChar);
          insertion = insertion.slice(1);
          start++;
        }
      }
      newValue = value;
    } else {
      if (newValue.charAt(start) === ":") start++;
      // apply default to selection
      var result = value;
      for (var i = start; i < end; i++) {
        result = replaceCharAt(result, i, newValue.charAt(i));
      }
      newValue = result;
    }
    if (validate(newValue)) {
      if (newValue.charAt(end) === ":") end++;
      this.onChange(newValue, end);
    } else {
      var caretIndex =
        this.format(this.props.value).length - (newValue.length - end);
      this.setState({ caretIndex: caretIndex });
    }
  };
  render() {
    return (
      <TextField
        {...this.props}
        inputRef={this.input}
        type="text"
        value={this.format(this.props.value)}
        onKeyDown={this.keyDown}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default NLTimeField;
