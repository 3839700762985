import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { RootState } from "rx/store";
import {
  ExportContentTypes,
  ExportType,
  download,
  htmlEntities,
} from "utils/SmallUtils";
import { SlideUpTransition } from "utils/DialogUtils";
import { stringify } from "csv-stringify/sync";

const kplistToCsv = (state: RootState) => {
  const { kpList, kpAnswers, kpData } = state;

  return stringify(
    Object.entries(kpList).map(([kpid, kp]) => {
      const kpd = kpData[kpid] || {};
      return {
        id: kpid,
        nr_: kp.nr,
        raskusaste_: kp.ra,
        kohustuslik: kp.kohustuslik,
        desc: kpd.desc,
        longdesc: kpd.longdesc,
        location: kpd.loc ? `${kpd.loc.lat} ${kpd.loc.lng}` : undefined,
        tyhistatud: kp.tyhistatud,
        maxanswerdistance: kp.maxanswerdistance,
        allok: kp.allok,
        autokp: kp.autokp,
        wrongpenalty: kp.wrongpenalty,
        tahis: kpAnswers[kpid],
        responses: JSON.stringify(kpd.responses),
      };
    }),
    { bom: true, header: true }
  );
};

const kplistToJson = (state: RootState) => {
  return JSON.stringify({
    kpData: state.kpData,
    kpList: state.kpList,
    kpAnswers: state.kpAnswers,
  });
};

const kplistToGpx = (state: RootState) => {
  var gpxdoc = document.implementation.createDocument(null, "gpx");
  var gpx = gpxdoc.children[0];
  gpx.setAttribute("version", "1.1");
  gpx.setAttribute("creator", "trackerweb");
  gpx.setAttribute("xmlns", "http://www.topografix.com/GPX/1/1");
  gpx.setAttribute("xmlns:osmand", "https://osmand.net");
  gpx.setAttribute("xmlns:nutilogi", "https://nutilogi.eu");
  var gpxmetadata = gpxdoc.createElement("metadata");
  // TODO Add event owner as author.
  var tmp = gpxdoc.createElement("name");
  tmp.innerHTML = htmlEntities(state.event.name || "Unknown");
  gpxmetadata.appendChild(tmp);
  gpx.appendChild(gpxmetadata);
  const { kpList, kpAnswers, kpData } = state;
  for (let [i, kp] of Object.entries(kpList)) {
    var kpelement = gpxdoc.createElement("wpt");
    var symelement = gpxdoc.createElement("sym");
    var kpextensions = gpxdoc.createElement("extensions");
    tmp = gpxdoc.createElement("nutilogi:serverid");
    tmp.innerHTML = htmlEntities(i);
    kpextensions.appendChild(tmp);
    if (kp.maxanswerdistance) {
      tmp = gpxdoc.createElement("nutilogi:maxanswerdistance");
      tmp.innerHTML = kp.maxanswerdistance.toString();
      kpextensions.appendChild(tmp);
    }
    if (kp.tyhistatud) {
      tmp = gpxdoc.createElement("nutilogi:tyhistatud");
      tmp.innerHTML = "true";
      kpextensions.appendChild(tmp);
    }
    if (kp.kohustuslik) {
      tmp = gpxdoc.createElement("nutilogi:kohustuslik");
      tmp.innerHTML = "true";
      kpextensions.appendChild(tmp);
    }
    if (kp.allok) {
      tmp = gpxdoc.createElement("nutilogi:allok");
      tmp.innerHTML = "true";
      kpextensions.appendChild(tmp);
    }
    tmp = gpxdoc.createElement("osmand:icon");
    tmp.innerHTML =
      kp.nr === "F"
        ? "special_flag_finish"
        : kp.allok
          ? "special_flag"
          : "special_flag_stroke";
    kpextensions.appendChild(tmp);

    const raval = kp.ra?.toString();
    if (raval === "3" || raval === "2") {
      tmp = gpxdoc.createElement("osmand:background");
      tmp.innerHTML = raval === "3" ? "square" : "octagon";
      kpextensions.appendChild(tmp);
    }
    tmp = gpxdoc.createElement("osmand:color");
    tmp.innerHTML =
      raval === "3" ? "#ee0290" : raval === "2" ? "#021aee" : "#008b00";
    kpextensions.appendChild(tmp);

    tmp = gpxdoc.createElement("name");
    tmp.innerHTML = htmlEntities(kp.nr.toString());
    kpelement.appendChild(tmp);
    const racol = kp.inwork ? "Blue" : "Red";
    if (kp.ra === 1) symelement.innerHTML = `Circle, ${racol}`;
    else if (kp.ra === 2) symelement.innerHTML = `Triangle, ${racol}`;
    else if (kp.ra === 3) symelement.innerHTML = `Rectangle, ${racol}`;
    else symelement.innerHTML = kp.ra?.toString() || "Circle, Red";
    if (i in kpAnswers) {
      tmp = gpxdoc.createElement("cmt");
      tmp.innerHTML = htmlEntities(kpAnswers[i]);
      kpelement.appendChild(tmp);
    }
    const kpd = kpData[i];
    if (kpd) {
      if (kpd.loc) {
        kpelement.setAttribute("lat", kpd.loc.lat.toString());
        kpelement.setAttribute("lon", kpd.loc.lng?.toString());
      }
      if (kpd.desc && kpd.desc.length !== 0) {
        tmp = gpxdoc.createElement("desc");
        tmp.innerHTML = htmlEntities(kpd.desc);
        kpelement.appendChild(tmp);
      }
      if (kpd.responses) {
        let tmp = gpxdoc.createElement("nutilogi:responses");
        Object.entries(kpd.responses).forEach(([id, v]) => {
          let resp = gpxdoc.createElement("nutilogi:response");
          resp.setAttribute("id", id);
          resp.innerHTML = htmlEntities(v);
          tmp.appendChild(resp);
        });
        kpextensions.appendChild(tmp);
      }
    }
    kpelement.appendChild(symelement);
    kpelement.appendChild(kpextensions);
    gpx.appendChild(kpelement);
  }
  var serializer = new XMLSerializer();
  return serializer.serializeToString(gpx);
};

export const KPDataExporter: React.FC<{
  type: ExportType;
  onComplete: () => void;
}> = (props) => {
  const state = useStore<RootState>().getState();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const handler = (
      {
        gpx: [kplistToGpx, ExportContentTypes.gpx],
        json: [kplistToJson, ExportContentTypes.json],
        csv: [kplistToCsv, ExportContentTypes.csv],
      } as { [key: string]: [(state: RootState) => string, string] }
    )[props.type];

    download(
      handler[0](state),
      state.event.name + "_kps." + props.type,
      handler[1]
    );

    props.onComplete();
    snackbar.enqueueSnackbar(t("exportkps.completed", { type: props.type }), {
      variant: "info",
      persist: false,
    });
  }, [props, state, snackbar, t]);
  return null;
};

const KPDataExportHandler: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const [type, setType] = useState(null as ExportType | null);
  const { t } = useTranslation();

  const exportKPs = (type: ExportType) => () => {
    handleClose();
    setType(type);
  };
  return (
    <>
      {type !== null && (
        <KPDataExporter
          type={type}
          onComplete={() => {
            setType(null);
          }}
        />
      )}
      <Dialog
        TransitionComponent={SlideUpTransition}
        open={open && type === null}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Trans>kpexport.chooseformattitle</Trans>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ButtonGroup
              orientation="vertical"
              variant="contained"
              aria-label="vertical contained primary button group"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={exportKPs("json")}
              >
                JSON
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={exportKPs("csv")}
              >
                CSV
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={exportKPs("gpx")}
              >
                GPX
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t("button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KPDataExportHandler;
