import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { TrackPoint } from "rx/tracksSlice";
import * as L from "leaflet";

const mapState = (state: RootState, props: TrackTailOwnProps) => ({
  color: state.teamsList[props.tid]?.col,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TrackTailOwnProps = {
  tid: string;
  firstidx: number;
  lastidx: number;
  track: TrackPoint[];
  map: L.Map;
};
type TrackTailProps = PropsFromRedux & TrackTailOwnProps;
type TrackTailState = {};
class TrackTail extends React.Component<TrackTailProps, TrackTailState> {
  state: TrackTailState = {};

  polyline?: L.Polyline;

  componentDidMount() {
    this.polyline = new L.Polyline(
      this.props.track.slice(this.props.firstidx, this.props.lastidx),
      { color: this.props.color || "black" }
    );
    this.props.map.addLayer(this.polyline);
  }

  componentDidUpdate(prevProps: TrackTailProps) {
    if (!this.polyline) return;
    if (
      prevProps.firstidx !== this.props.firstidx ||
      prevProps.lastidx !== this.props.lastidx
    ) {
      let trackLatLngs: TrackPoint[] | L.LatLng[] = [];
      if (this.props.lastidx < prevProps.firstidx) {
        trackLatLngs = this.props.track.slice(
          this.props.firstidx,
          this.props.lastidx + 1
        );
      } else {
        trackLatLngs = this.polyline.getLatLngs() as L.LatLng[];

        let prevlastidx = prevProps.lastidx;
        while (prevlastidx < this.props.lastidx) {
          trackLatLngs.push(L.latLng(this.props.track[prevlastidx++]));
        }
        while (prevlastidx > this.props.lastidx) {
          trackLatLngs.pop();
          prevlastidx--;
        }
        let prevfirstidx = prevProps.firstidx;
        while (prevfirstidx < this.props.firstidx) {
          trackLatLngs.shift();
          prevfirstidx++;
        }
        while (prevfirstidx > this.props.firstidx) {
          trackLatLngs.unshift(L.latLng(this.props.track[--prevfirstidx]));
        }
      }
      this.polyline.setLatLngs(trackLatLngs);
    }
  }
  componentWillUnmount() {
    if (this.polyline) this.props.map.removeLayer(this.polyline);
  }
  render() {
    return null;
  }
}

export default connector(TrackTail);
