import React from "react";
import { useTSelector } from "rx/store";
import { Box, Button, DialogContent } from "@mui/material";
import { argouid } from "utils/SmallUtils";
import { child, getDatabase, ref, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { deleteField, doc, getFirestore, updateDoc } from "firebase/firestore";
import AdminsAutocomplete from "./AdminsAutocomplete";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";

const ArgoAdminBox: React.FC<{ eventId: string }> = ({ eventId }) => {
  const admins = useRFirebaseDb<{ [uid: string]: string }>(
    `events/${eventId}/admins`
  );

  const haveArgo =
    admins && Object.keys(admins).filter((u) => u === argouid).length > 0;
  if (haveArgo) return null;
  return (
    <Box p={1}>
      <Button
        onClick={() => {
          updateDoc(doc(getFirestore(), "events", eventId), {
            ["admins." + argouid]: "Argo Vessmann",
          });
          set(
            child(ref(getDatabase(), `events/${eventId}/admins`), argouid),
            "Argo Vessmann"
          );
        }}
        variant="contained"
        color="primary"
      >
        Add me (Argo)
      </Button>
    </Box>
  );
};

const AccessRightsDialog: React.FC = () => {
  const eventId = useTSelector((state) => state.eventId || "evid");
  const adminpath = `events/${eventId}/admins`;
  return (
    <DialogContent>
      <AdminsAutocomplete
        adminpath={adminpath}
        onChange={(uid, val, reason) => {
          // AdminsAutoComplete is used also in cup rights managment, thats why doc updated is here.
          if (reason === "selectOption") {
            updateDoc(doc(getFirestore(), "events", eventId), {
              ["admins." + uid]: val,
            }).catch((e) => {
              console.error("got error", e);
            });
          } else if (reason === "removeOption" || reason === "clear") {
            updateDoc(
              doc(getFirestore(), "events", eventId),
              "admins." + uid,
              deleteField()
            );
          }
        }}
      />
      {argouid === getAuth().currentUser?.uid && (
        <ArgoAdminBox eventId={eventId} />
      )}
    </DialogContent>
  );
};

export default AccessRightsDialog;
