import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tooltip,
} from "@mui/material";
import { CommentOutlined } from "@mui/icons-material";
import { withNL, WithNLProps } from "utils/NLContext";
import {
  child,
  DatabaseReference,
  getDatabase,
  onValue,
  ref,
  Unsubscribe,
} from "firebase/database";

type EventSettingsNewFeaturesProps = WithNLProps & {
  eventId: string;
};
type EventSettingsNewFeaturesState = {
  features: {
    [fkey: string]: {
      label?: string;
      secondary?: string;
      tooltip?: string;
    };
  };
  conf: {
    [fkey: string]: boolean;
  };
};
class EventSettingsNewFeatures extends React.Component<
  EventSettingsNewFeaturesProps,
  EventSettingsNewFeaturesState
> {
  state: EventSettingsNewFeaturesState = {
    features: {},
    conf: {},
  };
  unsubscribeFeatureRef?: Unsubscribe;
  unsubscribeConfRef?: Unsubscribe;
  confref: DatabaseReference;
  constructor(props: EventSettingsNewFeaturesProps) {
    super(props);
    this.confref = ref(getDatabase(), `/eventsdata/${this.props.eventId}/conf`);
  }

  componentDidMount() {
    const db = getDatabase();
    this.unsubscribeFeatureRef = onValue(
      ref(db, "/sysconf/newfeatures"),
      (snap) => {
        this.setState({ features: snap.val() || {} });
      }
    );
    this.unsubscribeConfRef = onValue(this.confref, (snap) => {
      this.setState({ conf: snap.val() || {} });
    });
  }
  componentWillUnmount() {
    this.unsubscribeConfRef && this.unsubscribeConfRef();
    this.unsubscribeFeatureRef && this.unsubscribeFeatureRef();
  }
  toggleFeature =
    (fid: string) =>
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      this.props.fbSet(child(this.confref, fid), checked);
    };
  render() {
    return (
      <List sx={{ maxWidth: 400 }}>
        {Object.entries(this.state.features)
          .filter(([fid]) => fid !== "sample")
          .map(([fid, fdata]) => (
            <ListItem key={fid}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(this.state.conf[fid])}
                  onChange={this.toggleFeature(fid)}
                />
              </ListItemIcon>
              <ListItemText primary={fdata.label} secondary={fdata.secondary} />
              {fdata.tooltip && (
                <ListItemSecondaryAction>
                  <Tooltip
                    key={fid}
                    title={
                      <React.Fragment>
                        <div
                          dangerouslySetInnerHTML={{ __html: fdata.tooltip }}
                        />
                      </React.Fragment>
                    }
                  >
                    <CommentOutlined />
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
      </List>
    );
  }
}

export default withNL(EventSettingsNewFeatures);
