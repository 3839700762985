import {
  CaseReducer,
  createSlice,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit";
import { DocumentData, GeoPoint } from "firebase/firestore/lite";
import { LatLngLiteral } from "leaflet";

export type AreaObjectType = {
  name: string;
  area: GeoPoint[];
  restricted?: boolean;
  limit?: number;
  timed?: boolean;
};

export const isPointInArea = (
  point: LatLngLiteral,
  areaObject: AreaObjectType
) => {
  const polyPoints = areaObject.area;
  var x = point.lat,
    y = point.lng;

  var inside = false;
  for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    var xi = polyPoints[i].latitude,
      yi = polyPoints[i].longitude;
    var xj = polyPoints[j].latitude,
      yj = polyPoints[j].longitude;

    // Formatting changes this: ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    var intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      
    if (intersect) inside = !inside;
  }

  return inside;
};

export interface EventDoc {
  admins: {
    [uid: string]: string;
  };
  speed?: {
    limit?: number;
    over1?: number;
    delay1?: number;
    over2?: number;
    delay2: number;
  };
  speedareas?: {
    [aid: string]: AreaObjectType;
  };
}

type FireStoreSliceActions<T> = {
  setDoc: CaseReducer<T, PayloadAction<DocumentData | null>>;
};

export type FireStoreSlice<T> = {
  path: string;
} & Slice<T, FireStoreSliceActions<T>, string>;

function fsDocSlice<T>(name: string, initialState: T | null, path: string) {
  return Object.assign(
    {
      path: path,
    },
    createSlice({
      name: name,
      initialState,
      reducers: {
        setDoc: (_state, action: PayloadAction<DocumentData | null>) => {
          return action.payload as T;
        },
      },
    })
  );
}

export const eventDocSlice = fsDocSlice<EventDoc>("eventdoc", null, "events");
