import { Check, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TeamData, TeamList } from "rx/fbListSlices";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { RegForm, fieldsFromRegData } from "./RegToEvent";
import { RegData } from "./EventSettingsRegistration";
import { useNL } from "utils/NLContext";
import { green, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { ExportContentTypes, download } from "utils/SmallUtils";
import { stringify } from "csv-stringify/sync";
import Grid2 from "@mui/material/Unstable_Grid2";
import { child, getDatabase, ref, set } from "firebase/database";
import { SearchField } from "./SearchField";
import { useTeamDelete } from "./TeamDialog";

const LubaButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "allowed",
})<ButtonProps & { allowed: boolean }>(({ theme, allowed }) => ({
  color: theme.palette.getContrastText(allowed ? green[500] : red[500]),
  backgroundColor: allowed ? green[500] : red[500],
  "&:hover": {
    backgroundColor: allowed ? green[700] : red[700],
  },
}));

type RowType = { [k: string]: string } & { regtime: number; allowed: boolean };

const DownloadButton: React.FC<{
  rows: RowType[];
  cols: { field: string; svals: string[] }[];
  splitmulties: boolean;
}> = ({ rows, cols, splitmulties }) => {
  const evname = useTSelector((state) => state.event.name);
  const { t } = useTranslation();
  const triggerDownload = useCallback(
    (r: RowType[], c: { field: string; svals: string[] }[], split: boolean) => {
      let columns = ["registered_time"];
      c.forEach((cv, idx) => {
        if (splitmulties && cv.svals.length > 1) {
          cv.svals.forEach((sv) => columns.push(cv.field + " - " + sv));
          r.forEach((rv) => {
            rv[cv.field]
              ?.split(",")
              .forEach((e) => (rv[cv.field + " - " + e] = "Yes"));
          });
        } else {
          columns.push(cv.field);
        }
      });
      download(
        stringify(
          r.map((r) =>
            Object.assign(
              {
                registered_time:
                  r.regtime !== Number.MAX_VALUE && dayjs(r.regtime).format(),
              },
              r
            )
          ),
          {
            columns,
            header: true,
            bom: true,
          }
        ),
        `reg_${evname}.csv`,
        ExportContentTypes.csv
      );
    },
    [evname]
  );
  if (!evname) return null;
  return (
    <Button
      variant="contained"
      onClick={() => triggerDownload(rows, cols, splitmulties)}
    >
      {t("button.export")}
    </Button>
  );
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TeamDeleteButton: React.FC<{ tid: string }> = ({ tid }) => {
  const deleteTeam = useTeamDelete(tid);
  const { t } = useTranslation();
  return (
    <Button variant="contained" onClick={deleteTeam}>
      {t("button.delete")}
    </Button>
  );
};

const EditEntry: React.FC<{
  tid?: string;
  onClose: () => void;
  regdata: RegData;
  data: RowType | undefined;
}> = ({ tid, onClose, regdata, data }) => {
  const { t } = useTranslation();
  const evid = useTSelector((s) => s.eventId);
  const { fbUpdate } = useNL();
  const [cmnt, setCmnt] = useState<string | undefined>(
    data ? data["orgcomment"] : undefined
  );
  if (data === undefined) return null;
  const fields = structuredClone(fieldsFromRegData(regdata, t));
  fields["teamname"].defaultvalue = data["teamname"];
  Object.entries(fields).forEach(([f, v]) => {
    if (v.muitype === "boolean") v.defaultvalue = data[f] === "on";
    else if (data[f]) v.defaultvalue = data[f];
  });
  return (
    <Dialog open={tid !== undefined} onClose={onClose}>
      <DialogTitle>Edit team registration </DialogTitle>
      <DialogContent>
        <Grid2 container spacing={2}>
          <Grid2 display="flex" justifyContent="center" xs={12}>
            <TextField
              label={t("label.orgcmnt" as any)}
              variant="outlined"
              value={cmnt || ""}
              onChange={(event) => setCmnt(event.target.value)}
            />
          </Grid2>
          <RegForm
            fields={fields}
            onSubmit={(newdata) => {
              if (evid === null) return;
              const db = getDatabase();
              const userarea = ref(db, `/teams/${evid}/data/${tid}`);

              let updatedata: { [k: string]: string | null } = {};
              Object.keys(fields).forEach((f) => {
                if (newdata[f] === data[f]) return;
                console.log(
                  "Field ",
                  f,
                  "changed old:",
                  data[f],
                  "new",
                  newdata[f]
                );
                updatedata[f] = newdata[f];
                if (
                  fields[f].muitype === "boolean" &&
                  newdata[f] === undefined
                ) {
                  updatedata[f] = null;
                }
                if (f === "teamname") {
                  set(
                    ref(db, `/teams/${evid}/list/${tid}/name`),
                    newdata["teamname"]
                  );
                }
              });
              if (Object.keys(updatedata).length > 0) {
                console.log("doing up", updatedata);
                fbUpdate(child(userarea, "regdata"), updatedata);
              }
              if (cmnt !== data["orgcomment"]) {
                set(child(userarea, "regdata/orgcomment"), cmnt);
              }
              onClose();
            }}
          />
        </Grid2>
      </DialogContent>
      <DialogActions>
        {tid && <TeamDeleteButton tid={tid} />}
        <div style={{ flex: "1 0 0" }} />
        <Button variant="contained" onClick={() => onClose()}>
          {t("button.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const form = document.getElementById(
              "regform"
            ) as HTMLFormElement | null;
            if (form) form.requestSubmit();
          }}
        >
          {t("button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const RegisteredTeams: React.FC = () => {
  const { t } = useTranslation();
  const evid = useTSelector((state) => state.eventId) || "dummyevent";
  const data = useRFirebaseDb<{ [tid: string]: TeamData }>(
    `/teams/${evid}/data`
  );
  const list = useRFirebaseDb<{ [tid: string]: TeamList }>(
    `/teams/${evid}/list`
  );
  const regdata = useRFirebaseDb<RegData>(
    `/eventsdata/${evid}/data/registration`
  );
  const [edittid, setEditTid] = useState<string>();
  const { fbSet } = useNL();
  const [useRows, setUserRows] = useState<RowType[]>([]);
  const [splitMulties, setSplitMulties] = useState(false);

  const setUserRowsCallback = useCallback(
    (rows: RowType[]) => setUserRows(rows),
    [setUserRows]
  );

  const rows = useMemo(() => {
    let rows: RowType[] = [];

    if (!list || !data) return rows;
    Object.entries(list).forEach(([tid, listdata]) => {
      rows.push(
        Object.assign(
          {
            id: tid,
            teamname: listdata.name || data[tid]?.regdata?.teamname || "-",
            allowed: listdata.allowed || false,
            regtime: Number(data[tid]?.regdata?.regtime) || Number.MAX_VALUE,
          },
          data[tid]?.regdata
        )
      );
    });
    rows.sort((l, r) => l.regtime - r.regtime);
    return rows;
  }, [data, list]);

  if (!list || !data || !regdata) return null;

  const columns = Object.entries(fieldsFromRegData(regdata, t))
    .sort(([_l, l], [_r, r]) => (l.s || 100) - (r.s || 100))
    .map(([f, v]) => {
      const svals = v.selectvalues?.split(",") ?? [];
      if (v.muitype === "multiple") {
        for (let r of rows) {
          r[f]?.split(",").forEach((v) => {
            if (!svals.includes(v)) {
              svals.push(v);
            }
          });
        }
      }
      return {
        field: f,
        headerName: v.label,
        ftype: v.muitype,
        svals,
        render: undefined as undefined | ((v: any) => string),
        smulti: v.muitype === "multiple" && splitMulties,
      };
    });
  columns.splice(1, 0, {
    field: "regtime",
    headerName: t("reg.field.regtime"),
    ftype: "text",
    svals: [],
    smulti: false,
    render: (v) =>
      v !== Number.MAX_VALUE
        ? dayjs(v).format("D MMM HH:mm").split(" ").join("\u00A0")
        : "",
  });

  const hasSmallMulti = columns.some((c) => c.smulti);
  const rsp = hasSmallMulti ? 2 : 0;
  return (
    <DialogContent
      style={{
        minHeight: "80vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Box>
          <SearchField
            items={rows}
            setNewList={setUserRowsCallback}
            sx={{ paddingBottom: 2 }}
            focused
            autoFocus
            variant="standard"
            label={t("teams.filter")}
          />
          <FormControlLabel
            control={
              <Switch
                value={splitMulties}
                onChange={(_, v) => setSplitMulties(v)}
              />
            }
            label="Split multi responses"
          />
        </Box>
        <Box sx={{ alignContent: "center" }}>
          <DownloadButton
            rows={useRows}
            splitmulties={splitMulties}
            cols={columns.map((c) => ({
              field: c.field,
              svals: c.ftype === "multiple" ? c.svals : [],
            }))}
          />
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="registrered table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow>
              <StyledTableCell rowSpan={rsp}>Nr.</StyledTableCell>
              <StyledTableCell rowSpan={rsp}></StyledTableCell>
              <StyledTableCell rowSpan={rsp}>
                {t("teamsallow.allowedheader")}
              </StyledTableCell>

              {columns.map((column) => (
                <StyledTableCell
                  rowSpan={column.smulti ? 1 : rsp}
                  colSpan={column.smulti ? column.svals?.length || 1 : 1}
                  key={column.field}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
              <StyledTableCell rowSpan={rsp}>
                {t("reg.field.orgcomment")}
              </StyledTableCell>
            </TableRow>
            {hasSmallMulti && (
              <TableRow>
                {columns
                  .filter((c) => c.smulti)
                  .map((c) =>
                    c.svals?.map((ce) => (
                      <StyledTableCell key={ce}>{ce}</StyledTableCell>
                    ))
                  )}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {useRows.map((row, idx) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setEditTid(row.id)}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <LubaButton
                    variant="contained"
                    allowed={row.allowed}
                    onClick={() => {
                      fbSet(
                        "/teams/" + evid + "/list/" + row.id + "/allowed",
                        !row.allowed
                      );
                    }}
                  >
                    {row.allowed
                      ? t("teamsallow.button.allow")
                      : t("teamsallow.button.notallow")}
                  </LubaButton>
                </TableCell>
                {columns.map((column) => {
                  const value = row[column.field];
                  if (column.render)
                    return (
                      <TableCell key={column.field}>
                        {column.render(value)}
                      </TableCell>
                    );
                  if (column.smulti) {
                    return (column.svals || []).map((s) => (
                      <TableCell key={s}>
                        {value?.includes(s) && (
                          <Tooltip title={s}>
                            <Check />
                          </Tooltip>
                        )}
                      </TableCell>
                    ));
                  }
                  return (
                    <TableCell key={column.field}>
                      {column.ftype === "multiple" ? (
                        <List dense>
                          {value
                            ?.split(",")
                            .map((e) => <ListItem>{e}</ListItem>)}
                        </List>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
                <TableCell>{row["orgcomment"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditEntry
        key={edittid}
        tid={edittid}
        onClose={() => setEditTid(undefined)}
        regdata={regdata}
        data={rows.find((r) => r.id === edittid)}
      />
    </DialogContent>
  );
};

export default RegisteredTeams;
