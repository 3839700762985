import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";
import { useMap, WMSTileLayer } from "react-leaflet";
import React, { useEffect, useState } from "react";
import "proj4leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import FBSelect from "components/FBSelect";

/*
var crs_epsg_3301a = new L.Proj.CRS(
  "EPSG:3301",
  "+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  {
    resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5],
  }
);
*/

const crs_epsg_3301 = new L.Proj.CRS(
  "EPSG:3301",
  "+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  {
    resolutions: [
      4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625,
      1.953125, 0.9765625, 0.48828125, 0.244140625, 0.122070313, 0.061035156,
      0.030517578, 0.015258789,
    ],
    origin: [40500, 5993000],
    bounds: L.bounds([40500, 5993000], [1064500, 7017000]),
  }
);

export const NutimeriLayer: React.FC = () => {
  const map = useMap();

  console.log("enne settings crs", map.options.crs);
  return (
    <WMSTileLayer
      url="https://gis.vta.ee/primar/wms_ip/TranspordiametNutimeri?"
      params={{ layers: "cells" }}
      minZoom={10}
    />
  );
};
/* WMS does not work as well as tile layer.
const MaaAmetLayerWMS: React.FC = () => {
  const map = useMap();

 
  console.log("enne settings crs", map.options.crs);
  map.options.crs = crs_epsg_3301;
  map.setView([58.66, 25.05], 2);
  return (
    <WMSTileLayer
      url="https://kaart.maaamet.ee/wms/alus"
      params={{ layers: "MA-ALUS" }}
      minZoom={10}
    />
  );
};
*/

type MaaAmetConf = {
  title?: string;
  template?: string;
  tilematrixset?: string;
  abstract?: string;
  identifier?: string;
  bounds?: string;
};

export const MaaametVariants: React.FC<{ eventId: string }> = ({ eventId }) => {
  const [items, setItems] = useState<{ value: string; desc: string }[]>([]);

  useEffect(() => {
    fetch("https://tiles.maaamet.ee/tm/wmts/1.0.0/WMTSCapabilities.xml")
      .then((r) => r.text())
      .then((tm) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(tm, "text/xml");
        const tilemaps = Array.from(doc.getElementsByTagName("Layer"));
        const items: { value: string; desc: string }[] = [];
        tilemaps.forEach((tile) => {
          let conf: MaaAmetConf = {};
          Array.from(tile.children).forEach((element) => {
            if (element.nodeName === "ows:Title")
              conf.title = element.textContent || undefined;
            else if (element.nodeName === "ResourceURL") {
              conf.template = element.getAttribute("template") || undefined;
            } else if (element.nodeName === "ows:Abstract") {
              conf.abstract = element.textContent || undefined;
            } else if (element.nodeName === "ows:Identifier") {
              conf.identifier = element.textContent || undefined;
            } else if (element.nodeName === "ows:WGS84BoundingBox") {
              const corners: string[] = [];
              Array.from(element.children).forEach((corner) => {
                if (!corner.textContent) return;
                corners.push(corner.textContent);
              });
              if (corners.length === 2) {
                conf.bounds = corners.join(",");
              }
            } else if (element.nodeName === "TileMatrixSetLink") {
              conf.tilematrixset =
                element.getElementsByTagName("TileMatrixSet")[0].textContent ||
                undefined;
            }
          });
          const matrixes1 = Array.from(
            tile.getElementsByTagName("TileMatrixSetLink")
          );
          matrixes1.forEach((m) => {
            conf.tilematrixset =
              m.getElementsByTagName("TileMatrixSet")[0].textContent ||
              undefined;
            items.push({
              value: JSON.stringify(conf),
              desc:
                conf.title + (conf.tilematrixset === "GMC" ? " GMC" : "") ||
                "-- Unknown --",
            });
          });
        });
        setItems(items);
      });
  }, [setItems]);
  if (items.length === 0) return null;

  return (
    <FBSelect
      id="leafletvariant"
      path={"eventsdata/" + eventId + "/data/map/default/conf"}
      items={items}
      defaultvalue=""
    />
  );
};

const MaaAmetLayer: React.FC<{ conf: string }> = ({ conf }) => {
  const map = useMap();
  const leafletContext = useLeafletContext();
  useEffect(() => {
    const confobject = JSON.parse(conf) as MaaAmetConf;
    let minZoom = 0;
    let maxZoom = 18;

    const changeCRS = (crs: L.CRS) => {
      // We detect if map is removed with follogin two lines.
      // Can't call getBounds if map is removed. Maybe this will be fixed someday in leaflet
      const panes = map.getPanes();
      if (Array.isArray(panes) && panes.length === 0) return;

      const bounds = map.getBounds();
      map.options.crs = crs;
      map.fitBounds(bounds, { animate: false });
    };
    const oldcrs = map.options.crs;
    if (confobject.tilematrixset === "GMC") {
      minZoom = 6;
      if (confobject.identifier === "kaart") minZoom = 4;
    } else if (confobject.tilematrixset === "LEST") {
      changeCRS(crs_epsg_3301);
    }
    const matches = (confobject.abstract || "").match(/\(z(\d*) - z(\d*)\)/);
    if (matches) {
      minZoom = Number(matches[1]);
      maxZoom = Number(matches[2]);
    }
    let template = (confobject.template || "")
      .replace("{TileMatrixSet}", confobject.tilematrixset || "")
      .replace("{TileMatrix}", "{z}")
      .replace("{TileRow}", "{y}")
      .replace("{TileCol}", "{x}");
    let tmsvalue = false;
    if (confobject.tilematrixset === "LEST") {
      // Hacking to tms , because WMTS LEST maps seem to be broken.
      // Not sure if problem is in EPRSG:3301 projection, leaflet, or maaamet server.
      template = template
        .replace("wmts", "tms")
        .replace(
          confobject.identifier + "/default/LEST",
          confobject.identifier + "@LEST"
        )
        .replace("{y}", "dummy")
        .replace("{x}", "{y}")
        .replace("dummy", "{x}");
      tmsvalue = true;
    }
    let boundsvalue: L.LatLngBounds | undefined = undefined;
    if (confobject.bounds) {
      const corners = confobject.bounds.split(",");
      if (corners.length === 2) {
        const [x1, y1] = corners[0].split(" ");
        const [x2, y2] = corners[1].split(" ");
        if (
          x1 !== undefined &&
          x2 !== undefined &&
          y1 !== undefined &&
          y2 !== undefined
        ) {
          boundsvalue = L.latLngBounds(
            [Number(y1), Number(x1)],
            [Number(y2), Number(x2)]
          );
        }
      }
    }
    const leafletlayer = new L.TileLayer(
      template + "&ASUTUS=Nutilogi&KESKKOND=LIVE&IS=portal",
      {
        tms: tmsvalue,
        attribution: "Map: <a  href='http://www.maaamet.ee/'>Maad-amet</a>",
        maxZoom: maxZoom,
        minZoom: minZoom,
        zIndex: 2,
        bounds: boundsvalue,
        updateWhenIdle: false,
      }
    );
    if (leafletContext.layerContainer) {
      leafletContext.layerContainer.addLayer(leafletlayer);
    } else leafletlayer.addTo(map);

    return () => {
      if (leafletContext.layerContainer) {
        leafletContext.layerContainer.removeLayer(leafletlayer);
      }
      leafletlayer.remove();
      if (confobject.tilematrixset === "LEST" && oldcrs) {
        changeCRS(oldcrs);
      }
    };
  }, [map, conf, leafletContext.layerContainer]);

  return null;
};

export default MaaAmetLayer;
