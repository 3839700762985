import {
  get,
  getDatabase,
  ref,
  set,
  onValue,
  onChildAdded,
  onChildChanged
} from "firebase/database";

const i18nNutilogiBackend = {
  type: "backend",
  havefbdata: false,
  fblocale: function (snap) {
    snap.forEach((ns) => {
      this.services.resourceStore.addResourceBundle(
        snap.key,
        ns.key,
        ns.val(),
        true,
        true
      );
    });
  },
  init: function (services, _backendOptions, _i18nextOptions) {
    if (process.env.JEST_WORKER_ID !== undefined) {
      this.create = () => {};
      return;
    }
    this.services = services;
    this.fblocale = this.fblocale.bind(this);
    this.localeref = ref(getDatabase(), "/locales");
    onChildAdded(this.localeref, this.fblocale);
    onChildChanged(this.localeref, this.fblocale);
    onValue(this.localeref, () => {
      this.havefbdata = true;
    }, {onlyOnce: true});
    services.resourceStore.addResourceBundle(
      "et-EE",
      "translation",
      {
        evset: {
          title: {
            classes: "Klassid",
          },
        },
      },
      true,
      true
    );
    /* use services and options */
  },
  read: function (_language, _namespace, callback) {
    /* return resources */
    callback(null, {});

    /* if method fails/returns an error, call this: */
    /* callback(truthyValue, null); */
  },

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
    get(this.localeref).then((snap) => {
      languages.forEach((lng) => {
        const keysnap = snap
          .child(lng)
          .child(namespace)
          .child(key.replaceAll(".", "/"));
        if (keysnap.exists()) return;
        set(keysnap.ref, fallbackValue);
      });
    });
  },
};
export default i18nNutilogiBackend;
