import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { User } from "firebase/auth";

interface UserState {
  user?: User;
  eventAccess?: string;
}

const initialState: UserState = {};

export const userSlice = createSlice({
  name: "eventid",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setEventAccess: (state, action) => {
      state.eventAccess = action.payload;
    },
  },
});

export const { setEventAccess, setUser } = userSlice.actions;

export const setUser2 = (
  user: User | null
): ThunkAction<void, RootState, unknown, Action<string>> => (dispatch) => {
  // Add setting the value of eventAccess
  dispatch(userSlice.actions.setUser(user));
};

export default userSlice.reducer;
