import React from "react";
import * as L from "leaflet";
import "utils/leaflet.rotatedMarker";
import { TrackPoint } from "rx/tracksSlice";

export type NutiId = {
  type: string;
  id: string;
};
type ArrowMarkerProps = {
  className?: string;
  loc: TrackPoint;
  map: L.Map;
  color?: string;
  label?: string;
  title?: string;
  nutiid?: NutiId;
};
type ArrowMarkerState = {};
class ArrowMarker extends React.Component<ArrowMarkerProps, ArrowMarkerState> {
  state: ArrowMarkerState = {};
  marker?: L.Marker;
  markerOptions(size: number, heading: number, color: string) {
    return Object.assign(
      {
        icon: L.divIcon({
          iconSize: [size, size],
          iconAnchor: [size / 2, size / 2],
          className: "mymarker",
          html: `<svg xmlns="http://www.w3.org/2000/svg" fill="${color}" viewBox="0 0 32 32"><path d="M 2,32 L 16,0 L 30,32 L 16,25 Z"/></svg>`,
        }),
        rotationAngle: heading,
      },
      this.props.title ? { title: this.props.title } : {}
    );
  }

  componentDidMount() {
    this.createMarker();
  }
  createMarker() {
    if (this.marker) {
      this.props.map.removeLayer(this.marker);
    }
    this.marker = L.marker(
      this.props.loc,
      this.markerOptions(25, this.props.loc.g + 0, this.props.color || "blue")
    );
    if (this.props.nutiid) (this.marker as any).nutiid = this.props.nutiid;
    if (this.props.label) {
      this.marker.bindTooltip(
        `<div style="color:${this.props.color || "blue"}">${
          this.props.label
        }</div>`,
        {
          permanent: true,
          offset: [10, -10],
          className: "ArrowHeadTooltip",
        }
      );
    }
    this.props.map.addLayer(this.marker);
  }
  componentDidUpdate(prevProps: ArrowMarkerProps) {
    if (
      this.props.color !== prevProps.color ||
      this.props.label !== prevProps.label
    ) {
      this.createMarker();
      return;
    }
    if (this.props.loc !== prevProps.loc) {
      this.marker?.setLatLng(this.props.loc);
      (this.marker?.options as any).rotationAngle = this.props.loc.g;
      (this.marker as any)?.update();
    }
  }
  componentWillUnmount() {
    if (this.marker) this.props.map.removeLayer(this.marker);
  }
  render() {
    return null;
  }
}

export default ArrowMarker;
