import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import * as L from "leaflet";
import { LeafletContext } from "@react-leaflet/core";
import TrackDataDrawer from "components/TrackDataDrawer";
import ArrowMarker from "./ArrowMarker";
import { TrackPoint } from "rx/tracksSlice";
import { SpdTickets } from "rx/fbListSlices";

//type TicketType = RootState["spdTickets"][string][string][string];

const mapState = (state: RootState) => ({
  speedranges: state.event.speedranges || [
    { s: 10, c: "#b4e7ff" },
    { s: 30, c: "#00aeff" },
    { s: 50, c: "#0ec21b" },
    { s: 70, c: "#ffc27a" },
    { s: 80, c: "#fa8411" },
    { s: 90, c: "#e30000" },
    { s: 110, c: "#f600ff" },
  ],
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SpeedTrackOwnProps = {
  track: TrackPoint[];
  currentTicketIndex?: number;
  tickets?: SpdTickets;
  map: L.Map;
};
type SpeedTrackProps = PropsFromRedux & SpeedTrackOwnProps;
type SpeedTrackState = {
  currentPointIdx?: number;
};
class SpeedTrack extends React.Component<SpeedTrackProps, SpeedTrackState> {
  static contextType = LeafletContext;
  state: SpeedTrackState = {};
  polylines: L.Polyline[] = [];
  trackpoints: L.LatLng[] = [];

  createTracks() {
    const { map, track, speedranges } = this.props;
    this.polylines.forEach((p) => map.removeLayer(p));
    let si: number | undefined = undefined;
    let sidx = 0;
    for (let i = 0; i < track.length; i++) {
      const spd = track[i].s;
      if (si === undefined) {
        for (si = 0; si < speedranges.length && spd > speedranges[si].s; si++);
        if (si === speedranges.length) si--;
        sidx = i;
        continue;
      }
      if (spd <= speedranges[si].s && (si === 0 || speedranges[si - 1].s < spd))
        continue;
      // Speed range change
      const trackidx = sidx;

      const polyline = new L.Polyline(track.slice(sidx, i + 1), {
        color: speedranges[si].c,
        weight: 5,
        lineCap: "square",
      });
      sidx = i;

      polyline.on("click", (event) => {
        const ev = event as L.LeafletMouseEvent;
        const target = ev.target as L.Polyline;
        let closestpoint: L.LatLng | undefined = undefined;
        let closestdistance: number = 0;
        let pointidx = trackidx;
        target.getLatLngs().forEach((ll: any, index: number) => {
          if (!(ll instanceof L.LatLng)) return;
          if (closestpoint === undefined) {
            closestpoint = ll;
            closestdistance = ll.distanceTo(ev.latlng);
          } else {
            const newdst = ll.distanceTo(ev.latlng);
            if (newdst < closestdistance) {
              pointidx = trackidx + index;
              closestdistance = newdst;
              closestpoint = ll;
            }
          }
        });

        this.setState({ currentPointIdx: pointidx });
      });
      this.polylines.push(polyline);

      if (spd > speedranges[si].s) {
        for (si++; si < speedranges.length && spd > speedranges[si].s; si++);
        if (si === speedranges.length) si--;
        continue;
      }
      for (si--; si > 0 && spd < speedranges[si - 1].s; si--);
    }
    if (si !== undefined)
      this.polylines.push(
        new L.Polyline(track.slice(sidx, track.length + 1), {
          color: speedranges[si].c,
        })
      );
    this.polylines.forEach((p) => map.addLayer(p));
  }
  componentDidMount() {
    this.createTracks();
  }
  componentWillUnmount() {
    this.polylines.forEach((p) => this.props.map.removeLayer(p));
  }

  componentDidUpdate(prevProps: SpeedTrackProps) {
    // TODO Should add optimization, for example when only added points.
    if (this.props.track.length !== prevProps.track.length) {
      this.polylines.forEach((p) => this.props.map.removeLayer(p));
      this.polylines = [];

      this.createTracks();
    }
  }
  render() {
    return (
      <>
        {this.state.currentPointIdx && (
          <ArrowMarker
            map={this.props.map}
            loc={this.props.track[this.state.currentPointIdx]}
          />
        )}
        <TrackDataDrawer
          track={this.props.track}
          tickets={this.props.tickets}
          ticketIndex={this.props.currentTicketIndex}
          currentIndex={this.state.currentPointIdx}
        />
        ;
      </>
    );
  }
}

export default connector(SpeedTrack);
