import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import { addPoints } from "rx/tracksSlice";
import {
  StorageReference,
  getDownloadURL,
  getStorage,
  ref,
  listAll,
} from "firebase/storage";
import { PointData } from "@nutilogi/nutilogitypes";

export const declocdata = (buf: Uint8Array) => {
  let idx = 0;
  let jend = 0;
  let entries = [];

  while ((jend = buf.indexOf(0, idx)) !== -1) {
    const h = JSON.parse(new TextDecoder("utf-8").decode(buf.slice(idx, jend)));
    //console.log(jend);
    //console.log(h)
    idx = jend + 1;
    h.tidx = idx + h.tstart;
    h.sidx = idx + h.sstart;
    h.aidx = idx + h.astart;
    h.lidx = idx + h.lstart;
    h.gidx = idx + h.gstart;
    h.lonidx = idx + h.lonstart;
    h.latidx = idx + h.latstart;
    let getnum = (f: string) => {
      let b = 0;
      let v = 0;
      let pos = 0;
      do {
        b = buf[h[f + "idx"]];
        if (b !== 0) h[f + "idx"]++;
        v = v | ((b & 0x7f) << (7 * pos));
        pos++;
      } while (b & 0x80);
      if (v === 0) {
        const zc = (buf[h[f + "idx"] + 1] -= 1);
        if (zc === 0) h[f + "idx"] += 2;
      }
      if (v & 0x1) v = ~v;
      v = v >> 1;
      //console.log(v/h[f+'prec']);
      return v / h[f + "prec"];
    };
    let objects = [];
    let prevob: PointData = {
      x: 0,
      y: 0,
      t: Number(h.firstt - 1000),
      s: 0,
      a: 0,
      g: 0,
      l: 0,
    };
    for (let i = 0; i < h.count; i++) {
      const nob = {
        y: prevob.y + getnum("lat"),
        x: prevob.x + getnum("lon"),
        t: prevob.t + getnum("t") + 1000,
        s: prevob.s + getnum("s"),
        a: prevob.a + getnum("a"),
        g: prevob.g + getnum("g"),
        l: prevob.l + getnum("l"),
      };
      objects.push(nob);
      prevob = nob;
    }
    idx = h.lonidx;

    objects.forEach((p) => (p.s = (p.s * 3600) / 1000));
    entries.push({ tid: h.tid, devid: h.devid, points: objects });
  }
  return entries;
};

const mapState = (state: RootState) => ({});
const mapDispatch = {
  addPoints: (tid: string, devid: string, points: PointData[]) =>
    addPoints({ tid, devid, points }),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type OldTracksDataListenerProps = PropsFromRedux & {
  eventId: string;
};
type OldTracksDataListenerState = {};
class OldTracksDataListener extends React.Component<
  OldTracksDataListenerProps,
  OldTracksDataListenerState
> {
  state: OldTracksDataListenerState = {};
  componentDidMount() {
    this.readInitalTracks();
  }

  getTrack = (fref: StorageReference) => {
    return getDownloadURL(fref).then((url) => {
      return fetch(url)
        .then((r) => r.arrayBuffer())
        .then((fdata) => {
          let fview = new Uint8Array(fdata);
          const locdata = declocdata(fview);
          locdata.forEach((ent) => {
            const points = ent.points;
            if (points.length === 0) return;
          });
          return locdata;
        });
    });
  };

  tracklisteners = {};
  readInitalTracks = async () => {
    const { eventId } = this.props;
    this.tracklisteners = {};

    let loadedTracks: {
      [tid: string]: {
        [devid: string]: {};
      };
    } = {};
    const listing = await listAll(ref(getStorage(), `/tracks/${eventId}`));
    let finaltracksref = listing.items.find(
      (ent) => ent.name === "finaltracks.data"
    );
    if (finaltracksref !== undefined) {
      await getDownloadURL(finaltracksref).then((url) => {
        fetch(url)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            //console.log('pref buf', Date.now() - startd);
            var view = new Uint8Array(data);
            //console.log('got data', Date.now(   ) - startd);
            const locdata = declocdata(view);

            //console.log('got decoded data', Date.now() - startd);

            locdata.forEach((en) => {
              if (!(en.tid in loadedTracks)) loadedTracks[en.tid] = {};
              loadedTracks[en.tid][en.devid] = true;
              // TODO SHould send track to store
              //dispatch(actions.addFinalTrackData(en.tid, en.devid, usepoints));
            });
          })
          .catch((e) => {
            console.log("failed to fetch data", e);
            debugger;
          });
      });
    }
    let promlist: Promise<any>[] = [];
    //console.log(loadedTracks);
    listing.items.forEach((lit) => {
      //console.log(lit, lit.fullPath, lit.name);
      if (lit.name.endsWith("-finished.data")) {
        promlist.push(
          this.getTrack(lit).then((locdata) => {
            locdata.forEach((ent) => {
              if (!(ent.tid in loadedTracks)) loadedTracks[ent.tid] = {};
              if (loadedTracks[ent.tid][ent.devid])
                // Already from finaltracks.data
                return;
              loadedTracks[ent.tid][ent.devid] = true;
              if (ent.points.length > 0)
                this.props.addPoints(ent.tid, ent.devid, ent.points);
            });
          })
        );
      }
    });
    await Promise.all(promlist);
    listing.prefixes.forEach((teamref) => {
      //console.log(teamref.name);
      listAll(teamref).then((tlisting) => {
        let partpromlist = [];
        tlisting.items.forEach((tpart) => {
          //console.log(tpart.name);
          const found = tpart.name.match(/(.*)-([0-9]+).data/);
          if (!found) return;
          if ((loadedTracks[teamref.name] || {})[found[1]]) return;
          partpromlist.push(
            this.getTrack(tpart).then((locdata) => {
              //console.log(locdata);
              locdata.forEach((ent) => {
                // TODO Send to store...
                /*
                dispatch(
                  actions.addTrackSegment(ent.tid, ent.devid, ent.points)
                );
                */
              });
            })
          );
          //console.log(loadedTracks);
          //console.log(found);
        });
      });
    });
  };

  render() {
    return null;
  }
}

export default connector(OldTracksDataListener);
