import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Done } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";
import FBTextField from "./FBTextField";

const KPGroupsConfigCard: React.FC = () => {
  const kpgroups = useTSelector((state) => state.event.kpgroups);
  const eventId = useTSelector((state) => state.eventId);
  const [editconf, setEditConf] = useState(null as string | null);
  const { fbSet } = useNL();
  const { t } = useTranslation();
  const dbpath = `eventsdata/${eventId}/data/kpgroups/`;
  return (
    <Card>
      <CardHeader title={t("kpgroups.title" as any)} />
      <CardContent>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            let v = 1;
            while (kpgroups && kpgroups[v]) v++;
            fbSet(dbpath + v.toString(), v);
          }}
        >
          {t("kpgroup.add")}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("kpgroup.headlabel.group")}</TableCell>
              <TableCell>{t("kpgroup.headlabel.multiplier")}</TableCell>
              <TableCell>{t("ra.headlabel.delete")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(kpgroups || {}).map(([group, value]) => (
              <TableRow key={group}>
                <TableCell>{group}</TableCell>
                <TableCell onClick={() => setEditConf(group)}>
                  {editconf === group ? (
                    <ClickAwayListener onClickAway={() => setEditConf(null)}>
                      <div>
                        <FBTextField
                          style={{ width: "14ch" }}
                          type="number"
                          id="rakoef"
                          path={dbpath + group}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="complete ra entry"
                                  onClick={(event) => {
                                    setEditConf(null);
                                    event.stopPropagation();
                                  }}
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  size="large"
                                >
                                  <Done />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  ) : (
                    value
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => fbSet(dbpath + group, null)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default KPGroupsConfigCard;
