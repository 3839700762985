import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { get, getDatabase, ref } from "firebase/database";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
} from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { EventType } from "rx/fbListSlices";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { archiveprefix } from "./DebuggerTools";

const EventDate: React.FC<{ evid: string }> = ({ evid }) => {
  const d = useRFirebaseDb<number>("/events/" + evid + "/starttime");
  return <>{dayjs(d).format("DD/MM/YYYY")}</>;
};
const DeviceTable: React.FC<{ devs: DevWithEvidType[] }> = ({ devs }) => {
  const cols: GridColDef[] = [
    {
      field: "evdate",
      headerName: "Event date",
      width: 200,
      renderCell: (row) => {
        return <EventDate evid={row.row.evid} />;
      },
    },
  ];
  devs.forEach((ent) => {
    Object.keys(ent).forEach((k) => {
      if (cols.find((c) => c.field === k) !== undefined) return;
      cols.push({
        field: k,
        headerName: k,
        width: 150,
        editable: true,
        renderCell: (row) => {
          return typeof (row.value) === "object" ? JSON.stringify(row.value) : row.value;
        }
      });
    });
  });
  return (
    <DialogContent>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={devs.map((v, idx) => Object.assign({ id: idx }, v))}
          columns={cols}
          pageSizeOptions={[5, 10]}
          disableRowSelectionOnClick
        />
      </Box>
    </DialogContent>
  );
};

// https://en.wikipedia.org/wiki/Darwin_(operating_system)
const darwintoios: { [k: string]: string } = {
  "13": "iOS 6",
  "14": "iOS 7, iOS8",
  "15": "iOS 9",
  "16": "iOS 10",
  "17": "iOS 11",
  "18": "iOS 12",
  "19": "iOS 13",
  "20": "iOS 14",
  "21": "iOS 15",
  "22": "iOS 16",
  "23": "iOS 17",
  "24": "iOS 18", // Not yet realeased
};
const StatTable: React.FC<{ data: StatData; title: string }> = ({
  data,
  title,
}) => {
  const [showdevs, setShowDevs] = useState<string>();
  const keys = Object.keys(data);
  const allcount = keys.reduce(
    (prev, current) => prev + data[current].length,
    0
  );
  const sorted = keys.sort((a, b) => data[b].length - data[a].length);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3} align="center">
            {title}
          </TableCell>
        </TableRow>
      </TableHead>
      {sorted.map((ent) => (
        <TableRow key={ent} onClick={() => setShowDevs(ent)}>
          <TableCell>{ent}</TableCell>
          <TableCell>{data[ent].length}</TableCell>
          <TableCell>
            {Math.floor((100 / allcount) * data[ent].length)} %
          </TableCell>
        </TableRow>
      ))}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={showdevs !== undefined && data[showdevs] !== undefined}
        onClose={() => setShowDevs(undefined)}
      >
        {showdevs && data[showdevs] && <DeviceTable devs={data[showdevs]} />}
      </Dialog>
    </Table>
  );
};
const inmonths: { [m: number]: string } = {
  1: "Üks kuu",
  3: "Kolm kuud",
  6: "Kuus kuud",
  12: "Üks aasta",
};

type iphoneFirstApp = {
  appName: string;
  appVersion: string;
  buildNumber: string;
  identifierForVendor: string;
  utsname: string; // 2  on darwini versioon. 5 - ipone ID
};

type AndroidKotlinType = {
  api: number;
  appversioncode: number;
  appversionname: string;
  baseos: string;
  board: string;
  brand: string;
  codename: string;
  fingerprint: string;
  manufacturer: string;
};

type FlutterAppType = {
  platform: string;
  pkginfo: {
    appName: string;
    appVersion: string;
    buildNumber: string;
    packageName: string;
  }
  version: {
    release: string;
    sdkInt: number;
    securityPatch: string;
  }
}

/* Sample android flutter dev data
{
    "evid": "Hara",
    "board": "courbet",
    "bootloader": "unknown",
    "brand": "Xiaomi",
    "device": "courbet",
    "display": "TKQ1.221013.002 test-keys",
    "displayMetrics": {
        "heightPx": 2400,
        "widthPx": 1080,
        "xDpi": 401.6390075683594,
        "yDpi": 401.8450012207031
    },
    "fingerprint": "Xiaomi/courbet_eea/courbet:13/TKQ1.221013.002/V14.0.3.0.TKQEUXM:user/release-keys",
    "hardware": "qcom",
    "host": "pangu-build-component-system-191700-x98lt-j8dgk-1v86q",
    "id": "TKQ1.221013.002",
    "isPhysicalDevice": true,
    "manufacturer": "Xiaomi",
    "model": "M2101K9AG",
    "pkginfo": {
        "appName": "Nutilogi",
        "appVersion": "0.9.30",
        "buildNumber": "30",
        "packageName": "com.nutilogi.nutilogi"
    },
    "platform": "android",
    "product": "courbet_eea",
    "serialNumber": "unknown",
    "supported32BitAbis": [
        "armeabi-v7a",
        "armeabi"
    ],
    "supported64BitAbis": [
        "arm64-v8a"
    ],
    "supportedAbis": [
        "arm64-v8a",
        "armeabi-v7a",
        "armeabi"
    ],
    "systemFeatures": [
        "android.hardware.sensor.proximity",
        "com.google.android.feature.ASI_MINIMAL",
        "android.hardware.telephony",
        "android.software.file_based_encryption"
    ],
    "tags": "release-keys",
    "type": "user",
    "version": {
        "baseOS": "",
        "codename": "REL",
        "incremental": "V14.0.3.0.TKQEUXM",
        "previewSdkInt": 0,
        "release": "13",
        "sdkInt": 33,
        "securityPatch": "2023-10-01"
    }
}
*/
type FlutterAndroidAppType = FlutterAppType & {
  board: string;
  bootloader: string;
  brand: string;
  device: string;
  display: string;
}


/* Sample ios flutter dev data.
{
  "evid": "NE2024PiirimailAsfalt",
  "identifierForVendor": "804FB167-1EA2-4D08-8648-61CE79364B47",
  "isPhysicalDevice": "true",
  "localizedModel": "iPhone",
  "model": "iPhone",
  "name": "iPhone",
  "pkginfo": {
      "appName": "",
      "appVersion": "0.9.27",
      "buildNumber": "27",
      "packageName": "com.nutilogi.nutilogi"
  },
  "platform": "ios",
  "systemName": "iOS",
  "systemVersion": "16.6",
  "utsname": {
      "machine": "iPhone12,1",
      "nodename": "Agness-iPhone",
      "release": "22.6.0",
      "sysname": "Darwin",
      "version": "Darwin Kernel Version 22.6.0: Wed Jun 28 20:51:23 PDT 2023; root:xnu-8796.142.1~1/RELEASE_ARM64_T8030"
  }
}
*/
type FlutteriOSAppType = FlutterAppType & {
  systemName: string;
  systemVersion: string;
  utsname: {
    machine: string;
    nodename: string;
    release: string;
    sysname: string;
    version: string;
  }
}


type DevType = iphoneFirstApp | AndroidKotlinType | FlutterAppType;
type DevWithEvidType = DevType & { evid: string };
type StatData = {
  [k: string]: (DevType & { evid: string })[];
};

const isFirstIOS = (dev: DevWithEvidType): dev is iphoneFirstApp & { evid: string } => "appName" in dev;
const isFlutterIOS = (dev: DevWithEvidType): dev is FlutteriOSAppType & { evid: string } => "pkginfo" in dev && dev.platform === "ios";
const isIOS = (dev: DevWithEvidType): dev is (FlutteriOSAppType | iphoneFirstApp) & { evid: string } => isFirstIOS(dev) || isFlutterIOS(dev);

const isKotlinAndroid = (dev: DevWithEvidType): dev is AndroidKotlinType & { evid: string } => "api" in dev;
const isFlutterAndroid = (dev: DevWithEvidType): dev is FlutterAndroidAppType & { evid: string } => "pkginfo" in dev && dev.platform === "android";
const isAndroid = (dev: DevWithEvidType): dev is (FlutterAndroidAppType | AndroidKotlinType) & { evid: string } => isKotlinAndroid(dev) || isFlutterAndroid(dev);

const isFlutterApp = (dev: DevWithEvidType): dev is FlutterAppType & { evid: string } => "pkginfo" in dev;



const VersionDistribution: React.FC = () => {
  const [months, setMonths] = useState(3);
  const [devs, setDevs] = useState<DevWithEvidType[]>([]);
  const events = useRFirebaseDb<{ [evid: string]: EventType }>("/events");
  const setMonthsCallback = useCallback(
    (ev: SelectChangeEvent<number>) => {
      if (typeof ev.target.value == "number") setMonths(ev.target.value);
    },
    [setMonths]
  );

  useEffect(() => {
    if (!events) return;
    const stdate = dayjs().subtract(months, "months").valueOf();
    const useevs = Object.entries(events).filter(
      ([evid, ev]) => (ev.starttime || 0) > stdate
    );
    let docancel = false;
    //const usedevs2 = usedevs.splice(0, 2);

    setDevs([]);
    const addDevsFromData = (tv: any, evid: string) => {
      if (!tv) return;
      const newdevs: DevWithEvidType[] = [];
      Object.entries(tv).forEach(([tid, teamdata]: [string, any]) => {
        const devs = teamdata["devs"];
        if (devs === undefined) return;
        Object.values(devs).forEach((dev) =>
          newdevs.push(Object.assign({ evid: evid }, dev) as DevWithEvidType)
        );
      });
      setDevs((prevstate) => {
        if (docancel) return prevstate;
        const newv = [...prevstate];
        newv.push(...newdevs);
        return newv;
      });
    };
    const getarch = async (arch: string, evid: string) => {
      const url =
        arch === "a2"
          ? archiveprefix + "/" + evid + "/evdata.json"
          : await getDownloadURL(
            storageRef(getStorage(), `/archive/${evid}/evdata.json`)
          );
      const evdata = await fetch(url).then((v) => v.json());
      if (docancel) return;
      addDevsFromData(evdata?.teams?.data, evid);
    };
    const dataGetter = async () => {
      for (const ev of useevs) {
        if (docancel) return;
        if (ev[1].arch) {
          await getarch(ev[1].arch, ev[0]);
        } else {
          const tl = await get(ref(getDatabase(), "teams/" + ev[0] + "/data"));
          addDevsFromData(tl.val(), ev[0]);
        }
      }
    };
    dataGetter();
    return () => {
      docancel = true;
    };
  }, [months, events]);
  function genstatdata<T extends DevWithEvidType>(
    data: T[],
    getkey: (dev: T) => string
  ): StatData {
    const resp: StatData = {};
    data.forEach((ent) => {
      const k = getkey(ent);
      if (k in resp) resp[k].push(ent);
      else resp[k] = [ent];
    });
    return resp;
  }


  const iphonedevs = devs.filter(isIOS);
  const iphonedarwin = genstatdata(iphonedevs, (d) => {
    if (typeof (d.utsname) === "string")
      return d.utsname.split("/")[2];
    else
      return d.utsname.release
  });

  const iphoneos = genstatdata(iphonedevs, (d) => {
    const release = (typeof (d.utsname) === "string") ? d.utsname.split("/")[2] : d.utsname.release;
    const k = release.split(".")[0];
    return k in darwintoios ? darwintoios[k] : "unknown iOS";
  });

  const androiddevs = devs.filter(isAndroid);
  const androidapi = genstatdata(androiddevs, (d) => {
    return isKotlinAndroid(d) ? d.api.toString() : d.version.sdkInt.toString();
  });
  const appversions = genstatdata(devs, (d) => {
    if (isFlutterApp(d))
      return `${d.platform} ${d.pkginfo.appVersion}`;
    else if (isIOS(d))
      return `ios ${d.appVersion}`
    else
      return `android ${d.appversionname} (${d.appversioncode})`
  });
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        Viimased{" "}
        <Select value={months} onChange={setMonthsCallback}>
          {Object.entries(inmonths).map(([v, d]) => (
            <MenuItem key={v} value={parseInt(v)}>
              {d}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title="iPhone" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item>
                <StatTable data={iphonedarwin} title="Darwin" />
              </Grid>
              <Grid item>
                <StatTable data={iphoneos} title="iOS" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title="Android" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item>
                <StatTable data={androidapi} title="API" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title="App" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item>
                <StatTable data={appversions} title="App" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default VersionDistribution;
