import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTSelector } from "rx/store";
import { useNL } from "utils/NLContext";
import duration from "dayjs/plugin/duration"; // import plugin
import { Delete } from "@mui/icons-material";

dayjs.extend(duration);

const AssignStartTimesContents: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { fbSet, fbRemove } = useNL();
  const teams = useTSelector((state) => state.teamsList);
  const eventId = useTSelector((state) => state.eventId);
  const [forcetime, setForcetime] = useState<Dayjs | null>();
  const [intervall, setIntervall] = useState<duration.Duration>(
    dayjs.duration({ minutes: 1 })
  );
  const starttimes = Object.values(teams)
    .map((t) => t.starttime)
    .filter((t) => t)
    .sort();
  const lateststarttime = starttimes.length
    ? dayjs(starttimes[starttimes.length - 1]).add(intervall)
    : undefined;
  const nextstart =
    forcetime ||
    lateststarttime ||
    dayjs().add(1, "hour").minute(0).second(0).millisecond(0);
  const intervallref = dayjs(new Date(2000, 1, 1, 0, 0, 0));

  return (
    <>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item>
            <DateTimePicker
              label={t("label.nextstarttime")}
              value={nextstart}
              ampm={false}
              format="DD.MM.YY HH:mm:ss"
              onChange={(newValue) => {
                setForcetime(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <TimePicker
              label={t("label.startinterval")}
              value={intervallref.add(intervall)}
              ampm={false}
              format="HH:mm:ss"
              views={["hours", "minutes", "seconds"]}
              onChange={(newValue) => {
                console.log(newValue);
                if (newValue && newValue.isValid())
                  console.log(
                    intervallref.toISOString(),
                    "\n",
                    newValue.toISOString(),
                    newValue.diff(intervallref)
                  );
                if (newValue && newValue.isValid())
                  setIntervall(dayjs.duration(newValue.diff(intervallref)));
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List>
          {Object.entries(teams)
            .sort(
              ([ltid, lteam], [rtid, rteam]) =>
                (lteam.starttime || -1) - (rteam.starttime || -1)
            )
            .map(([tid, team]) => (
              <ListItem>
                <ListItemIcon>
                  <IconButton
                    onClick={() =>
                      fbRemove(
                        `/teams/${eventId}/list/${tid}/starttime`,
                        t("undo.clearstarttime", { team: team.name })
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </ListItemIcon>
                <ListItemButton
                  onClick={() => {
                    fbSet(
                      `/teams/${eventId}/list/${tid}/starttime`,
                      nextstart.valueOf(),
                      t("undo.setstarttime", {
                        time: nextstart.format("HH:mm:ss"),
                        team: team.name,
                      })
                    );
                  }}
                >
                  <ListItemText primary={team.name} />
                  <ListItemSecondaryAction>
                    {team.starttime && dayjs(team.starttime).format("HH:mm:ss")}
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t("button.close")}
        </Button>
      </DialogActions>
    </>
  );
};

const AssignStartTimes: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <AssignStartTimesContents onClose={onClose} />
    </Dialog>
  );
};

export default AssignStartTimes;
