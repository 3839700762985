import React from "react";
import { RootState } from "rx/store";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { closeDialog } from "rx/dialogsSlice";
import {
  generateEventConfigurations,
  GroupConfiguration,
} from "./EventSettings";
import { isMobile } from "react-device-detect";

const mapState = (state: RootState) => ({});
const mapDispatch = {
  close: () => closeDialog("configureEvent"),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type ConfigureEventProps = PropsFromRedux &
  WithTranslation & {
    eventId: string;
  };
type ConfigureEventState = {
  activeStep: number;
};
class ConfigureEvent extends React.Component<
  ConfigureEventProps,
  ConfigureEventState
> {
  state: ConfigureEventState = {
    activeStep: 0,
  };
  steps: GroupConfiguration[];
  constructor(props: ConfigureEventProps) {
    super(props);
    this.steps = generateEventConfigurations(props.t, props.eventId).filter(
      (s) => s.id !== "gentab"
    );
  }
  handleNext = () => {
    if (this.state.activeStep === this.steps.length - 1) {
      this.props.close();
    } else {
      this.setState((state) => {
        return { activeStep: state.activeStep + 1 };
      });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Dialog
        disableEscapeKeyDown
        open={true}
        fullWidth
        maxWidth="lg"
        fullScreen={isMobile}
        sx={{ "& .MuiDialog-paper": { minHeight: "80vh" } }}
      >
        <DialogTitle>{t("eventconf.title")}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={this.state.activeStep}>
            {this.steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              if (this.state.activeStep > index) stepProps.completed = true;
              return (
                <Step key={step.title} {...stepProps}>
                  <StepLabel {...labelProps}>{step.title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {this.steps[this.state.activeStep].content}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.activeStep === 0}
            variant="contained"
            color="primary"
            onClick={() =>
              this.setState((state) => {
                return { activeStep: state.activeStep - 1 };
              })
            }
          >
            {t("button.back")}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleNext}>
            {this.state.activeStep === this.steps.length - 1
              ? t("button.finish")
              : t("button.next")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.close}
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(withTranslation()(ConfigureEvent));
