import Dialog, { DialogProps } from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton/IconButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import CupMgmt from "components/CupMgmt";
import React, { useCallback, useState } from "react";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { closeDialog, NextGenDialogNames } from "rx/dialogsSlice";
import { useTSelector } from "rx/store";
import { SlideUpTransition } from "./DialogUtils";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AccessRightsDialog from "components/AccessRightsDialog";
import { CupResultDialog } from "components/CupResult";
import {
  AppStateKeysForDialogs,
  clearAppStateValue,
} from "rx/appStateSlice";
import { Box, DialogContent } from "@mui/material";
import EventsManager from "components/EventsManager";
import TeamDialog from "components/TeamDialog";
import VersionDistribution from "components/VersionDistribution";
import StartOrderContents from "components/StartOrderDialog";
import DownloadsDialogContent from "components/DownloadsDialog";
import DebuggerTools from "components/DebuggerTools";
import { isMobile } from "react-device-detect";
import JoinCodes from "components/JoinCodes";
import EditorDialog from "components/EditorDialog";
import TranslatorTool from "components/TranslatorTool";
import NewsDialog from "components/NewsDialog";
import CreateEvent from "components/CreateEvent";
import SendMsgDialog from "components/SendMsgDialog";
import AccountingDialog from "components/AccountingDialog";
import SignInDialog from "components/SignInDialog";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import RegisteredTeams from "components/RegisteredTeams";
import WheelOfNamesContent from "components/WheelOfNames";
import DeletedKPOnTeam from "components/DeletedKPOnTeam";
import KPData from "components/KPData";

type NutilogiDialogProps = Omit<DialogProps, "open"> & {
  name: NextGenDialogNames;
  dispatch: Dispatch<AnyAction>; // TODO AnyAction is deprecated
  children: React.ReactNode;
  disableDialogActinos?: boolean;
  enableFullScreenButton?: boolean;
  disableOnCloseForDialog?: boolean;
};

const noFullScreenOnMobile: { [key in NextGenDialogNames]?: boolean } = {
  debuggerTools: false,
};

const AppStateDialogContent = (props: {
  appkey: AppStateKeysForDialogs;
  appStateValue: string;
  disableDialogActions?: boolean;
  titleContents?: (v: string) => React.ReactNode | null;
  children: {
    (v: string): React.ReactElement | null;
  };
  onClose: () => void;
}) => {
  const {
    appkey,
    appStateValue,
    disableDialogActions,
    children,
    titleContents,
    onClose,
  } = props;
  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <>
          {titleContents
            ? titleContents(appStateValue!)
            : t(`dialog.title.${appkey}` as const)}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      </DialogTitle>
      {appStateValue && children(appStateValue)}
      {!disableDialogActions && (
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            {t("button.close")}
          </Button>
        </DialogActions>
      )}
    </DialogContent>
  );
};

// This would be nicer if could have done with generics. That the type that is passed to
// children would be deducted from props.appkey
export const AppStateDialog = (
  props: {
    appkey: AppStateKeysForDialogs;
    disableDialogActions?: boolean;
    titleContents?: (v: string) => React.ReactNode | null;
    children: {
      (v: string): React.ReactElement | null;
    };
  } & Omit<DialogProps, "children" | "open">
) => {
  const {
    appkey,
    children,
    titleContents,
    disableDialogActions,
    ...dialogprops
  } = props;
  const appStateValue = useTSelector((state) => state.appState[appkey]);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={Boolean(appStateValue)}
      TransitionProps={{ unmountOnExit: true }}
      TransitionComponent={SlideUpTransition}
      onClose={() => dispatch(clearAppStateValue(appkey))}
      {...dialogprops}
    >
      {appStateValue && (
        <AppStateDialogContent
          appkey={appkey}
          disableDialogActions={disableDialogActions}
          children={children}
          onClose={() => dispatch(clearAppStateValue(appkey))}
          titleContents={titleContents}
          appStateValue={appStateValue}
        />
      )}
    </Dialog>
  );
};

const NutilogiDialog: React.FC<NutilogiDialogProps> = (props) => {
  const {
    name,
    dispatch,
    children,
    disableDialogActinos,
    enableFullScreenButton,
    disableOnCloseForDialog,
    ...dialogprops
  } = props;
  const isOpen = useTSelector((state) => state.dialog[name]);
  const [forceFullScreen, setForceFullScreen] = useState(false);
  const { t } = useTranslation();
  const closeHandler = useCallback(
    () => dispatch(closeDialog(name)),
    [dispatch, name]
  );
  return (
    <Dialog
      open={Boolean(isOpen)}
      TransitionProps={{ unmountOnExit: true }}
      TransitionComponent={SlideUpTransition}
      fullScreen={forceFullScreen || (isMobile && !noFullScreenOnMobile[name])}
      onClose={disableOnCloseForDialog ? undefined : closeHandler}
      {...dialogprops}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(`dialog.title.${name}` as const)}
        <Box
          sx={{
            position: "absolute",
            justifyContent: "flex-end",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {enableFullScreenButton &&
            !(isMobile && !noFullScreenOnMobile[name]) && (
              <IconButton
                aria-label="fullcreen"
                onClick={() => {
                  setForceFullScreen((state) => !state);
                }}
              >
                {forceFullScreen ? <CloseFullscreen /> : <OpenInFull />}
              </IconButton>
            )}
          <IconButton aria-label="close" onClick={closeHandler}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {children}
      {!disableDialogActinos && (
        <DialogActions>
          <Button variant="contained" onClick={closeHandler}>
            {t("button.close")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const NextGenDialogFactory: React.FC = () => {
  const dispatch = useDispatch();

  console.log("Render next gen dialog factory");

  return (
    <Fragment>
      <SignInDialog />
      <NutilogiDialog
        dispatch={dispatch}
        key="cup"
        name="cupMgmt"
        maxWidth="xl"
        fullWidth
      >
        <CupMgmt />
      </NutilogiDialog>
      <NutilogiDialog dispatch={dispatch} key="accr" name="accessRights">
        <AccessRightsDialog />
      </NutilogiDialog>
      <NutilogiDialog dispatch={dispatch} key="download" name="downloads">
        <DownloadsDialogContent />
      </NutilogiDialog>
      <NutilogiDialog
        fullWidth
        maxWidth="md"
        dispatch={dispatch}
        key="joincodes"
        name="joinCodes"
      >
        <JoinCodes />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="debuggertools"
        name="debuggerTools"
      >
        <DebuggerTools />
      </NutilogiDialog>
      <NutilogiDialog
        fullWidth
        maxWidth={false}
        dispatch={dispatch}
        key="evmngr"
        name="eventsManager"
      >
        <EventsManager />
      </NutilogiDialog>
      <TeamDialog />
      <CupResultDialog />
      <NutilogiDialog key="vdist" name="versiondist" dispatch={dispatch}>
        <VersionDistribution />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="start"
        name="startOrder"
        fullScreen={true}
        disableDialogActinos
      >
        <StartOrderContents />
      </NutilogiDialog>
      <NutilogiDialog
        key="translatorTool"
        name="translatorTool"
        dispatch={dispatch}
      >
        <TranslatorTool />
      </NutilogiDialog>
      <NutilogiDialog
        key="news"
        name="news"
        dispatch={dispatch}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <NewsDialog />
      </NutilogiDialog>
      <EditorDialog />
      <NutilogiDialog
        key="createEvent"
        name="createEvent"
        dispatch={dispatch}
        disableDialogActinos
      >
        <CreateEvent
          closeHandler={() => dispatch(closeDialog("createEvent"))}
        />
      </NutilogiDialog>
      <NutilogiDialog
        key="sendMsg"
        name="sendMsg"
        dispatch={dispatch}
        maxWidth="lg"
        fullWidth
      >
        <SendMsgDialog />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="account"
        name="accounting"
        maxWidth="xl"
        fullWidth
      >
        <AccountingDialog />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="reg"
        name="registered"
        maxWidth="xl"
        enableFullScreenButton
        fullWidth
      >
        <RegisteredTeams />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="won"
        name="wheelofnames"
        maxWidth="md"
        fullWidth
      >
        <WheelOfNamesContent />
      </NutilogiDialog>
      <NutilogiDialog
        dispatch={dispatch}
        key="kpData"
        name="kpData"
        maxWidth="md"
        enableFullScreenButton
        disableOnCloseForDialog
        fullWidth
        PaperProps={{
          sx: { height: "100%" },
        }}
      >
        <KPData />
      </NutilogiDialog>
      <DeletedKPOnTeam />
    </Fragment>
  );
};

export default React.memo(NextGenDialogFactory);
