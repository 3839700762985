import eventIdReducer from "rx/eventIdSlice";
import userReducer from "rx/userSlice";
import dialogReducer from "rx/dialogsSlice";
import appStateReducer from "rx/appStateSlice";
import tracksReducer from "rx/tracksSlice";
import saveInProgressReducer from "rx/saveInProgressSlice";
import {
  fbReducerMap,
  eventSlice,
  dataAvailabilitySlice,
} from "rx/fbListSlices";
import { eventDocSlice } from "rx/simpleFirstoreDocSlice";
import uidmapSlice from "./uidmapSlice";

const rootReducer = {
  eventId: eventIdReducer,
  uidmap: uidmapSlice,
  user: userReducer,
  event: eventSlice.reducer,
  saveInProgress: saveInProgressReducer,
  eventDoc: eventDocSlice.reducer,
  dialog: dialogReducer,
  appState: appStateReducer,
  tracks: tracksReducer,
  dataAvailability: dataAvailabilitySlice.reducer,
  ...fbReducerMap,
  conf: (
    state = {
      hostname: "nutilogi.ee",
    }
  ) => state,
};

export default rootReducer;
