import React, { useEffect, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import { DatabaseReference } from "firebase/database";

type FBTextFieldProps = {
  path: string | DatabaseReference;
  disabletooltip?: boolean;
  tooltipfollow?: boolean;
  parsefbvalue?: (v: any) => string;
  convertofbvalue?: (v: string) => any;
};
const FBTextField: React.FC<TextFieldProps & FBTextFieldProps> = (props) => {
  const [fbvalue, setFbValue] = useFirebaseDb(props.path);
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    value: undefined as string | undefined,
    timer: null as NodeJS.Timeout | null,
  });

  useEffect(() => {
    if (fbvalue.value !== undefined && state.timer === null) {
      const v1 = props.parsefbvalue
        ? props.parsefbvalue(fbvalue.value)
        : fbvalue.value ?? "";
      const v2 = props.convertofbvalue
        ? props.convertofbvalue(state.value ?? "")
        : state.value;
      const v3 = props.parsefbvalue ? props.parsefbvalue(v2) : v2;
      if (v1 !== v3 || state.value === undefined) {
        setState((state) => ({
          ...state,
          ...{ value: v1 },
        }));
      }
    }
  }, [fbvalue.value, props, state.timer, state.value]);

  const {
    parsefbvalue,
    convertofbvalue,
    tooltipfollow,
    disabletooltip,
    ...textFieldProps
  } = props;
  return (
    <NLTooltipWrapper
      followCursor={tooltipfollow}
      tkey={disabletooltip ? undefined : (`fbf.${props.id}.tooltip` as any)}
    >
      <SkeletonWrapper condition={state.value === undefined}>
        <TextField
          label={t(`fbf.${props.id}.label` as any)}
          {...textFieldProps}
          value={props.value ?? state.value ?? ""}
          onChange={(event) => {
            if (state.timer) {
              clearTimeout(state.timer);
            }
            const tofbvalue = convertofbvalue
              ? convertofbvalue(event.target.value)
              : event.target.value;
            var newstate = Object.assign({}, state, {
              value: event.target.value,
            });
            var newetimer = null;
            if (tofbvalue !== undefined) {
              newetimer = setTimeout(() => {
                setState((state) => ({ ...state, ...{ timer: null } }));
                setFbValue(
                  props.id || "dummy",
                  t(`fbf.${props.id}.changed` as any),
                  tofbvalue === ""
                    ? null
                    : props.type === "number"
                      ? Number(tofbvalue)
                      : tofbvalue
                );
              }, 1000);
            }
            setState(Object.assign(newstate, { timer: newetimer }));
          }}
          helperText={
            i18n.exists(`fbf.${props.id}.helper`)
              ? t(`fbf.${props.id}.helper` as any, {
                count: Number(state.value),
                value: state.value,
              })
              : undefined
          }
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};
/* 



Sample implementatoin using HOC
class FBTextField2 extends React.Component<
  TextFieldProps & { path: string },
  { value: string | undefined }
> {
  state = {
    value: undefined,
  };
  timer: NodeJS.Timeout | undefined = undefined;
  setFbValue: ((v: any) => void) | undefined = undefined;
  valueChaged = (v: string) => {
    this.setState({ value: v });
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.timer = undefined;
      if (this.setFbValue) this.setFbValue(v);
    }, 300);
  };
  render() {
    return (
      <FirebaseDbWrapper path={this.props.path}>
        {(value: any, setValue: (v: any) => void) => {
          this.setFbValue = setValue;
          if (!this.timer) this.state.value = value;
          return (
            <TextField
              value={this.state.value || value}
              {...this.props}
              onChange={(event) => {
                this.valueChaged(event.target.value);
              }}
            />
          );
        }}
      </FirebaseDbWrapper>
    );
  }
}*/

export default FBTextField;
