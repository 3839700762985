import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeDialog } from "rx/dialogsSlice";
import { useTSelector } from "rx/store";
import { useRFirebaseDb } from "utils/FirebaseDbWrapper";
import { DeletedKPEntry } from "./KPData";
import { Delete } from "@mui/icons-material";
import { getDatabase, ref, remove } from "firebase/database";

const DeletedKPOnTeamContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const evid = useTSelector((s) => s.eventId);
  const deletedKPS = useRFirebaseDb<{ [eid: string]: DeletedKPEntry }>(
    `/eventsdata/${evid ?? "badevid"}/deletedkps`
  );

  const [teams, kps, answers] = useTSelector(
    (s) => [s.teamsList, s.kpList, s.teamAnswers],
    (l, r) => {
      const ss = l.some((ent, idx) => ent !== r[idx]);
      return !ss;
    }
  );

  const rows: {
    tid: string;
    tname: string;
    kpid: string;
    aeg?: number;
    answer?: string;
  }[] = [];
  let dkp: DeletedKPEntry | undefined;
  Object.entries(teams).forEach(([tid, team]) => {
    const tanswers = answers[tid];
    if (!tanswers) return;
    Object.entries(tanswers).forEach(([kpid, a]) => {
      if (kps[kpid]) return;
      dkp = Object.values(deletedKPS ?? {}).find((dkp) => dkp.id === kpid);
      let answer = a.answer;
      if (dkp) {
        if (answer) {
          const decanswer = dkp.data?.responses?.[answer];
          if (decanswer) answer = decanswer;
        }
      }
      rows.push({
        tid,
        tname: team.name ?? tid,
        kpid,
        aeg: a.aeg,
        answer: answer,
      });
    });
  });
  //console.log("jjj", rows, teams);
  console.log("Main render..");
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(`dialog.title.deletedkponteam` as const)}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("team.header.team")}</TableCell>
              <TableCell>{t("header.kpid")}</TableCell>
              <TableCell>{t("header.aeg")}</TableCell>
              <TableCell>{t("header.isdeletedkp")}</TableCell>
              <TableCell>{t("header.answer")}</TableCell>
              <TableCell>{t("header.deletedkpnr")}</TableCell>
              <TableCell>{t("header.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r) => (
              <TableRow>
                <TableCell>{r.tname}</TableCell>
                <TableCell>{r.kpid}</TableCell>
                <TableCell>{dayjs(r.aeg).toISOString()}</TableCell>
                <TableCell>{dkp ? "Yes" : "no"}</TableCell>
                <TableCell>{r.answer}</TableCell>
                <TableCell>{dkp?.kp.nr ?? ""}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      remove(
                        ref(
                          getDatabase(),
                          `/teams/${evid ?? "missingevent"}/kpanswers/${r.tid}/${r.kpid}`
                        )
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      ;
      <DialogActions>
        <Button
          variant="contained"
          disabled={rows.length !== 0}
          onClick={() => {
            dispatch(closeDialog("deletedkponteam"));
            console.log("Check that everything is solved.");
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

const DeletedKPOnTeamWrapper: React.FC = () => {
  const isAdmin = useTSelector((s) => s.user.eventAccess);
  const { t } = useTranslation();

  if (isAdmin) return <DeletedKPOnTeamContent />;
  else return <DialogContent>{t("message.brokenresult" as any)}</DialogContent>;
};

const DeletedKPOnTeam: React.FC = () => {
  const isOpen = useTSelector((state) => state.dialog["deletedkponteam"]);

  return (
    <Dialog open={isOpen} maxWidth="xl">
      <DeletedKPOnTeamWrapper />
    </Dialog>
  );
};

export default DeletedKPOnTeam;
