import React from "react";

import { useFirebaseDb } from "utils/FirebaseDbWrapper";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { NLTooltipWrapper, SkeletonWrapper } from "utils/SmallUtils";
import { useNL } from "utils/NLContext";
import { DatabaseReference } from "firebase/database";

const FBSwitch: React.FC<
  SwitchProps & {
    path: string | DatabaseReference;
    id?: string;
    readOnly?: boolean;
  }
> = (props) => {
  const { fbSet } = useNL();
  const [fbvalue] = useFirebaseDb(props.path);
  const { t } = useTranslation();

  return (
    <NLTooltipWrapper
      tkey={props.id ? (`fbf.${props.id || "noid"}.tooltip` as any) : undefined}
    >
      <SkeletonWrapper condition={fbvalue.value === undefined}>
        <FormControlLabel
          control={
            <Switch
              {...props}
              checked={
                props.checked !== undefined
                  ? props.checked
                  : Boolean(fbvalue.value)
              }
              onChange={
                props.readOnly
                  ? undefined
                  : (_event, checked) => fbSet(props.path, checked)
              }
            />
          }
          label={props.id ? t(`fbf.${props.id}.label` as any) : undefined}
        />
      </SkeletonWrapper>
    </NLTooltipWrapper>
  );
};

export default FBSwitch;
