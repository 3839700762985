import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "rx/store";

export const saveInProgressSlice = createSlice({
  name: "dialogs",
  initialState: 0,
  reducers: {
    incrementProgressCount(state) {
      return state + 1;
    },
    decrementProgressCount(state) {
      return state - 1;
    },
  },
});

export const {
  incrementProgressCount,
  decrementProgressCount,
} = saveInProgressSlice.actions;

export const execWithProgress = (
  f: (getState: () => RootState) => Promise<any>
): AppThunk => async (dispatch, getState) => {
  dispatch(incrementProgressCount());
  await f(getState);
  dispatch(decrementProgressCount());
};

export default saveInProgressSlice.reducer;
