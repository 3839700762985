import dayjs from "dayjs";
import React from "react";
import { useTSelector } from "rx/store";
import KPMarker from "./KPMarker2";
import { ZoomLevelListener } from "./MapUtils";

export const KPManager: React.FC = () => {
  const showkps = useTSelector(
    (state) =>
      ((state.event.endtime && dayjs().isAfter(state.event.endtime)) ||
        Boolean(state.user.eventAccess) ||
        state.event.kpsinweb) &&
      state.appState.showKPs
  );
  const kpList = useTSelector((s) => s.kpList);
  const kpData = useTSelector((s) => s.kpData);
  if (!showkps) return null;
  return (
    <ZoomLevelListener>
      {(zoom) => (
        <>
          {Object.entries(kpList).map(([kpid, kp]) => (
            <KPMarker
              key={kpid}
              kp={kp}
              kpid={kpid}
              kpdata={kpData[kpid]}
              atzoom={zoom}
              enableInfoWidget
            />
          ))}
        </>
      )}
    </ZoomLevelListener>
  );
};
