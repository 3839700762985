import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Editor from "./Editor";
import { useTSelector } from "rx/store";
import Dialog from "@mui/material/Dialog";
import { SlideUpTransition } from "utils/DialogUtils";
import { useDispatch } from "react-redux";
import { clearAppStateValue } from "rx/appStateSlice";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Editor as TinyMCEEditor } from "tinymce";
import { get, getDatabase, onValue, ref } from "firebase/database";
import { useNL } from "utils/NLContext";

const EditorDialogContent: React.FC<{ path: string; onClose: () => void }> = ({
  path,
  onClose,
}) => {
  const { t } = useTranslation();
  const editorRef = useRef<TinyMCEEditor>();
  const [currentvalue, setCurrentValue] = useState<string>("");
  const [lang, setLang] = useState<string>();
  const { fbSet, fbRemove } = useNL();

  const ename = useMemo(() => {
    if (path.includes("longdesc")) return "longdesc";
    return "unknown";
  }, [path]);
  const getFullPath = (path: string, lng?: string) => {
    if (!lng || lng === "def") return path;
    return path + "_i18n/" + lng;
  };
  const fullpath = getFullPath(path, lang);
  useEffect(() => {
    return onValue(ref(getDatabase(), fullpath), (snap) => {
      if (snap.exists()) setCurrentValue(snap.val());
      //console.log("useEffect", editorRef.current, snap.val());
      /*
      if (editorRef.current) editorRef.current.setContent(snap.val());
      //      if (!currenteditor.isDirty()) currenteditor.setContent(snap.val());
      else console.log("Warn server changes");
      */
    });
  }, [fullpath]);

  const saveToFB = useCallback(
    (savepath: string) => {
      if (!editorRef.current) return;
      const content = editorRef.current.getContent();
      if (content.length === 0)
        fbRemove(savepath, t(("editor.dialog.removed." + ename) as any));
      else fbSet(savepath, content, t(("editor.dialog.saved." + ename) as any));
      editorRef.current.setDirty(false);
    },
    [fbRemove, t, ename, fbSet]
  );

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(("editor.dialog.title." + ename) as any)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Editor
          value={currentvalue}
          onEditorChange={(s) => {
            setCurrentValue(s);
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          onSetup={(editor, callbacks) => {
            let editorlng = "def";
            callbacks.setSaveableContent(true);

            callbacks.getUsedLanguages = async () => {
              const v = await get(ref(getDatabase(), path + "_i18n"));
              return v.val();
            };

            callbacks.setLanguage = (lng) => {
              setLang(lng);
              editorlng = lng;
            };
            callbacks.doSave = (data: string) => {
              saveToFB(getFullPath(path, editorlng));
            };
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            saveToFB(fullpath);
            onClose();
          }}
        >
          {t("button.save")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            editorRef.current?.setDirty(false);
            onClose();
          }}
        >
          {t("button.cancel")}
        </Button>
      </DialogActions>
    </>
  );
};

const EditorDialog: React.FC = () => {
  const path = useTSelector((state) => state.appState.richEditorPath);
  const dispatch = useDispatch();

  return (
    <Dialog
      open={Boolean(path)}
      disableEnforceFocus
      TransitionProps={{ unmountOnExit: true }}
      TransitionComponent={SlideUpTransition}
      onClose={() => dispatch(clearAppStateValue("richEditorPath"))}
    >
      {path && (
        <EditorDialogContent
          path={path}
          onClose={() => dispatch(clearAppStateValue("richEditorPath"))}
        />
      )}
    </Dialog>
  );
};
export default EditorDialog;
