import { z } from "zod";

const geoPointSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

const areaObjectTypeSchema = z.object({
  name: z.string(),
  area: z.array(geoPointSchema),
  restricted: z.boolean().optional(),
  limit: z.number().optional(),
  timed: z.boolean().optional(),
});

const eventDocSchema = z.object({
  admins: z.record(z.string()),
  speed: z
    .object({
      limit: z.number().optional(),
      over1: z.number().optional(),
      delay1: z.number().optional(),
      over2: z.number().optional(),
      delay2: z.number(),
    })
    .optional(),
  speedareas: z.record(areaObjectTypeSchema).optional(),
});

const eventTypeSchema = z.object({
  name: z.string().optional(),
  starttime: z.number().optional(),
  endtime: z.number().optional(),
  arch: z.string().optional(),
  hidden: z.boolean().optional(),
  admins: z.record(z.string()),
});

export const latLngLiteralSchema = z.object({
  lat: z.number(),
  lng: z.number(),
});

const eventDataTypeSchema = z.object({
  kpsinweb: z.boolean().optional(),
  type: z
    .union([z.literal("paevak"), z.literal("valik"), z.literal("race")])
    .optional(),
  resulttype: z.literal("roadbook").optional(),
  joinstartinterval: z.number().optional(),
  clientstart: z.boolean().optional(),
  massstarttimems: z.number().optional(),
  startloc: latLngLiteralSchema.optional(),
  maxmarkingdistance: z.number().optional(),
  maxstartdistance: z.number().optional(),
  firstkpvisitbonus: z.number().optional(),
  firstkpvisitaddbonus: z.number().optional(),
  orderrules: z.string().optional(),
  sfwithseconds: z.boolean().optional(),
  sfwithdate: z.boolean().optional(),
  hidespeedings: z.boolean().optional(),
  joinrequiresconfirmation: z.boolean().optional(),
  appmessage: z.string().optional(),
  prepagemessage: z.string().optional(),
  notallowedmessage: z.string().optional(),
  finishedmessage: z.string().optional(),
  nokpopenwhennotclose: z.boolean().optional(),
  firstbonusismultiplier: z.boolean().optional(),
  distanceinresult: z.boolean().optional(),
  hidewrongmarks: z.boolean().optional(),
  valiktahed: z.string().optional(),
  dstresult: z
    .object({
      targetdst: z.number().optional(),
      dststeps: z.number().optional(),
      dstpoints: z.number().optional(),
      dstpointssteps: z.number().optional(),
    })
    .optional(),
  firstbonus: z.tuple([z.number()]).optional(),
  eventbounds: z
    .object({
      northEast: latLngLiteralSchema,
      southWest: latLngLiteralSchema,
    })
    .optional(),
  speedranges: z
    .tuple([
      z.object({
        s: z.number(),
        c: z.string(),
      }),
    ])
    .optional(),
  klassid: z.record(z.union([z.string(), z.boolean()])).optional(),
  ly: z
    .record(
      z.object({
        name: z.string(),
        ispenalty: z.boolean().optional(),
        forclasses: z.array(z.string()).optional(),
      })
    )
    .optional(),
  ajad: z
    .object({
      lisaajad: z
        .array(
          z.object({
            penalty: z.number(),
            per: z.number().optional(),
            time: z.number().optional(),
          })
        )
        .optional(),
      normaalaeg: z.number().optional(),
    })
    .optional(),
  hideresults: z.boolean().optional(),
  answerscores: z.boolean().optional(),
  hidetracks: z.boolean().optional(),
  wrongpenaltyenabled: z.boolean().optional(),
  wrongcoef: z.number().optional(),
  sharedrakoef: z.string().optional(),
  pointssuffix: z.string().optional(),
  map: z
    .object({
      default: z
        .object({
          type: z.string().optional(),
          conf: z.string().optional(),
        })
        .optional(),
      userlayers: z
        .array(
          z.object({
            type: z.string(),
            title: z.string(),
            conf: z.string(),
          })
        )
        .optional(),
    })
    .optional(),
  rakoef: z
    .union([z.record(z.number()), z.array(z.number().nullable())])
    .optional(),
});

const teamDataSchema = z.object({
  devs: z.record(
    z.object({
      api: z.number().optional(),
      appversioncode: z.number().optional(),
      appVersion: z.string().optional(),
      board: z.string().optional(),
      bootloader: z.string().optional(),
      brand: z.string().optional(),
      device: z.string().optional(),
      display: z.string().optional(),
      displayMetrics: z
        .object({
          heightPx: z.number(),
          widthPx: z.number(),
          xDpi: z.number(),
          yDpi: z.number(),
        })
        .optional(),
      hardware: z.string().optional(),
      host: z.string().optional(),
      id: z.string().optional(),
      isPhysicalDevice: z.boolean().optional(),
      model: z.string().optional(),
      name: z.string().optional(),
      pkginfo: z
        .object({
          appName: z.string(),
          appVersion: z.string(),
          buildNumber: z.string(),
          packageName: z.string(),
        })
        .optional(),
      platform: z.string().optional(),
      product: z.string().optional(),
      fingerprint: z.string().optional(),
      manufacturer: z.string().optional(),
      systemName: z.string().optional(),
      version: z
        .object({
          baseOS: z.string(),
          codename: z.string(),
          incremental: z.string(),
          previewSdkInt: z.number(),
          release: z.string(),
          sdkInt: z.number(),
          securityPatch: z.string(),
        })
        .optional(),
    })
  ),
  displayName: z.string().optional(),
  eolcode: z.string().optional(),
  providerData: z.string().optional(),
  providerId: z.string().optional(),
  email: z.string().optional(),
  emailVerified: z.union([z.boolean(), z.string()]).optional(),
  phoneNumber: z.string().optional(),
  photoURL: z.string().optional(),
  providerid: z.string().optional(),
  regdata: z
    .object({
      teamname: z.string(),
    })
    .optional(),
});

const teamListSchema = z.object({
  name: z.string().optional(),
  nr: z.string().optional(),
  disabled: z.boolean().optional(),
  starttime: z.number().optional(),
  finishtime: z.number().optional(),
  col: z.string().optional(),
  klassid: z.record(z.union([z.string(), z.boolean()])),
  devs: z.record(z.string()),
  defaultdev: z.string().optional(),
  allowed: z.boolean().optional(),
  dsq: z.boolean().optional(),
  katk: z.boolean().optional(),
});

const kpSchema = z.object({
  nr: z.union([z.string(), z.number()]),
  allok: z.boolean().optional(),
  autokp: z.boolean().optional(),
  ra: z.number().optional(),
  maxanswerdistance: z.number().optional(),
  tyhistatud: z.boolean().optional(),
  inwork: z.boolean().optional(),
  kohustuslik: z.boolean().optional(),
  noopenwhennotclose: z.boolean().optional(),
});

const kPdataSchema = z.object({
  desc: z.string().optional(),
  longdesc: z.string().optional(),
  a: latLngLiteralSchema.optional(),
  loc: latLngLiteralSchema.optional(),
  anycross: z.boolean().optional(),
  responses: z.record(z.string()).optional(),
});

const kPAnswersSchema = z.string();

const archivedEventsDataTypeSchema = z.object({
  data: eventDataTypeSchema,
  conf: z
    .object({
      enablewslocations: z.boolean().optional(),
      featuretimetogo: z.boolean().optional(),
    })
    .optional(),
  docver: z.number(),
  issues: z.any().optional(),
  kp: z.record(kpSchema),
  kpanswer: z.record(kPAnswersSchema),
  kpdata: z.record(kPdataSchema),
});

const archivedTeamsTypeSchema = z.object({
  data: z.record(teamDataSchema),
  list: z.record(teamListSchema),
  marklog: z.any().optional(),
  track: z.any().optional(),
  trackstat: z.any().optional(),
});

export const archiveTypeSchema = z.object({
  events: eventTypeSchema,
  eventsdata: archivedEventsDataTypeSchema,
  teams: archivedTeamsTypeSchema,
  evdoc: eventDocSchema,
});
