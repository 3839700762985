import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import { Pause, PlayArrow } from "@mui/icons-material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState, useTSelector } from "rx/store";
import dayjs from "dayjs";
import {
  drawerMenuContentTypes,
  incPlayTime,
  setPlayTime,
} from "rx/appStateSlice";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const TimeSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 4px)",
  },
  "& .MuiSlider-track": {
    height: 8,
    borderRadius: 4,
  },
  "& .MuiSlider-rail": {
    height: 8,
    borderRadius: 4,
  },
});

const PlaySlider: React.FC<{
  setattheend: (v: boolean) => void;
  attheend: boolean;
}> = ({ setattheend, attheend }) => {
  const { teamsList, startTime, endTime, playTime, massStartTime } =
    useSelector((state: RootState) => {
      return {
        teamsList: state.teamsList,
        startTime: state.event.starttime,
        endTime: state.event.endtime,
        playTime: state.appState.playTime,
        massStartTime: state.appState.massStartTime,
      };
    }, shallowEqual);
  const dispatch = useDispatch();
  const { startms, endms } = useMemo(() => {
    if (massStartTime) {
      let endms = massStartTime;
      for (let tid in teamsList) {
        const team = teamsList[tid];
        if (team.disabled || !team.starttime || !team.finishtime) continue;
        const len = team.finishtime - team.starttime;
        if (massStartTime + len > endms) endms = massStartTime + len;
      }
      return { startms: massStartTime, endms };
    }
    let startms = null;
    let endms = null;
    for (let tid in teamsList) {
      if (teamsList[tid].disabled) continue;
      const teamstart = teamsList[tid].starttime;
      if (teamstart) {
        if (startms === null || teamstart < startms) startms = teamstart;
      }
      const teamfinish = teamsList[tid].finishtime;

      if (teamfinish) {
        if (endms === null || teamfinish > endms) endms = teamfinish;
      }
    }
    if (startms === null) startms = startTime || Date.now();
    if (endms === null) endms = endTime || Date.now();
    if (endms < startms) endms = startms;

    return { startms, endms };
  }, [teamsList, startTime, endTime, massStartTime]);

  useEffect(() => {
    if (playTime < startms) {
      dispatch(setPlayTime(startms));
    } else if (playTime > endms) {
      setattheend(true);
      dispatch(setPlayTime(endms));
    } else {
      if (attheend) setattheend(false);
    }
  }, [playTime, startms, endms, attheend, setattheend, dispatch]);

  return (
    <TimeSlider
      min={startms}
      max={endms}
      value={playTime}
      onChange={(_event, value) => {
        dispatch(setPlayTime(value));
      }}
    />
  );
};

var playSpeedList = [
  { name: "1x", interval: 100, step: 100 },
  { name: "10x", interval: 50, step: 500 },
  { name: "50x", interval: 50, step: 2500 },
  { name: "100x", interval: 50, step: 5000 },
];

const PlayButton: React.FC<{ attheend: boolean }> = ({ attheend }) => {
  const [running, setRunning] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(3);
  const dispatch = useDispatch();
  if (attheend && running) setRunning(false);
  useEffect(() => {
    if (running) {
      const spd = playSpeedList[playSpeed];
      const timer = setInterval(() => {
        dispatch(incPlayTime(spd.step));
      }, spd.interval);
      return () => {
        clearInterval(timer);
      };
    }
  }, [running, playSpeed, attheend, dispatch]);
  return (
    <>
      <IconButton
        aria-label="play"
        size="small"
        onClick={() => {
          setRunning(!running);
        }}
      >
        {running ? <Pause /> : <PlayArrow />}
      </IconButton>
      <FormControl
        sx={{
          width: 140,
          paddingLeft: 3,
          paddingRight: 3,
        }}
      >
        <Select
          aria-label="play-speed"
          variant="standard"
          value={playSpeed}
          onChange={(event) => setPlaySpeed(event.target.value as number)}
        >
          {playSpeedList.map((s, idx) => (
            <MenuItem key={idx} value={idx}>
              {s.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
const PlayTime: React.FC = () => {
  const massStartTime = useTSelector((s) => s.appState.massStartTime);
  const playTime = useTSelector((s) => s.appState.playTime);
  /* Another correct way to implement with single selector, without uneccessery re-renders, or use shallowEqual as compare function
  const [massStartTime, playTime] = useTSelector(
    (s) => [s.appState.massStartTime, s.appState.playTime],
    (oldv, newv) => oldv[0] === newv[0] && oldv[1] === newv[1]
  );*/
  const showTime = massStartTime
    ? dayjs(playTime - massStartTime).utc()
    : dayjs(playTime);
  return (
    <Typography variant={"h4"} sx={{ paddingLeft: 2 }}>
      {showTime.format("HH:mm:ss")}
    </Typography>
  );
};

const PlayControls: React.FC = () => {
  const [attheend, setAtTheEnd] = useState(false);
  const { eventId, liveGPSData, endTime, drawerContent } = useSelector(
    (state: RootState) => {
      return {
        eventId: state.eventId,
        liveGPSData: state.appState.liveGPSData,
        endTime: state.event.endtime,
        drawerContent: state.appState.drawerMenuContent,
      };
    },
    shallowEqual
  );
  if (
    !eventId ||
    endTime === undefined ||
    (liveGPSData && endTime > Date.now()) ||
    drawerContent === drawerMenuContentTypes.Speedings
  )
    return null;

  return (
    <Container
      sx={{
        padding: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      <PlayButton attheend={attheend} />
      <PlaySlider setattheend={setAtTheEnd} attheend={attheend} />
      <PlayTime />
    </Container>
  );
};

export default PlayControls;
