import isEqual from "lodash/isEqual";
import {
  createSelectorCreator,
  lruMemoize,
  createSelector,
} from "reselect";
import dayjs from "dayjs";
import { FBValueToArray, formatDuration } from "utils/SmallUtils";
import { getPerformance, trace } from "firebase/performance";
import store from "rx/store";
import { openDialog } from "rx/dialogsSlice";
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const calcOrder = (teamsList, results, orderrules) => {
  let neworder = teamsList
    .sort((l, r) => {
      let lr = results[l.id];
      let rr = results[r.id];
      if (!lr || !rr) return 0;
      let resp = 0;
      orderrules.find(
        (rule) => (resp = rule.rule(lr, rr, l.id, r.id))
      );
      return resp;
      /*\n          if (eventid < 118) {\n              tmp = r.labitudkpsid - l.labitudkpsid;\n              if (tmp !== 0) return tmp;\n              tmp = l.goingtimes - r.goingtimes;\n              if (tmp !== 0) return tmp;\n          } else {\n              tmp = l.goingtimes - r.goingtimes;\n              if (tmp !== 0) return tmp;\n              tmp = r.labitudkpsid - l.labitudkpsid;\n              if (tmp !== 0) return tmp;\n          }\n          */

    })
    .map((t) => t.id);
  let pospenaltys = {};
  teamsList.forEach(t => {
    if (t.penaltypos) {
      pospenaltys[t.id] = t.penaltypos;

    }

  });
  if (Object.keys(pospenaltys).length > 0) {
    for (let i = 0; i < neworder.length; i++) {
      if (pospenaltys[neworder[i]]) {
        let newpos = i;
        while (pospenaltys[neworder[i]] > 0) {
          newpos++;
          if (pospenaltys[neworder[newpos]] > 0) {
            pospenaltys[neworder[newpos]]++;

          } else {
            pospenaltys[neworder[i]]--;

          }
        };
        if (newpos !== i) {
          if (newpos > neworder.length)
            newpos = neworder.length - 1;
          neworder.splice(newpos, 0, neworder.splice(i, 1)[0]);

        }
        i--;
      }
    }
  }
  return [neworder, Object.keys(pospenaltys).length > 0];
}



export const calculateTimeResult = (result, starttime, finishtime) => {
  result.timepenaltytotal = 0;
  result.timepenaltys = [];
  let nextchange = finishtime ? undefined : null;
  const ajad = result.data.ajad;
  if (starttime) {
    let spent = Math.floor(
      ((finishtime ? finishtime : new Date().getTime()) - starttime) / 1000
    );
    result.data.spent = spent;
    if ("normaalaeg" in ajad) {
      if (spent < ajad.normaalaeg) {
        nextchange = ajad.normaalaeg - spent;
        spent = 0;
      } else spent -= ajad.normaalaeg;
      if (ajad.lisaajad) {
        for (let t of (ajad.lisaajad)) {
          let used = spent > t.time ? t.time : spent;
          spent -= used;
          if (spent === 0 && nextchange === null) {
            if (t.per === 0) nextchange = t.time - used;
            else nextchange = t.per - (used % t.per);
          }
          let tp = Math.ceil(used / t.per) * t.penalty;
          result.timepenaltys.push(tp);
          result.timepenaltytotal += tp;
        }
      }
    } else {
      for (let t of Object.values(ajad).sort((l, r) => l.order - r.order)) {
        let used = spent > t.time ? t.time : spent;
        spent -= used;
        if (spent === 0 && nextchange === null) {
          if (t.per === 0) nextchange = t.time - used;
          else nextchange = t.per - (used % t.per);
        }
        if (t.penalty === 0) continue;
        //let tp = Math.floor(used / t.per * t.penalty);
        let tp = Math.ceil(used / t.per) * t.penalty;
        result.timepenaltys.push(tp);
        result.timepenaltytotal += tp;
      }
    }
    if (spent > 0) result.data.overtime = true;
  } else {
    if ("normaalaeg" in ajad) {
      result.data.spent = 0;
      for (let i = 0; i < (ajad.lisaajad || {}).length; i++)
        result.timepenaltys.push(0);
    } else {
      for (let t of Object.values(ajad).sort((l, r) => l.order - r.order)) {
        result.data.spent = 0;
        if (t.penalty === 0) continue;
        result.timepenaltys.push(0);
      }
    }
  }
  return nextchange;
};

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);

const makeGetSpdTickets = () => {
  return createDeepEqualSelector(
    teamtickets => teamtickets,
    (teamtickets) => {
      if (!teamtickets) return null;
      let resp = {};
      Object.entries(teamtickets).forEach(([dev, devtickets]) => {
        Object.entries(devtickets).forEach(([tstamp, ticket]) => {
          if (ticket.state !== "enabled") return;
          if (!(dev in resp)) resp[dev] = {};
          resp[dev][tstamp] = ticket;
        });
      });
      return resp;
    }
  );
};

let kpstats = {};

export const getVisitStats = createSelector(
  [
    (state) => state.answerResult,
    (state) => state.teamAnswers,
    (state) => state.kpList,
    (state) => state.teamsList,
    (state) => state.event.answerscores,
    (state) => state.event.firstbonus,
  ],
  (answerResult, teamAnswers, kpList, teamsList, answerscores, firstbonuses) => {
    const [firstarray] = FBValueToArray(firstbonuses);
    const lastbonusindex = firstarray.length - 1;
    const traceob = trace(getPerformance(), "kpstats");
    traceob.incrementMetric("kps", Object.keys(kpList).length);
    traceob.incrementMetric("teams", Object.keys(answerResult).length);
    traceob.start();
    const kpgroups = {};
    Object.entries(teamAnswers).forEach(([tid, answers]) => {
      Object.entries(answers).forEach(([kpid, oneanswer]) => {
        if (kpgroups[kpid] === undefined)
          kpgroups[kpid] = {}
        kpgroups[kpid][tid] = oneanswer;
      });
    });

    Object.entries(kpgroups).forEach(([kpid, onekp]) => {
      Object.keys(onekp).sort((a, b) => {
        return ((onekp[a].aeg || Number.MAX_VALUE) - teamsList[a].starttime) - ((onekp[b].aeg || Number.MAX_VALUE) - teamsList[b].starttime)
      }).forEach((k, idx) => {
        if (!onekp[k].aeg)
          return;
        onekp[k] = Object.assign({}, onekp[k], { idx: idx })
      });
    });


    Object.keys(kpstats).forEach((stid) => {
      if (!(stid in teamsList)) {
        delete kpstats[stid];
        return;
      }
      const kpkeys = Object.keys(kpstats[stid]);
      const testkpid =
        (kpkeys.length > 0 && kpkeys[0] !== "firstkps" && kpkeys[0]) ||
        (kpkeys.length > 1 && kpkeys[1]);
      if (!(testkpid in kpList)) delete kpstats[stid];
    });
    Object.keys(answerResult)
      .filter((tid) => (teamsList[tid] && !teamsList[tid].disabled))
      .forEach((tid) => {
        if (!(tid in kpstats)) kpstats[tid] = { firstkps: {} };
      });
    let teamupdate = {};
    Object.keys(kpList).forEach((kpid) => {
      const kpvisticount = Object.entries(answerResult).reduce(
        (total, [tid, teamkps]) =>
          teamsList[tid] &&
            !teamsList[tid].disabled &&
            teamkps[kpid] &&
            (answerscores || teamkps[kpid].ok)
            ? total + 1
            : total,
        0
      );
      const firstteam = Object.entries(teamAnswers).reduce(
        (firstteam, [tid, teamkps]) =>
          teamsList[tid] &&
            !teamsList[tid].disabled &&
            teamkps[kpid] &&
            (answerscores || ((answerResult[tid] && answerResult[tid][kpid]) || {}).ok) &&
            (firstteam == null || firstteam.aeg > teamkps[kpid].aeg)
            ? { tid: tid, aeg: teamkps[kpid].aeg }
            : firstteam,
        null
      );
      //console.log('kp:', kpid, ', visitcount: ', kpvisticount);
      Object.entries(answerResult)
        .filter(([tid, _]) => teamsList[tid] && !teamsList[tid].disabled)
        .forEach(([tid, teamkps]) => {
          if (firstteam) {
            if (kpstats[tid].firstkps[kpid] && firstteam.tid !== tid) {
              teamupdate[tid] = true;
              delete kpstats[tid].firstkps[kpid];
            } else if (firstteam.tid === tid && !kpstats[tid].firstkps[kpid]) {
              teamupdate[tid] = true;
              kpstats[tid].firstkps[kpid] = firstteam.aeg;
            }
          }
          if (teamkps[kpid] && (teamkps[kpid].ok || answerscores)) {
            if (kpstats[tid]["first"] === undefined)
              kpstats[tid]["first"] = {};
            if (kpgroups[kpid] && kpgroups[kpid][tid] && kpstats[tid]["first"][kpid] !== kpgroups[kpid][tid].idx) {
              if (kpgroups[kpid][tid].idx + 1 > lastbonusindex) {
                if (kpstats[tid]["first"][kpid] !== undefined) {
                  teamupdate[tid] = true;
                  kpstats[tid]["first"][kpid] = undefined;
                }
              } else {
                kpstats[tid]["first"][kpid] = kpgroups[kpid][tid].idx;
                teamupdate[tid] = true;
              }
            }
            if (kpstats[tid][kpid] !== kpvisticount) {
              teamupdate[tid] = true;
              kpstats[tid][kpid] = kpvisticount;
              //console.log('assign kp stat', tid, kpid, kpvisticount);
            }
          }
        });
    });
    Object.keys(teamupdate).forEach((tid) => {
      kpstats[tid] = Object.assign({}, kpstats[tid]);
    });
    //console.log('final stats', kpstats, answerResult);
    traceob.stop();
    return kpstats;
  }
);

export const makeGetResult = () => {
  const resultFuncHolder = {};
  const getSpdTickets = makeGetSpdTickets();

  const emptyobj = {};

  return createSelector(
    [
      (_, props) => props.tid,
      (_, props) => props.klass,
      (state, props) => state.answerResult[props.tid],
      (state, props) => state.teamAnswers[props.tid],
      (state) => state.kpAnswers,
      (state) => state.kpList,
      (state) => state.kpData,
      (state) => state.event.rakoef,
      (state) => state.event.kpgroups,
      (state) => state.event.sharedrakoef,
      (state) => state.event.answerscores,
      (state) => state.event.wrongpenaltyenabled,
      (state) => state.event.wrongcoef || 1,
      (state) => state.event.ly,
      (state) => state.event.firstkpvisitbonus,
      (state) => state.event.distanceinresult,
      (state) => state.event.dstresult,
      (state, props) => state.teamsList[props.tid].defaultdev,
      (state, props) => state.teamsList[props.tid].lypoints || emptyobj,
      (state, props) => state.teamsList[props.tid].starttime,
      (state, props) => state.teamsList[props.tid].finishtime,
      (state) => state.event.ajad,
      (state) => state.event.firstbonus,
      (state) => state.event.firstbonusismultiplier,
      (state, props) =>
        getSpdTickets(
          props.havekiirustrahvid ? state.spdTickets[props.tid] : null
        ),
      (state) => state.event.speedpenalty,
      (state, props) =>
        state.event.sharedrakoef || state.event.firstkpvisitbonus || state.event.firstbonus
          ? getVisitStats(state, props)[props.tid]
          : emptyobj,
      (_, props) => {
        resultFuncHolder.newResult = props.newResult;
        return resultFuncHolder;
      },
    ],
    (
      tid,
      klass,
      answerResult,
      teamAnswers,
      kpAnswers,
      kpList,
      kpData,
      rakoef,
      kpgroups,
      sharedrakoef,
      answerscores,
      wrongpenaltyenabled,
      wrongcoef,
      ly,
      firstkpvisitbonus,
      distanceinresult,
      dstresult,
      defaultdev,
      lypoints,
      starttime,
      finishtime,
      ajad,
      firstbonus,
      firstismultiplier,
      spdtickets,
      spdpenalty,
      visitstats,
      reportNewResult
    ) => {
      //console.log('Recalculatingf result for ', tid, sharedrakoef, starttime, visitstats);
      let sharedrakoefids = {};
      if (sharedrakoef)
        sharedrakoef.split(",").forEach((sid) => (sharedrakoefids[sid] = true));
      let newresult = {
        rasums: rakoef.map((_) => 0),
        timepenaltys: [],
        ly: lypoints,
        okcount: 0,
        wrongcount: 0,
        kppenalty: 0,
        kpkokku: 0,
        data: {
          starttime: starttime,
          finishtime: finishtime,
          ajad: "normaalaeg" in ajad ? ajad : ajad[klass],
        },
      };
      let okkps = {};
      let kpok = ([id, v]) => {
        if (kpList[id] === undefined) {
          const state = store.getState();
          store.dispatch(openDialog("deletedkponteam"));
          console.error("team", tid, state.teamsList[tid], "has a KP with id ", id, "that does not exist");
          return;
        }
        if (kpList[id].tyhistatud) return;
        let isok = kpList[id].allok || kpList[id].autokp || (kpData[id] || {}).a;
        if (!isok) isok = "answer" in v ? v.answer === kpAnswers[id] : v.ok;
        if (isok === undefined) {
          return;
        }
        let ra = kpList[id].ra || 1;
        let grp = kpList[id].grp || 1;
        if (isok || answerscores) {
          if (
            sharedrakoef &&
            (visitstats === undefined || !(id in visitstats))
          ) {
            console.error(
              "visitstats is empty when it should not be. Might be there area entries in kpanswers but not in answerresult. team id: " +
              tid +
              ", kp id:" +
              id
            );
          }
          //console.log('hmm', ra, id, sharedrakoefids[ra], visitstats[id]);
          newresult.rasums[ra] += (sharedrakoefids[ra]
            ? Math.floor(rakoef[ra] / visitstats[id])
            : rakoef[ra]) * grp;
          newresult.okcount++;
          okkps[id] = true;
        }
        if (!isok) {
          newresult.wrongcount++;
          if (kpList[id].wrongpenalty !== undefined) {
            newresult.kppenalty += Number(kpList[id].wrongpenalty)
          } else if (wrongpenaltyenabled)
            newresult.kppenalty +=
              (sharedrakoefids[ra]
                ? Math.floor(rakoef[ra] / (visitstats[id] || 1))
                : rakoef[ra]) * wrongcoef;
        }
      };
      if (teamAnswers && Object.keys(teamAnswers).length > 0) {
        Object.entries(teamAnswers).forEach(kpok);
      } else {
        Object.entries(answerResult || {}).forEach(kpok);
      }

      calculateTimeResult(newresult, starttime, finishtime);

      Object.entries(kpList)
        .filter(([id, e]) => e.kohustuslik && !e.tyhistatud)
        .forEach(([id, e]) => {
          //console.log(id, e);
          if (!okkps[id]) {
            let ra = kpList[id].ra || 1;
            newresult.kppenalty += rakoef[ra] * 2;
          }
        });
      if (visitstats && firstkpvisitbonus) {
        newresult.firstkpsbonus = Object.keys(visitstats.firstkps).reduce(
          (total, fkpid) => {
            let ra = kpList[fkpid].ra;
            let kpval = sharedrakoefids[ra]
              ? Math.floor(rakoef[ra] / visitstats[fkpid])
              : rakoef[ra];
            return total + kpval * firstkpvisitbonus;
          },
          0
        );
      }
      if (visitstats && firstbonus) {
        newresult.firstbonus = rakoef.map((_) => 0)
        if (visitstats.first) {
          Object.entries(visitstats.first).forEach(([fkpid, idx]) => {
            let ra = kpList[fkpid].ra || 1;
            newresult.firstbonus[ra] += firstbonus[idx + 1] * (firstismultiplier ? rakoef[ra] : 1);
          });
        }
      }
      newresult.kpsum = newresult.rasums.reduce((a, v) => a + v);
      newresult.kpkokku =
        newresult.kpsum - newresult.kppenalty + (newresult.firstkpsbonus || 0);
      if (newresult.firstbonus)
        newresult.kpkokku += newresult.firstbonus.reduce((a, v) => a + v);
      newresult.spdpenalty = 0;
      if (spdtickets) {
        Object.values(spdtickets).forEach((v) => {
          newresult.spdpenalty += Object.values(v).length * spdpenalty;
        });
      }
      const lykeys = Object.keys(ly || {}).filter(
        (lyid) =>
          ly[lyid].forclasses === undefined ||
          ly[lyid].forclasses.includes(klass)
      );

      newresult.lypoints = lykeys.reduce(
        (total, current) =>
          total + (lypoints[current] || 0) * (ly[current].ispenalty ? -1 : 1),
        0
      );
      newresult.dst = 0;
      if (distanceinresult && starttime) {
        const st = store.getState().tracks[tid];
        if (st) {
          const tkeys = Object.keys(st);
          const tk = tkeys.length > 0 ? tkeys[0] : "none";
          const t = st[defaultdev] || st[tk];
          let startd = undefined;
          let endd = undefined;
          let i;
          for (i = 0; i < t.length; i++) {
            if (t[i].t < starttime) continue;
            if (startd === undefined) {
              startd = t[i].d;
            }
            if (!finishtime) break;
            else {
              if (t[i].t > finishtime) break;
            }
          }
          if (!finishtime || i === t.length) {
            endd = t[t.length - 1].d;
          } else {
            endd = t[i - 1].d;
          }

          newresult.dst = Math.floor(endd - startd);
          if (dstresult && dstresult.targetdst && dstresult.dstpoints) {
            let dstawaysteps = Math.floor(Math.abs(dstresult.targetdst - newresult.dst) / (dstresult.dststeps || 1));
            newresult.dstpoints = dstresult.dstpoints || 0;
            while (dstawaysteps && newresult.dstpoints) {
              newresult.dstpoints -= (dstresult.dstpointssteps || 1);
              if (newresult.dstpoints < 0)
                newresult.dstpoints = 0;
              dstawaysteps--;
            }
          }
        }
      }
      newresult.sum =
        newresult.kpkokku -
        newresult.timepenaltytotal -
        newresult.spdpenalty +
        (newresult.dstpoints || 0) +
        newresult.lypoints;

      newresult.spenttime =
        !newresult.data.finishtime || newresult.data.spent === 0
          ? ""
          : formatDuration(dayjs.duration(newresult.data.spent * 1000));
      if (reportNewResult.newResult) reportNewResult.newResult(newresult);

      return newresult;
    }
  );
};
