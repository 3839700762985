import { Card, CardHeader, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";

import "utils/NutiMap.css";
import * as L from "leaflet";
import { KPPaths } from "components/KPIcon";
import { KP, KPdata } from "rx/fbListSlices";
import { useDispatch } from "react-redux";
import { setEditKPId } from "rx/appStateSlice";
import { useTSelector } from "rx/store";
import { Edit } from "@mui/icons-material";
import "./kpmarkerstyles.css";

const KPMarker: React.FC<{
  kp: KP;
  kpid?: string;
  kpdata?: KPdata;
  position?: L.LatLngLiteral;
  atzoom?: number;
  color?: string;
  draggable?: boolean;
  eventHandlers?: L.LeafletEventHandlerFnMap;
  enableInfoWidget?: boolean;
}> = ({
  position,
  eventHandlers,
  color,
  kpid,
  kp,
  kpdata,
  atzoom,
  draggable,
  enableInfoWidget,
}) => {
  const isAdmin = useTSelector((state) => state.user.eventAccess);
  const dispatch = useDispatch();
  const marker = useRef<L.Marker>(null);
  let size = 40;
  if (atzoom) {
    if (atzoom <= 8) size = 10;
    else if (atzoom <= 10) size = 20;
  }
  const markercolor = color || kp.inwork ? "blue" : "red";
  let pathstrings: string[] = [];
  let ra = kp.ra || 1;
  if (ra < 1 || ra > 3) ra = 1;
  KPPaths[ra][kp.kohustuslik ? "double" : "single"].forEach((p) => {
    pathstrings.push(`<path d="${p}"/>`);
  });
  const icon = L.divIcon({
    className: "my-custom-pin",
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    html: `<svg xmlns="http://www.w3.org/2000/svg" fill="${markercolor}" viewBox="0 0 24 24">${pathstrings.join()}</svg>`,
  });
  const sethandlers = Object.assign({}, eventHandlers, {
    click: (event: L.LeafletMouseEvent) => {
      const parentclick = eventHandlers?.click;
      if (parentclick) parentclick(event);
    },
  });
  const loc = position || kpdata?.loc;
  if (!loc || !loc.lat || !loc.lng) return null;
  const tooltipp = size / 2 + 2;
  return (
    <Marker
      eventHandlers={sethandlers}
      autoPan
      riseOnHover={true}
      icon={icon}
      position={loc}
      draggable={draggable}
      ref={marker}
    >
      {atzoom && atzoom > 10 && (
        <Tooltip
          direction="center"
          offset={[tooltipp, -tooltipp]}
          permanent
          className="KPTooltip"
        >
          {kp.nr}
        </Tooltip>
      )}
      {enableInfoWidget && (
        <Popup className={"kpmarker"}>
          <Card
            elevation={0}
            style={{
              backgroundColor: "white",
              margin: "1px !important",
              borderRadius: "12px",
            }}
          >
            <CardHeader
              title={"Nr: " + kp.nr}
              avatar={
                kpid &&
                isAdmin && (
                  <IconButton
                    onClick={() => {
                      marker.current?.closePopup();
                      dispatch(setEditKPId(kpid));
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                )
              }
              subheader={kpdata?.desc}
            />
          </Card>
        </Popup>
      )}
    </Marker>
  );
};

export default KPMarker;
