import React, { useMemo } from "react";
import { RootState, useTSelector } from "rx/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import { CheckCircle, CheckCircleOutline, Clear } from "@mui/icons-material";
import {
  hideTrack,
  unhideAllTracks,
  unhideTrack,
  TrackStat,
  TrackTailLengths,
  setTrackTailLength,
  setLiveGPSData,
  setMassStartTime,
  toggleShowTrackLabels,
} from "rx/appStateSlice";
import dayjs from "dayjs";
import TeamContextMenu from "./TeamContextMenu";
import { flyToTeam } from "utils/MapUtils";

const defaultTrack = (state: RootState, tid: string) => {
  const teamtracks = state.tracks[tid];
  if (teamtracks === undefined) return undefined;
  const devs = Object.entries(state.teamsList[tid].devs || {});
  if (devs.length === 0) return undefined;
  let dev = devs.find(([, devtype]) => devtype === "default");
  if (dev === undefined) dev = devs[0];
  return teamtracks[dev[0]];
};

const TeamEntryStat: React.FC<{ tid: string }> = ({ tid }) => {
  const statType = useTSelector((state) => state.appState.trackMenuStatType);
  const track = useTSelector((state) => defaultTrack(state, tid));
  const statstr = useMemo(() => {
    if (track && track.length > 0) {
      const lastpoint = track[track.length - 1];
      if (statType === TrackStat.Speed) {
        if (new Date().getTime() - lastpoint.t < 60000)
          return lastpoint.s.toFixed(0) + " km/h";
      } else if (statType === TrackStat.Distance) {
        if (lastpoint.d < 1000) return lastpoint.d.toFixed(0) + " m";
        else return (lastpoint.d / 1000).toFixed(1) + "  km";
      }
    }
    return "";
  }, [statType, track]);
  return <>{statstr}</>;
};
const TeamEntry: React.FC<{ tid: string; style: any }> = ({ tid, style }) => {
  const hidden = useTSelector((state) => state.appState.hiddenTracks[tid]);
  const team = useTSelector((state) => state.teamsList[tid]);

  const dispatch = useDispatch();
  return (
    <TeamContextMenu tid={tid}>
      {(onContext) => (
        <ListItem
          style={style}
          button={true}
          onContextMenu={onContext}
          onClick={() => {
            if (hidden) dispatch(unhideTrack(tid));
            flyToTeam(tid);
          }}
        >
          <ListItemIcon>
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(hidden ? unhideTrack(tid) : hideTrack(tid));
              }}
            >
              {hidden ? (
                <CheckCircleOutline sx={{ color: team.col }} />
              ) : (
                <CheckCircle sx={{ color: team.col }} />
              )}
            </IconButton>
          </ListItemIcon>
          <ListItemText inset={false} secondary={<TeamEntryStat tid={tid} />}>
            <Typography noWrap>{team.name}</Typography>
          </ListItemText>
        </ListItem>
      )}
    </TeamContextMenu>
  );
};
const mapState = (state: RootState) => ({
  teamsList: state.teamsList,
  trackTailLength: state.appState.trackTailLength,
  liveGPS: state.appState.liveGPSData,
  massStartTime: state.appState.massStartTime,
  eventEndTime: state.event.endtime,
  showTrackLabels: state.appState.showTrackLabels,
});
const mapDispatch = {
  hideTracks: (tids: string[]) => hideTrack(tids),
  unhideAllTracks: () => unhideAllTracks(),
  unhideTrack: (tid: string) => unhideTrack(tid),
  setTrackTailLength: (l: TrackTailLengths) => setTrackTailLength(l),
  setLiveGPS: (v: boolean) => setLiveGPSData(v),
  setMassStartTime: (t: number | null) => setMassStartTime(t),
  toggleTrackLabels: () => toggleShowTrackLabels(),
};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TracksMenuProps = PropsFromRedux & WithTranslation & {};
type TracksMenuState = {
  tracksfilter: string;
  tailTypeAnchorEl: null | HTMLElement;
};
class TracksMenu extends React.Component<TracksMenuProps, TracksMenuState> {
  state: TracksMenuState = {
    tracksfilter: "",
    tailTypeAnchorEl: null,
  };

  allTracksHides = true;
  toggleAllTracks = () => {
    if (this.allTracksHides) {
      this.props.hideTracks(Object.keys(this.props.teamsList));
    } else {
      this.props.unhideAllTracks();
    }
    this.allTracksHides = !this.allTracksHides;
  };
  toggleLiveGPS = () => {
    this.props.setLiveGPS(!this.props.liveGPS);
  };
  toggleMassStart = () => {
    if (this.props.massStartTime) {
      this.props.setMassStartTime(null);
      return;
    }
    let mintime = 0;
    Object.values(this.props.teamsList)
      .filter((team) => !team.disabled)
      .forEach((team) => {
        if (mintime === 0 && team.starttime) mintime = team.starttime;
        else {
          if (team.starttime && team.starttime < mintime)
            mintime = team.starttime;
        }
      });
    this.props.setMassStartTime(mintime);
  };
  render() {
    const {
      teamsList,
      trackTailLength,
      liveGPS,
      eventEndTime,
      massStartTime,
      showTrackLabels,
    } = this.props;
    const { tracksfilter } = this.state;
    const eventended = eventEndTime && dayjs().isAfter(eventEndTime);
    const teams = Object.keys(teamsList)
      .filter((t) => !teamsList[t].disabled)
      .filter(
        (t) =>
          tracksfilter.trim().length === 0 ||
          teamsList[t].name
            ?.toLocaleLowerCase()
            .includes(tracksfilter.toLocaleLowerCase())
      )
      .sort((l, r) =>
        (teamsList[l].name || "").localeCompare(teamsList[r].name || "")
      );
    const { t } = this.props;
    const Row = ({ index, style }: { index: number; style: any }) => (
      <TeamEntry style={style} tid={teams[index]} />
    );
    const tailLengthToStr = (l: TrackTailLengths) => {
      if (l === TrackTailLengths.Full) return t("track.tail.full");
      else if (l === TrackTailLengths.NoTail) return t("track.tail.notrack");
      else if (l < TrackTailLengths.Tail1d)
        return t("track.tail.inminutes", {
          count: l / TrackTailLengths.Tail1m,
        });
      else
        return t("track.tail.days", {
          count: l / TrackTailLengths.Tail1d,
        });
    };

    return (
      <>
        <ListItem>
          <ListItemIcon>
            <IconButton size="small" onClick={this.toggleAllTracks}>
              <CheckCircle />
            </IconButton>
          </ListItemIcon>
          <TextField
            variant="standard"
            label={t("track.filterlabel")}
            value={this.state.tracksfilter}
            onChange={(event) => {
              this.setState({ tracksfilter: event.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear team filter"
                    onClick={() => {
                      this.setState({ tracksfilter: "" });
                    }}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                    size="large"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        <List height={300} width={240} itemCount={teams.length} itemSize={45}>
          {Row}
        </List>
        <Divider />
        <ListItem
          button
          dense
          onClick={(e) => {
            this.setState({ tailTypeAnchorEl: e.currentTarget });
          }}
        >
          <ListItemText
            inset
            primary={t("track.tail.label")}
            secondary={tailLengthToStr(trackTailLength)}
          />
        </ListItem>
        {!eventended && (
          <ListItem dense button onClick={this.toggleLiveGPS}>
            <ListItemIcon>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={liveGPS}
                onClick={this.toggleLiveGPS}
              />
            </ListItemIcon>
            <ListItemText>{t("trackmenu.live")}</ListItemText>
          </ListItem>
        )}
        {(eventended || !liveGPS) && (
          <ListItem dense button onClick={this.toggleMassStart}>
            <ListItemIcon>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={Boolean(massStartTime)}
                onClick={this.toggleMassStart}
              />
            </ListItemIcon>
            <ListItemText>{t("trackmenu.massstart")}</ListItemText>
          </ListItem>
        )}
        <ListItem dense button onClick={this.props.toggleTrackLabels}>
          <ListItemIcon>
            <Checkbox tabIndex={-1} disableRipple checked={showTrackLabels} />
          </ListItemIcon>
          <ListItemText>{t("trackmenu.tracklabels")}</ListItemText>
        </ListItem>
        <Menu
          anchorEl={this.state.tailTypeAnchorEl}
          open={Boolean(this.state.tailTypeAnchorEl)}
          onClose={() => {
            this.setState({ tailTypeAnchorEl: null });
          }}
        >
          {Object.keys(TrackTailLengths)
            .filter((key: any) => isNaN(Number(TrackTailLengths[key])))
            .map((tl) => (
              <MenuItem
                key={tl}
                selected={Number(tl) === trackTailLength}
                onClick={() => {
                  this.setState({ tailTypeAnchorEl: null });
                  this.props.setTrackTailLength(Number(tl));
                }}
              >
                {tailLengthToStr(Number(tl))}
              </MenuItem>
            ))}
        </Menu>
      </>
    );
  }
}

export default connector(withTranslation()(TracksMenu));

/*
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { hideTeam, zoomToTrack, setHiddenTeams, setTrackTailLength, setReplayType, setTrackType } from '../redux/actionsEvents';


const trackTypes = {
    'no': 'No tracks',
    'live': 'Live tracking',
    'replay': 'Replay',
    'full': 'Full track'
};

const tailTypes = {
    '0': 'No tail',
    '60000': '1 minute',
    '300000': '5 minutes',
    '86400000': '1 day'
};

const replayTypes = {
    'ownstart': 'Oma stardiajad',
    'massstart': 'Ühisstart',
};

class TracksMenu extends Component {

    state = {
        replayType: 'ownstart'
    }

    teamClicked = (tid, devid) => () => {
        this.props.showTeam(tid, true);
        const devid = this.props.teamsList[tid].defaultdev || Object.keys(this.props.teamsList[tid].devs || {})[0];
        this.props.zoomToTrack(tid, devid);
        return;
    };


    toggleTeam = (tid) => () => {
        this.props.showTeam(tid, this.props.hiddenTeams[tid]);
    }

    activateAllTeams = () => {
        this.props.setHiddenTeams([]);
        return;
    }

    deactivateAllTeams = () => {
        const { teamsList } = this.props;
        this.props.setHiddenTeams(Object.keys(teamsList));
    }

    selectTrackType = (type) => () => {
        this.props.setTrackType(type);
        this.setState({ trackViewTypeAnchorEl: null });
    }

    selectTailType = (type) => () => {
        this.props.setTrackTailLength(type);
        this.setState({ tailTypeAnchorEl: null });
    }
    selectReplayType = (type) => () => {
        this.props.setReplayType(type);
        this.setState({ replayType: type, replayTypeAnchorEl: null });
    }

    render() {
        const { classes, teamsList, trackType, trackTailLength, eventended, hiddenTeams } = this.props;
        const { replayType } = this.state;
        let active = trackType !== 'no';

        return (
            <div>
                <ListItem button className={classes.nested} onClick={(e) => { this.setState({ trackViewTypeAnchorEl: e.currentTarget }) }} >
                    <ListItemText primary="Track view type" secondary={trackTypes[trackType]} />
                </ListItem>
                {(trackType === 'live' || trackType === 'replay') && (<ListItem button className={classes.nested} onClick={(e) => { this.setState({ tailTypeAnchorEl: e.currentTarget }) }} >
                    <ListItemText primary="Tail length" secondary={tailTypes[trackTailLength]} />
                </ListItem>)}
                {trackType === 'replay' && (
                    <ListItem button className={classes.nested} onClick={(e) => { this.setState({ replayTypeAnchorEl: e.currentTarget }) }}>
                        <ListItemText primary="Replay type" secondary={replayTypes[replayType]} />
                    </ListItem>
                )}
                <Divider />
                <ListSubheader className={classes.nested} disableSticky={true} >Teams</ListSubheader>
                {active && (<ListItem>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.activateAllTeams}>
                        Show all
                    </Button>
                    <Button variant="contained" style={{ marginLeft: '16px' }} color="primary" className={classes.button} onClick={this.deactivateAllTeams}>
                        Show none
                     </Button>
                </ListItem>)}
                {Object.keys(teamsList).filter(t => !teamsList[t].disabled).sort((l, r) => (teamsList[l].name || "").localeCompare(teamsList[r].name)).map((tkey) => (
                    <TeamEntryInMenu
                        key={tkey}
                        teamid={tkey}
                        devid={teamsList[tkey].defaultdev || Object.keys(teamsList[tkey].devs || { 'dummy': '' })[0]}
                        onClick={active ? this.teamClicked(tkey) : undefined}
                        secondaryClick={active ? this.toggleTeam(tkey) : undefined}
                        active={active && !hiddenTeams[tkey]}
                    />
                ))}
                <Menu anchorEl={this.state.trackViewTypeAnchorEl} open={Boolean(this.state.trackViewTypeAnchorEl)} onClose={() => { this.setState({ trackViewTypeAnchorEl: null }) }} >
                    {Object.keys(trackTypes).filter(t => !(t === 'live' && eventended)).map(t => (
                        <MenuItem key={t} selected={t === trackType} onClick={this.selectTrackType(t)}>{trackTypes[t]}</MenuItem>
                    ))}
                </Menu>
                <Menu anchorEl={this.state.tailTypeAnchorEl} open={Boolean(this.state.tailTypeAnchorEl)} onClose={() => { this.setState({ tailTypeAnchorEl: null }) }} >
                    {Object.keys(tailTypes).map(t => (
                        <MenuItem key={t} selected={t === trackTailLength} onClick={this.selectTailType(t)}>{tailTypes[t]}</MenuItem>
                    ))}
                </Menu>
                <Menu anchorEl={this.state.replayTypeAnchorEl} open={Boolean(this.state.replayTypeAnchorEl)} onClose={() => { this.setState({ replayTypeAnchorEl: null }) }} >
                    {Object.keys(replayTypes).map(t => (
                        <MenuItem key={t} selected={t === replayType} onClick={this.selectReplayType(t)}>{replayTypes[t]}</MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentEventData: state.currentEventData,
    teamsList: state.teamsList,
    trackType: state.appState.trackType,
    trackTailLength: state.appState.trackTailLength,
    hiddenTeams: state.appState.hiddenTeams,

})

const mapDispatchToProps = (dispatch) => ({
    setTrackType: (type) => dispatch(setTrackType(type)),
    setTrackTailLength: l => dispatch(setTrackTailLength(l)),
    setReplayType: (type) => dispatch(setReplayType(type)),
    showTeam: (tid, show) => dispatch(hideTeam(tid, !show)),
    setHiddenTeams: (teamids) => dispatch(setHiddenTeams(teamids)),
    zoomToTrack: (tid, devid) => dispatch(zoomToTrack(tid, devid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TracksMenu);
*/
