import { styled } from "@mui/material/styles";
import {
  LatLng,
  LeafletEvent,
  LeafletMouseEvent,
  Map as LeafletMap,
} from "leaflet";
import React, { useEffect } from "react";
import {
  LayersControl,
  MapContainer,
  MapContainerProps,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "utils/NutiMap.css";
import * as L from "leaflet";
import { estoniaCoords } from "./SmallUtils";
import { useTSelector } from "rx/store";
import GoogleMapsLayer from "./GoogleMapsLayer";
import BingMapsLayer from "./BingMapsLayer";
import "leaflet-providers";
import MaaAmetLayer, { NutimeriLayer } from "./MaaAmetLayers";
import LeafLetProviderLayer from "./LeafletProviderLayer";

const Root = styled("div")({
  height: "100%",
  width: "100%",
});

export const ClickHandler: React.FC<{ onClick: (ll: LatLng) => void }> = ({
  onClick,
}) => <ClickHandlerOnMap onClick={onClick} map={useMap()} />;

const ClickHandlerOnMap: React.FC<{
  onClick: (ll: LatLng) => void;
  map: L.Map;
}> = ({ onClick, map }) => {
  useMapEvents({
    click(event: LeafletEvent) {
      onClick((event as LeafletMouseEvent).latlng);
    },
  });
  useEffect(() => {
    L.DomUtil.addClass(map.getContainer(), "crosshair-cursor-enabled");
    return () => {
      L.DomUtil.removeClass(map.getContainer(), "crosshair-cursor-enabled");
    };
  }, [map]);
  return null;
};

const TileLayerWrapper: React.FC = () => {
  const haveEventData = useTSelector(
    (state) => state.dataAvailability.eventData
  );
  const maplayer = useTSelector((state) => state.event.map?.default?.type);
  const mapconf = useTSelector((state) => state.event.map?.default?.conf);
  const eventId = useTSelector((state) => state.eventId);
  if (eventId && !haveEventData) return null;
  return <SingleTileLayer maplayer={maplayer} conf={mapconf} />;
};

const SingleTileLayer: React.FC<{ maplayer?: string; conf?: string }> = ({
  maplayer,
  conf,
}) => {
  if (maplayer === "google") {
    return <GoogleMapsLayer conf={conf} />;
  } else if (maplayer === "bing") {
    return <BingMapsLayer conf={conf} />;
  } else if (maplayer === "leaflet") {
    return <LeafLetProviderLayer conf={conf} />;
  } else if (maplayer === "maaamet") {
    if (conf) return <MaaAmetLayer conf={conf} />;
    else return null;
  } else if (maplayer === "nutimeri") {
    return <NutimeriLayer />;
  }
  return (
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  );
};

const defaultLayers = [
  {
    type: "maaamet",
    title: "Maaameti ortofoto",
    conf: '{"identifier":"foto","tilematrixset":"GMC","template":"https://tiles.maaamet.ee/tm/wmts/1.0.0/foto/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png"}',
  },
  {
    type: "maaamet",
    title: "Maaameti eesti kaart",
    conf: '{"title":"Eesti kaart","abstract":"","identifier":"kaart","tilematrixset":"GMC","template":"https://tiles.maaamet.ee/tm/wmts/1.0.0/kaart/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png"}',
  },
  {
    type: "osm",
    title: "Open street map",
  },
  {
    type: "google",
    title: "Google Maps",
  },
  {
    type: "google",
    title: "Google satellite",
    conf: "satellite",
  },
  {
    type: "bing",
    title: "Bing Maps",
  },
];

const NutiMapLayersControl: React.FC = () => {
  const configuredLayers = useTSelector((state) => state.event.map?.userlayers);
  const defaultmap = useTSelector((state) => state.event.map?.default?.type);
  const defaultconf = useTSelector((state) => state.event.map?.default?.conf);

  if (defaultmap === "maaamet" && defaultconf) {
    const confobj = JSON.parse(defaultconf);
    if (confobj.tilematrixset === "LEST") return <TileLayerWrapper />;
  }
  const usedLayers = configuredLayers || defaultLayers;
  if (usedLayers.length === 1 && usedLayers[0].title === "Default") return null;
  return (
    <LayersControl position="topleft">
      {usedLayers.map((l) => (
        <LayersControl.BaseLayer name={l.title} key={l.title}>
          <SingleTileLayer conf={l.conf} maplayer={l.type} />
        </LayersControl.BaseLayer>
      ))}
      <LayersControl.BaseLayer checked name="Default">
        <TileLayerWrapper />
      </LayersControl.BaseLayer>
    </LayersControl>
  );
};
const NutiMap: React.FC<
  {
    center?: L.LatLngExpression | undefined;
    onClick?: (ll: LatLng) => void;
    disableLayersControl?: boolean;
  } & MapContainerProps &
    React.RefAttributes<LeafletMap>
> = (props) => {
  const { center, onClick, children, ...rest } = props;

  return (
    <Root>
      <MapContainer
        center={center || estoniaCoords.center}
        zoom={center ? 13 : estoniaCoords.zoom}
        {...rest}
      >
        {props.disableLayersControl ? (
          <TileLayerWrapper />
        ) : (
          <NutiMapLayersControl />
        )}
        {onClick && <ClickHandler onClick={onClick} />}
        {children}
      </MapContainer>
    </Root>
  );
};

export default NutiMap;
