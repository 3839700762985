import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";

//import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import i18nNutilogiBackend from "utils/i18n-nutilogi-backend";

require("dayjs/locale/et");

const resources = require("./translations.json");

new LanguageDetector();
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  .use(i18nNutilogiBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .on("languageChanged", (lng) => {
    dayjs.locale(lng.substring(0, 2));
  })
  .init({
    resources,
    fallbackLng: "en",
    debug: false,
    saveMissing: true,
    saveMissingTo: "all",
    partialBundledLanguages: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}",
      addPath: "locales/add/{{lng}}/{{ns}}",
    },
  });

export default i18n;
