import {
  ClickAwayListener,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LatLngBounds, Marker } from "leaflet";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setEditKPId,
  setEditTeamId,
  setViewTeamResult,
} from "rx/appStateSlice";
import { KP, KPdata, TeamData, TeamList } from "rx/fbListSlices";
import store from "rx/store";
import { flyToTeam } from "utils/MapUtils";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  Edit,
  LocationSearching,
  Reorder,
  LocalAirport,
  LocalMall,
  LocationCity,
} from "@mui/icons-material";

enum SearchGroups {
  Teams = 0,
  KPs,
  Commands,
  GeoLocations,
  Unset,
}

var titles = ["search.teams", "search.kps", "search.commands", "search.geoloc"];

type SelectParameters = { selected: boolean; params: string | undefined };
type SetTriggerParameter = {
  setTrigger: (t: (param?: string) => boolean) => (param?: string) => void;
};

type SearchResult = SearchResultEntry[];
type BaseSearchResultEntry = {
  renderer: (
    o: SearchResultEntry,
    opts: SelectParameters,
    setTrigger: SetTriggerParameter
  ) => React.ReactElement | null;
  id: string;
  actions: { name: string; icon: React.ReactNode }[];
  trigger: (param?: string) => void;
};

type TeamResultEntry = {
  group: SearchGroups.Teams;
  team: TeamList;
  data: TeamData;
  tid: string;
};
type KPResultEntry = {
  group: SearchGroups.KPs;
  kp: KP;
  data: KPdata;
  kpid: string;
};
type GooglePlacesResultEntry = {
  group: SearchGroups.GeoLocations;
} & google.maps.places.AutocompletePrediction;
type SearchResultEntry = BaseSearchResultEntry &
  (TeamResultEntry | KPResultEntry | GooglePlacesResultEntry);

const optionRenderer = (props: {
  id: string;
  icon: React.ReactNode | null;
  primaryText: string;
  secondaryText?: string;
  actions: { name: string; icon: React.ReactNode }[];
  trigger: (param?: string) => void;
  opts: SelectParameters;
}): React.ReactElement => {
  const { id, icon, primaryText, secondaryText, actions, trigger, opts } =
    props;
  var styleForListItem: React.CSSProperties = {};
  if (actions.length > 0) {
    styleForListItem = { paddingRight: 96 };
  }
  return (
    <ListItem key={id} style={styleForListItem}>
      <ListItemButton
        onClick={() => trigger()}
        className={
          opts.selected && !opts.params ? "Mui-focusVisible Mui-focused" : ""
        }
      >
        <ListItemIcon>{icon && <Icon>{icon}</Icon>}</ListItemIcon>

        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
      {actions.length > 0 && (
        <ListItemSecondaryAction>
          {actions.map((a) => (
            <IconButton
              key={a.name}
              style={
                opts.selected && opts.params === a.name
                  ? { backgroundColor: "gray" }
                  : {}
              }
              onClick={() => trigger(a.name)}
            >
              {a.icon}
            </IconButton>
          ))}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const geoLocIcon = (types: string[]): React.ReactElement | null => {
  //console.log("icon types", types);
  if (types.includes("airport")) return <LocalAirport />;
  else if (types.includes("store")) return <LocalMall />;
  else if (types.includes("administrative_area_level_2"))
    return <LocationCity />;
  return null;
};
class GeoSearch {
  static searchloctimeout: NodeJS.Timeout | undefined = undefined;
  static requestInProgress: boolean = false;
  static waitinput: string | undefined = undefined;

  static service: google.maps.places.AutocompleteService | undefined =
    undefined;
  static sessionToken: google.maps.places.AutocompleteSessionToken | undefined =
    undefined;

  static abort() {
    if (this.searchloctimeout) {
      clearTimeout(this.searchloctimeout);
      this.searchloctimeout = undefined;
    }
    this.waitinput = undefined;
  }
  static doQuery(
    str: string,
    callback: (options: SearchResultEntry[]) => void
  ) {
    this.requestInProgress = true;
    const bounds = mainMap.getBounds();

    if (this.sessionToken === undefined)
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // TODO country should be dynamic.
    var req: google.maps.places.AutocompletionRequest = {
      input: str,
      componentRestrictions: { country: "ee" },
      sessionToken: this.sessionToken,
      bounds: new google.maps.LatLngBounds(
        bounds.getSouthWest(),
        bounds.getNorthEast()
      ),
    };

    if (this.service === undefined)
      this.service = new google.maps.places.AutocompleteService();

    this.service.getPlacePredictions(req, (result, status) => {
      if (this.waitinput !== undefined) {
        const newstr = this.waitinput;
        this.waitinput = undefined;
        this.doQuery(newstr, callback);
        return;
      }
      this.requestInProgress = false;
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        if (status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS)
          console.error("Places api failed: ", status);
        return;
      }
      /*
       interface AutocompletePrediction {
        description: string;


         distance_meters?: number;

         id?: string;
         matched_substrings: PredictionSubstring[];
         place_id: string;
         reference: string;
         structured_formatting: AutocompleteStructuredFormatting;
         terms: PredictionTerm[];
         types: string[];
     }*/
      const r: SearchResultEntry[] | undefined = result?.map((ent) => {
        return {
          id: "g" + ent.place_id,
          group: SearchGroups.GeoLocations,
          renderer: (o, opts, setTrigger: SetTriggerParameter) => {
            return optionRenderer({
              id: o.id,
              icon: geoLocIcon(ent.types),
              primaryText: ent.structured_formatting.main_text,
              secondaryText: ent.structured_formatting.secondary_text,
              opts: opts,
              trigger: setTrigger.setTrigger(() => {
                this.sessionToken = undefined;
                new google.maps.places.PlacesService(
                  document.createElement("div")
                ).getDetails(
                  {
                    placeId: ent.place_id,
                    fields: ["geometry.viewport"],
                  },
                  (details) => {
                    const vp = details?.geometry?.viewport;
                    if (vp !== undefined) {
                      const sw = vp.getSouthWest();
                      const ne = vp.getNorthEast();
                      var bounds = new LatLngBounds(
                        [sw.lat(), sw.lng()],
                        [ne.lat(), ne.lng()]
                      );
                      mainMap.flyToBounds(bounds);
                    }
                  }
                );
                return true;
              }),
              actions: o.actions,
            });
          },
          actions: [],
          trigger: () => {},
          ...ent,
        };
      });
      callback(r || []);
    });
  }

  static search(
    str: string,
    dofast: boolean,
    callback: (options: SearchResultEntry[]) => void
  ) {
    if (this.searchloctimeout) {
      clearTimeout(this.searchloctimeout);
    }

    this.searchloctimeout = setTimeout(
      () => {
        this.searchloctimeout = undefined;

        if (this.requestInProgress) {
          this.waitinput = str;
          return;
        }

        this.doQuery(str, callback);
      },
      dofast ? 400 : 2000
    );
  }
}

const TeamRenderer = (
  props: Pick<BaseSearchResultEntry, "id" | "actions"> &
    TeamResultEntry & { opts: SelectParameters } & SetTriggerParameter
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return optionRenderer({
    id: props.id,
    icon: <LocationSearching />,
    primaryText: props.team.name || "--",
    opts: props.opts,
    trigger: props.setTrigger((forceparams) => {
      const params = forceparams || props.opts.params;
      if (params === undefined) {
        const feedback = flyToTeam(props.tid);
        if (feedback)
          enqueueSnackbar(t(feedback, { name: props.team.name }), {
            variant: "warning",
          });
      } else if (params === "edit") {
        dispatch(setEditTeamId(props.tid));
      } else if (params === "result") {
        dispatch(setViewTeamResult(props.tid));
      }
      return true;
    }),
    actions: props.actions,
  });
};

const searchNutilogi = (str: string): SearchResult => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const isAdmin = state.user.eventAccess;
  var found: SearchResult = [];
  found.push(
    ...Object.entries(state.teamsList)
      .filter(
        ([_, team]) => !team.disabled && team.name?.toLowerCase().includes(str)
      )
      .map(([tid, team]) => {
        return {
          id: "t" + tid,
          group: SearchGroups.Teams,
          tid: tid,
          team: team,
          data: state.teamsData[tid],
          actions: [
            { name: "edit", icon: <Edit /> },
            { name: "result", icon: <Reorder /> },
          ],
          renderer: (o, opts, setTrigger: SetTriggerParameter) => {
            if (o.group === SearchGroups.Teams)
              return (
                <TeamRenderer key={o.id} {...o} opts={opts} {...setTrigger} />
              );
            return null;
          },
          trigger: () => false,
        } as SearchResultEntry;
      })
  );
  // TODO: Should also show when event has ended or kp's are visible in web
  if (isAdmin) {
    found.push(
      ...Object.entries(state.kpList)
        .filter(
          ([kpid, kp]) =>
            ("kp " + kp.nr).includes(str) ||
            state.kpData[kpid]?.desc?.includes(str) ||
            state.kpData[kpid]?.longdesc?.includes(str) ||
            Object.values(state.kpData[kpid]?.responses || {}).find((r) =>
              r.toLowerCase().includes(str)
            )
        )
        .map(([kpid, kp]) => {
          return {
            id: "k" + kpid,
            group: SearchGroups.KPs,
            kpid: kpid,
            kp: kp,
            data: state.kpData[kpid],
            actions: [{ name: "edit", icon: <Edit /> }],
            renderer: (o, opts, setTrigger) => {
              return optionRenderer({
                id: o.id,
                icon: <LocationSearching />,
                primaryText: `KP ${kp.nr}`,
                secondaryText: state.kpData[kpid]?.desc,
                opts: opts,
                actions: o.actions,
                trigger: setTrigger.setTrigger((forceparams) => {
                  const params = forceparams || opts.params;
                  if (params === undefined) {
                    const loc = state.kpData[kpid]?.loc;
                    if (loc) {
                      mainMap.flyTo(loc, 14);
                      mainMap.eachLayer((layer) => {
                        if (
                          layer instanceof Marker &&
                          layer.getLatLng().equals(loc)
                        ) {
                          layer.openPopup();
                        }
                      });
                    }
                  } else {
                    dispatch(setEditKPId(kpid));
                  }
                  return true;
                }),
              });
            },
            trigger: () => false,
          } as SearchResultEntry;
        })
    );
  }
  return found;
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

const renderOptions = (
  options: SearchResult,
  current: number | undefined,
  selectedParam: string | undefined,
  cleanupSearch: () => void
): React.ReactNode[] => {
  var resp: React.ReactNode[] = [];

  var currentgroup = SearchGroups.Unset;
  options.forEach((o, idx) => {
    if (currentgroup !== o.group) {
      currentgroup = o.group;
      if (o.group === SearchGroups.GeoLocations)
        resp.push(
          <ListSubheader key={o.group}>
            {titles[o.group]}
            <ListItemSecondaryAction>
              <img
                src="powered_by_google_on_white.png"
                alt="Powerd by Google"
              />
            </ListItemSecondaryAction>
          </ListSubheader>
        );
      else
        resp.push(
          <ListSubheader key={o.group}>{titles[o.group]}</ListSubheader>
        );
    }
    resp.push(
      o.renderer(
        o,
        { selected: idx === current, params: selectedParam },
        {
          setTrigger: (trigger: (param?: string) => boolean) => {
            o.trigger = (param?: string) => {
              if (trigger(param)) {
                cleanupSearch();
              }
            };
            return o.trigger;
          },
        }
      )
    );
  });
  return resp;
};

const SearchBox = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [currentSelected, setCurrentSelected] = useState<number>();
  const [currentSelectedParameter, setCurrentSelectedParameter] =
    useState<string>();
  const anchorEl = useRef(null);
  const listref = useRef<any>(null);

  const [nutioptions, setNutiOptions] = useState<SearchResult>([]);
  const [geooptions, setGeoOptions] = useState<SearchResult>([]);

  const options = nutioptions.concat(geooptions);
  useEffect(() => {
    if (titles[0] !== "search.teams") return;
    titles = titles.map((e) => t(e as any));
  }, [t]);
  const selectOption = (nextSelected: number) => {
    setCurrentSelected(nextSelected);
    if (listref.current) {
      var element = listref.current.querySelector(
        `[id=${options[nextSelected].id}]`
      );
      if (element.nodeName !== "LI") element = element.parentElement;
      /* This does not work with ListSubHeaders
      element.scrollIntoView({
        scrollMode: "if-needed",
        block: "nearest",
      });*/
      const listboxNode = listref.current.parentElement;
      if (listboxNode.scrollHeight > listboxNode.clientHeight) {
        const scrollBottom = listboxNode.clientHeight + listboxNode.scrollTop;
        const elementBottom = element.offsetTop + element.offsetHeight;
        if (elementBottom > scrollBottom) {
          listboxNode.scrollTop = elementBottom - listboxNode.clientHeight;
        } else if (
          element.offsetTop - element.offsetHeight * 1.3 <
          listboxNode.scrollTop
        ) {
          listboxNode.scrollTop =
            element.offsetTop - element.offsetHeight * 1.3;
        }
      }
    }
  };
  const sideArrowMove = (n: 1 | -1, event: React.KeyboardEvent) => {
    if (currentSelected === undefined) return;
    const actions = options[currentSelected].actions;
    if (actions.length === 0) return;
    if (currentSelectedParameter === undefined) {
      if (n === 1) {
        event.preventDefault();
        setCurrentSelectedParameter(actions[0].name);
      }
    } else {
      const cidx = actions.findIndex(
        (a) => a.name === currentSelectedParameter
      );
      if (n === 1 && cidx === actions.length - 1) return;
      event.preventDefault();
      if (n === -1 && cidx === 0) {
        setCurrentSelectedParameter(undefined);
      } else {
        setCurrentSelectedParameter(actions[cidx + n].name);
      }
    }
  };

  const cleanupSearch = () => {
    setValue("");
    setNutiOptions([]);
    setGeoOptions([]);

    GeoSearch.abort();
    GeoSearch.sessionToken = undefined;
  };

  return (
    <>
      <ClickAwayListener onClickAway={cleanupSearch}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>

          <StyledInputBase
            onKeyDown={(event) => {
              switch (event.key) {
                case "ArrowDown":
                  event.preventDefault();
                  const nextselected =
                    currentSelected === undefined ? 1 : currentSelected + 1;
                  if (nextselected < options.length) {
                    selectOption(nextselected);
                  }
                  break;
                case "ArrowUp":
                  event.preventDefault();
                  if (currentSelected !== undefined && currentSelected > 0) {
                    selectOption(currentSelected - 1);
                  }
                  break;
                case "Enter":
                  if (currentSelected !== undefined) {
                    options[currentSelected].trigger();
                  }
                  break;
                case "ArrowRight":
                  sideArrowMove(1, event);
                  break;
                case "ArrowLeft":
                  sideArrowMove(-1, event);
                  break;
              }
            }}
            ref={anchorEl}
            onChange={(event) => {
              if (event.target.value.trim().length === 0) {
                setNutiOptions([]);
                setGeoOptions([]);
              } else {
                const searchstrnig = event.target.value.toLowerCase();
                const newoptions = searchNutilogi(searchstrnig);
                setCurrentSelectedParameter(undefined);
                if (newoptions.length > 0 || geooptions.length > 0) {
                  setCurrentSelected(0);
                } else {
                  setCurrentSelected(undefined);
                }
                setNutiOptions(newoptions);

                setGeoOptions([]);
                GeoSearch.search(
                  searchstrnig,
                  newoptions.length === 0,
                  (geooptions) => {
                    setGeoOptions(geooptions);
                  }
                );
              }
              setValue(event.target.value);
            }}
            value={value}
            placeholder={t("search.placeholder")}
            inputProps={{ "aria-label": "search", type: "search" }}
          />
          {options.length > 0 && anchorEl.current && (
            <SearchPopper
              open
              anchorEl={anchorEl.current}
              placement="bottom-end"
              modifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 8],
                  },
                },
              ]}
            >
              <Paper
                style={{
                  maxHeight: 400,
                  minWidth: 300,
                  maxWidth: 500,
                  overflow: "auto",
                }}
              >
                <List dense ref={listref}>
                  {renderOptions(
                    options,
                    currentSelected,
                    currentSelectedParameter,
                    cleanupSearch
                  )}
                </List>
              </Paper>
            </SearchPopper>
          )}
        </Search>
      </ClickAwayListener>
    </>
  );
};

export default SearchBox;
