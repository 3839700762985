const hmacSHA384InBase64 = async (message: string, key: string) => {
  // Convert the key and message to Uint8Arrays
  const keyBuffer = new TextEncoder().encode(key);
  const messageBuffer = new TextEncoder().encode(message);

  try {
    // Import the key using SubtleCrypto
    const importedKey = await crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "HMAC", hash: { name: "SHA-384" } },
      false,
      ["sign"]
    );

    // Generate the HMAC-SHA384 digest
    const signature = await crypto.subtle.sign(
      "HMAC",
      importedKey,
      messageBuffer
    );

    const signatureArray = Array.from(new Uint8Array(signature));

    const base64Result = btoa(String.fromCharCode.apply(null, signatureArray));
    return base64Result;
  } catch (error) {
    console.error("Error calculating HMAC-SHA384:", error);
    return null;
  }
};

type TransactionUpdate = {
  clients_id?: number;
};

type TimeParameters = {
  modified_since?: Date;
  start_date?: Date;
  end_date?: Date;
};
export type TransactionEntry = {
  id: number;
  date: string;
  bank_ref_number: string;
  accounts_id: number;
  accounts_dimensions_id: number;
  amount: number;
  base_amount: number;
  cl_currencies_id: string;
  currency_rate: number;
  type: "C" | "D";
  clients_id: number | null;
  description: string;
  status: "CONFIRMED" | "PROJECT";
};
export type ClientEntry = {
  id: number;
  name: string;
  alt_name: string;
  purchase_accounts_id?: number;
  purchase_accounts_dimensions_id?: number;
  cl_purchase_articles_id?: number;
};
export type CreateJournalEntry = {
  effective_date?: string;
  insert_date?: string;
  register_date?: string;
  operations_id?: number;
  operation_type?: "TRANSACTION" | "ENTRY";
  clients_id?: number | null;
  number?: number;
  document_number?: string;
  registered?: boolean;
  is_deleted?: boolean;
  currency_rate?: number;
  cl_currencies_id?: string;
  postings?: {
    accounts_id: number;
    accounts_dimensions_id?: number;
    type: "C" | "D";
    amount: number;
    base_amount: number;
    cl_currencies_id: string;
  }[];
  title?: string;
};
export type JournalEntry = CreateJournalEntry & {
  id: number;
};
export type AccountEntry = {
  id: number;
};
export type BankAccountEntry = {
  id: number;
};
export type AccountDimensionEntry = {
  accounts_id: number;
  title_est?: string;
  cl_currencies_id?: string;
};

export type GetAccountDimensionEntry = {
  id: number;
} & AccountDimensionEntry;

export type RegisterTransactionBody = {
  related_table: string;
  related_id?: number;
  realated_sub_id?: number;
  amount: number;
};
export type PurchaseInvoice = {
  clients_id: number;
  client_name: string;
  number: string;
  create_date: string;
  journal_date: string;
  gross_price?: number;
  payment_type?: string;
  bank_ref_number?: string;
  bank_account_no?: string;
  term_days: number;
  overdue_charge?: number;
  notes?: string;
  paid_in_cash?: boolean;
  cash_accounts_id?: number;
  cash_accounts_dimensions_id?: number;
  liability_accounts_id?: number;
  liability_accounts_dimensions_id?: number;
  cl_currencies_id: string;
  currency_rate?: number;
  cash_payment_date?: string;
  subclients_id?: number;
  status?: "CONFIRMED";
  vat_price?: number;
  base_vat_price?: number;
  items?: {
    cl_purchase_articles_id?: number;
    purchase_accounts_dimensions_id?: number;
    purchase_accounts_id?: number;
    cl_fringe_benefits_id?: number;
    amount?: number;
    unit?: string;
    unit_net_price?: number;
    total_net_price?: number;
    cl_var_articles_id?: number;
    vat_accounts_id?: number;
    vat_accounts_dimensions_id?: number;
    vat_rate_dropdown?: string;
    custom_title: string;
    projects_project_id?: number;
    projects_location_id?: number;
    projects_persion_id?: number;
    reversed_vad_it?: number;
    products_id?: number;
    project_no_vat_gross_price?: number;
    vat_amount: number;
    vat_rate?: number | null;
  }[];
};
class EArveldajaClient {
  ApiKeyId: string;
  ApiKeyPublic: string;
  password: string;
  // IP address must be configured in e-Arveldaja.
  // To configure for all the addresses set 0.0.0.0/0
  // Then in the log it's possible to see what is the actual ip
  baseUrl = "https://kodu.vessmann.ee:9991/earvproxy";
  baseUrl3 = "http://127.0.0.1:5001/nutilogi/europe-west1/api/earvproxy";
  baseUrl2 = "https://europe-west1-nutilogi.cloudfunctions.net/api/earvproxy";
  baseUrl1 = "https://rmp-api.rik.ee";
  version = "/v1";
  constructor(ApiKeyId: string, ApiKeyPublic: string, password: string) {
    this.ApiKeyId = ApiKeyId;
    this.ApiKeyPublic = ApiKeyPublic;
    this.password = password;
  }

  doFetch = async (
    path: string,
    querystr: string,
    method: string,
    body?: Object
  ) => {
    const querytime = new Date().toISOString().substring(0, 19);

    const encstr = await hmacSHA384InBase64(
      this.ApiKeyId + ":" + querytime + ":" + path,
      this.password
    );

    try {
      const r = await fetch(
        this.baseUrl + path + (querystr !== "" ? "?" + querystr : ""),
        {
          method: method,
          headers: {
            "X-Auth-Key": this.ApiKeyPublic + ":" + encstr,
            "X-Auth-Querytime": querytime,
          },
          body: body ? JSON.stringify(body) : undefined,
        }
      );
      return await r.json();
    } catch (err) {
      return console.error(err);
    }
  };
  getPages = async (key: string, path: string, params?: TimeParameters) => {
    let currentdate = new Date().toISOString().substring(0, 10);
    let cld = localStorage.getItem(key + "_modtime");
    let cl = localStorage.getItem(key + "_data");
    let items = [];
    if (cl !== null) {
      items = JSON.parse(cl);
    }
    if (cld !== null && cld === currentdate) {
      console.log("Using only storage value");
      return Promise.resolve(items);
    }
    console.log("What");
    let page = 1;
    let querystr = "";
    let qpairs = [];
    if (params) {
      if (params.start_date) {
        qpairs.push(
          "start_date=" + params.start_date.toISOString().substring(0, 10)
        );
      } else if (params.end_date) {
        qpairs.push(
          "end_date=" + params.end_date.toISOString().substring(0, 10)
        );
      }
    }
    if (cld !== null) {
      qpairs.push("modified_since=" + cld);
    }
    if (qpairs.length > 0) querystr += "&" + qpairs.join("&");

    do {
      const r = await this.doFetch(path, "page=" + page + querystr, "GET");
      items.push(...r.items);
      if (r.current_page < r.total_pages) {
        page++;
      } else {
        localStorage.setItem(key + "_modtime", currentdate);
        localStorage.setItem(key + "_data", JSON.stringify(items));
        return items;
      }
    } while (true);
  };
  getArray = async (key: string, path: string) => {
    let currentdate = new Date().toISOString().substring(0, 10);
    let cl = localStorage.getItem(key + "_data");
    let cld = localStorage.getItem(key + "_modtime");
    if (cld !== null && cl !== null && cld === currentdate) {
      return Promise.resolve(JSON.parse(cl));
    }
    const r = await this.doFetch(path, "", "GET");
    localStorage.setItem(key + "_data", JSON.stringify(r));
    localStorage.setItem(key + "_modtime", currentdate);
    return r;
  };
  clearLocalStorage = () => {
    localStorage.removeItem("earv-clients");
    localStorage.removeItem("earv-transactions");
    localStorage.removeItem("earv-journals");
    localStorage.removeItem("earv-accounts");
    localStorage.removeItem("earv-account_dimensions");
    localStorage.removeItem("earv-purchaseinvoices");
    localStorage.removeItem("earv-bankaccounts");
  };
  GetClients = (): Promise<ClientEntry[]> =>
    this.getPages("earv-clients", "/v1/clients");

  GetTransactions = (): Promise<TransactionEntry[]> => {
    return this.getPages("earv-transactions", "/v1/transactions");
  };
  GetTransaction = (id: number): Promise<TransactionEntry | undefined> => {
    let cl = localStorage.getItem("earv-transactions");
    if (!cl) return Promise.resolve(undefined);
    let clt = JSON.parse(cl) as TransactionEntry[];
    return Promise.resolve(clt.find((t) => t.id === id));
  };
  timeParToQueryStr = (props?: TimeParameters) => {
    return undefined;
  };
  GetJournals = (): Promise<JournalEntry[]> => {
    console.log("In here");
    return this.getPages("earv-journals", "/v1/journals");
  };
  GetPurchaseInvoices = (): Promise<PurchaseInvoice[]> => {
    return this.getPages("earv-purchaseinvoices", "/v1/purchase_invoices");
  };
  GetPurchaseInvoice = (id: number): Promise<PurchaseInvoice> => {
    return this.doFetch(
      this.version + "/purchase_invoices/" + id.toString(),
      "",
      "GET"
    );
  };
  GetJournal = (id: number): Promise<JournalEntry> => {
    return this.doFetch(this.version + "/journals/" + id.toString(), "", "GET");
  };
  GetAccounts = (): Promise<AccountEntry[]> => {
    return this.getArray("earv-accounts", "/v1/accounts");
  };
  GetAccountDimensions = (): Promise<GetAccountDimensionEntry[]> => {
    return this.getArray("earv-account_dimensions", "/v1/account_dimensions");
  };
  UpdateAccountDimensions = (id: number, values: AccountDimensionEntry) => {
    return this.doFetch(
      this.version + "/account_dimensions/" + id.toString(),
      "",
      "PATCH",
      values
    );
  };
  GetBankAccounts = (): Promise<BankAccountEntry[]> => {
    return this.getArray("earv-bankaccounts", "/v1/bank_accounts");
  };
  CreateJournalEntry = (entry: CreateJournalEntry) => {
    return this.doFetch(this.version + "/journals", "", "POST", entry);
  };
  RegisterJournalEntry = (id: number) => {
    return this.doFetch(
      this.version + "/journals/" + id.toString() + "/register",
      "",
      "PATCH"
    );
  };
  CreatePurchaseInvoice = (entry: PurchaseInvoice) => {
    return this.doFetch(this.version + "/purchase_invoices", "", "POST", entry);
  };
  RegisterPurchaseInvoice = (id: number) => {
    return this.doFetch(
      this.version + "/purchase_invoices/" + id.toString() + "/register",
      "",
      "PATCH"
    );
  };
  UpdateTransaction = (id: number, values: TransactionUpdate) => {
    return this.doFetch(
      this.version + "/transactions/" + id.toString(),
      "",
      "PATCH",
      values
    );
  };
  RegisterTransaction = (id: number, body: RegisterTransactionBody) => {
    return this.doFetch(
      this.version + "/transactions/" + id.toString() + "/register",
      "",
      "PATCH",
      body
    );
  };
  UpdateJournalEntry = (id: number, values: CreateJournalEntry) => {
    return this.doFetch(
      this.version + "/journals/" + id.toString(),
      "",
      "PATCH",
      values
    );
  };
}

export default EArveldajaClient;
